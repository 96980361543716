import React from "react";
import { ModalTemplate, DoneButton, dangerModalIcon } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { useIntl } from "react-intl";
import { OVERLAPPING_SHIPPING_PLAN_SAME_SELLER_LABELS } from "./StartProcessingOverlappingShippingPlanSameSellerModal.labels";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { Text } from "@deliverr/ui";
import { take } from "lodash";
import { Pallet } from "@deliverr/crossdock-service-client";

const PENDING_PALLETS_TO_LIMIT = 2;

interface StartProcessingOverlappingShippingPlanSameSellerModalProps {
  lastShippingPlanId: string;
  pendingPallets: Pallet[];
  shippingPlanId: string;
  sellerId: string;
}

export const StartProcessingOverlappingShippingPlanSameSellerModal: React.FC<
  StartProcessingOverlappingShippingPlanSameSellerModalProps
> = ({ pendingPallets, shippingPlanId, sellerId, lastShippingPlanId }) => {
  const { hideAllModals } = useCrossdockModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(OVERLAPPING_SHIPPING_PLAN_SAME_SELLER_LABELS.title)}
      text={formatMessage(OVERLAPPING_SHIPPING_PLAN_SAME_SELLER_LABELS.message, {
        shippingPlanId,
        lastShippingPlanId,
        sellerId,
      })}
      content={
        <>
          <Text textAlign={TextAlign.center} appearance="INFO">
            {formatMessage(OVERLAPPING_SHIPPING_PLAN_SAME_SELLER_LABELS.pendingPalletMessage, {
              numPendingPallets: pendingPallets.length,
            })}
          </Text>
          {take(pendingPallets, PENDING_PALLETS_TO_LIMIT).map((pallet, index) => (
            <Text key={pallet.id} textAlign={TextAlign.start} appearance="INFO">
              {index + 1}. {pallet.barcode}
            </Text>
          ))}
        </>
      }
      iconProps={dangerModalIcon}
    >
      <DoneButton onClick={hideAllModals} />
    </ModalTemplate>
  );
};
