import { FC } from "react";
import { MissingLabelModal } from "./MissingLabelModal";
import { MissingLabelPrintingCaseLabelModal } from "./MissingLabelPrintingCaseLabelModal";
import { MissingLabelUnexpectedSkuModal } from "./MissingLabelUnexpectedSkuModal";
import { MissingLabelUnsolvableBoxModal } from "./MissingLabelUnsolvableBoxModal";
import { MissingLabelInputBarcodeModal } from "./MissingLabelInputBarcodeModal";
import { MissingLabelQuitModal } from "./MissingLabelQuitModal";

export const missingLabelModalMap: Record<MissingLabelModal, FC<any>> = {
  [MissingLabelModal.MISSING_LABEL_PRINTING_LABELS]: MissingLabelPrintingCaseLabelModal,
  [MissingLabelModal.MISSING_LABEL_UNEXPECTED_SKU]: MissingLabelUnexpectedSkuModal,
  [MissingLabelModal.MISSING_LABEL_UNSOLVABLE_BOX]: MissingLabelUnsolvableBoxModal,
  [MissingLabelModal.MISSING_LABEL_INPUT_BARCODE_MODAL]: MissingLabelInputBarcodeModal,
  [MissingLabelModal.MISSING_LABEL_QUIT]: MissingLabelQuitModal,
};
