import styled from "@emotion/styled";
import { Button, Card, Stack, Title, LocalizedMessage, ThemeProps } from "@deliverr/ui";
import React from "react";
import { Link } from "react-router-dom";
import { ScanPath } from "crossdock/base/routes";
import { stringify } from "qs";
import { toLinkUrl } from "@deliverr/ui-facility/lib-facility/utils";

interface Props {
  title: LocalizedMessage;
  paths: Array<{
    text: LocalizedMessage;
    path: ScanPath;
    query?: Record<string, string>; // query string params
  }>;
}

const TallCard = styled(Card)<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing.S4} 0;
  text-align: center;
  height: 80vh;
`
);

const InlineStack = styled(Stack)`
  display: inline-flex;
  width: 85%;
`;

const BlockLink = styled(Link)`
  width: 100%;
`;

const PathSelectTitle = styled(Title)<ThemeProps>(
  ({ theme }) => `
  padding: 0 ${theme.spacing.S5} ${theme.spacing.S4} ${theme.spacing.S5};
  margin-bottom: ${theme.spacing.S4};
  text-align: left;
`
);

export const PathSelectPage: React.FC<Props> = ({ title, paths }) => {
  return (
    <TallCard as="section" size="LG">
      <PathSelectTitle as="h2" displayAs="h3">
        {title}
      </PathSelectTitle>
      <InlineStack gap="S4" center>
        {paths.map(({ text, path, query }) => {
          const search = query ? stringify(query) : "";
          return (
            <BlockLink to={{ pathname: path, search }} key={toLinkUrl(path, search)}>
              <Button block>{text}</Button>
            </BlockLink>
          );
        })}
      </InlineStack>
    </TallCard>
  );
};
