import React from "react";
import { useSetRecoilState } from "recoil";
import { useIntl } from "react-intl";

import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { createScanPoCard, createScanTrackingCard } from "./MissingLabelCardCreators";
import { useResetMissingLabelState } from "./useResetMissingLabelState";
import { InboundShipMethod } from "@deliverr/ui-facility/lib-facility/types";
import { headerTitleState } from "crossdock/base/headerTitleState";
import { ROUTE_LABELS } from "crossdock/base/routes/routes.labels";
import { ScanPathName } from "crossdock/base/routes/ScanPath";
import { getShippingMethod } from "./utils/getShippingMethod";

const getMountCardData = () => {
  const shippingMethod = getShippingMethod();
  return shippingMethod === InboundShipMethod.SMALL_PARCEL ? createScanTrackingCard({}) : createScanPoCard({});
};

export const MissingLabel: React.FC = () => {
  const { formatMessage } = useIntl();
  const { resetFlow } = useScanFlow();
  const { resetMissingLabelState } = useResetMissingLabelState();

  const setHeaderTitle = useSetRecoilState(headerTitleState);

  const onMount = () => {
    resetMissingLabelState();
    const shippingMethod = getShippingMethod();
    if (shippingMethod === InboundShipMethod.SMALL_PARCEL) {
      setHeaderTitle(formatMessage(ROUTE_LABELS[ScanPathName.MISSING_LABEL_SPD]));
    }
  };

  const onUnmount = () => {
    resetMissingLabelState();
    resetFlow();
  };

  return <ScanFlowV2 onMount={onMount} onMountCardData={getMountCardData()} onUnmount={onUnmount} />;
};
