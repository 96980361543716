import { Button } from "@deliverr/ui";
import React from "react";
import { ScanPath } from "crossdock/base/routes";
import { useCrossdockModal, GenericModal } from "crossdock/common/modal";
import { ModalTemplate } from "@deliverr/ui-facility";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";

export const ConnectToPrinterWarning: React.FC = () => {
  const router = useRouter();
  const { hideModal } = useCrossdockModal();

  const onClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    hideModal(GenericModal.CONNECT_TO_PRINTER_WARNING);
    router.push(ScanPath.ManagePrinters);
  };

  return (
    <ModalTemplate
      title="Connect to a Printer"
      text="A printer is not connected to this scanner. Connect to a printer before scanning"
    >
      <Button type="button" onClick={onClick}>
        Manage Printers
      </Button>
    </ModalTemplate>
  );
};
