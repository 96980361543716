import { InboundClient, LABEL_DPI, TransferPallet, TransferPalletActionSource } from "@deliverr/legacy-inbound-client";
import { useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";

import { ScanPath } from "crossdock/base/routes";
import { log, logStart, logError, isZebraScanner, printPdf } from "@deliverr/ui-facility/lib-facility/utils";
import { crossdockClient, inboundClient } from "crossdock/base/Clients";
import { InvalidDataError } from "crossdock/common/errors";
import { dpiState, usePrintZpl } from "@deliverr/ui-facility/lib-facility/printer";
import {
  createLabelLoadingState,
  selectedWarehouseIdsState,
  palletLabelsPerWarehouseState,
  selectedDestinationsState,
  selectedPalletBuildStationBarcodesState,
  SelectedDestinationsState,
} from "./TransfersDestinationsState";
import { duplicateEach } from "@deliverr/ui-facility/lib-facility/utils/array/duplicateEach";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";

interface CreatePalletProps {
  fromWarehouseId?: string;
  toWarehouseId?: string;
  palletBuildStationBarcode?: string;
}

export async function createPallet({
  fromWarehouseId,
  toWarehouseId,
  palletBuildStationBarcode,
}: CreatePalletProps): Promise<TransferPallet> {
  const ctx = logStart({ fn: "createPallet", fromWarehouseId, toWarehouseId, palletBuildStationBarcode });
  const actionOptions = { actionSource: TransferPalletActionSource.TRANSFERS_CREATE_PALLET };

  try {
    if (!fromWarehouseId) {
      throw new InvalidDataError("missing from warehouse id and pallet build station barcode");
    }
    return await crossdockClient.createOutboundPallet({
      fromWarehouseId,
      toWarehouseId,
      palletBuildStationBarcode,
      actionOptions,
    });
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
}

async function createPalletWithBuildStation(
  fromWarehouseId: string,
  palletBuildStationBarcode: string
): Promise<TransferPallet> {
  const palletBuildStation = await crossdockClient.getPalletBuildStationByBarcode(
    fromWarehouseId,
    palletBuildStationBarcode
  );
  const pallet = await createPallet({
    fromWarehouseId,
    palletBuildStationBarcode,
    toWarehouseId: palletBuildStation.fcId,
  });

  return pallet;
}

async function generatePallets(
  palletLabelsPerWarehouse: number,
  fromWarehouseId: string,
  selectedDestinations: SelectedDestinationsState
): Promise<TransferPallet[]> {
  const { warehouseIds, palletBuildStationBarcodes } = selectedDestinations;
  return await Promise.all([
    ...duplicateEach(palletLabelsPerWarehouse, [...palletBuildStationBarcodes]).map(
      async (palletBuildStationBarcode) =>
        await createPalletWithBuildStation(fromWarehouseId, palletBuildStationBarcode)
    ),
    ...duplicateEach(palletLabelsPerWarehouse, [...warehouseIds]).map(
      async (toWarehouseId) =>
        await createPallet({
          fromWarehouseId,
          toWarehouseId,
        })
    ),
  ]);
}

export async function generatePalletLabels(
  inbCLient: InboundClient,
  palletId: number,
  dpi: LABEL_DPI,
  printZpl: (zpl: string) => Promise<void>,
  fromWarehouseId?: string
) {
  const ctx = logStart({ fn: "generatePalletLabels", palletId, dpi });

  try {
    if (!fromWarehouseId) {
      throw new InvalidDataError("missing from warehouse id");
    }

    const reqOptions = { actionSource: TransferPalletActionSource.TRANSFERS_CREATE_PALLET };
    if (isZebraScanner) {
      log(ctx, "printing pallet labels zpl");
      const zplLabels = await inbCLient.generatePalletLabelsZpl(palletId, dpi, fromWarehouseId!, reqOptions);
      for (const zpl of zplLabels) {
        await printZpl(zpl);
      }
    } else {
      log(ctx, "printing pallet labels pdf");
      const pdfBlob = await inbCLient.generatePalletLabelsPdf(palletId, fromWarehouseId!, reqOptions);
      printPdf(pdfBlob);
    }
  } catch (err) {
    logError(ctx, err);
    throw err;
  }
}

export function useFulfillmentCenterSelect(arePalletBuildStations = false) {
  const router = useRouter();
  const { printZpl } = usePrintZpl();
  const dpi = useRecoilValue(dpiState);
  const { warehouseId: fromWarehouseId } = useRecoilValue(userState);
  const resetDestination = useResetRecoilState(selectedDestinationsState);
  const [createPalletLoading, setCreatePalletLoading] = useRecoilState(createLabelLoadingState);
  const selectedDestinations = useRecoilValue(selectedDestinationsState);
  const [selectedDestinationsByType, setSelectedDestinationsByType] = useRecoilState(
    arePalletBuildStations ? selectedPalletBuildStationBarcodesState : selectedWarehouseIdsState
  );
  const [palletLabelsPerWarehouse, setPalletLabelsPerWarehouseState] = useRecoilState(palletLabelsPerWarehouseState);

  async function onFulfillmentCenterCheck(destinationId: string, checked: boolean): Promise<void> {
    if (checked && !selectedDestinationsByType.has(destinationId)) {
      setSelectedDestinationsByType(new Set([...selectedDestinationsByType, destinationId]));
    } else if (!checked && selectedDestinationsByType.has(destinationId)) {
      const draftData = new Set([...selectedDestinationsByType]);
      draftData.delete(destinationId);
      setSelectedDestinationsByType(draftData);
    }
  }

  async function createPalletsAndPrintLabels() {
    try {
      setCreatePalletLoading(true);

      const pallets = await generatePallets(Number(palletLabelsPerWarehouse), fromWarehouseId, selectedDestinations);
      for (const pallet of pallets) {
        await generatePalletLabels(inboundClient, pallet.id, dpi, printZpl, fromWarehouseId);
      }

      resetDestination();
      setPalletLabelsPerWarehouseState("1");
      router.push(ScanPath.Transfers);
    } catch (err) {
      log({ fn: "createPalletsAndPrintLabels" }, "error creating pallet or printing label. stopping.");
    } finally {
      setCreatePalletLoading(false);
    }
  }

  return {
    onFulfillmentCenterCheck,
    createPalletsAndPrintLabels,
    createPalletLoading,
  };
}
