import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "crossdock.prepRequiredModal.title",
    defaultMessage: "Prep required",
  },
  message: {
    id: "crossdock.prepRequiredModal.message",
    defaultMessage: "Apply printed prep label to box. Then, take all boxes from this PO # to prep area.",
  },
  poNumLabel: {
    id: "crossdock.prepRequiredModal.poNumLabel",
    defaultMessage: "PO # {poNum}",
  },
  notification: {
    id: "crossdock.prepRequiredModal.notification",
    defaultMessage: "Prep label printed",
  },
  notificationError: {
    id: "crossdock.prepRequiredModal.notification.error",
    defaultMessage: "Failed to print Prep label: {errorCode}",
  },
});
