import React from "react";

import { DoneScanningButton } from "@deliverr/ui-facility";
import { useMissingLabelDoneScanningButton } from "./useMissingLabelDoneScanningButton";

export const MissingLabelDoneScanningButton: React.FC = () => {
  const { onButtonClick, disabled } = useMissingLabelDoneScanningButton();

  return <DoneScanningButton onClick={onButtonClick} disabled={disabled} />;
};
