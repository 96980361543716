import { commonUploadMessages } from "@deliverr/ui-facility/lib-facility/labels";
import React from "react";

import { createUploadProductCard } from "../BoxAuditCardCreators";
import { BoxAuditUnknownBarcodeNonComplianceField } from "../BoxAuditTypes";
import { BoxAuditUnknownBarcodeUploadCard } from "./BoxAuditUnknownBarcodeUploadCard";

export const BoxAuditUnknownBarcodeSkuBarcodeUploadCard: React.FC = () => (
  <BoxAuditUnknownBarcodeUploadCard
    title={commonUploadMessages.takeAPhotoBarcodeTitle}
    uploads={[
      {
        iconText: commonUploadMessages.takePhoto,
        photoType: "barcodePicture",
        iconId: "barcode",
        field: BoxAuditUnknownBarcodeNonComplianceField.BARCODE_PHOTO,
      },
    ]}
    nextCard={createUploadProductCard({})}
  />
);
