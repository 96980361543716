import { useRecoilValue } from "recoil";
import { log, logStart, logError, isZebraScanner, printPdf } from "@deliverr/ui-facility/lib-facility/utils";
import { dpiState, usePrintZpl } from "@deliverr/ui-facility/lib-facility/printer";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { ScanResponseType, DestinationScanLabelData } from "@deliverr/commons-clients";
import { crossdockClient } from "crossdock/base/Clients";

enum CrossdockLabelDestination {
  BOX_BREAK_CONTAINER = "BOX BREAK CONTAINER",
  BOX_BREAK = "BOX BREAK",
  NON_COMPLIANCE = "SCAN BOX",
}

export function usePrintCrossdockLabel() {
  const dpi = useRecoilValue(dpiState);
  const { printZpl } = usePrintZpl();
  const { warehouseId: crossdockWarehouseId } = useRecoilValue(userState);

  async function printCrossdockLabel({
    cdsku,
    destination,
    destinationWarehouseId,
    reprint,
  }: Pick<DestinationScanLabelData, "cdsku" | "destination" | "destinationWarehouseId" | "reprint">): Promise<void> {
    const ctx = logStart({ fn: "printCrossdockLabel", cdsku, destination, destinationWarehouseId, reprint });
    log(ctx, "getting label data");

    try {
      if (isZebraScanner) {
        const zpl = await crossdockClient.generateCrossdockLabelZpl({
          cdsku,
          destination,
          reprint,
          crossdockWarehouseId,
          destinationWarehouseId,
          dpi,
        });
        log(ctx, "retrieved label zpl");
        await printZpl(zpl);
      } else {
        const pdf = await crossdockClient.generateCrossdockLabelPdf({
          cdsku,
          destination,
          reprint,
          crossdockWarehouseId,
          destinationWarehouseId,
        });
        log(ctx, "retrieved label pdf");
        printPdf(pdf);
      }
      log(ctx, "printed label");
    } catch (err) {
      logError(ctx, err);
    }
  }

  // For non-compliance resolution. Prompts user to take box to PANDA.
  const printNcCrossdockLabel = async (cdsku: string, reprint?: boolean) => {
    await printCrossdockLabel({
      cdsku,
      reprint,
      destination: CrossdockLabelDestination.NON_COMPLIANCE,
    });
  };

  // Labels for empty boxes to be filled with box break output.
  const printBoxBreakContainerLabel = async (cdsku: string, reprint?: boolean) => {
    await printCrossdockLabel({
      cdsku,
      reprint,
      destination: CrossdockLabelDestination.BOX_BREAK_CONTAINER,
    });
  };

  // Plain old crossdock labels from audits and manual destination scans.
  const printScanCrossdockLabel = async (
    cdsku: string,
    action: ScanResponseType,
    destinationWarehouse?: string,
    reprint?: boolean
  ) => {
    await printCrossdockLabel({
      cdsku,
      reprint,
      destination: action === ScanResponseType.BOX_BREAK ? CrossdockLabelDestination.BOX_BREAK : destinationWarehouse,
      destinationWarehouseId: destinationWarehouse,
    });
  };

  return { printScanCrossdockLabel, printNcCrossdockLabel, printBoxBreakContainerLabel };
}
