import { defineMessages } from "react-intl";

export const UNEXPECTED_SKU_NO_CAPABLE_WAREHOUSES_LABELS = defineMessages({
  title: {
    id: "crossdock.boxBreakUnexpectedSkuNoCapableWarehouses.title",
    defaultMessage: "No Capable Warehouses",
  },
  message: {
    id: "crossdock.boxBreakUnexpectedSkuNoCapableWarehouses.message",
    defaultMessage: "No capable warehouses available for this SKU. Eject box and audit.",
  },
});
