module.exports = {
  getNamespace() {
    return {
      get() {},
      set() {},
      bindEmitter() {},
      bind() {},
    };
  },
};
