import { useRecoilState, useResetRecoilState } from "recoil";
import { CrossdockError } from "@deliverr/commons-clients";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { boxBreakStationIdState } from "../station/stationState";
import { log, logStart, logError } from "@deliverr/ui-facility/lib-facility/utils";
import { useMount } from "react-use";
import { useResetCurrentBreakState } from "../BoxBreak/hooks";
import { useResetStationState } from "../station/useResetStationState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useConnectToPrinterWarning } from "crossdock/common/zebra";
import { crossdockClient } from "crossdock/base/Clients";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { BOX_BREAK_LOGIN_LABELS as labels } from "./BoxBreakLoginCard.labels";
import { useIntl } from "react-intl";
import { isValidBoxBreakStationBarcode } from "@deliverr/crossdock-service-client";
import { toScanBox } from "../station/BoxBreakStation.transitions";
import { useBoxBreakChute } from "../BoxBreak/hooks/useBoxBreakChute";

// "login" here is choosing a box break station
export function useBoxBreakLogin() {
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [boxBreakStationId, setBoxBreakStationId] = useRecoilState(boxBreakStationIdState);
  const resetBoxBreakStationId = useResetRecoilState(boxBreakStationIdState);
  const { resetCurrentBreak } = useResetCurrentBreakState();
  const resetStation = useResetStationState();
  const { handleUnknownError, transition } = useScanFlow();
  const { verifyAndShowPrinterConnected } = useConnectToPrinterWarning(true);
  const { formatMessage } = useIntl();
  const { refreshChutes } = useBoxBreakChute();

  // prevent old values from persisting after switching to another page then going back to break
  useMount(() => {
    resetCurrentBreak();
    resetStation();
  });

  const submitBoxBreakLogin = async (newBoxBreakStationId: string): Promise<void> => {
    const ctx = logStart({ fn: "submitBoxBreakLogin", stationId: newBoxBreakStationId });

    if (!verifyAndShowPrinterConnected()) {
      log(ctx, "printer not yet connected");
      return;
    }

    if (!isValidBoxBreakStationBarcode(newBoxBreakStationId)) {
      log(ctx, "invalid box break station id");
      inputError(formatMessage(labels.invalidStationId), resetBoxBreakStationId);
      return;
    }

    try {
      const chutes = await crossdockClient.getChutes(newBoxBreakStationId);
      if (chutes.length === 0) {
        log(ctx, "box break station has no chutes");
        inputError(formatMessage(labels.stationNotSetup), resetBoxBreakStationId);
        return;
      }
      await refreshChutes(chutes);

      transition(toScanBox());
    } catch (err) {
      if (err.code === CrossdockError.BOX_BREAK_STATION_NOT_FOUND) {
        logError(ctx, err);
        inputError(formatMessage(labels.stationNotFound), resetStation);
      } else {
        handleUnknownError(ctx, err, resetStation);
      }
    }
  };

  const onBoxBreakStationChange = onScannerInputChange(
    boxBreakStationId,
    setBoxBreakStationId,
    submitBoxBreakLogin,
    "upper"
  );

  return {
    submitBoxBreakLogin: resetErrorOnExecution(submitBoxBreakLogin),
    onBoxBreakStationChange,
    boxBreakStationId,
    errorMessage: inputErrorMessage,
  };
}
