import * as React from "react";
import { DefaultTheme, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useSetRecoilState } from "recoil";

import { SquareIcon } from "crossdock/common/components";
import { BoxBreakChuteContainerInfo } from "./BoxBreakChuteContainerInfo";
import { selectedSidebarChuteIdState } from "../boxBreakSidebarState";

const ChutesOverviewItemContainer = styled.div<{ activeChute: boolean }, DefaultTheme>(
  ({ theme, activeChute }) => `
  width: 100%;
  border-radius: ${theme.border.radius.R5};
  display: flex;
  background-color: ${activeChute ? theme.colors.BLUE["500"] : theme.colors.NEUTRAL["40"]};
  padding: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S4};
  align-items: center;

  p {
    ${activeChute ? `color: ${theme.colors.NEUTRAL["00"]}` : ""};
  }

  :hover {
    cursor: pointer;
  }
`
);

const ChutesOverviewItemInfo = styled.div<ThemeProps>`
  margin-left: ${({ theme }) => theme.spacing.S4};
`;

export interface BoxBreakChutesOverviewItemProps {
  activeChute: boolean;
  chuteCdsku: string;
  chuteBarcode: string;
  chuteUnitsTotal: number;
  chuteSKUsTotal: number;
}

export const BoxBreakChutesOverviewItem: React.FC<BoxBreakChutesOverviewItemProps> = (props) => {
  const { activeChute, chuteCdsku, chuteBarcode, chuteUnitsTotal = 0, chuteSKUsTotal = 0 } = props;
  const setOpenChute = useSetRecoilState(selectedSidebarChuteIdState);
  return (
    <ChutesOverviewItemContainer
      data-testid={chuteBarcode.slice(-1)}
      activeChute={activeChute}
      onClick={() => {
        setOpenChute(chuteBarcode);
      }}
    >
      <SquareIcon length="3rem" textIcon={chuteBarcode.slice(-1)} accentColor={"BLUE"} />
      <ChutesOverviewItemInfo>
        <BoxBreakChuteContainerInfo
          overview
          chuteCdsku={chuteCdsku}
          chuteUnitsTotal={chuteUnitsTotal}
          chuteSKUsTotal={chuteSKUsTotal}
        />
      </ChutesOverviewItemInfo>
    </ChutesOverviewItemContainer>
  );
};
