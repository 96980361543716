import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetRecoilState } from "recoil";
import { palletIdState, trackingCodeState } from "../ShipPalletState";
import { createShipPalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";

export function useShipPalletButton() {
  const { addFlowCard, resetFlowCards, hideAllFlowButtons } = useScanFlow();
  const resetPalletId = useResetRecoilState(palletIdState);
  const resetTrackingCode = useResetRecoilState(trackingCodeState);

  function onShipPalletButtonClick() {
    resetFlowCards();
    resetPalletId();
    resetTrackingCode();
    addFlowCard(createShipPalletScanPalletCard({}));
    hideAllFlowButtons();
  }

  return { onShipPalletButtonClick };
}
