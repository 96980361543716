import React from "react";
import { ModalTemplate, warningModalIcon, DoneButton } from "@deliverr/ui-facility";
import { SEND_TO_FULFILLMENT_LABELS } from "./StartProcessingSendToFulfillmentModal.labels";
import { useCrossdockModal } from "crossdock/common/modal";
import { useIntl } from "react-intl";

export const StartProcessingSendToFulfillmentModal: React.FC = () => {
  const { hideAllModals } = useCrossdockModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(SEND_TO_FULFILLMENT_LABELS.title)}
      text={formatMessage(SEND_TO_FULFILLMENT_LABELS.message)}
      iconProps={warningModalIcon}
    >
      <DoneButton onClick={hideAllModals} />
    </ModalTemplate>
  );
};
