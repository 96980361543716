import { CrossdockError, ScanResponseType, ScanResponse, ScanResponseError } from "@deliverr/commons-clients";
import { InboundError } from "@deliverr/legacy-inbound-client";
import {
  createUnknownInputCard,
  createErrorTextCard,
  createUnknownErrorCard,
} from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types";
import { UnrecognizedValueError } from "crossdock/common/errors";
import {
  createSortByWarehouseCard,
  createSortByBoxBreakCard,
  createSortPalletToMilkRunErrorCard,
} from "crossdock/common/flow/scanFlowCardCreators";

export function createScanResponseCard(cdsku: string, scanResponse: ScanResponse): FlowCardData<any> {
  switch (scanResponse.action) {
    case ScanResponseType.SORT_TO_PALLET_BUILD_STATION:
      return createSortByWarehouseCard({ cdsku, warehouseId: scanResponse.palletBuildStationBarcode });
    case ScanResponseType.SORT_TO_WAREHOUSE:
      return createSortByWarehouseCard({ cdsku, warehouseId: scanResponse.transferToWarehouseId });
    case ScanResponseType.BOX_BREAK:
      return createSortByBoxBreakCard({ cdsku });
    default:
      throw new UnrecognizedValueError("Unrecognized scan response type");
  }
}

// handles passthrough errors originating from inbound service
export function createInboundErrorCard(cdsku: string, code: string): FlowCardData<any> | undefined {
  const defaultMessage = "Put this box in Problem Area and contact Deliverr.";

  switch (code) {
    case InboundError.CDSKU_TRANSFER_CONFLICT:
      return createErrorTextCard({
        value: cdsku,
        title: "Transfer Conflict",
        message: defaultMessage,
      });
    case InboundError.CROSS_DOCK_STATUS_MISMATCH:
      return createErrorTextCard({
        value: cdsku,
        title: "Cross-Dock Status Mismatch",
        message: defaultMessage,
      });
    case InboundError.TOO_CLOSE_TO_CUTOFF_TO_ADD_PACKAGE:
      return createErrorTextCard({
        value: cdsku,
        title: "Too Close to Cutoff",
        message:
          "You cannot scan CDSKUs right now because today's transfers are closed. " +
          "If you want to scan CDSKUs for tomorrow's transfers, please try again in 10 minutes.",
      });
  }
}

export function createCrossdockErrorCard(cdsku: string, code: string): FlowCardData<any> | undefined {
  switch (code) {
    case CrossdockError.NO_CAPABLE_WAREHOUSE_TO_FORCE_TRANSFER:
      return createErrorTextCard({
        value: cdsku,
        title: "No Capable Warehouse",
        message: "No warehouse capable of receiving every product in this box exists.",
      });
  }
}

// only errors where we have instructions for a user should be shown
export function createScanErrorCard(cdsku: string, err: any): FlowCardData<any> {
  const errorStatus = err?.response?.status;

  if (errorStatus === 504) {
    return createUnknownInputCard({
      value: cdsku,
      message: "A network error has occurred. Please check network connection, then try again.",
    });
  }

  const errorCode = err?.code || err?.response?.data?.error?.code;

  if (errorCode) {
    const inboundErrorCard = createInboundErrorCard(cdsku, errorCode);
    if (inboundErrorCard) {
      return inboundErrorCard;
    }

    const crossdockErrorCard = createCrossdockErrorCard(cdsku, errorCode);
    if (crossdockErrorCard) {
      return crossdockErrorCard;
    }

    if (
      [
        CrossdockError.VALIDATION_ERROR,
        CrossdockError.CROSS_DOCK_PRODUCT_NOT_FOUND,
        InboundError.CDSKU_NOT_FOUND,
        CrossdockError.UNRECOGNIZED_BARCODE_ERROR,
      ].includes(errorCode)
    ) {
      return createUnknownInputCard({
        value: cdsku,
        message: "Does not appear to be a valid box barcode.",
      });
    }
  }

  return createUnknownErrorCard({ value: cdsku });
}

export function createHubScanErrorCard(palletId: string, err: any): FlowCardData<any> {
  const errorCode = err.code;

  switch (errorCode) {
    case ScanResponseError.PALLET_SCANNED_AT_SOURCE_HUB:
      return createSortPalletToMilkRunErrorCard({
        palletId,
        title: "Not allowed",
        message: "You cannot Destination Scan a pallet created at your own facility.",
      });
    case ScanResponseError.WAREHOUSE_ID_NOT_FOUND_AT_HUB:
      return createSortPalletToMilkRunErrorCard({
        palletId,
        title: "Unrouted pallet",
        message:
          "This pallet was not routed by Deliverr. Set this pallet aside and Deliverr will contact you when it is routed.",
      });
    case ScanResponseError.LABEL_ID_NOT_FOUND:
      return createSortPalletToMilkRunErrorCard({
        palletId,
        title: "Unknown input",
        message: "Does not appear to be a valid box or pallet barcode.",
      });
    case ScanResponseError.PALLET_NO_DESTINATION:
      return createSortPalletToMilkRunErrorCard({
        palletId,
        title: "No destination",
        message: "Please contact Deliverr about this pallet.",
      });
  }

  return createUnknownErrorCard({ value: palletId });
}
