import {
  DamagedProductUnifiedRequest,
  DamageType,
} from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";
import { LogContext } from "@deliverr/ui-logging";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useAsyncFn } from "react-use";
import {
  damagedProductNcServiceErrorMap,
  damagedProductValidationConfig as validation,
} from "@deliverr/ui-facility/lib-facility/flow/forms/DamagedProductForm/DamagedProductFormConfig";

import { DamagedProductField } from "@deliverr/ui-facility/lib-facility/flow/forms/DamagedProductForm/DamagedProductForm.types";
import { ComposeUseDamagedProductFormProps } from "crossdock/common/flow/forms/DamagedProductForm/ComposeUseDamagedProductFormProps";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { composeUseWarehouseEmailState } from "crossdock/common/flow/forms/DamagedProductForm/composeUseWarehouseEmailState";
import { useScanner } from "@deliverr/ui-facility/lib-facility/hooks/useScanner";
import { minASNLength } from "@deliverr/ui-facility/lib-facility/utils/config";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";

function parseServiceErrors(error: Partial<Error> & Partial<{ subcode: string }>) {
  if (error.subcode && damagedProductNcServiceErrorMap[error.subcode]) {
    return damagedProductNcServiceErrorMap[error.subcode];
  }

  return damagedProductNcServiceErrorMap.unknown;
}

// sorted in order appearing in form
const SORTED_SCANNER_INPUT_FIELDS = [DamagedProductField.BARCODE, DamagedProductField.ASN, DamagedProductField.CDSKU];

const getScannerInputFieldPairs = (fields: Record<string, string>): Array<[DamagedProductField, string]> =>
  SORTED_SCANNER_INPUT_FIELDS.map((field) => [field, fields[field]]);

interface UseDamagedProductFormProps {
  onFailure?: (ctx: LogContext, err: any) => void;
}

export const composeUseDamagedProductForm =
  ({
    warehouseId,
    defaultShouldShowPOField,
    onSubmitSuccess,
    isCaseEnabled,
    getWarehouseEmailFn,
    getNcAsnTypeFn,
    createSelfServeReceivingDamageFn,
  }: ComposeUseDamagedProductFormProps) =>
  ({ onFailure }: UseDamagedProductFormProps) => {
    const useWarehouseEmailState = composeUseWarehouseEmailState(getWarehouseEmailFn);
    const warehouseEmailAsyncState = useWarehouseEmailState(warehouseId);
    const { match, push } = useRouter();
    const formHandler = useForm({
      mode: "onBlur",
    });
    const formValues = formHandler.getValues();
    const [shouldShowPOField, setShouldShowPOField] = useState<boolean>(defaultShouldShowPOField ?? false);

    const watchDamageType: DamageType = formHandler.watch(DamagedProductField.DAMAGE_TYPE);

    useScanner({
      onChange: (newValue: string) => {
        const values = formHandler.getValues();
        const emptyFieldPair = getScannerInputFieldPairs(values).find(([, value]) => value.length === 0);

        if (emptyFieldPair) {
          const fieldKey = emptyFieldPair[0];
          formHandler.setValue(fieldKey, newValue);
          formHandler.trigger(fieldKey).catch((err) => {
            throw err;
          });
        }
      },
    });

    const [asnTypeState, getAsnType] = useAsyncFn(getNcAsnTypeFn);

    const [submitState, submitData] = useAsyncFn(createSelfServeReceivingDamageFn);

    // watch for changes to ASN/PO input, and validate any time it changes
    useEffect(() => {
      const newAsn = formValues[DamagedProductField.ASN];
      const hasASNLength = newAsn?.length >= minASNLength;
      if (hasASNLength) {
        getAsnType(newAsn).catch((err) => {
          throw err;
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues[DamagedProductField.ASN]]);

    useEffect(() => {
      if (isCaseEnabled) {
        const caseQty = formValues[DamagedProductField.CASE_QUANTITY];
        const unitsPerCase = formValues[DamagedProductField.UNITS_PER_CASE];
        if (caseQty && unitsPerCase) {
          formHandler.setValue(DamagedProductField.QUANTITY, caseQty * unitsPerCase);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formValues[DamagedProductField.CASE_QUANTITY], formValues[DamagedProductField.UNITS_PER_CASE]]);

    // watch for ASN/PO validation errors, and display any that occur
    useEffect(() => {
      if (asnTypeState.error) {
        const [errFieldKey, errMsg] = parseServiceErrors(asnTypeState.error);
        formHandler.setError(errFieldKey, errMsg);
        formHandler.setFocus(errFieldKey);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [asnTypeState]);

    useEffect(() => {
      if (watchDamageType === DamageType.EXPIRED) {
        formHandler.setValue(DamagedProductField.BOX_DAMAGED, undefined);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchDamageType]);

    // show modal on successful damage case submission + error handling
    useEffect(() => {
      const ctx = logStart({ fn: "onSubmitStateChange", submitState });
      if (submitState.error) {
        const [errFieldKey, errMsg] = parseServiceErrors(submitState.error);
        formHandler.setError(errFieldKey, errMsg);
        formHandler.setFocus(errFieldKey);

        if (!submitState.loading) {
          onFailure?.(ctx, submitState.error);
        }

        return;
      }

      if (!submitState.loading && submitState?.value?.id) {
        onSubmitSuccess(submitState.value.id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submitState, push, match.url]);

    const shouldShowBoxDamagedField = formValues[DamagedProductField.DAMAGE_TYPE] === DamageType.DAMAGED;

    const onFormValues = async (values: FieldValues) => {
      const requestData: Record<string, unknown> = { warehouseId };

      Object.entries(values).forEach(([fieldKey, fieldValue]) => {
        if (fieldValue !== undefined && fieldValue !== "" && !["caseQty", "unitsPerCase"].includes(fieldKey)) {
          requestData[fieldKey] = fieldValue;
        }
      });

      requestData.isDisposed = Boolean(Number(values.isDisposed));
      requestData.boxDamaged = Boolean(Number(values.boxDamaged));

      await submitData(requestData as unknown as DamagedProductUnifiedRequest);
    };

    const onFormSubmit = formHandler.handleSubmit(onFormValues);

    const toggleShouldShowPOField = () => {
      setShouldShowPOField((_shouldShowPOField) => !_shouldShowPOField);
    };

    const register = {
      [DamagedProductField.DAMAGED_PRODUCT_PHOTO]: formHandler.register(
        DamagedProductField.DAMAGED_PRODUCT_PHOTO,
        validation[DamagedProductField.DAMAGED_PRODUCT_PHOTO]
      ),
      [DamagedProductField.DAMAGED_EXTERIOR_PHOTO]: formHandler.register(
        DamagedProductField.DAMAGED_EXTERIOR_PHOTO,
        validation[DamagedProductField.DAMAGED_EXTERIOR_PHOTO]
      ),
      [DamagedProductField.ADDITIONAL_PHOTO1]: formHandler.register(
        DamagedProductField.ADDITIONAL_PHOTO1,
        validation[DamagedProductField.ADDITIONAL_PHOTO1]
      ),
      [DamagedProductField.ADDITIONAL_PHOTO2]: formHandler.register(
        DamagedProductField.ADDITIONAL_PHOTO2,
        validation[DamagedProductField.ADDITIONAL_PHOTO2]
      ),
      [DamagedProductField.BARCODE]: formHandler.register(
        DamagedProductField.BARCODE,
        validation[DamagedProductField.BARCODE]
      ),
      [DamagedProductField.DAMAGE_TYPE]: formHandler.register(DamagedProductField.DAMAGE_TYPE),
      [DamagedProductField.QUANTITY]: formHandler.register(
        DamagedProductField.QUANTITY,
        validation[DamagedProductField.QUANTITY]
      ),
      [DamagedProductField.BOX_DAMAGED]: formHandler.register(DamagedProductField.BOX_DAMAGED),
      [DamagedProductField.DISPOSED]: formHandler.register(DamagedProductField.DISPOSED),
      [DamagedProductField.OTHER_DETAILS]: formHandler.register(
        DamagedProductField.OTHER_DETAILS,
        validation[DamagedProductField.OTHER_DETAILS]
      ),
      [DamagedProductField.EMAIL]: formHandler.register(DamagedProductField.EMAIL),
    };

    if (shouldShowPOField) {
      register[DamagedProductField.ASN] = formHandler.register(
        DamagedProductField.ASN,
        validation[DamagedProductField.ASN]
      );
    } else {
      register[DamagedProductField.CDSKU] = formHandler.register(
        DamagedProductField.CDSKU,
        validation[DamagedProductField.CDSKU]
      );
    }

    if (isCaseEnabled) {
      register[DamagedProductField.STORAGE_LOCATION] = formHandler.register(DamagedProductField.STORAGE_LOCATION);
      register[DamagedProductField.CASE_QUANTITY] = formHandler.register(
        DamagedProductField.CASE_QUANTITY,
        validation[DamagedProductField.QUANTITY]
      );
      register[DamagedProductField.UNITS_PER_CASE] = formHandler.register(
        DamagedProductField.UNITS_PER_CASE,
        validation[DamagedProductField.QUANTITY]
      );
    }

    return {
      errors: formHandler.formState.errors,
      formHandler,
      onFormSubmit,
      preventSubmit: submitState.loading,
      warehouseEmailAsyncState,
      shouldShowBoxDamagedField,
      shouldShowPOField,
      toggleShouldShowPOField,
      register,
    };
  };
