import { useRecoilValue, useSetRecoilState } from "recoil";

import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { allSkusHaveQtyValue, boxContentsQtyLoadingState, scannedBoxAuditState } from "../BoxAuditState";
import { InvalidDataError } from "crossdock/common/errors";
import { createReviewSkusCard } from "../BoxAuditCardCreators";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useShowAddSku } from "../../hooks";
import { showBackButtonState } from "../../ProblemState";
import { useBoxAuditReviewSkusCard } from "../useBoxAuditReviewSkusCard";

export function useBoxAuditScanningComplete() {
  const { addFlowCard, hideAllFlowButtons, showFlowButton } = useScanFlow();
  const allSkusHaveQty = useRecoilValue(allSkusHaveQtyValue);
  const setShowBackButton = useSetRecoilState(showBackButtonState);
  const boxAudit = useRecoilValue(scannedBoxAuditState);
  const loading = useRecoilValue(boxContentsQtyLoadingState);
  const { hideAddSku } = useShowAddSku();
  const { items } = useBoxAuditReviewSkusCard();

  const onButtonClick = async () => {
    if (!boxAudit?.items) {
      throw new InvalidDataError("Missing audited dskus");
    }
    hideAllFlowButtons();
    addFlowCard(createReviewSkusCard({ items }));
    setShowBackButton(true);
    showFlowButton(ScanFlowButtonType.BOX_AUDIT_CONFIRM_AUDIT_BUTTON);
    hideAddSku();
  };

  return {
    onButtonClick,
    disabled: loading || !allSkusHaveQty,
  };
}
