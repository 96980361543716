import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import {
  createFcDimsAndWeightCaptureCubiscanImagesCard,
  createFcDimsAndWeightScanProductBarcodeCard,
  createFcDimsAndWeightScanProductDimsCard,
} from "crossdock/modes/inspection/fc-dims-and-weight/cards/CardCreators";

export const toScanProductBarcode = (): FlowTransition => ({
  card: createFcDimsAndWeightScanProductBarcodeCard({}),
});

export const toScanProductDims = (): FlowTransition => ({
  card: createFcDimsAndWeightScanProductDimsCard({}),
});

export const toCaptureCubiscanImages = (): FlowTransition => ({
  card: createFcDimsAndWeightCaptureCubiscanImagesCard({}),
});
