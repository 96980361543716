import { atom } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { SortToWarehouseResponse, SortToBoxBreakStationResponse } from "@deliverr/commons-clients";

interface LastScan {
  cdsku: string;
  response: SortToWarehouseResponse | SortToBoxBreakStationResponse;
}

export const lastScanState = atom<LastScan | undefined>({
  key: CrossdockStateKey.LAST_SCAN_RESPONSE,
  default: undefined,
});

export const cdskuState = atom<string>({
  key: CrossdockStateKey.CASE_SCANNER_CDSKU,
  default: "",
});

export const scanInputDisabledState = atom<boolean>({
  key: CrossdockStateKey.SCAN_INPUT_DISABLED,
  default: false,
});

export const destinationScanState = atom<string>({
  key: CrossdockStateKey.SCAN_INPUT,
  default: "",
});
