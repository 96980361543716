import React from "react";
import { useIntl } from "react-intl";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";
import { useDimsAndWeightScanProductDims } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/ScanProductDims/useCrossdockDimsAndWeightScanProductDims";
import { ProductDetailsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ProductDetailsCard/ProductDetailsCard";
import { TakeProductDimsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/TakeProductDimsCard/TakeProductDimsCard";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";

export const CrossdockDimsAndWeightScanProductDimsCard: React.FC = () => {
  const { productBarcode, product, submitProductDims, onProductDimsChange, productDimsQrCode, loading, errorMessage } =
    useDimsAndWeightScanProductDims();
  const { formatMessage } = useIntl();

  return (
    <>
      <ProductDetailsCard product={product} barcode={productBarcode} />
      <TakeProductDimsCard />
      <ContentTextInputCard
        onSubmit={submitProductDims}
        title={formatMessage(DIMS_AND_WEIGHT_LABELS.scanProductDimsCardTitle)}
        onChange={onProductDimsChange}
        value={productDimsQrCode}
        disabled={loading}
        errorMessage={errorMessage}
        placeholder={formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsQR)}
        icon="scan"
        accentColor="BLUE"
      />
    </>
  );
};
