import { defineMessages } from "react-intl";
import { ScanPath } from "crossdock/base/routes/ScanPath";
import { ScanMenuPage } from "./ScanMenuPage";

export const MENU_LABELS = {
  [ScanMenuPage.MAIN]: defineMessages({
    scanner: {
      id: "crossdock.menu.scanner",
      defaultMessage: "Box Scanner",
    },
    scannerHub: {
      id: "crossdock.menu.hub.scanner",
      defaultMessage: "Scanner",
    },
    freight: {
      id: "crossdock.menu.freight",
      defaultMessage: "Freight",
    },
    inspection: {
      id: "crossdock.menu.inspection",
      defaultMessage: "Inspection",
    },
    problem: {
      id: "crossdock.menu.problem",
      defaultMessage: "Problem",
    },
    break: {
      id: "crossdock.menu.break",
      defaultMessage: "Break",
    },
    dashboard: {
      id: "crossdock.menu.dashboard",
      defaultMessage: "Dashboard",
    },
    managePrinters: {
      id: "crossdock.menu.managePrinters",
      defaultMessage: "Manage Printers",
    },
    sortationApp: {
      id: "crossdock.menu.sortationApp",
      defaultMessage: "Sortation Center App",
    },
    showLogs: {
      id: "crossdock.menu.showLogs",
      defaultMessage: "Show Logs",
    },
    inbounds: {
      id: "crossdock.menu.inbounds",
      defaultMessage: "Inbounds",
    },
  }),
  [ScanMenuPage.FREIGHT]: defineMessages({
    [ScanMenuPage.RECORD_INBOUND]: {
      id: "crossdock.menu.recordInbound",
      defaultMessage: "Record Inbound",
    },
    [ScanMenuPage.MANAGE_OUTBOUND]: {
      id: "crossdock.menu.manageOutbound",
      defaultMessage: "Manage Outbound",
    },
  }),
  [ScanMenuPage.RECORD_INBOUND]: defineMessages({
    freightArrival: {
      id: "crossdock.menu.freightArrival",
      defaultMessage: "Freight Arrival",
    },
    spdArrival: {
      id: "crossdock.menu.spdArrival",
      defaultMessage: "Small Parcel Arrival",
    },
    fromCrossdock: {
      id: "crossdock.menu.fromCrossdock",
      defaultMessage: "From Crossdock",
    },
    [ScanPath.MovePallet]: {
      id: "crossdock.menu.movePallet",
      defaultMessage: "Move Pallet",
    },
    [ScanPath.StartProcessingPallet]: {
      id: "crossdock.menu.startProcessingPallet",
      defaultMessage: "Start Processing Pallet",
    },
    [ScanPath.PrintInboundPalletLabel]: {
      id: "crossdock.menu.printInboundPalletLabels",
      defaultMessage: "Print Inbound Pallet Labels",
    },
  }),
  [ScanMenuPage.MANAGE_OUTBOUND]: defineMessages({
    [ScanPath.TransfersCreatePallet]: {
      id: "crossdock.menu.createPallets",
      defaultMessage: "Create Pallets",
    },
    [ScanPath.TransfersAddCase]: {
      id: "crossdock.menu.buildPallets",
      defaultMessage: "Build Pallets",
    },
    [ScanPath.TransfersClosePallet]: {
      id: "crossdock.menu.closePallets",
      defaultMessage: "Close Pallets",
    },
    [ScanPath.TransfersShipPallet]: {
      id: "crossdock.menu.transferPallets",
      defaultMessage: "Transfer Pallets",
    },
    [ScanPath.TransfersReopenPallet]: {
      id: "crossdock.menu.reopenPallets",
      defaultMessage: "Re-open Pallets",
    },
  }),
  [ScanMenuPage.BOX_BREAK]: defineMessages({
    [ScanPath.BoxBreak]: {
      id: "crossdock.menu.breakBoxes",
      defaultMessage: "Break Boxes",
    },
    [ScanPath.BoxBreakCreateContainers]: {
      id: "crossdock.menu.createContainers",
      defaultMessage: "Create Containers",
    },
  }),
  [ScanMenuPage.DASHBOARD]: defineMessages({
    [ScanPath.Pallets]: {
      id: "crossdock.menu.pallets",
      defaultMessage: "Pallets",
    },
    [ScanPath.PalletSummary]: {
      id: "crossdock.menu.pallets.summary",
      defaultMessage: "Pallet Summary",
    },
  }),
  [ScanMenuPage.INSPECTION]: defineMessages({
    [ScanPath.CrossdockDimsAndWeight]: {
      id: "crossdock.menu.crossdockDimsAndWeights",
      defaultMessage: "Dims and Weight (Crossdock)",
    },
    [ScanPath.FcDimsAndWeight]: {
      id: "crossdock.menu.fcDimsAndWeights",
      defaultMessage: "Dims and Weight (FC)",
    },
    [ScanPath.BoxAudit]: {
      id: "crossdock.menu.crossdockDimsAndWeights",
      defaultMessage: "Box Audit",
    },
  }),
};
