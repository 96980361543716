import React from "react";
import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { SquareIcon } from "crossdock/common/components";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { BOX_BREAK_COMMON_LABELS } from "../BoxBreak.labels";

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

export const BoxBreakScanBoxSuccessCard: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <TransitionCard
      title={formatMessage(BOX_BREAK_COMMON_LABELS.scanBoxSuccessCardTitle)}
      message={formatMessage(BOX_BREAK_COMMON_LABELS.scanBoxSuccessCardMessage)}
    >
      <IconContainer>
        <SquareIcon iconType="box" accentColor="BLUE" />
      </IconContainer>
    </TransitionCard>
  );
};
