import { Region } from "@deliverr/crossdock-service-client";
import { defineMessages } from "react-intl";

export const REGIONS = defineMessages({
  [Region.SC]: {
    id: "region.southernca",
    defaultMessage: "Southern CA\nLAX1",
  },
  [Region.NC]: {
    id: "region.northernca",
    defaultMessage: "Northern CA",
  },
  [Region.MW]: {
    id: "region.midwest",
    defaultMessage: "Midwest\nHUB_ORD",
  },
  [Region.SE]: {
    id: "region.southeast",
    defaultMessage: "Southeast\nHUB_ATL",
  },
  [Region.TX]: {
    id: "region.texas",
    defaultMessage: "Texas\nHUB_DFW",
  },
  [Region.NE]: {
    id: "region.northeast",
    defaultMessage: "Northeast\nEWR1",
  },
});

export const PALLETS_SUMMARY_LABELS = {
  palletsSummary: {
    caption: {
      id: "palletsSummary.caption",
      defaultMessage: "Pallet Summary",
    },
    rowColHeading: {
      id: "palletsSummary.rowColHeading",
      defaultMessage: "Time/Regions",
    },
    columns: [
      ...Object.values(REGIONS),
      {
        id: "palletsSummary.columns.total",
        defaultMessage: "Total",
      },
    ],
    categories: defineMessages({
      openedWithin24Hours: {
        id: "palletsSummary.categories.openedWithin24Hours",
        defaultMessage: "OPEN: < 24 hours since opened",
      },
      openedMoreThan24Hours: {
        id: "palletsSummary.categories.openedMoreThan24Hours",
        defaultMessage: "OPEN: > 24 hours since opened",
      },
      openedMoreThan48Hours: {
        id: "palletsSummary.categories.openedMoreThan48Hours",
        defaultMessage: "OPEN: > 48 hours since opened",
      },
      totalOpened: {
        id: "palletsSummary.categories.totalOpened",
        defaultMessage: "Total Opened",
      },
      closedWithin24Hours: {
        id: "palletsSummary.categories.closedWithin24Hours",
        defaultMessage: "CLOSED: < 24 hours since opened",
      },
      closedMoreThan24Hours: {
        id: "palletsSummary.categories.closedMoreThan24Hours",
        defaultMessage: "CLOSED: > 24 hours since opened",
      },
      closedMoreThan48Hours: {
        id: "palletsSummary.categories.closedMoreThan48Hours",
        defaultMessage: "CLOSED: > 48 hours since opened",
      },
      totalClosed: {
        id: "palletsSummary.categories.totalClosed",
        defaultMessage: "Total Closed",
      },
    }),
  },

  notification: {
    dataLoadError: {
      id: "pallets.summary.notification.dataLoadError",
      defaultMessage: "Error fetching data from the backend. Contact eng support",
    },
    dataUpdateError: {
      id: "pallets.summary.notification.dataUpdateError",
      defaultMessage: "Error updating data on pallets summary. Contact eng support",
    },
  },
};
