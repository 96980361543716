import { useRecoilValue, useRecoilState } from "recoil";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../../modals/BoxBreakModal";
import { allTargetsMetValue, showedEndBreakModalState } from "../../boxBreakState";

/**
 * If all quantities of "known" units have been scanned and placed into a chute
 * we prompt the user to end the box break
 */
export function useCheckEndBoxBreak() {
  const { showModal } = useCrossdockModal();
  const allTargetsMet = useRecoilValue(allTargetsMetValue);
  const [showedEndBreakModal, setShowedEndBreakModal] = useRecoilState(showedEndBreakModalState);

  return () => {
    if (!showedEndBreakModal && allTargetsMet) {
      showModal(BoxBreakModal.BOX_BREAK_END_MODAL, { shortage: false });
      setShowedEndBreakModal(true);
    }
  };
}
