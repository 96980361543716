import React from "react";
import { useRecoilValue } from "recoil";

import { AddSkuButton } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxAuditModal } from "./BoxAuditModal";
import { showAddButtonState } from "../ProblemState";
import { boxContentsQtyLoadingState } from "./BoxAuditState";

export const BoxAuditAddSkuButton: React.FC = () => {
  const showButton = useRecoilValue(showAddButtonState);
  const loading = useRecoilValue(boxContentsQtyLoadingState);
  const { showModal } = useCrossdockModal();

  return showButton && !loading ? (
    <AddSkuButton
      onClick={() => {
        showModal(BoxAuditModal.BOX_AUDIT_INPUT_BARCODE_MODAL, {});
      }}
    />
  ) : null;
};
