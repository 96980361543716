import React from "react";
import { useIntl } from "react-intl";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { useCrossdockDimsAndWeightScanProductBarcode } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/ScanProductBarcode/useCrossdockDimsAndWeightScanProductBarcode";

export const CrossdockDimsAndWeightScanProductBarcodeCard: React.FC = () => {
  const { onProductBarcodeChange, submitProductBarcode, productBarcode, loading, errorMessage } =
    useCrossdockDimsAndWeightScanProductBarcode();
  const { formatMessage } = useIntl();

  return (
    <ContentTextInputCard
      onSubmit={submitProductBarcode}
      title={formatMessage(DIMS_AND_WEIGHT_LABELS.scanProductBarcodeCardTitle)}
      onChange={onProductBarcodeChange}
      value={productBarcode}
      disabled={loading}
      errorMessage={errorMessage}
      placeholder={formatMessage(DIMS_AND_WEIGHT_LABELS.productBarcode)}
      icon="scan"
      accentColor="BLUE"
    />
  );
};
