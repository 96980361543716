import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import FreightArrivalScanSuccessCardLabels from "./FreightArrivalScanSuccess.labels";
import { ACCENT_COLORS, ICONS } from "./FreightArrivalScanSuccess.constants";
import { palletDestinationLaneTypeValue, isSpdState } from "../../FreightArrivalState";
import { useRecoilValue } from "recoil";
import { getTitleLabel } from "./getTitleLabel";

export interface FreightArrivalScanSuccessCardProps {
  label: string;
  noPo?: boolean;
}

export const FreightArrivalScanSuccessCard: React.FC<FreightArrivalScanSuccessCardProps> = ({ label, noPo }) => {
  const palletDestinationLaneType = useRecoilValue(palletDestinationLaneTypeValue);
  const isSpd = useRecoilValue(isSpdState);
  const { formatMessage } = useIntl();
  const title = isSpd
    ? formatMessage(FreightArrivalScanSuccessCardLabels.spdArrivalTitle)
    : formatMessage(FreightArrivalScanSuccessCardLabels[getTitleLabel(palletDestinationLaneType)]);
  const message = isSpd
    ? formatMessage(FreightArrivalScanSuccessCardLabels.spdArrival)
    : noPo
      ? formatMessage(FreightArrivalScanSuccessCardLabels.freightArrivalNoPo)
      : formatMessage(FreightArrivalScanSuccessCardLabels[palletDestinationLaneType], {
          label,
        });

  return (
    <FlowCard
      accentColor={ACCENT_COLORS[palletDestinationLaneType]}
      className="noDefinedHeight"
      icon={ICONS[palletDestinationLaneType]}
      message={message}
      size="lg"
      title={title}
    />
  );
};
