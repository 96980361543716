import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import labels from "./FreightArrivalHighPriorityPalletCard.labels";
import { useFreightArrivalHighPriorityPalletCard } from "./useFreightArrivalHighPriorityPalletCard";

// Prompts the user to apply a "High Priority" label.
// This label that a pallet contains at least one OOS product,
// and therefore needs to be processed ASAP.
export const FreightArrivalHighPriorityPalletCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { inboundPalletLabelId } = useFreightArrivalHighPriorityPalletCard();

  return (
    <FlowCard
      icon="exclamation-triangle"
      accentColor="YELLOW"
      title={formatMessage(labels.title)}
      message={formatMessage(labels.message, { inboundPalletLabelId })}
    />
  );
};
