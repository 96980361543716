import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { ScanResponseType, SortToWarehouseResponse, SortToPalletBuildStationResponse } from "@deliverr/commons-clients";
import { useAsyncFn } from "react-use";
import { genericOnScannerInputChange, log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { clientIdState } from "crossdock/base/clientIdState";
import { createScannerScanCard, createSortPalletToMilkRunCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { usePrintCrossdockLabel } from "crossdock/modes/caseScanner/usePrintCrossdockLabel";
import { destinationScanState, lastScanState, scanInputDisabledState } from "crossdock/modes/caseScanner/caseScanState";
import { createScanResponseCard, createScanErrorCard, createHubScanErrorCard } from "./scanCardCreators";
import { crossdockClient, inboundClient } from "crossdock/base/Clients";
import { useConnectToPrinterWarning } from "crossdock/common/zebra";
import { GenericModal, useCrossdockModal } from "crossdock/common/modal";
import { CaseScannerDuplicateLabelPalletModalProps, CaseScannerModal } from "./modals";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow";
import { FlashType } from "@deliverr/ui-facility/lib-facility/components/Flash";
import { isNil } from "lodash";
import { DeliverrError } from "@deliverr/commons-objects";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { PrepRequiredModalProps } from "crossdock/common/modal/PrepRequiredModal/usePrepRequiredModal";
import { validateFreightPalletLabelId, validatePalletLabelId, ModalGenericProps } from "@deliverr/ui-facility";
import { useSubmitFreightPalletId } from "./useSubmitFreightPalletId";

export const handleSubmitCdsku = async (
  barcode: string,
  previousCdskuValue: string,
  warehouseId: string,
  clientId: string,
  emitFlash: (type: FlashType) => void,
  showModal: <P extends ModalGenericProps>(modalType: string, props: P) => void,
  addFlowCard: (card: FlowCardData<any>) => void,
  resetFlowCards: () => void,
  printScanCrossdockLabel: (
    cdsku: string,
    action: ScanResponseType,
    destinationWarehouse?: string,
    reprint?: boolean
  ) => Promise<void>,
  setLastScan,
  verifyAndShowPrinterConnected: () => boolean,
  handleBeginScan: () => void,
  handleFinishScan: () => void
) => {
  const ctx = logStart({ fn: "handleSubmitCdsku", barcode, previousCdskuValue });
  if (!verifyAndShowPrinterConnected()) {
    return;
  }

  handleBeginScan();
  try {
    const scanResponse = await crossdockClient.scan({ clientId, barcode, warehouseId });
    log(ctx, "successful scan", { scanResponse });

    if (scanResponse.previousScanResponse) {
      log(ctx, "duplicate scan");
      emitFlash("DEFAULT");
      const palletItem = [ScanResponseType.SORT_TO_WAREHOUSE, ScanResponseType.SORT_TO_PALLET_BUILD_STATION].includes(
        scanResponse.action
      )
        ? await inboundClient.getPalletItem(scanResponse.cdsku)
        : undefined;

      // CDSKU has already been scanned onto a pallet
      if (palletItem) {
        const { palletLabelId } = await inboundClient.getPalletById(palletItem.palletId);
        showModal<CaseScannerDuplicateLabelPalletModalProps>(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PALLET, {
          itemCreatedAt: palletItem.createdAt,
          palletLabelId,
          scanResponse: scanResponse as SortToWarehouseResponse,
        });
      } else {
        const printSummary = await crossdockClient.getPrintSummary(scanResponse.cdsku);
        showModal(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PROMPT, {
          scanResponse,
          printSummary,
        });
      }
      // We won't have a scanResponse card in either case, so reset to avoid duplicate Case Scanner Scan cards.
      resetFlowCards();
    } else if (scanResponse.action === ScanResponseType.PREP) {
      // catch boxes that have prep requested but not completed
      log({ ...ctx }, "Attempted to scan box with Prep required!");
      showModal<PrepRequiredModalProps>(GenericModal.PREP_REQUIRED, { cdsku: barcode });
      resetFlowCards();
    } else {
      emitFlash("SUCCESS");
      await printScanCrossdockLabel(
        scanResponse.cdsku,
        scanResponse.action,
        (scanResponse as SortToPalletBuildStationResponse)?.palletBuildStationBarcode
      );
      addFlowCard(createScanResponseCard(scanResponse.cdsku, scanResponse));
    }

    setLastScan({ cdsku: scanResponse.cdsku, response: scanResponse });
  } catch (err) {
    log(ctx, "scan error", { err });
    emitFlash("DANGER");
    addFlowCard(createScanErrorCard(barcode, err));
  }
  handleFinishScan();
};

export const handleSubmitPalletId = async (
  palletId: string,
  warehouseId: string,
  addFlowCard: (card: FlowCardData<any>) => void,
  emitFlash: (type: FlashType) => void,
  handleBeginScan: () => void,
  handleFinishScan: () => void
) => {
  logStart({ fn: "handleSubmitPalletId", palletId });
  handleBeginScan();
  try {
    await crossdockClient.scanHubArrival(warehouseId!, palletId);
    const { toWarehouseId, trackingCode = "" } = await inboundClient.getPalletByLabel(palletId);

    if (isNil(toWarehouseId)) {
      throw new DeliverrError({ code: "WAREHOUSE_ID_NOT_FOUND_AT_HUB" });
    }

    const { scannedPalletCount, totalPalletCount } = trackingCode
      ? await inboundClient.getHubScannedPalletCountByTrackingCode(trackingCode)
      : { scannedPalletCount: 0, totalPalletCount: 0 };

    const flowCardData = createSortPalletToMilkRunCard({
      palletId,
      warehouseId: toWarehouseId,
      scannedPalletCount,
      totalPalletCount,
    });

    emitFlash("SUCCESS");
    addFlowCard(flowCardData);
  } catch (err) {
    emitFlash("DANGER");
    addFlowCard(createHubScanErrorCard(palletId, err));
  }
  handleFinishScan();
};

export function useCaseScanner() {
  const { addFlowCard, hideAllFlowButtons, resetFlowCards, emitFlash, resetNotifications } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const clientId = useRecoilValue(clientIdState);
  const { warehouseId } = useRecoilValue(userState);
  const [scanInput, setScanInput] = useRecoilState(destinationScanState);
  const { verifyAndShowPrinterConnected } = useConnectToPrinterWarning();
  const setLastScan = useSetRecoilState(lastScanState);
  const { printScanCrossdockLabel } = usePrintCrossdockLabel();
  const setScanInputDisabled = useSetRecoilState(scanInputDisabledState);
  const { handleSubmitFreightPalletId } = useSubmitFreightPalletId();

  // common functionality for initial scan submission
  const handleBeginScan = () => {
    setScanInputDisabled(true);
    hideAllFlowButtons();
    resetNotifications();
  };

  // common functionality for initial scan submission
  const handleFinishScan = () => {
    setScanInput("");
    setScanInputDisabled(false);
    addFlowCard(createScannerScanCard({}));
  };

  const [, handleSubmit] = useAsyncFn(
    async (newScan: string) => {
      if (validatePalletLabelId(newScan)) {
        await handleSubmitPalletId(newScan, warehouseId, addFlowCard, emitFlash, handleBeginScan, handleFinishScan);
        return;
      } else if (validateFreightPalletLabelId(newScan)) {
        await handleSubmitFreightPalletId(newScan, handleBeginScan, handleFinishScan);
        return;
      }

      await handleSubmitCdsku(
        newScan,
        scanInput,
        warehouseId,
        clientId,
        emitFlash,
        showModal,
        addFlowCard,
        resetFlowCards,
        printScanCrossdockLabel,
        setLastScan,
        verifyAndShowPrinterConnected,
        handleBeginScan,
        handleFinishScan
      );
    },
    [scanInput]
  );

  const updateScanInput = (newScan: string) => {
    setScanInput(newScan);
  };

  const handleChange = genericOnScannerInputChange(scanInput, updateScanInput, handleSubmit, "upper");

  return {
    scanInput,
    handleChange,
    handleSubmit,
  };
}
