import { useIntl } from "react-intl";
import { useSetRecoilState } from "recoil";

import { headerTitleState } from "crossdock/base/headerTitleState";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export function useUpdateBoxBreakHeader() {
  const setHeaderTitle = useSetRecoilState(headerTitleState);
  const { formatMessage } = useIntl();

  return {
    setLinkContainerHeader: () => {
      setHeaderTitle("Link Container");
    },
    setRemoveContainerHeader: () => {
      setHeaderTitle("Remove Container");
    },
    setScanBoxHeader: () => {
      setHeaderTitle(formatMessage(WarehouseMessages.boxTitleText));
    },
    setCdskuHeader: (cdsku: string) => {
      setHeaderTitle(cdsku);
    },
    setBoxBreakHeader: () => {
      setHeaderTitle(undefined);
    },
  };
}
