import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "crossdock.freightArrivalHighPriorityPallet.title",
    defaultMessage: "High Priority Pallet",
  },
  message: {
    id: "crossdock.freightArrivalHighPriorityPallet.message",
    defaultMessage: "Apply a HIGH PRIORITY PALLET label to {inboundPalletLabelId} and move to PANDA immediately.",
  },
});
