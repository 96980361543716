import { showAddButtonState } from "../ProblemState";
import { useSetRecoilState } from "recoil";

export function useShowAddSku() {
  const setShowAddButton = useSetRecoilState(showAddButtonState);
  return {
    showAddSku: () => {
      setShowAddButton(true);
    },
    hideAddSku: () => {
      setShowAddButton(false);
    },
  };
}
