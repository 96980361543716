import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { useIntl } from "react-intl";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import labels from "./PrintPalletLabelCard.labels";

export function usePrintInboundPalletLabelsNotification() {
  const { formatMessage } = useIntl();
  const { addAutoCloseNotification } = useScanFlow();

  const showInboundPalletLabelsGeneratedSuccessNotification = (palletCount: number) => {
    const notification = createSuccessNotification(formatMessage(labels.successNotification, { palletCount }));
    addAutoCloseNotification(notification);
  };

  return {
    showInboundPalletLabelsGeneratedSuccessNotification,
  };
}
