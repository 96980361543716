import { useSolveBoxLabelIncident } from "../useSolveBoxLabelIncident";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { logStart, logError } from "@deliverr/ui-facility/lib-facility/utils";
import { BoxLabelIncidentErrorCode } from "@deliverr/commons-clients";
import { useCrossdockModal } from "crossdock/common/modal";
import { MissingLabelModal } from "../MissingLabelModal";
import { allSkusHaveQtyValue } from "../MissingLabelState";
import { useRecoilValue } from "recoil";

export function useMissingLabelDoneScanningButton() {
  const { handleUnknownError, errorResponse } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const { handleDoneScanning } = useSolveBoxLabelIncident();
  const allSkusHaveQty = useRecoilValue(allSkusHaveQtyValue);

  const onButtonClick = async () => {
    const ctx = logStart({ fn: "useMissingLabelDoneScanningButton" });
    try {
      await handleDoneScanning();
    } catch (err) {
      if (err.code === BoxLabelIncidentErrorCode.UNABLE_TO_ADD_NEW_DSKUS_TO_SHIPPING_PLAN) {
        logError(ctx, err);
        errorResponse();
        showModal(MissingLabelModal.MISSING_LABEL_UNEXPECTED_SKU, { dsku: err.payload.dsku });
      } else if (err.code === BoxLabelIncidentErrorCode.NO_MATCH_FOR_BOX_CONTENTS) {
        logError(ctx, err);
        errorResponse();
        showModal(MissingLabelModal.MISSING_LABEL_UNSOLVABLE_BOX, {});
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return {
    onButtonClick,
    disabled: !allSkusHaveQty,
  };
}
