import { useIntl } from "react-intl";
import { useSetRecoilState } from "recoil";

import { headerSubtitleState } from "crossdock/base/headerTitleState";
import labels from "./FreightArrival.labels";

export function useSetFreightArrivalSubtitle() {
  const setHeaderSubtitle = useSetRecoilState(headerSubtitleState);
  const { formatMessage } = useIntl();

  return {
    setInboundPalletSubtitle: (inboundPalletLabelBarcode: string) => {
      setHeaderSubtitle(inboundPalletLabelBarcode);
    },
    setPoSubtitle: (inboundPalletLabelBarcode: string, poNum: number) => {
      setHeaderSubtitle(formatMessage(labels.poHeaderSubtitle, { poNum, inboundPalletLabelBarcode }));
    },
  };
}
