import { useRecoilState, useRecoilValue } from "recoil";
import {
  palletLabelsPerWarehouseState,
  MAX_PALLETS_PER_WAREHOUSE,
  MIN_PALLETS_PER_WAREHOUSE,
  selectedDestinationsArrayState,
} from "../TransfersDestinationsState";
import { useFulfillmentCenterSelect } from "../useFulfillmentCenterSelect";

export const usePrintPalletLabels = () => {
  const { createPalletsAndPrintLabels } = useFulfillmentCenterSelect();
  const selectedDestinationsArray = useRecoilValue(selectedDestinationsArrayState);
  const [palletLabelsPerWarehouse, setPalletLabelsPerWarehouse] = useRecoilState(palletLabelsPerWarehouseState);
  const palletLabelsNumber = Number(palletLabelsPerWarehouse);
  const totalLabelCount =
    palletLabelsNumber && selectedDestinationsArray.length > 0
      ? ` (${palletLabelsNumber * selectedDestinationsArray.length})`
      : "";
  const printButtonDisabled =
    selectedDestinationsArray.length <= 0 ||
    !palletLabelsNumber ||
    palletLabelsNumber < MIN_PALLETS_PER_WAREHOUSE ||
    palletLabelsNumber > MAX_PALLETS_PER_WAREHOUSE;

  return {
    createPalletsAndPrintLabels,
    palletLabelsPerWarehouse,
    setPalletLabelsPerWarehouse,
    totalLabelCount,
    printButtonDisabled,
  };
};
