import { useRecoilState, useRecoilValue } from "recoil";
import { palletLabelIdState } from "../ReopenPalletState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { validatePalletLabelId } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";
import {
  createReopenPalletInvalidStatusCard,
  createReopenPalletScanPalletCard,
  createReopenPalletScanPalletSuccessCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { TransferPalletActionSource, InboundError } from "@deliverr/legacy-inbound-client";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { logStart, logError, isScanInput, log } from "@deliverr/ui-facility/lib-facility/utils";
import { createUnknownInputCard, createUnknownErrorCard } from "@deliverr/ui-facility/lib-facility/flow";
import { crossdockClient } from "crossdock/base/Clients";

export function useReopenPalletScanPallet() {
  const { addFlowCard, emitFlash } = useScanFlow();
  const [curPalletLabelId, setPalletLabelId] = useRecoilState(palletLabelIdState);
  const { warehouseId } = useRecoilValue(userState);

  async function submitPalletLabelId(palletLabelId: string) {
    const ctx = logStart({ fn: "ReopenPallet.submitPalletLabelId", palletLabelId });

    if (!validatePalletLabelId(palletLabelId)) {
      log(ctx, "invalid pallet label id");
      emitFlash("DANGER");
      setPalletLabelId("");
      addFlowCard(createUnknownInputCard({ value: palletLabelId, message: "Does not appear to be a valid Pallet" }));
      addFlowCard(createReopenPalletScanPalletCard({}));
      return;
    }

    try {
      await crossdockClient.reopenOutboundPallet(palletLabelId, warehouseId, {
        actionSource: TransferPalletActionSource.TRANSFERS_REOPEN_PALLET,
      });
      emitFlash("SUCCESS");
      addFlowCard(createReopenPalletScanPalletSuccessCard({ palletLabelId }));
    } catch (err) {
      emitFlash("DANGER");
      if (err.code === InboundError.INVALID_PALLET_SCAN) {
        log(ctx, "invalid pallet label id");
        addFlowCard(createUnknownInputCard({ value: palletLabelId, message: "Does not appear to be a valid Pallet." }));
      } else if ([InboundError.PALLET_ALREADY_IN_STATUS, InboundError.CANNOT_REOPEN_PALLET].includes(err.code)) {
        const { statusUpdatedAt, status } = err.payload;
        log(ctx, "attempted to re-open a pallet in an invalid state");
        addFlowCard(
          createReopenPalletInvalidStatusCard({
            palletLabelId,
            status,
            statusUpdatedAt: new Date(statusUpdatedAt),
          })
        );
      } else {
        logError(ctx, err);
        addFlowCard(createUnknownErrorCard({ value: palletLabelId }));
      }
    }
    setPalletLabelId("");
    addFlowCard(createReopenPalletScanPalletCard({}));
  }

  async function onPalletLabelIdChange(newPalletId: string) {
    const formattedPalletLabelId = newPalletId.trim().toUpperCase();
    setPalletLabelId(formattedPalletLabelId);
    if (isScanInput(formattedPalletLabelId, curPalletLabelId)) {
      await submitPalletLabelId(formattedPalletLabelId);
    }
  }

  return {
    palletLabelId: curPalletLabelId,
    onPalletLabelIdChange,
    submitPalletLabelId,
  };
}
