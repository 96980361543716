import { useRecoilValue } from "recoil";
import styled from "@emotion/styled";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { Checkbox, Pill, Title } from "@deliverr/ui";
import React from "react";
import { useFulfillmentCenterSelect } from "crossdock/modes/transfers/CreatePallet/useFulfillmentCenterSelect";
import { palletLabelsPerWarehouseState } from "./TransfersDestinationsState";
import { FormattedMessage } from "react-intl";

interface Props {
  title: string;
  destinations: string[];
  selectedDestinations: string[];
  hideLastBorder?: boolean;
  arePalletBuildStations?: boolean;
}

const UnderlineTitle = styled(Title)`
  width: 100%;
  text-align: center;
  margin-top: ${FACILITY_THEME.spacing.S5};
`;

const BlockList = styled.ol`
  width: 100%;
  margin: 0;
`;

const SpacedListItem = styled.li<{ checked: boolean; hideLastBorder: boolean }>(
  ({ checked, hideLastBorder }) => `
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${FACILITY_THEME.spacing.S3};
  padding-left: ${FACILITY_THEME.spacing.S4};
  background: ${checked ? FACILITY_THEME.colors.NEUTRAL[60] : FACILITY_THEME.colors.NEUTRAL["00"]};
  min-height: 55px;
  ${hideLastBorder ? "" : `border-bottom: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};`}
  cursor: pointer;
  position: relative;
  user-select: none;

  &:first-of-type {
    border-top: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};
  }
  &:hover {
    background: ${FACILITY_THEME.colors.NEUTRAL[60]}C0;
  }
`
);

const NoDataPlaceHolder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 55px;
  border-top: ${FACILITY_THEME.border.width.B1} ${FACILITY_THEME.border.type} ${FACILITY_THEME.colors.NEUTRAL[80]};
  border-bottom: ${FACILITY_THEME.border.width.B1} ${FACILITY_THEME.border.type} ${FACILITY_THEME.colors.NEUTRAL[80]};
  color: ${FACILITY_THEME.colors.NEUTRAL[200]};
`;

export const FulfillmentCenterSelect: React.FC<Props> = ({
  title,
  destinations,
  selectedDestinations,
  arePalletBuildStations = false,
  hideLastBorder = false,
}) => {
  const { onFulfillmentCenterCheck, createPalletLoading } = useFulfillmentCenterSelect(arePalletBuildStations);
  const palletLabelsPerWarehouse = useRecoilValue(palletLabelsPerWarehouseState);
  return (
    <>
      <UnderlineTitle as="h3" displayAs="h4">
        {title}
      </UnderlineTitle>
      <BlockList>
        {destinations.length > 0 ? (
          destinations.map((warehouseId, i) => {
            const checked = selectedDestinations.includes(warehouseId);
            const isLastDestination = i === destinations.length - 1;

            return (
              <SpacedListItem
                key={warehouseId}
                checked={checked}
                hideLastBorder={hideLastBorder && isLastDestination}
                onClickCapture={async (e) => {
                  await onFulfillmentCenterCheck(warehouseId, !checked);
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Checkbox
                  label={warehouseId}
                  name={warehouseId}
                  disabled={createPalletLoading}
                  checked={checked}
                  readOnly
                />
                {checked && Number(palletLabelsPerWarehouse) && <Pill color="BLUE">{palletLabelsPerWarehouse}</Pill>}
              </SpacedListItem>
            );
          })
        ) : (
          <NoDataPlaceHolder>
            <FormattedMessage id="crossdock.fulfillmentCenterSelect.noData" defaultMessage="Not Available" />
          </NoDataPlaceHolder>
        )}
      </BlockList>
    </>
  );
};
