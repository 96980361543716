import { isNumber, isNaN } from "lodash";
import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { getVerifyChuteTransition } from "crossdock/modes/break/transitions";
import { bulkQtyTransferredState, destinationChuteValue, singleSkuBoxProductBarcodeState } from "../../bulkState";

export function useConfirmBulkTransfer() {
  const { transition } = useScanFlow();
  const bulkQtyTransferred = useRecoilValue(bulkQtyTransferredState);
  const destinationChute = useRecoilValue(destinationChuteValue);
  const singleSkuBoxProductBarcode = useRecoilValue(singleSkuBoxProductBarcodeState);

  const onClick = async () => {
    log({ fn: "useConfirmBulkTransfer" }, "sending to verify chute");
    transition(
      getVerifyChuteTransition({
        chute: destinationChute,
        unitBarcode: singleSkuBoxProductBarcode,
      })
    );
  };

  return {
    onClick,
    disabled: !isNumber(bulkQtyTransferred) || isNaN(bulkQtyTransferred),
  };
}
