import { createBoxBreakLoginCard } from "../../boxBreakCardCreators";
import { useUpdateBoxBreakHeader } from "../../useUpdateBoxBreakHeader";
import { useResetCurrentBreakState } from "./useResetCurrentBreakState";
import { useResetStationState } from "../../station/useResetStationState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export function useBoxBreakPage() {
  const { setBoxBreakHeader } = useUpdateBoxBreakHeader();
  const { resetCurrentBreak } = useResetCurrentBreakState();
  const resetStationState = useResetStationState();
  const { resetFlow } = useScanFlow();

  return {
    onMountCardData: createBoxBreakLoginCard({}),
    onUnmount: () => {
      setBoxBreakHeader();
      resetCurrentBreak();
      resetStationState();
      resetFlow();
    },
  };
}
