import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { nonComplianceClient, warehouseClient } from "crossdock/base/Clients";
import { ScanPath } from "crossdock/base/routes";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { useRecoilValue } from "recoil";
import { DamagedProductModal } from "./DamagedProductModal";
import { composeUseDamagedProductForm } from "crossdock/common/flow/forms/DamagedProductForm";
import { logError } from "@deliverr/ui-facility/lib-facility/utils";
import { DamagedProductUnifiedRequest } from "@deliverr/commons-clients/lib/non-compliance/damages/DamagedProductUnified";

export const useDamagedProductCard = () => {
  const { warehouseId } = useRecoilValue(userState);
  const { playSfx, emitFlash, handleUnknownError } = useScanFlow();
  const { push } = useRouter();
  const { showModal: showCrossdockModal, hideModal: hideCrossdockModal } = useCrossdockModal();

  const onSubmitSuccess = (caseNumber: number) => {
    const onDoneClick = () => {
      push(ScanPath.Problem);
      hideCrossdockModal(DamagedProductModal.DAMAGED_PRODUCT_SUCCESS);
    };

    showCrossdockModal(DamagedProductModal.DAMAGED_PRODUCT_SUCCESS, {
      onDoneClick,
      caseNumber,
    });
  };

  const useDamagedProductForm = composeUseDamagedProductForm({
    warehouseId,
    onSubmitSuccess,
    getWarehouseEmailFn: async (_warehouseId: string) => {
      try {
        const warehouse = await warehouseClient.getWarehouse(_warehouseId);
        return warehouse?.inboundContactEmail;
      } catch (error) {
        logError({ fn: "getWarehouseEmailFn" }, error as any);
      }
      return undefined;
    },
    createSelfServeReceivingDamageFn: async (damageRequest: DamagedProductUnifiedRequest) => {
      try {
        return await nonComplianceClient.createSelfServeReceivingDamage(damageRequest);
      } catch (error) {
        logError({ fn: "createSelfServeReceivingDamageFn" }, error as any);
        return undefined;
      }
    },
    getNcAsnTypeFn: async (asnId: number) => {
      try {
        const { type } = await nonComplianceClient.getASNType(asnId as any);
        return type;
      } catch (error) {
        logError({ fn: "getNcAsnTypeFn" }, error as any);
        return undefined;
      }
    },
  });

  const onFailure = (ctx, err) => {
    playSfx(SoundFx.ERROR);
    emitFlash("DANGER");
    handleUnknownError(ctx, err);
  };

  return {
    warehouseId,
    onFailure,
    useDamagedProductForm,
  };
};
