import { useCreateIncident } from "../useCreateIncident";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";

export function useBoxInfoUnknownButton() {
  const { onCreateIncidentSubmit } = useCreateIncident();
  return async () => {
    const ctx = logStart({ fn: "useBoxInfoUnknownButton" });
    await onCreateIncidentSubmit(ctx, null);
  };
}
