import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";
import { createFlowCard } from "crossdock/common/flow/scanFlowCardCreators";

export const createCrossdockDimsAndWeightScanPackageCdskuCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PACKAGE_CDSKU
);
export const createCrossdockDimsAndWeightScanProductBarcodeCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE
);
export const createCrossdockDimsAndWeightScanProductDimsCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS
);

export const createCrossdockDimsAndWeightCaptureCubiscanImagesCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.CROSSDOCK_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES
);
