import React from "react";
import { Button } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

import { createBoxBreakScanBoxCard } from "../../boxBreakCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetCurrentBreakState } from "../hooks/useResetCurrentBreakState";
import { getBoxPausedNotification } from "../../boxBreakNotifications";

// Ends the current break immediately without further interaction or dialogue.
// This has the exact same effect as ending a box break session.
// Used by workers before they go on break.
// It is expected that the box will be box broken again in the future.
export const BoxBreakPauseSessionButton: React.FC = () => {
  const { transition, hideAllFlowButtons } = useScanFlow();
  const { resetCurrentBreak } = useResetCurrentBreakState();

  const onClick = () => {
    transition({
      card: createBoxBreakScanBoxCard({}),
      notification: getBoxPausedNotification(),
      onTransition: () => {
        resetCurrentBreak();
        hideAllFlowButtons();
      },
    });
  };

  return (
    <Button secondary onClick={onClick} block>
      <FormattedMessage id="crossdock.boxBreakPauseSessionButton" defaultMessage="Pause box break" />
    </Button>
  );
};
