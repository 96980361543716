import { useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { emptyChuteState } from "./stationState";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { toScanUnit, toRemoveContainer, toAddContainer, toScanBox } from "./BoxBreakStation.transitions";
import { selectedSidebarChuteIdState } from "../BoxBreak/sidebar/boxBreakSidebarState";
import { chuteByBarcodeState } from "../station/stationState";
import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { BoxBreakStationReadyToBreakResponse, BoxBreakGetReadyAction } from "@deliverr/commons-clients";

/**
 * Routes the user depending on if the station is setup to start breaking a box.
 * A station is ready if all chutes with a destination have a chute box linked
 * to those chutes going to the same destination as that chute.
 * For stations that are ready, we send the user to start a break or resume a current one.
 * For stations that aren't, we send the user to unlink or link containers until it is.
 */
export function useBoxBreakStationReadyTransition() {
  const chuteByBarcode = useRecoilValue(chuteByBarcodeState);
  const setEmptyChute = useSetRecoilState(emptyChuteState);
  const resetSelectedSidebarChuteId = useResetRecoilState(selectedSidebarChuteIdState);
  const resetEmptyChute = useResetRecoilState(emptyChuteState);

  // isSingleSkuBox will be outdated because of a closure unless we use recoil callback to fetch from current snapshot
  return async (response?: BoxBreakStationReadyToBreakResponse): Promise<FlowTransition> => {
    const ctx = logStart({ fn: "boxBreakStationReadyTransition", ...response });

    // prevent scenarios where the open chute gets removed and causes an error
    resetSelectedSidebarChuteId();
    resetEmptyChute();

    // user removed a chute before a box was scanned
    if (!response) {
      log(ctx, "removed container between box scans, sending back to scan box");
      return toScanBox();
    }

    if (!response.ready) {
      const { action, chuteId } = response.requiredAction;
      const errorChute = chuteByBarcode[chuteId];

      switch (action) {
        case BoxBreakGetReadyAction.REMOVE_CONTAINER_FROM_CHUTE:
          log({ ...ctx, errorChute }, "station has a chute that should be cleaered");
          return toRemoveContainer(errorChute);

        case BoxBreakGetReadyAction.ADD_EMPTY_CONTAINER_TO_CHUTE:
          log({ ...ctx, errorChute }, "station has an empty chute");
          setEmptyChute(errorChute);
          return toAddContainer();
      }
    }

    log(ctx, "resuming current box break");
    return toScanUnit();
  };
}
