import { UnitTransfer } from "@deliverr/commons-clients";
import { findIndex } from "lodash/fp";

import { UnitTransfersByChuteBox } from "../types/UnitTransfersByChuteBox";

export const getUnitTransfersByChute = (
  unitTransfersByChute: UnitTransfersByChuteBox,
  newUnitTransfer: UnitTransfer,
  toCdsku: string
) => {
  const newUnitTransfersByChute: UnitTransfersByChuteBox = {};
  const unitTransferChuteIndex = findIndex({ id: newUnitTransfer.id }, unitTransfersByChute?.[toCdsku]);

  for (const chute of Object.keys(unitTransfersByChute)) {
    newUnitTransfersByChute[chute] = [...unitTransfersByChute[chute]];
  }

  if (unitTransferChuteIndex !== -1) {
    newUnitTransfersByChute[toCdsku][unitTransferChuteIndex] = newUnitTransfer;
  } else {
    newUnitTransfersByChute[toCdsku].push(newUnitTransfer);
  }

  return newUnitTransfersByChute;
};
