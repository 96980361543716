export enum ScanFlowButtonType {
  UNDO_ADD_CASE = "UNDO_ADD_CASE",
  UNDO_SHIP_PALLET = "UNDO_SHIP_PALLET",

  BOX_BREAK_END_SESSION = "BOX_BREAK_END_SESSION",
  BOX_BREAK_PAUSE_SESSION = "BOX_BREAK_PAUSE_SESSION",
  BOX_BREAK_REMOVE_CONTAINER_CANCEL = "BOX_BREAK_REMOVE_CONTAINER_CANCEL",
  BOX_BREAK_END_BREAK_COMPLETE = "BOX_BREAK_END_BREAK_COMPLETE",
  BOX_BREAK_UNIT_COUNT_COMPLETE = "BOX_BREAK_UNIT_COUNT_COMPLETE",
  BOX_BREAK_UNIT_COUNT_CANCEL = "BOX_BREAK_UNIT_COUNT_CANCEL",
  BOX_BREAK_EJECT_BOX = "BOX_BREAK_EJECT_BOX",
  BOX_BREAK_BULK_TRANSFER_CONFIRM = "BOX_BREAK_BULK_TRANSFER_CONFIRM",
  BOX_BREAK_BULK_TRANSFER_BACK_TO_SCAN_ANY = "BOX_BREAK_BULK_TRANSFER_BACK_TO_SCAN_ANY",

  MISSING_LABEL_SCANNING_COMPLETE = "MISSING_LABEL_SCANNING_COMPLETE",
  MISSING_LABEL_PRINT_LABELS_BUTTON = "MISSING_LABEL_PRINT_LABELS_BUTTON",

  BOX_AUDIT_SCANNING_COMPLETE = "BOX_AUDIT_SCANNING_COMPLETE",
  BOX_AUDIT_CONFIRM_AUDIT_BUTTON = "BOX_AUDIT_CONFIRM_AUDIT_BUTTON",

  DAMAGED_PRODUCT_DONE = "DAMAGED_PRODUCT_DONE",

  FREIGHT_ARRIVAL_MOVE_PALLET_NOW_DONE = "FREIGHT_ARRIVAL_MOVE_PALLET_NOW_DONE",
  FREIGHT_ARRIVAL_BACK_TO_SCAN_FPL = "FREIGHT_ARRIVAL_BACK_TO_SCAN_FPL",
  FREIGHT_ARRIVAL_BACK_TO_MOVE_PALLET_NOW = "FREIGHT_ARRIVAL_BACK_TO_MOVE_PALLET_NOW",
  FREIGHT_ARRIVAL_USE_LAST_PO_BUTTON = "FREIGHT_ARRIVAL_USE_LAST_PO_BUTTON",
  FREIGHT_ARRIVAL_MOVE_UNBUNDLED_DONE_BUTTON = "FREIGHT_ARRIVAL_MOVE_UNBUNDLED_DONE_BUTTON",
  FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET_DONE_BUTTON = "FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET_DONE_BUTTON",
  FREIGHT_ARRIVAL_ENTER_PO_DONE_BUTTON = "FREIGHT_ARRIVAL_ENTER_PO_DONE_BUTTON",
  FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT_DONE_BUTTON = "FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT_DONE_BUTTON",

  FC_DIMS_AND_WEIGHT_CANCEL_BUTTON = "FC_DIMS_AND_WEIGHT_CANCEL_BUTTON",
  FC_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON = "FC_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON",
  FC_DIMS_AND_WEIGHT_COMPLETE_BUTTON = "FC_DIMS_AND_WEIGHT_COMPLETE_BUTTON",

  CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON = "CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON",
  CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON = "CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON",
  CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_PRODUCT_DIMS_SCAN_BUTTON = "CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_PRODUCT_DIMS_SCAN_BUTTON",
  CROSSDOCK_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON = "CROSSDOCK_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON",
  CROSSDOCK_DIMS_AND_WEIGHT_SUBMIT_IMAGES_BUTTON = "CROSSDOCK_DIMS_AND_WEIGHT_SUBMIT_IMAGES_BUTTON",
}
