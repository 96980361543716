import React from "react";
import { useIntl, FormattedMessage } from "react-intl";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useBoxBreakVerifyChute } from "./useBoxBreakVerifyChute";
import { Chute } from "@deliverr/commons-clients";
import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";
import { TextInputCardProps } from "@deliverr/ui-facility/lib-facility/flow/cards/TextInputCard/TextInputCardProps";

const DarkTextInputCard = styled(TextInputCard)<TextInputCardProps, DefaultTheme>(
  ({ theme }) => `
  background-color: ${theme.colors.BLUE["500"]};
  h2, p {
    color: ${theme.colors.NEUTRAL["00"]};
  }
`
);

export interface BoxBreakVerifyChuteCardProps {
  chuteToVerify: Chute;
  postVerifyTransition?: FlowTransition;
}

export const BoxBreakVerifyChuteCard: React.FC<BoxBreakVerifyChuteCardProps> = ({
  chuteToVerify,
  postVerifyTransition,
}) => {
  const { formatMessage } = useIntl();
  const { chuteBarcode, onChuteBarcodeChange, submitVerifyChute, errorMessage } = useBoxBreakVerifyChute(
    chuteToVerify,
    postVerifyTransition
  );

  return (
    <DarkTextInputCard
      title={
        <FormattedMessage
          id="crossdock.boxBreakVerifyChuteCard.title"
          defaultMessage="Scan Chute {chuteLetter}"
          values={{ chuteLetter: chuteToVerify.letter }}
        />
      }
      message={
        <FormattedMessage
          id="crossdock.boxBreakVerifyChuteCard.helpText"
          defaultMessage="Scan chute to confirm unit placement"
        />
      }
      value={chuteBarcode}
      placeholder={formatMessage(WarehouseMessages.chuteScanPlaceholder)}
      icon="scan"
      onChange={onChuteBarcodeChange}
      accentColor="BLUE" // revisit icon bg for this darker bg card when we switch icon sets
      errorMessage={errorMessage}
      onSubmit={submitVerifyChute}
      newest={true}
    />
  );
};
