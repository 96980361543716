import { FlowTransition, FlowTransitionDuration } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import {
  createLinkContainerScanChuteCard,
  createLinkContainerScanContainerCard,
  createLinkContainerSuccessCard,
} from "../boxBreakCardCreators";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import {
  getContainerScannedNotification,
  containerNotLinkedNotification,
  getContainerChuteConnectedNotification,
} from "crossdock/modes/break/boxBreakNotifications";

export const toLinkContainerScanChute = (containerBarcode: string): FlowTransition => ({
  card: createLinkContainerScanChuteCard({ containerBarcode }),
  notification: getContainerScannedNotification(containerBarcode),
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
});

export const backToLinkContainerScanContainer = (): FlowTransition => ({
  card: createLinkContainerScanContainerCard({}),
  notification: containerNotLinkedNotification,
  sfx: SoundFx.ERROR,
  flash: "DANGER",
});

export const toLinkContainerSuccess = (chuteLetter: string): FlowTransition => ({
  duration: FlowTransitionDuration.MEDIUM,
  card: createLinkContainerSuccessCard({ chuteLetter }),
  notification: getContainerChuteConnectedNotification(chuteLetter),
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
});
