import { useResetRecoilState } from "recoil";
import { useLifecycles } from "react-use";
import React from "react";
import { createClosePalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";
import { ScanFlow } from "crossdock/common/flow/ScanFlow";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { palletLabelIdState } from "./ClosePalletState";

export const ClosePallet: React.FC = () => {
  const { addFlowCard, resetFlowCards } = useScanFlow();
  const resetPalletLabelId = useResetRecoilState(palletLabelIdState);

  useLifecycles(
    () => {
      addFlowCard(createClosePalletScanPalletCard({}));
    },
    () => {
      resetFlowCards();
      resetPalletLabelId();
    }
  );

  return <ScanFlow />;
};
