import { CrossdockError } from "@deliverr/commons-clients";
import { TransferPalletActionSource, InboundError } from "@deliverr/legacy-inbound-client";
import { createErrorTextCard, createUnknownErrorCard } from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import { log, logError, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  createAddCaseClosedPalletErrorCard,
  createAddCaseScanCaseCard,
  createAddCaseScanPalletCard,
  createAddCaseScanPalletSuccessCard,
  createInvalidPalletInputCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { cdskuState, palletIdState } from "crossdock/modes/transfers/AddCaseToPallet/AddCaseToPalletState";
import { validatePalletScan } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { crossdockClient } from "crossdock/base/Clients";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { ADD_CASE_SCAN_PALLET_LABELS as labels } from "./AddCaseScanPalletCard.labels";
import { useIntl } from "react-intl";
import { debounce } from "lodash";
import { useCallback } from "react";

export function useAddCaseScanPallet() {
  const { addFlowCard, hideAllFlowButtons, errorResponse, successResponse } = useScanFlow();
  const cdsku = useRecoilValue(cdskuState);
  const resetCdsku = useResetRecoilState(cdskuState);
  const [palletId, setPalletId] = useRecoilState(palletIdState);
  const resetPalletId = useResetRecoilState(palletIdState);
  const { warehouseId } = useRecoilValue(userState);
  const { formatMessage } = useIntl();

  const handleSubmitPalletError = (err: any, newPalletId: string) => {
    const ctx = { fn: "submitPalletId", cdsku, palletId: newPalletId };

    if (
      err.code === InboundError.DESTINATIONS_DO_NOT_MATCH ||
      err.code === CrossdockError.ITEM_PALLET_DESTINATION_MISMATCH
    ) {
      log(ctx, "pallet and case destination mismatch");
      addFlowCard(
        createErrorTextCard({
          value: newPalletId,
          title: formatMessage(labels.destinationMismatchTitle),
          message: formatMessage(labels.destinationMismatchMessage),
        })
      );
    } else if (err.subcode === InboundError.PACKAGE_NEED_MEASUREMENT) {
      log(ctx, "Box needs measurements");
      addFlowCard(
        createErrorTextCard({
          value: newPalletId,
          title: formatMessage(labels.boxNeedsMeasurementTitle),
          message: err.message,
        })
      );
    } else if (err.subcode === InboundError.PACKAGE_NEEDS_AUDIT) {
      log(ctx, "Box needs audit");
      addFlowCard(
        createErrorTextCard({
          value: newPalletId,
          title: formatMessage(labels.boxNeedsAuditTitle),
          message: err.message,
        })
      );
    } else if (err.code === InboundError.INVALID_PALLET_SCAN || err.code === CrossdockError.INVALID_TRANSFER_PALLET) {
      log(ctx, "invalid pallet id");
      addFlowCard(createInvalidPalletInputCard({ value: newPalletId }));
    } else if (err.code === InboundError.ADD_CDSKU_CLOSED_PALLET) {
      log(ctx, "attempted to add a case to a closed pallet");
      addFlowCard(createAddCaseClosedPalletErrorCard({ value: newPalletId }));
    } else if (err.code === CrossdockError.PALLET_NOT_FOUND) {
      log(ctx, "Pallet not found");
      addFlowCard(createInvalidPalletInputCard({ value: newPalletId }));
    } else if (err.code === InboundError.UNROUTEABLE_PALLET) {
      log(ctx, "Linking box would result in unrouteable pallet");
      addFlowCard(
        createErrorTextCard({
          value: newPalletId,
          title: formatMessage(labels.unrouteablePalletTitle),
          message: formatMessage(labels.unrouteablePalletMessage),
        })
      );
    } else {
      logError(ctx, err);
      addFlowCard(createUnknownErrorCard({ value: newPalletId }));
    }

    addFlowCard(createAddCaseScanPalletCard({}));
  };

  const submitPalletId = async (newPalletId: string) => {
    const ctx = logStart({ fn: "submitPalletId", cdsku, palletId: newPalletId });

    if (!validatePalletScan(newPalletId)) {
      log(ctx, "invalid pallet id");
      errorResponse(resetPalletId);
      addFlowCard(createInvalidPalletInputCard({ value: newPalletId }));
      addFlowCard(createAddCaseScanPalletCard({}));
      return;
    }

    try {
      const reqOptions = { actionSource: TransferPalletActionSource.TRANSFERS_ADD_CASES_TO_PALLET };
      const pallet = await crossdockClient.linkPalletByLabel(newPalletId, cdsku, warehouseId, reqOptions);

      resetCdsku();
      resetPalletId();
      successResponse();
      addFlowCard(
        createAddCaseScanPalletSuccessCard({ cdsku, palletId: newPalletId, totalCases: pallet.items.length })
      );
      addFlowCard(createAddCaseScanCaseCard({}));
      hideAllFlowButtons();
    } catch (err) {
      errorResponse(resetPalletId);
      handleSubmitPalletError(err, newPalletId);
    }
  };

  const debouncedSubmitPalletId = useCallback(debounce(submitPalletId, 1000), []);

  const onPalletIdChange = onScannerInputChange(palletId, setPalletId, debouncedSubmitPalletId);

  return {
    cdsku,
    palletId,
    onPalletIdChange,
    debouncedSubmitPalletId,
  };
}
