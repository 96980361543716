import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useMount } from "react-use";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import {
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightProductDimsState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";

export const useCrossdockDimsAndWeightCaptureCubiscanImages = () => {
  const product = useRecoilValue(crossdockDimsAndWeightCurrentProductState);
  const dims = useRecoilValue(crossdockDimsAndWeightProductDimsState);

  const { showFlowButton, hideAllFlowButtons } = useScanFlow();

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON);
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_SUBMIT_IMAGES_BUTTON);
  });

  return {
    productInfo: product,
    dims,
  };
};
