import { Chute } from "@deliverr/commons-clients";
import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import {
  createBoxBreakScanBoxCard,
  createRemoveContainerCard,
  createLinkContainerScanContainerCard,
  createBoxBreakScanAnyUnitCard,
} from "../boxBreakCardCreators";
import { getRemoveContainerNotification } from "crossdock/modes/break/boxBreakNotifications";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";

export const toScanBox = (): FlowTransition => ({
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
  card: createBoxBreakScanBoxCard({}),
});

export const toScanUnit = (): FlowTransition => ({
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
  card: createBoxBreakScanAnyUnitCard({}),
});

export const toRemoveContainer = (mislinkedChute: Chute): FlowTransition => ({
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
  notification: getRemoveContainerNotification(mislinkedChute.letter),
  card: createRemoveContainerCard({ chuteToRemoveFrom: mislinkedChute }),
});

export const toAddContainer = (): FlowTransition => ({
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
  card: createLinkContainerScanContainerCard({}),
});
