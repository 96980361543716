import { Button } from "@deliverr/ui";
import React from "react";
import { useUndoAddCasePalletButton } from "./useUndoAddCasePalletButton";

export const UndoAddCasePalletButton: React.FC = () => {
  const { onUndoAddCasePalletButton } = useUndoAddCasePalletButton();

  return (
    <Button secondary onClick={onUndoAddCasePalletButton}>
      Cancel
    </Button>
  );
};
