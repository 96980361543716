import { defineMessages } from "react-intl";

export const SHIP_PALLET_SCAN_PALLET_SUCCESS_CARD_LABELS = defineMessages({
  bundledTitle: {
    id: "crossdock.shipPalletScanPalletSuccessCard.bundledTitle",
    defaultMessage: "Pallet scanned",
  },
  unbundledTitle: {
    id: "crossdock.shipPalletScanPalletSuccessCard.nbundledTitle",
    defaultMessage: "Freight pallet scanned",
  },
});
