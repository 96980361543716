import { defineMessages } from "react-intl";

export const CREATE_CONTAINERS_LABELS = defineMessages({
  title: {
    id: "crossdock.createContainersAmountCard.title",
    defaultMessage: "Enter Amount",
  },
  printLabelsButton: {
    id: "crossdock.createContainersAmountCard.stationNotSetup",
    defaultMessage: "Print Labels",
  },
});
