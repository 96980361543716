import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export const useCrossdockDamagedProduct = () => {
  const { resetFlow } = useScanFlow();

  const onUnmount = () => {
    resetFlow();
  };

  return {
    resetFlow,
    onUnmount,
  };
};
