import { CenterSpinner, log } from "@deliverr/ui-facility";
import React, { ReactElement, Suspense, useEffect } from "react";
import { PalletsTable } from "./PalletsTable";
import { useIntl } from "react-intl";
import { PALLETS_LABELS } from "./pallets.labels";
import { toast } from "@deliverr/ui";

export const PalletsTableView: React.FC<PalletsTableViewProps> = ({ appReady, onLoad, palletDetailsRows }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    onLoad().catch((error) => {
      log({ error }, "Failed to load pallets details");
      toast.error(formatMessage(PALLETS_LABELS.notification.error));
    });
  }, [onLoad, formatMessage]);

  return (
    <Suspense fallback={<CenterSpinner loading={true} />}>
      <CenterSpinner loading={!appReady}>
        <PalletsTable
          caption={formatMessage(PALLETS_LABELS.pallets.caption)}
          headings={Object.values(PALLETS_LABELS.pallets.columns).map((descriptor) => formatMessage(descriptor))}
          rows={palletDetailsRows}
        />
      </CenterSpinner>
    </Suspense>
  );
};

export interface PalletsTableViewProps {
  onLoad: () => Promise<void>;
  palletDetailsRows: ReactElement[][];
  appReady: boolean;
}
