import { useResetRecoilState } from "recoil";
import { useLifecycles } from "react-use";
import React from "react";
import { createReopenPalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";
import { ScanFlow } from "crossdock/common/flow/ScanFlow";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { palletLabelIdState } from "./ReopenPalletState";

export const ReopenPallet: React.FC = () => {
  const { addFlowCard, resetFlowCards } = useScanFlow();
  const resetPalletLabelId = useResetRecoilState(palletLabelIdState);

  useLifecycles(
    () => {
      addFlowCard(createReopenPalletScanPalletCard({}));
    },
    () => {
      resetFlowCards();
      resetPalletLabelId();
    }
  );

  return <ScanFlow />;
};
