import { useState } from "react";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { INVALID_CHUTE_ERROR, WRONG_CHUTE_ERROR } from "crossdock/common/flow/inputErrorMessages";
import { Chute } from "@deliverr/commons-clients";
import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { useBoxBreakScanUnit, useBoxBreakState } from "../../hooks";
import { getScanAnyUnitTransition } from "../../../transitions";
import { isValidBoxBreakChuteBarcode } from "@deliverr/crossdock-service-client";

// checking to make sure user put the scanned units in the correct chute
export function useBoxBreakVerifyChute(chuteToVerify: Chute, postVerifyTransition?: FlowTransition) {
  const { errorMessage, inputError, resetErrorOnExecution, lastUnitScanned, chuteByBarcode } = useBoxBreakState();
  const [chuteBarcode, setChuteBarcode] = useState<string>("");
  const resetChuteBarcode = () => {
    setChuteBarcode("");
  };
  const { transition, handleUnknownError } = useScanFlow();
  const { commitUnitTransfer } = useBoxBreakScanUnit();

  const submitVerifyChute = async (newChute: string): Promise<void> => {
    const ctx = logStart({ fn: "submitVerifyChute", chuteBarcode: newChute });

    if (!isValidBoxBreakChuteBarcode(newChute)) {
      log(ctx, "invalid chute id");
      inputError(INVALID_CHUTE_ERROR, resetChuteBarcode);
      return;
    } else if (newChute !== chuteToVerify.barcode) {
      log(ctx, "scanned unexpected chute");
      inputError(WRONG_CHUTE_ERROR, resetChuteBarcode);
      return;
    }

    const chute = chuteByBarcode[newChute];
    try {
      log(ctx, "set units transferred");
      await commitUnitTransfer(chute.barcode);

      if (postVerifyTransition) {
        transition(postVerifyTransition);
        return;
      }

      transition(getScanAnyUnitTransition(lastUnitScanned, chute.letter));
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  const onChuteBarcodeChange = onScannerInputChange(chuteBarcode, setChuteBarcode, submitVerifyChute, "upper");

  return {
    chuteBarcode,
    onChuteBarcodeChange,
    submitVerifyChute: resetErrorOnExecution(submitVerifyChute),
    errorMessage,
  };
}
