import { TransferPalletActionType, TransferPalletActionSource } from "@deliverr/legacy-inbound-client";
import { useMount } from "react-use";
import { useRecoilState, useRecoilValue } from "recoil";
import { createShipPalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";
import { handleScanPidPalletSuccess } from "../ShipPalletScanPalletCard/useShipPalletScanPallet";
import { palletIdState } from "../ShipPalletState";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { inboundClient, crossdockClient } from "crossdock/base/Clients";

export function useShipPalletNotClosedModal() {
  const { hideModal } = useCrossdockModal();
  const { warehouseId } = useRecoilValue(userState);
  const [palletId, setPalletId] = useRecoilState(palletIdState);
  const { resetFlow, addFlowCard, showFlowButton, emitFlash } = useScanFlow();

  // log pallet action for scanning and OPEN pallet on mount - no need to wait for result
  useMount(async () => {
    await inboundClient.logPalletAction(palletId, warehouseId, TransferPalletActionType.SCAN_OPEN_PALLET, {
      actionSource: TransferPalletActionSource.TRANSFERS_SHIP_PALLET,
      actionSucceeded: true,
    });
  });

  async function onConfirmClick() {
    const pallet = await crossdockClient.closeOutboundPallet(palletId, warehouseId!, {
      actionSource: TransferPalletActionSource.TRANSFERS_SHIP_PALLET,
    });

    handleScanPidPalletSuccess(
      addFlowCard,
      showFlowButton,
      emitFlash,
      (pallet as any).barcode,
      pallet.trackingCode,
      true
    );

    hideModal(TransfersModal.TRANSFERS_SHIP_PALLET_NOT_CLOSED);
  }

  async function onCancelClick() {
    resetFlow();
    setPalletId("");
    addFlowCard(createShipPalletScanPalletCard({}));

    // log pallet action for declining closing a pallet - no need to wait for result
    await inboundClient.logPalletAction(palletId, warehouseId, TransferPalletActionType.DECLINE_CLOSE_PALLET, {
      actionSource: TransferPalletActionSource.TRANSFERS_SHIP_PALLET,
      actionSucceeded: true,
    });

    hideModal(TransfersModal.TRANSFERS_SHIP_PALLET_NOT_CLOSED);
  }

  return {
    onConfirmClick,
    onCancelClick,
  };
}
