import React from "react";
import { infoModalIcon, ModalTemplate } from "@deliverr/ui-facility";
import { PrepRequiredModalProps, usePrepRequiredModal } from "./usePrepRequiredModal";
import { Button, Stack, TextProps, Text, Box } from "@deliverr/ui";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import styled from "@emotion/styled";

const CenteredText = styled(Text)<TextProps>(`
  text-align: center;
`);

export const PrepRequiredModal: React.FC<PrepRequiredModalProps> = (props) => {
  const { title, message, poNumLabel, buttonLabel, handleConfirmClick } = usePrepRequiredModal(props);

  return (
    <ModalTemplate
      title={title}
      content={
        <Box paddingBottom="S5">
          <Stack center gap="S5">
            <CenteredText>{message}</CenteredText>
            {props.poNum && <CenteredText bold>{poNumLabel}</CenteredText>}
          </Stack>
        </Box>
      }
      iconProps={infoModalIcon}
      soundFx={SoundFx.INFO}
    >
      <Button onClick={handleConfirmClick}>{buttonLabel}</Button>
    </ModalTemplate>
  );
};
