import { useRecoilValue } from "recoil";

import { boxContentsQtyLoadingState, scannedBoxAuditState } from "../BoxAuditState";
import { useScanBoxAuditSku } from "../useScanBoxAuditSku";
import { mapBoxAuditItemsToList } from "../utils";

export function useBoxAuditBoxContents() {
  const sortedAuditDskus = useRecoilValue(scannedBoxAuditState);
  const loading = useRecoilValue(boxContentsQtyLoadingState);

  const { onBoxContentsQtyChange } = useScanBoxAuditSku();

  return {
    boxContents: mapBoxAuditItemsToList(sortedAuditDskus?.items),
    loading,
    onBoxContentsQtyChange,
  };
}
