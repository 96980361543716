import { FlowButtonMap } from "@deliverr/ui-facility/lib-facility/flow/types";
import { UndoAddCasePalletButton } from "crossdock/modes/transfers/AddCaseToPallet";
import { UndoShipPalletButton } from "crossdock/modes/transfers/ShipPallet";
import { ScanFlowButtonType } from "../ScanFlowButtonType";
import {
  BoxBreakEndSessionButton,
  BoxBreakRemoveContainerCancelButton,
  BoxBreakEndBreakCompleteButton,
  BoxBreakUnitCountCompleteButton,
  BoxBreakUnitCountCancelButton,
  BoxBreakEjectBoxButton,
  BackToScanAnyButton,
  ConfirmBulkTransferButton,
} from "crossdock/modes/break/BoxBreak/buttons";
import {
  FreightArrivalBackToMovePalletNowButton,
  FreightArrivalBackToScanFPLButton,
  FreightArrivalMovePalletNowDoneButton,
  FreightArrivalUseLastPoButton,
  FreightArrivalMoveUnbundledDoneButton,
  FreightArrivalHighPriorityPalletDoneButton,
  FreightArrivalEnterPoDoneButton,
  FreightArrivalSendToFulfillmentDoneButton,
} from "crossdock/modes/transfers/FreightArrival/buttons";
import {
  MissingLabelDoneScanningButton,
  MissingLabelPrintLabelsButton,
} from "crossdock/modes/problem/MissingLabel/missingLabelButtons";
import { BoxAuditDoneScanningButton } from "crossdock/modes/problem/BoxAudit/BoxAuditDoneScanningButton";
import { BoxAuditConfirmAuditButton } from "crossdock/modes/problem/BoxAudit/BoxAuditConfirmAuditButton";
import { BoxBreakPauseSessionButton } from "crossdock/modes/break/BoxBreak/buttons/BoxBreakPauseSessionButton";
import { FcDimsAndWeightCancelButton } from "crossdock/modes/inspection/fc-dims-and-weight/buttons/cancel/FcDimsAndWeightCancelButton";
import { CrossdockDimsAndWeightEndSessionButton } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightEndSessionButton/CrossdockDimsAndWeightEndSessionButton";
import { CrossdockDimsAndWeightCancelSessionButton } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightCancelSessionButton/CrossdockDimsAndWeightCancelSessionButton";
import { CrossdockDimsAndWeightCancelProductDimsScanButton } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightCancelProductDimsScanButton/CrossdockDimsAndWeightCancelProductDimsScanButton";
import { FcDimsAndWeightBackToProductDimsButton } from "crossdock/modes/inspection/fc-dims-and-weight/buttons/backToProductDims/FcDimsAndWeightBackToProductDimsButton";
import { FcDimsAndWeightCompleteButton } from "crossdock/modes/inspection/fc-dims-and-weight/buttons/completeButton/FcDimsAndWeightCompleteButton";
import { CrossdockDimsAndWeightSubmitImagesButton } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/submitImagesButton/CrossdockDimsAndWeightSubmitImagesButton";
import { CrossdockDimsAndWeightBackToProductDimsButton } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/backToProductDims/CrossdockDimsAndWeightBackToProductDimsButton";

export const scanFlowButtonMap: FlowButtonMap = {
  [ScanFlowButtonType.UNDO_ADD_CASE]: UndoAddCasePalletButton,
  [ScanFlowButtonType.UNDO_SHIP_PALLET]: UndoShipPalletButton,

  [ScanFlowButtonType.BOX_BREAK_END_SESSION]: BoxBreakEndSessionButton,
  [ScanFlowButtonType.BOX_BREAK_PAUSE_SESSION]: BoxBreakPauseSessionButton,
  [ScanFlowButtonType.BOX_BREAK_REMOVE_CONTAINER_CANCEL]: BoxBreakRemoveContainerCancelButton,
  [ScanFlowButtonType.BOX_BREAK_END_BREAK_COMPLETE]: BoxBreakEndBreakCompleteButton,
  [ScanFlowButtonType.BOX_BREAK_UNIT_COUNT_COMPLETE]: BoxBreakUnitCountCompleteButton,
  [ScanFlowButtonType.BOX_BREAK_UNIT_COUNT_CANCEL]: BoxBreakUnitCountCancelButton,
  [ScanFlowButtonType.BOX_BREAK_EJECT_BOX]: BoxBreakEjectBoxButton,
  [ScanFlowButtonType.BOX_BREAK_BULK_TRANSFER_BACK_TO_SCAN_ANY]: BackToScanAnyButton,
  [ScanFlowButtonType.BOX_BREAK_BULK_TRANSFER_CONFIRM]: ConfirmBulkTransferButton,

  [ScanFlowButtonType.MISSING_LABEL_SCANNING_COMPLETE]: MissingLabelDoneScanningButton,
  [ScanFlowButtonType.MISSING_LABEL_PRINT_LABELS_BUTTON]: MissingLabelPrintLabelsButton,

  [ScanFlowButtonType.BOX_AUDIT_SCANNING_COMPLETE]: BoxAuditDoneScanningButton,
  [ScanFlowButtonType.BOX_AUDIT_CONFIRM_AUDIT_BUTTON]: BoxAuditConfirmAuditButton,

  [ScanFlowButtonType.FREIGHT_ARRIVAL_BACK_TO_SCAN_FPL]: FreightArrivalBackToScanFPLButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_BACK_TO_MOVE_PALLET_NOW]: FreightArrivalBackToMovePalletNowButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_MOVE_PALLET_NOW_DONE]: FreightArrivalMovePalletNowDoneButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_USE_LAST_PO_BUTTON]: FreightArrivalUseLastPoButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_MOVE_UNBUNDLED_DONE_BUTTON]: FreightArrivalMoveUnbundledDoneButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET_DONE_BUTTON]: FreightArrivalHighPriorityPalletDoneButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_ENTER_PO_DONE_BUTTON]: FreightArrivalEnterPoDoneButton,
  [ScanFlowButtonType.FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT_DONE_BUTTON]: FreightArrivalSendToFulfillmentDoneButton,

  [ScanFlowButtonType.FC_DIMS_AND_WEIGHT_CANCEL_BUTTON]: FcDimsAndWeightCancelButton,
  [ScanFlowButtonType.FC_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON]: FcDimsAndWeightBackToProductDimsButton,
  [ScanFlowButtonType.FC_DIMS_AND_WEIGHT_COMPLETE_BUTTON]: FcDimsAndWeightCompleteButton,

  [ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON]: CrossdockDimsAndWeightEndSessionButton,
  [ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON]: CrossdockDimsAndWeightCancelSessionButton,
  [ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_PRODUCT_DIMS_SCAN_BUTTON]:
    CrossdockDimsAndWeightCancelProductDimsScanButton,
  [ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_BACK_TO_PRODUCT_DIMS_BUTTON]:
    CrossdockDimsAndWeightBackToProductDimsButton,
  [ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_SUBMIT_IMAGES_BUTTON]: CrossdockDimsAndWeightSubmitImagesButton,
};
