import { Title } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import React from "react";
import { usePalletsTable } from "./usePalletsTable";
import { PalletsTableView } from "./PalletsTableView";

export const PalletsPage: React.FC = () => {
  const { appReady, getPalletDetailsOnLoad, rows, warehouseId } = usePalletsTable();

  return (
    <div>
      <Title textAlign={TextAlign.center} displayAs="h2" uppercase={true}>
        {warehouseId}
      </Title>

      <PalletsTableView appReady={appReady} onLoad={getPalletDetailsOnLoad} palletDetailsRows={rows} />
    </div>
  );
};
