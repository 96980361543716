import { DateFormat } from "@deliverr/ui-facility/lib-facility/utils/dateUtils";
import format from "date-fns/format";
import { useSetRecoilState } from "recoil";
import { createScannerScanCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { cdskuState, scanInputDisabledState } from "../caseScanState";
import { CaseScannerModal } from "./CaseScannerModal";

export function useCaseScannerDuplicateLabelPalletModal(itemCreatedAt?: Date) {
  const { hideModal } = useCrossdockModal();
  const { addFlowCard, resetFlowCards } = useScanFlow();
  const setScanInputDisabled = useSetRecoilState(scanInputDisabledState);
  const setCdsku = useSetRecoilState(cdskuState);

  const scannedAt = itemCreatedAt ? format(new Date(itemCreatedAt!), DateFormat.DateAtTime) : undefined;

  const onConfirmClick = () => {
    resetFlowCards();
    hideModal(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PALLET);
    setScanInputDisabled(false);
    setCdsku("");

    addFlowCard(createScannerScanCard({}));
  };

  return {
    scannedAt,
    onConfirmClick,
  };
}
