import { useState } from "react";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useConnectToPrinterWarning } from "crossdock/common/zebra";
import { useCreateAudit } from "../useCreateAudit";

export function useBoxAuditScanBoxInfo() {
  const { onCreateAuditSubmit, errorMessage } = useCreateAudit();
  const [boxInfo, setBoxInfo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const { verifyAndShowPrinterConnected } = useConnectToPrinterWarning(true);

  const submitBoxInfo = async (newBoxInfo: string) => {
    const ctx = logStart({ fn: "submitBoxInfo", boxInfo: newBoxInfo });
    setLoading(true);
    if (verifyAndShowPrinterConnected() && newBoxInfo !== "") {
      await onCreateAuditSubmit(newBoxInfo);
    }
    setBoxInfo("");
    log(ctx, "submitted box info");
    setLoading(false);
  };

  const onBoxInfoChange = genericOnScannerInputChange(boxInfo, setBoxInfo, submitBoxInfo, "upper");

  return {
    boxInfo,
    submitBoxInfo,
    onBoxInfoChange,
    loading,
    errorMessage,
  };
}
