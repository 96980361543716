// Currently error messages come from the backend in these full sentences.
// Invalid pallet ID is different because it is a user input error
export enum FreightArrivalScanPalletError {
  INVALID_PALLET_ID = "INVALID_PALLET_ID",
  SCANNED_AT_WRONG_CROSSDOCK = "SCANNED_AT_WRONG_CROSSDOCK",
  NOT_FOUND = "NOT_FOUND",
  PALLET_ALREADY_MARKED_AS_ARRIVED = "PALLET_ALREADY_MARKED_AS_ARRIVED",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  NO_RECEIVABLE_PALLETS = "NO_RECEIVABLE_PALLETS",
}

export const FreightArrivalScanPalletErrorMap: Record<string, FreightArrivalScanPalletError> = {
  "FreightPallet not found": FreightArrivalScanPalletError.NOT_FOUND,
  "Pallet received at incorrect destination": FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK,
  "Current FreightPallet has already been marked as arrived.":
    FreightArrivalScanPalletError.PALLET_ALREADY_MARKED_AS_ARRIVED,
  "FreightPallet may not transition from current status to given desired status":
    FreightArrivalScanPalletError.INVALID_PALLET_ID,
  "No receivable pallets remain for current PO#.": FreightArrivalScanPalletError.NO_RECEIVABLE_PALLETS,
};
