import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import {
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightProductBarcodeState,
  crossdockDimsAndWeightProductDimsState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { useLoading } from "crossdock/common/useLoading";
import { useIntl } from "react-intl";
import { getCubiscanDimsFromQRCode } from "crossdock/modes/inspection/utilities/getCubiscanDimsFromQRCode";
import { crossdockClient } from "crossdock/base/Clients";
import {
  toCrossdockCaptureCubiscanImages,
  toCrossdockDimsAndWeightScanProductBarcode,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { DIMS_AND_WEIGHT_ERRORS } from "crossdock/modes/inspection/constants";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { useState } from "react";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { ProductDimsAPIRequest } from "@deliverr/crossdock-service-client";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { useMount } from "react-use";

export function useDimsAndWeightScanProductDims() {
  const { warehouseId } = useRecoilValue(userState);
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [dimsSession, setDimsSession] = useRecoilState(crossdockDimsAndWeightSessionState);
  const barcode = useRecoilValue(crossdockDimsAndWeightProductBarcodeState);
  const resetBarcode = useResetRecoilState(crossdockDimsAndWeightProductBarcodeState);
  const currentProduct = useRecoilValue(crossdockDimsAndWeightCurrentProductState);
  const resetCurrentProduct = useResetRecoilState(crossdockDimsAndWeightCurrentProductState);
  const [productDimsQrCode, setProductDimsQrCode] = useState<string>("");
  const { loading, loadWhilePending } = useLoading();
  const setProductDims = useSetRecoilState(crossdockDimsAndWeightProductDimsState);

  const { formatMessage } = useIntl();

  const { hideAllFlowButtons, successResponse, infoResponse, transition, handleUnknownError, showFlowButton } =
    useScanFlow();

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_PRODUCT_DIMS_SCAN_BUTTON);
  });

  const submitProductDims = async (qrCode: string) => {
    const ctx = logStart({ fn: "submitProductDims#crossdock", qrCode });

    try {
      const dims = getCubiscanDimsFromQRCode(qrCode);

      const { height, length, width, weight, lengthUnit, weightUnit } = dims;

      log({ ...ctx, dims, dimsSession }, "dims");

      const productDimsAPIRequest: ProductDimsAPIRequest = {
        barcode: barcode!,
        warehouseId,
        ...dims,
      };

      if (currentProduct) {
        // Only known products go through the D&W problem flow
        const shouldCaptureImages = await crossdockClient.getShouldCreateDimsIssue(productDimsAPIRequest);

        if (shouldCaptureImages) {
          setProductDims(dims);
          infoResponse();
          transition(toCrossdockCaptureCubiscanImages());
          return;
        }
      }

      const updatedSession = await crossdockClient.createDimsAndWeightsSessionItem({
        barcode: barcode!,
        sessionId: dimsSession!.id,
        ...dims,
      });

      setDimsSession(updatedSession);

      successResponse();

      createSuccessNotification(
        formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsRecorded, {
          width,
          length,
          height,
          weight,
          lengthUnit,
          weightUnit,
        })
      );

      resetBarcode();
      resetCurrentProduct();

      transition(toCrossdockDimsAndWeightScanProductBarcode());
    } catch (err) {
      if (err.code === DIMS_AND_WEIGHT_ERRORS.INVALID_DIMS_QR_CODE) {
        log(ctx, "invalid QR code");
        inputError(formatMessage(DIMS_AND_WEIGHT_LABELS.invalidQrCode));
      } else {
        handleUnknownError(ctx, err);
      }
      setProductDimsQrCode("");
    }
  };

  return {
    productBarcode: barcode,
    productDimsQrCode,
    product: currentProduct,
    submitProductDims: loadWhilePending(resetErrorOnExecution(submitProductDims)),
    onProductDimsChange: genericOnScannerInputChange(
      productDimsQrCode,
      setProductDimsQrCode,
      submitProductDims,
      "upper"
    ),
    loading,
    errorMessage: inputErrorMessage,
  };
}
