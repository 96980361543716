import React from "react";

import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow";
import { FormattedMessage } from "react-intl";

export interface SortPalletToMilkRunCardErrorProps {
  palletId: string;
  title: string;
  message: string;
}

export const SortPalletToMilkRunErrorCard: React.FC<SortPalletToMilkRunCardErrorProps> = ({
  palletId,
  message,
  title,
}) => (
  <ErrorTextCard
    title={
      <FormattedMessage id="crossdock.sortPalletToMilkRunErrorCard.title" defaultMessage="{title}" values={{ title }} />
    }
    message={
      <FormattedMessage
        id="cards.sortPalletToMilkRunErrorCard.message"
        defaultMessage="{message}"
        values={{ message }}
      />
    }
    value={palletId}
    newest={false}
  />
);
