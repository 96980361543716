import React from "react";
import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { IconToIcon } from "crossdock/common/components";
import { FormattedMessage } from "react-intl";

export interface LinkContainerSuccessCardProps {
  chuteLetter: string;
}

export const LinkContainerSuccessCard: React.FC<LinkContainerSuccessCardProps> = ({ chuteLetter }) => (
  <TransitionCard
    title={<FormattedMessage id="crossdock.linkContainerSuccessCard.title" defaultMessage="Put Container Into Chute" />}
    message={
      <FormattedMessage
        id="crossdock.linkContainerSuccessCard.message"
        defaultMessage="Place the empty container inside Chute {chuteLetter}"
        values={{ chuteLetter }}
      />
    }
  >
    <IconToIcon
      leftIconProps={{
        iconType: "box",
        accentColor: "BLUE",
      }}
      rightIconProps={{
        textIcon: chuteLetter,
        accentColor: "BLUE",
      }}
    />
  </TransitionCard>
);
