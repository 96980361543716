import { FACILITY_COMMONS_BUTTON_LABELS } from "@deliverr/ui-facility";
import { defineMessages } from "react-intl";

export const FreightArrivalPalletPreviouslyRecordedModalLabels = defineMessages({
  title: {
    id: "crossdock.freightArrivalPalletPreviouslyRecordedModal.title",
    defaultMessage: "Duplicate pallet label?",
  },
  message: {
    id: "crossdock.freightArrivalPalletPreviouslyRecordedModal.message",
    defaultMessage: "{palletId} was previously recorded. Record arrival using PO# if this is a duplicate pallet label.",
  },
  confirm: {
    id: "crossdock.freightArrivalPalletPreviouslyRecordedModal.duplicateButton",
    defaultMessage: "Yes, it's a duplicate",
  },
  cancel: FACILITY_COMMONS_BUTTON_LABELS.noButton,
});
