import React from "react";
import { useRecoilValue } from "recoil";

import { AddSkuButton } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { MissingLabelModal } from "../MissingLabelModal";
import { showAddButtonState } from "../../ProblemState";

export const MissingLabelAddSkuButton: React.FC = () => {
  const showButton = useRecoilValue(showAddButtonState);
  const { showModal } = useCrossdockModal();

  return showButton ? (
    <AddSkuButton
      onClick={() => {
        showModal(MissingLabelModal.MISSING_LABEL_INPUT_BARCODE_MODAL, {});
      }}
    />
  ) : null;
};
