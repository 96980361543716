import { ScanPath } from "crossdock/base/routes/ScanPath";
import { isZebraScanner } from "@deliverr/ui-facility/lib-facility/utils";
import { ScanMenuPage } from "./ScanMenuPage";
import {
  FacilityMenuItemConfig,
  UserStateWithWarehouseId,
} from "@deliverr/ui-facility/lib-facility/components/FacilityMenu";
import { MENU_LABELS } from "./scanMenu.labels";
import { hubToSortationMap } from "./hubToSortationMap";

import { ScanMenuPageConfig } from "./ScanMenuPageConfig";

const mainScanMenuItem: FacilityMenuItemConfig = {
  icon: "scan",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].scannerHub,
  path: ScanPath.Scanner,
};
const mainFreightMenuItem: FacilityMenuItemConfig = {
  icon: "dolly",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].freight,
  nextPage: ScanMenuPage.FREIGHT,
};
const mainInspectionMenuItem: FacilityMenuItemConfig = {
  icon: "search",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].inspection,
  nextPage: ScanMenuPage.INSPECTION,
};
const mainProblemMenuItem: FacilityMenuItemConfig = {
  icon: "exclamation-triangle",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].problem,
  path: ScanPath.Problem,
};
const mainBoxBreakMenuItem: FacilityMenuItemConfig = {
  icon: "package",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].break,
  nextPage: ScanMenuPage.BOX_BREAK,
};
const mainDashboardMenuItem: FacilityMenuItemConfig = {
  icon: "chart-line",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].dashboard,
  nextPage: ScanMenuPage.DASHBOARD,
};
const mainPrinterMenuItem: FacilityMenuItemConfig = {
  icon: "print",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].managePrinters,
  isHidden: !isZebraScanner,
  path: ScanPath.ManagePrinters,
};
const mainSortationAppLinkMenuItem: FacilityMenuItemConfig = {
  icon: "truck",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].sortationApp,
  onClick: ({ warehouseId }: UserStateWithWarehouseId) => {
    // cannot use history from react-router as it only allows navigation on same domain
    const sortationId = hubToSortationMap[warehouseId];
    const url = `${process.env.SORTATION_BASEURL}/?sortationid=${sortationId}`;
    window.location.replace(url);
  },
};
const mainShowDevLogsMenuItem: FacilityMenuItemConfig = {
  icon: "info-circle",
  textDescriptor: MENU_LABELS[ScanMenuPage.MAIN].showLogs,
  isHidden: !isZebraScanner || process.env.NODE_ENV !== "development",
  onClick: () => window?.EB?.Log.showLog(),
};

const freightMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.MAIN,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.FREIGHT][ScanMenuPage.RECORD_INBOUND],
      nextPage: ScanMenuPage.RECORD_INBOUND,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.FREIGHT][ScanMenuPage.MANAGE_OUTBOUND],
      nextPage: ScanMenuPage.MANAGE_OUTBOUND,
    },
  ],
};

const recordInboundMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.FREIGHT,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.RECORD_INBOUND].freightArrival,
      path: ScanPath.FreightArrival,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.RECORD_INBOUND].spdArrival,
      path: ScanPath.SmallParcelArrival,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.RECORD_INBOUND][ScanPath.MovePallet],
      path: ScanPath.MovePallet,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.RECORD_INBOUND][ScanPath.StartProcessingPallet],
      path: ScanPath.StartProcessingPallet,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.RECORD_INBOUND][ScanPath.PrintInboundPalletLabel],
      path: ScanPath.PrintInboundPalletLabel,
    },
  ],
};

const manageOutboundMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.FREIGHT,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersCreatePallet],
      path: ScanPath.TransfersCreatePallet,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersAddCase],
      path: ScanPath.TransfersAddCase,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersClosePallet],
      path: ScanPath.TransfersClosePallet,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersShipPallet],
      path: ScanPath.TransfersShipPallet,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersReopenPallet],
      path: ScanPath.TransfersReopenPallet,
    },
  ],
};

const boxBreakMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.MAIN,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.BOX_BREAK][ScanPath.BoxBreak],
      path: ScanPath.BoxBreak,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.BOX_BREAK][ScanPath.BoxBreakCreateContainers],
      path: ScanPath.BoxBreakCreateContainers,
    },
  ],
};

const dashboardMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.MAIN,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.DASHBOARD][ScanPath.PalletSummary],
      path: ScanPath.PalletSummary,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.DASHBOARD][ScanPath.Pallets],
      path: ScanPath.Pallets,
    },
  ],
};

const inspectionMenu: ScanMenuPageConfig = {
  previousPage: ScanMenuPage.MAIN,
  items: [
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.INSPECTION][ScanPath.CrossdockDimsAndWeight],
      path: ScanPath.CrossdockDimsAndWeight,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.INSPECTION][ScanPath.FcDimsAndWeight],
      path: ScanPath.FcDimsAndWeight,
    },
    {
      textDescriptor: MENU_LABELS[ScanMenuPage.INSPECTION][ScanPath.BoxAudit],
      path: ScanPath.BoxAudit,
    },
  ],
};

export const hubMenuPageMap: Record<string, ScanMenuPageConfig> = {
  [ScanMenuPage.MAIN]: {
    items: [
      mainScanMenuItem,
      {
        icon: "dolly",
        textDescriptor: MENU_LABELS[ScanMenuPage.MANAGE_OUTBOUND][ScanPath.TransfersShipPallet],
        path: ScanPath.TransfersShipPallet,
      },
      mainPrinterMenuItem,
      mainSortationAppLinkMenuItem,
      mainShowDevLogsMenuItem,
    ],
  },
};

export const crossdockMenuPageMap: Record<string, ScanMenuPageConfig> = {
  [ScanMenuPage.MAIN]: {
    items: [
      mainScanMenuItem,
      mainFreightMenuItem,
      mainInspectionMenuItem,
      mainProblemMenuItem,
      mainBoxBreakMenuItem,
      mainDashboardMenuItem,
      mainPrinterMenuItem,
      mainShowDevLogsMenuItem,
    ],
  },
  [ScanMenuPage.FREIGHT]: freightMenu,
  [ScanMenuPage.RECORD_INBOUND]: recordInboundMenu,
  [ScanMenuPage.MANAGE_OUTBOUND]: manageOutboundMenu,
  [ScanMenuPage.BOX_BREAK]: boxBreakMenu,
  [ScanMenuPage.DASHBOARD]: dashboardMenu,
  [ScanMenuPage.INSPECTION]: inspectionMenu,
};

// is both a crossdock and hub
export const multiFacilityMenuPageMap: Record<string, ScanMenuPageConfig> = crossdockMenuPageMap;
