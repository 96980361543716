import React from "react";
import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow/cards/ErrorTextCard";

export interface CaseUnreceivedCardProps {
  value: string;
  newest?: boolean;
}

export const CaseUnreceivedCard: React.FC<CaseUnreceivedCardProps> = ({ value, newest }) => {
  return (
    <ErrorTextCard
      title="Scan this box in Box Scanner mode, attach the label, then retry."
      value={value}
      newest={newest}
    />
  );
};
