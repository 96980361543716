import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import {
  boxBreakStationIdState,
  chuteBoxByBarcodeState,
  chuteByBarcodeState,
  chuteByWarehouseState,
} from "../../station/stationState";
import {
  boxBreakBoxContentsBarcodesState,
  selectedSidebarChuteIdState,
  activeChuteBarcodeValue,
} from "../sidebar/boxBreakSidebarState";
import {
  currentBoxBarcodeState,
  currentBoxBarcodeUnitCountState,
  currentRunDestinationState,
  currentUnitBarcodeState,
  lastUnitScannedState,
  boxUnitTransfersState,
  unitTransfersByChuteBoxState,
  boxContentsAllUnitsDistributionByDskuState,
} from "../boxBreakState";
import { useResetCurrentBreakState } from "./useResetCurrentBreakState";

/**
 * include all recoil states and functions that might be used by multiple components,
 * (i.e. BoxBreakCountUnitsCard, BoxBreakScanUnitCard, etc.) in the box breaking flow
 */
export function useBoxBreakState() {
  const { inputErrorMessage, resetErrorOnExecution, inputError, resetError } = useInputErrorMessage();
  const unitBarcode = useRecoilValue(currentUnitBarcodeState);
  const resetUnitBarcode = useResetRecoilState(currentUnitBarcodeState);
  const boxBreakStationId = useRecoilValue(boxBreakStationIdState);
  const cdsku = useRecoilValue(currentBoxBarcodeState);
  const chuteByWarehouse = useRecoilValue(chuteByWarehouseState);
  const chuteByBarcode = useRecoilValue(chuteByBarcodeState);
  const chuteBoxByBarcode = useRecoilValue(chuteBoxByBarcodeState);
  const [unitTransfersByChute, setUnitTransfersByChute] = useRecoilState(unitTransfersByChuteBoxState);
  const currentRunDestination = useRecoilValue(currentRunDestinationState);
  const { resetCurrentUnitRun } = useResetCurrentBreakState();
  const lastUnitScanned = useRecoilValue(lastUnitScannedState);
  const resetLastUnitScanned = useResetRecoilState(lastUnitScannedState);
  const [currentUnitCount, setCurrentUnitCount] = useRecoilState(currentBoxBarcodeUnitCountState);
  const resetCurrentUnitCount = useResetRecoilState(currentBoxBarcodeUnitCountState);

  const activeChute = useRecoilValue(activeChuteBarcodeValue);
  const [boxUnitTransfers, setBoxUnitTransfers] = useRecoilState(boxUnitTransfersState);
  const selectedSidebarChuteId = useRecoilValue(selectedSidebarChuteIdState);
  const resetSelectedSidebarChuteId = useResetRecoilState(selectedSidebarChuteIdState);
  const setBoxBreakBoxContentsBarcodes = useSetRecoilState(boxBreakBoxContentsBarcodesState);
  const [boxContentsAllUnitsDistributionByDsku, setBoxContentsAllUnitsDistributionByDsku] = useRecoilState(
    boxContentsAllUnitsDistributionByDskuState
  );

  return {
    errorMessage: inputErrorMessage,
    resetErrorOnExecution,
    inputError,
    resetErrorMessage: resetError,
    unitBarcode,
    resetUnitBarcode,
    boxBreakStationId,
    cdsku,
    chuteByWarehouse,
    chuteByBarcode,
    chuteBoxByBarcode,
    currentRunDestination,
    resetCurrentUnitRun,
    lastUnitScanned,
    resetLastUnitScanned,
    currentUnitCount,
    setCurrentUnitCount,
    resetCurrentUnitCount,
    boxUnitTransfers,
    setBoxUnitTransfers,
    unitTransfersByChute,
    setUnitTransfersByChute,
    activeChute,
    selectedSidebarChuteId,
    resetSelectedSidebarChuteId,
    setBoxBreakBoxContentsBarcodes,
    boxContentsAllUnitsDistributionByDsku,
    setBoxContentsAllUnitsDistributionByDsku,
  };
}
