import React from "react";

import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { ProblemBackButton } from "../../components/ProblemBackButton/ProblemBackButton";
import { createBoxContentsCard } from "../MissingLabelCardCreators";

export const MissingLabelBackButton: React.FC = () => (
  <ProblemBackButton
    flowCard={createBoxContentsCard({})}
    flowButtonType={ScanFlowButtonType.MISSING_LABEL_SCANNING_COMPLETE}
  />
);
