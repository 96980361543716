import { CrossdockError } from "@deliverr/commons-clients";
import { useState } from "react";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useIntl } from "react-intl";
import labels from "./FreightArrivalScanLane.labels";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useFreightArrival } from "../../useFreightArrival";
import { validateStagingLaneBarcode } from "../../../InboundPalletLabels/utils/validateStagingLaneBarcode";
import { crossdockClient } from "crossdock/base/Clients";
import { inboundPalletLabelIdState, palletDestinationLaneTypeValue } from "../../FreightArrivalState";
import { useRecoilValue } from "recoil";
import { useFreightArrivalNotifications } from "../../useFreightArrivalNotifications";
import laneTypeToLocationMessages from "../../laneTypeToLocationMessages";
import { useMount } from "react-use";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

const DEFAULT_STAGING_LANE_BARCODE = "";

export function useFreightArrivalScanLane() {
  const { formatMessage } = useIntl();
  const { successResponse, handleUnknownError, showFlowButton, hideAllFlowButtons } = useScanFlow();
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [stagingLaneBarcode, setStagingLaneBarcode] = useState<string>(DEFAULT_STAGING_LANE_BARCODE);
  const { restartFreightArrival } = useFreightArrival();
  const inboundPalletLabelId = useRecoilValue(inboundPalletLabelIdState);
  const { showScanLaneSuccessNotification } = useFreightArrivalNotifications();
  const palletDestinationLaneType = useRecoilValue(palletDestinationLaneTypeValue);

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_BACK_TO_MOVE_PALLET_NOW);
  });

  const submitStagingLaneScan = async (newStagingLaneBarcode: string): Promise<void> => {
    const ctx = logStart({ fn: "useScanLane.submitStagingLaneScan", newStagingLaneBarcode, inboundPalletLabelId });

    if (!validateStagingLaneBarcode(newStagingLaneBarcode)) {
      log(ctx, "invalid staging lane barcode");
      setStagingLaneBarcode(DEFAULT_STAGING_LANE_BARCODE);
      inputError(formatMessage(labels.invalidStagingLaneBarcode));
      return;
    }

    try {
      await crossdockClient.recordLaneDrop(newStagingLaneBarcode, inboundPalletLabelId);
      log(ctx, "pallet dropped at lane");

      successResponse();
      restartFreightArrival();
      showScanLaneSuccessNotification(inboundPalletLabelId, newStagingLaneBarcode);
    } catch (err) {
      if (err?.code === CrossdockError.INVALID_LANE_FOR_PALLET_TYPE) {
        log(ctx, "attempted to drop at wrong lane type");
        setStagingLaneBarcode(DEFAULT_STAGING_LANE_BARCODE);
        inputError(
          formatMessage(labels.unexpectedStagingLaneError, {
            dropLocation: formatMessage(laneTypeToLocationMessages[palletDestinationLaneType]),
          })
        );
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  const onScanLaneChange = onScannerInputChange(
    stagingLaneBarcode,
    setStagingLaneBarcode,
    submitStagingLaneScan,
    "upper"
  );

  return {
    inputErrorMessage,
    value: stagingLaneBarcode,
    loading,
    onChange: onScanLaneChange,
    onSubmit: loadWhilePending(resetErrorOnExecution(submitStagingLaneScan)),
  };
}
