import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useRecoilValue } from "recoil";
import { Title } from "@deliverr/ui";

import { unitTransferSkuTotalsByChuteState, unitTransferUnitTotalsByChuteState } from "../boxBreakSidebarState";
import { BoxBreakChutesOverviewItem } from "./BoxBreakChutesOverviewItem";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";

export const BoxBreakChutesOverview: React.FC = () => {
  const { chuteBoxByBarcode, activeChute } = useBoxBreakState();
  const unitTransferUnitTotalsByChute = useRecoilValue(unitTransferUnitTotalsByChuteState);
  const unitTransferSkuTotalsByChute = useRecoilValue(unitTransferSkuTotalsByChuteState);

  return (
    <>
      <Title as="h4" displayAs="h4">
        <FormattedMessage id="crossdock.boxBreaksChutesOverview.title" defaultMessage="Chutes Overview" />
      </Title>
      {chuteBoxByBarcode
        ? Object.keys(chuteBoxByBarcode)
            .sort()
            .map(
              (chuteBarcode) =>
                chuteBoxByBarcode[chuteBarcode] && (
                  <BoxBreakChutesOverviewItem
                    key={chuteBarcode.slice(-1)}
                    chuteBarcode={chuteBarcode}
                    activeChute={activeChute === chuteBarcode}
                    chuteCdsku={chuteBoxByBarcode[chuteBarcode].cdsku}
                    chuteUnitsTotal={unitTransferUnitTotalsByChute[chuteBoxByBarcode[chuteBarcode].cdsku]}
                    chuteSKUsTotal={unitTransferSkuTotalsByChute[chuteBoxByBarcode[chuteBarcode].cdsku]}
                  />
                )
            )
        : null}
    </>
  );
};
