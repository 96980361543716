import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";
import { createFlowCard } from "crossdock/common/flow/scanFlowCardCreators";

export const createFcDimsAndWeightScanProductBarcodeCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_BARCODE
);
export const createFcDimsAndWeightScanProductDimsCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.FC_DIMS_AND_WEIGHT_SCAN_PRODUCT_DIMS
);
export const createFcDimsAndWeightCaptureCubiscanImagesCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.FC_DIMS_AND_WEIGHT_CAPTURE_CUBISCAN_IMAGES
);
