import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@deliverr/ui";

import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import { useCaseScannerDuplicateLabelConfirmationModal } from "./useCaseScannerDuplicateLabelConfirmationModal";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export const CaseScannerDuplicateLabelConfirmationModal: React.FC = () => {
  const { onConfirmClick } = useCaseScannerDuplicateLabelConfirmationModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage({
        id: "warehouse.caseScannerDuplicatLabelConfirmationModal.title",
        defaultMessage: "Duplicate box label!",
      })}
      text={formatMessage(WarehouseMessages.moveToProblemAreaLabel)}
      iconProps={warningModalIcon}
    >
      <Button block onClick={onConfirmClick}>
        Done
      </Button>
    </ModalTemplate>
  );
};
