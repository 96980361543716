import { useRecoilValue } from "recoil";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";
import { destinationMismatchContextState } from "../ShipPalletState";
import { useIntl } from "react-intl";
import { DESTINATION_MISMATCH_LABELS } from "./ShipPalletDestinationMismatchModal.labels";

export function useDestinationMismatchModal() {
  const { formatMessage } = useIntl();
  const { isMilkRun, expectedDestinationWarehouseId, currentPalletToWarehouseId } = useRecoilValue(
    destinationMismatchContextState
  );
  const { hideModal } = useCrossdockModal();

  const labels = {
    title: formatMessage(DESTINATION_MISMATCH_LABELS.title),
    button: formatMessage(DESTINATION_MISMATCH_LABELS.button),
    message: formatMessage(
      isMilkRun ? DESTINATION_MISMATCH_LABELS.milkRunMsg : DESTINATION_MISMATCH_LABELS.transferMsg,
      {
        expectedDestinationWarehouseId,
        currentPalletToWarehouseId,
      }
    ),
  };

  function onDoneClick() {
    hideModal(TransfersModal.TRANSFERS_DESTINATION_MISMATCH);
  }

  return {
    labels,
    onDoneClick,
  };
}
