import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetFcDimsAndWeightState } from "crossdock/modes/inspection/fc-dims-and-weight/hooks/useResetFcDimsAndWeightState";
import { createFcDimsAndWeightScanProductBarcodeCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/CardCreators";

export const useFcDimsAndWeightCancel = () => {
  const { resetFcDimsAndWeightState } = useResetFcDimsAndWeightState();
  const { addFlowCard, hideAllFlowButtons } = useScanFlow();

  const onClick = () => {
    resetFcDimsAndWeightState();
    hideAllFlowButtons();
    addFlowCard(createFcDimsAndWeightScanProductBarcodeCard({}));
  };

  return {
    onClick,
  };
};
