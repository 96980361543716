import { defineMessages } from "react-intl";

export default defineMessages({
  palletDroppedOffNotification: {
    id: "crossdock.freightArrival.palletDroppedOffNotification",
    defaultMessage: "Pallet {palletId} dropped at lane {laneId}",
  },
  freightArrivalRecordedNotification: {
    id: "crossdock.freightArrival.freightArrivalRecordedNotification",
    defaultMessage: "Pallet {palletId} arrival recorded, left pallet at dock.",
  },
  unexpectedStagingLaneError: {
    id: "crossdock.freightArrival.unexpectedStagingLaneError",
    defaultMessage: "Unexpected Lane, drop at {dropLocation}",
  },
  useLastPoButton: {
    id: "crossdock.freightArrival.useLastPoButton",
    defaultMessage: "Use PO# {lastPoNum}",
  },
  poHeaderSubtitle: {
    id: "crossdock.freightArrival.poHeaderSubtitle",
    defaultMessage: "{inboundPalletLabelBarcode}, PO#: {poNum}",
  },
});
