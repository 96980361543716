import { useResetRecoilState } from "recoil";
import { headerSubtitleState, headerTitleState, showPageLeftActionState } from "crossdock/base/headerTitleState";
import {
  crossdockDimsAndWeightCubiscanImagesState,
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightPackageCdskuState,
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightProductBarcodeState,
  crossdockDimsAndWeightProductDimsState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";

export function useResetCrossdockDimsAndWeightState() {
  const resetCrossdockDimsAndWeightPackageCdsku = useResetRecoilState(crossdockDimsAndWeightPackageCdskuState);
  const resetCrossdockDimsAndWeightPackage = useResetRecoilState(crossdockDimsAndWeightPackageState);
  const resetCrossdockDimsAndWeightSession = useResetRecoilState(crossdockDimsAndWeightSessionState);
  const resetCrossdockDimsAndWeightProductBarcode = useResetRecoilState(crossdockDimsAndWeightProductBarcodeState);
  const resetCrossdockDimsAndWeightCurrentProduct = useResetRecoilState(crossdockDimsAndWeightCurrentProductState);
  const resetCrossdockDimsAndWeightProductDimsState = useResetRecoilState(crossdockDimsAndWeightProductDimsState);
  const resetCrossdockDimsAndWeightCubiscanImagesState = useResetRecoilState(crossdockDimsAndWeightCubiscanImagesState);

  const resetHeaderSubtitle = useResetRecoilState(headerSubtitleState);
  const resetShowPageLeftAction = useResetRecoilState(showPageLeftActionState);
  const resetHeaderTitle = useResetRecoilState(headerTitleState);

  const resetCrossdockDimsAndWeightState = () => {
    resetCrossdockDimsAndWeightPackageCdsku();
    resetCrossdockDimsAndWeightPackage();
    resetCrossdockDimsAndWeightSession();
    resetCrossdockDimsAndWeightProductBarcode();
    resetCrossdockDimsAndWeightCurrentProduct();
    resetCrossdockDimsAndWeightProductDimsState();
    resetCrossdockDimsAndWeightCubiscanImagesState();

    resetHeaderSubtitle();
    resetShowPageLeftAction();
    resetHeaderTitle();
  };

  return {
    resetCrossdockDimsAndWeightState,
  };
}
