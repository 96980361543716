import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { atom } from "recoil";
import {
  Dims,
  DimsAndWeightSession,
  NonComplianceImagesMetadata,
  Package,
  Product,
} from "@deliverr/crossdock-service-client";

export const crossdockDimsAndWeightPackageCdskuState = atom<string>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_CDSKU,
  default: "",
});

export const crossdockDimsAndWeightPackageState = atom<Package | undefined>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE,
  default: undefined,
});

export const crossdockDimsAndWeightSessionState = atom<DimsAndWeightSession | undefined>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_SESSION,
  default: undefined,
});

export const crossdockDimsAndWeightProductBarcodeState = atom<string | undefined>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_BARCODE,
  default: undefined,
});

export const crossdockDimsAndWeightCurrentProductState = atom<Product | undefined>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PRODUCT,
  default: undefined,
});

export const crossdockDimsAndWeightProductDimsState = atom<Dims | undefined>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_DIMS,
  default: undefined,
});

export const crossdockDimsAndWeightCubiscanImagesState = atom<NonComplianceImagesMetadata>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_CUBISCAN_IMAGES,
  default: {} as NonComplianceImagesMetadata,
});
