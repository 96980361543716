import React from "react";
import { LinkedCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { plural } from "@deliverr/ui-facility/lib-facility/utils/stringUtils";

export interface ShipPalletScanTrackingSuccessCardProps {
  palletId: string;
  trackingCode: string;
  numOfPalletsLinkedToTracking?: number;
}

export const ShipPalletScanTrackingSuccessCard: React.FC<ShipPalletScanTrackingSuccessCardProps> = ({
  palletId,
  trackingCode,
  numOfPalletsLinkedToTracking,
}) => (
  <LinkedCard
    icon="check"
    accentColor="GREEN"
    title="Pallet Transferred"
    message="Move the pallet into the truck"
    topValue={palletId}
    bottomValue={trackingCode}
    pillProps={
      numOfPalletsLinkedToTracking
        ? {
            color: "GRAY",
            children: plural(`${numOfPalletsLinkedToTracking} pallet`, numOfPalletsLinkedToTracking),
          }
        : undefined
    }
  />
);
