import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetRecoilState } from "recoil";
import { crossdockDimsAndWeightCubiscanImagesState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { toCrossdockDimsAndWeightScanProductDims } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";

export const useCrossdockDimsAndWeightBackToProductDims = () => {
  const resetCrossdockDimsAndWeightCubiscanImages = useResetRecoilState(crossdockDimsAndWeightCubiscanImagesState);
  const { transition } = useScanFlow();

  const onClick = () => {
    resetCrossdockDimsAndWeightCubiscanImages();
    transition(toCrossdockDimsAndWeightScanProductDims());
  };

  return {
    onClick,
  };
};
