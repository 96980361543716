import { isNaN } from "lodash/fp";
import styled from "@emotion/styled";
import React from "react";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";

interface NumberSelectProps {
  value: string;
  onChange: (newValue: string) => void;
  min?: number; // inclusive
  max?: number; // inclusive
}

const sectionWidth = 100;
const sectionHeight = 38;

const NumberSelectContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};
  border-radius: ${FACILITY_THEME.border.radius.R2};
`;

const NumberButton = styled.button`
  width: ${sectionWidth}px;
  height: ${sectionHeight}px;
  font-family: ${FACILITY_THEME.font.family.STANDARD};
  font-size: ${FACILITY_THEME.font.size.F5};
  font-weight: bold;
  color: ${FACILITY_THEME.colors.BLUE[200]};

  :disabled {
    color: ${FACILITY_THEME.colors.NEUTRAL[200]};
  }
`;

const NumberInput = styled.input`
  width: ${sectionWidth}px;
  height: ${sectionHeight}px;
  text-align: center;
  border-left: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};
  border-right: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};
`;

export const NumberSelect: React.FC<NumberSelectProps> = ({ value, onChange, min, max }) => {
  const onDecrementClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const numberValue = Number(value);
    const minValue = min ?? 0;
    const newValue = (isNaN(numberValue) || numberValue <= minValue ? minValue : numberValue - 1).toString();
    onChange(newValue);
  };

  const onIncrementClick: React.MouseEventHandler<HTMLButtonElement> = () => {
    const numberValue = Number(value);

    if (isNaN(numberValue)) {
      onChange("1");
    } else if (max && numberValue >= max) {
      onChange(max.toString());
    } else {
      onChange((numberValue + 1).toString());
    }
  };

  const onInputChange: React.FormEventHandler<HTMLInputElement> = (e) => {
    onChange(e.currentTarget.value);
  };

  return (
    <NumberSelectContainer>
      <NumberButton type="button" onClick={onDecrementClick} disabled={Number(value) === min}>
        -
      </NumberButton>
      <NumberInput value={value} type="text" onChange={onInputChange} />
      <NumberButton type="button" onClick={onIncrementClick} disabled={Number(value) === max}>
        +
      </NumberButton>
    </NumberSelectContainer>
  );
};
