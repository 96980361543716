import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { createBoxBreakScanAnyUnitCard } from "../boxBreakCardCreators";
import { getUnitInChuteNotification } from "../boxBreakNotifications";

export const getScanAnyUnitTransition = (newUnitBarcode: string, chuteLetter: string): FlowTransition => ({
  card: createBoxBreakScanAnyUnitCard({}),
  notification: getUnitInChuteNotification(newUnitBarcode, chuteLetter),
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
});
