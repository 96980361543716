import React from "react";
import { useIntl } from "react-intl";
import { UnderstandButton, dangerModalIcon, ModalTemplate } from "@deliverr/ui-facility";
import { labels } from "./BoxBreakSellerMismatchModal.labels";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../BoxBreakModal";
import { richText } from "@deliverr/ui-facility/lib-facility/intl/richText";

export interface BoxBreakSellerMismatchModalProps {
  unitSellerId?: string;
  currentBoxSellerId?: string;
  unitBarcode?: string;
  currentBoxCdsku?: string;
}

export const BoxBreakSellerMismatchModal: React.FC<BoxBreakSellerMismatchModalProps> = ({
  unitSellerId,
  currentBoxSellerId,
  unitBarcode,
  currentBoxCdsku,
}) => {
  const { formatMessage } = useIntl();
  const { hideModal } = useCrossdockModal();

  return (
    <ModalTemplate
      title={formatMessage(labels.title)}
      text={
        formatMessage(
          labels.message,
          richText({
            unitSellerId,
            currentBoxSellerId,
            unitBarcode,
            currentBoxCdsku,
            br: <br />,
          })
        ) as any
      }
      iconProps={dangerModalIcon}
    >
      <UnderstandButton
        onClick={() => {
          hideModal(BoxBreakModal.BOX_BREAK_SELLER_MISMATCH_MODAL);
        }}
      />
    </ModalTemplate>
  );
};
