import { useRecoilValue } from "recoil";
import { InvalidDataError } from "crossdock/common/errors";
import { scannedBoxAuditState } from "./BoxAuditState";
import { mapBoxAuditItemsToList } from "./utils/mapBoxAuditItemsToList";

export function useBoxAuditReviewSkusCard() {
  const boxAudit = useRecoilValue(scannedBoxAuditState);

  const items = mapBoxAuditItemsToList(boxAudit?.items);

  if (!items) {
    throw new InvalidDataError("Missing boxAudit dskus");
  }

  return { items };
}
