import React from "react";
import { DoneButton } from "@deliverr/ui-facility";
import { useFreightArrival } from "../useFreightArrival";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export const FreightArrivalMoveUnbundledDoneButton: React.FC = () => {
  const { restartFreightArrival } = useFreightArrival();
  const { hideAllFlowButtons } = useScanFlow();

  return (
    <DoneButton
      onClick={() => {
        restartFreightArrival();
        hideAllFlowButtons();
      }}
      block
    />
  );
};
