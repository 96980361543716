import { ModalGenericProps } from "@deliverr/ui-facility";
import { FC } from "react";
import { caseScannerModalMap } from "crossdock/modes/caseScanner/modals";
import { missingLabelModalMap } from "crossdock/modes/problem/MissingLabel/missingLabelModalMap";
import { transfersModalMap } from "crossdock/modes/transfers/modals/transfersModalMap";
import { boxBreakModalMap } from "crossdock/modes/break/BoxBreak/modals/boxBreakModalMap";
import { genericModalMap } from "./genericModalMap";
import { boxAuditModalMap } from "crossdock/modes/problem/BoxAudit/boxAuditModalMap";
import { damagedProductModalMap } from "crossdock/modes/problem/CrossdockDamagedProduct/damagedProductModalMap";
import { dimsAndWeightModalMap } from "crossdock/modes/inspection/crossdock-dims-and-weight/modals/dimsAndWeightModalMap";

export const crossdockModalMap: Record<string, FC<ModalGenericProps>> = {
  ...genericModalMap,
  ...missingLabelModalMap,
  ...transfersModalMap,
  ...caseScannerModalMap,
  ...boxBreakModalMap,
  ...boxAuditModalMap,
  ...damagedProductModalMap,
  ...dimsAndWeightModalMap,
};
