import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useShipPalletScanTracking } from "./useShipPalletScanTracking";

export interface ShipPalletScanTrackingCardProps {
  isUnbundled?: boolean;
  disabled?: boolean;
  newest?: boolean;
}

export const ShipPalletScanTrackingCard: React.FC<ShipPalletScanTrackingCardProps> = ({
  disabled,
  newest,
  isUnbundled = false,
}) => {
  const { palletId, trackingCode, onTrackingCodeChange, submitUpdateTracking } = useShipPalletScanTracking({
    isUnbundled,
  });

  const onSubmit = async () => {
    await submitUpdateTracking(palletId, trackingCode);
  };

  return (
    <TextInputCard
      onSubmit={onSubmit}
      icon="scan"
      accentColor="BLUE"
      title="Scan BOL#"
      message="Scan BOL# to link the pallet to it."
      value={trackingCode}
      onChange={onTrackingCodeChange}
      disabled={disabled}
      newest={newest}
      placeholder="BOL#"
    />
  );
};
