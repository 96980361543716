import { FC } from "react";

import { BoxBreakModal } from "./BoxBreakModal";
import { BoxBreakEndModal } from "./BoxBreakEndModal";
import { BoxBreakUnrecognizedBarcodeModal } from "./BoxBreakUnrecognizedBarcodeModal";
import { BoxBreakConfirmOverageScanModal } from "./BoxBreakConfirmOverageScanModal";
import { BoxBreakSellerMismatchModal } from "./BoxBreakSellerMismatchModal";
import { BoxBreakUnexpectedSkuNoCapableWarehousesModal } from "./BoxBreakUnexpectedSkuNoCapableWarehousesModal";

export const boxBreakModalMap: Record<BoxBreakModal, FC<any>> = {
  [BoxBreakModal.BOX_BREAK_END_MODAL]: BoxBreakEndModal,
  [BoxBreakModal.BOX_BREAK_UNRECOGNIZED_BARCODE]: BoxBreakUnrecognizedBarcodeModal,
  [BoxBreakModal.BOX_BREAK_CONFIRM_OVERAGE_SCAN]: BoxBreakConfirmOverageScanModal,
  [BoxBreakModal.BOX_BREAK_SELLER_MISMATCH_MODAL]: BoxBreakSellerMismatchModal,
  [BoxBreakModal.BOX_BREAK_UNEXPECTED_SKU_NO_CAPABLE_WAREHOUSES]: BoxBreakUnexpectedSkuNoCapableWarehousesModal,
};
