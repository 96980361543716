import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useFreightArrivalEnterPoNum } from "./useFreightArrivalEnterPoNum";
import styled from "@emotion/styled";
import { NoPoButton } from "./NoPoButton";

const ResizedTextInputCard = styled(TextInputCard)`
  min-height: 138px !important;
  max-height: none !important;
  height: auto !important;
`;

export const FreightArrivalEnterPoNumCard: React.FC = () => {
  const { poNum, onSubmit, onPoNumChange, title, placeholder, errorMessage, disabled, loading } =
    useFreightArrivalEnterPoNum();

  return (
    <ResizedTextInputCard
      onSubmit={onSubmit}
      accentColor="BLUE"
      type="number"
      title={title}
      placeholder={placeholder}
      errorMessage={errorMessage}
      value={poNum}
      onChange={onPoNumChange}
      disabled={disabled}
      loading={loading}
      hideNumericalArrowControls
      newest
    >
      <NoPoButton />
    </ResizedTextInputCard>
  );
};
