import React from "react";
import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { FormattedMessage } from "react-intl";
import { PALLET_NO_BOXES_LABELS_CLOSE, PALLET_NO_BOXES_LABELS_SHIP } from "./PalletNoBoxesErrorCard.labels";

export interface PalletNoBoxesErrorCardProps {
  palletLabelId: string;
  sourceFlow: "close" | "ship";
  newest?: boolean;
}

export const PalletNoBoxesErrorCard: React.FC<PalletNoBoxesErrorCardProps> = ({
  palletLabelId,
  sourceFlow,
  newest,
}) => {
  // determine labels based on caller
  const labels = sourceFlow === "ship" ? PALLET_NO_BOXES_LABELS_SHIP : PALLET_NO_BOXES_LABELS_CLOSE;

  return (
    <ErrorTextCard
      title={<FormattedMessage {...labels.title} />}
      value={palletLabelId}
      message={<FormattedMessage {...labels.message} />}
      newest={newest}
    />
  );
};
