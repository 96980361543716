import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useIntl } from "react-intl";
import { crossdockClient } from "crossdock/base/Clients";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { toast } from "@deliverr/ui";
import { CrossdockError } from "@deliverr/commons-clients";
import { useLoading } from "crossdock/common/useLoading";
import {
  crossdockDimsAndWeightPackageCdskuState,
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { isValidCdsku } from "@deliverr/ui-facility";
import { toCrossdockDimsAndWeightScanProductBarcode } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { useMount } from "react-use";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";

export function useCrossdockDimsAndWeightScanPackageCdsku() {
  const { inputErrorMessage: errorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { loading, loadWhilePending } = useLoading();
  const [packageCdsku, setPackageCdsku] = useRecoilState(crossdockDimsAndWeightPackageCdskuState);
  const setDimsSession = useSetRecoilState(crossdockDimsAndWeightSessionState);
  const resetPackageCdsku = useResetRecoilState(crossdockDimsAndWeightPackageCdskuState);
  const setPackage = useSetRecoilState(crossdockDimsAndWeightPackageState);

  const { warehouseId: crossdockId } = useRecoilValue(userState);

  const { handleUnknownError, transition, successResponse, errorResponse, hideAllFlowButtons } = useScanFlow();
  const { formatMessage } = useIntl();

  useMount(() => {
    hideAllFlowButtons();
  });

  const submitPackageCdsku = async (cdsku: string) => {
    const ctx = logStart({ fn: "submitPackageCdsku", cdsku });

    if (!isValidCdsku(cdsku)) {
      log(ctx, "invalid cdsku");
      inputError(formatMessage(DIMS_AND_WEIGHT_LABELS.invalidBarcode));
      return;
    }

    try {
      const pkg = await crossdockClient.getPackage(cdsku, {
        filterZeroQtyItems: true,
        withUnknownBarcodeItems: true,
        withProductInfos: true,
      });
      const dimsSession = await crossdockClient.createDimsAndWeightsSession({
        cdsku,
        crossdockId,
      });

      setPackageCdsku(cdsku);
      setPackage(pkg);
      setDimsSession(dimsSession);

      log({ ctx, pkg }, "package cdsku scan successful");

      successResponse();

      transition(toCrossdockDimsAndWeightScanProductBarcode());
    } catch (err) {
      resetPackageCdsku();
      if (err.code === CrossdockError.PACKAGE_NOT_FOUND) {
        errorResponse();
        toast.error(formatMessage(DIMS_AND_WEIGHT_LABELS.packageNotFound), {
          autoClose: 5000,
        });
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return {
    cdsku: packageCdsku,
    submitPackageCdsku: loadWhilePending(resetErrorOnExecution(submitPackageCdsku)),
    onPackageCdskuChange: genericOnScannerInputChange(packageCdsku, setPackageCdsku, submitPackageCdsku, "upper"),
    loading,
    errorMessage,
  };
}
