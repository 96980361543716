import React from "react";
import { DoneButton } from "@deliverr/ui-facility";
import { useSubmitPoNum } from "../useSubmitPoNum";

export const FreightArrivalEnterPoDoneButton: React.FC = () => {
  const { loading, submitPoNum, poNum } = useSubmitPoNum();

  return (
    <DoneButton
      onClick={async () => {
        await submitPoNum(poNum);
      }}
      disabled={loading}
      block
    />
  );
};
