import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createFcDimsAndWeightScanProductDimsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/CardCreators";
import { useResetRecoilState } from "recoil";
import { fcDimsAndWeightCubiscanImagesState } from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";

export const useFcDimsAndWeightBackToProductDims = () => {
  const resetFcDimsAndWeightCubiscanImages = useResetRecoilState(fcDimsAndWeightCubiscanImagesState);
  const { addFlowCard, hideAllFlowButtons } = useScanFlow();

  const onClick = () => {
    resetFcDimsAndWeightCubiscanImages();
    hideAllFlowButtons();
    addFlowCard(createFcDimsAndWeightScanProductDimsCard({}));
  };

  return {
    onClick,
  };
};
