import { defineMessages } from "react-intl";

export const CLOSE_PALLET_PROMPT_LABELS = defineMessages({
  title: {
    id: "crossdock.closePalletPromptModal.title",
    defaultMessage: "Close Pallet {palletLabelId}?",
  },
  cancel: {
    id: "crossdock.closePalletPromptModal.cancel",
    defaultMessage: "No",
  },
  confirm: {
    id: "crossdock.closePalletPromptModal.confirm",
    defaultMessage: "Yes, close it",
  },
});
