import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../../modals/BoxBreakModal";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";

export function useBoxBreakEndSession(isShortage: boolean) {
  const { showModal } = useCrossdockModal();

  return () => {
    logStart({ fn: "onBoxBreakEndSessionClick" });
    showModal(BoxBreakModal.BOX_BREAK_END_MODAL, { isShortage });
  };
}
