import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { IconToIcon } from "crossdock/common/components";
import { END_EARLY_LABELS as labels } from "./BoxBreakEndEarlyCard.labels";
import { useMount } from "react-use";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

// Prompts the user to move the box back to the scanning area.
// This a physical process only, we have already recorded that the box
// has been ejected at this point (see useBoxBreakEjectBox).
export const BoxBreakEndEarlyCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { showFlowButtons } = useScanFlow();

  useMount(() => {
    showFlowButtons(ScanFlowButtonType.BOX_BREAK_END_BREAK_COMPLETE);
  });

  return (
    <FlowCard
      title={formatMessage(labels.title)}
      icon="arrow-right"
      message={formatMessage(labels.message)}
      accentColor="BLUE"
    >
      <IconToIcon
        leftIconProps={{
          iconType: "box",
          accentColor: "BLUE",
        }}
        rightIconProps={{
          iconType: "truck",
          accentColor: "BLUE",
        }}
      />
    </FlowCard>
  );
};
