import React from "react";
import { Stack, Text, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { Product } from "@deliverr/crossdock-service-client";
import { useIntl } from "react-intl";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";

export interface ProductDetailsCardProps {
  product?: Product;
  barcode?: string;
}

const StyledFlowCard = styled(FlowCard)<ThemeProps>(
  () => `
  height: fit-content !important;
`
);

export const ProductDetailsCard: React.FC<ProductDetailsCardProps> = ({ product, barcode }) => {
  const { formatMessage } = useIntl();
  return (
    <StyledFlowCard>
      <Stack>
        <Text bold>
          {formatMessage(
            product
              ? DIMS_AND_WEIGHT_LABELS.dimsAndWeightKnownProductDetailsTitle
              : DIMS_AND_WEIGHT_LABELS.dimsAndWeightUnknownProductDetailsTitle
          )}
        </Text>
        <Text bold appearance="INFO">
          {product?.dsku ?? barcode}
        </Text>
        <Text appearance="INFO">{product?.name}</Text>

        {(product?.barcodes ?? []).map((_barcode, index) => (
          <Text key={index} appearance="INFO" size="caption">
            {_barcode}
          </Text>
        ))}
      </Stack>
    </StyledFlowCard>
  );
};
