import { defineMessages } from "react-intl";
import { LaneType } from "@deliverr/commons-clients/lib/crossdock/models/Lane";
import { getTitleLabel } from "./getTitleLabel";

export default defineMessages({
  [getTitleLabel(LaneType.GENERAL_INBOUND)]: {
    id: "crossdock.freightArrivalScanSuccessCard.title",
    defaultMessage: "To crossdock staging or PANDA",
  },
  [getTitleLabel(LaneType.PREP)]: {
    id: "crossdock.freightArrivalScanSuccessCard.prepTitle",
    defaultMessage: "To prep staging",
  },
  [getTitleLabel(LaneType.PROBLEM_AREA)]: {
    id: "crossdock.freightArrivalScanSuccessCard.problemTitle",
    defaultMessage: "To problem area",
  },
  [LaneType.GENERAL_INBOUND]: {
    id: "crossdock.freightArrivalScanSuccessCard.crossdock",
    defaultMessage: "Arrival recorded for {label}. Sort to crossdock staging area or PANDA.",
  },
  [LaneType.PREP]: {
    id: "crossdock.freightArrivalScanSuccessCard.prep",
    defaultMessage: "Arrival recorded for {label}. Sort to prep staging area.",
  },
  [LaneType.PROBLEM_AREA]: {
    id: "crossdock.freightArrivalScanSuccessCard.problem",
    defaultMessage: "Problem arrival recorded for {label}. Sort to problem area.",
  },
  freightArrivalNoPo: {
    id: "crossdock.freightArrivalScanSuccessCard.noPo",
    defaultMessage: "Sort pallet to problem area.",
  },
  spdArrivalTitle: {
    id: "crossdock.freightArrivalScanSuccessCard.spdArrivalTitle",
    defaultMessage: "To crossdock staging or PANDA",
  },
  spdArrival: {
    id: "crossdock.freightArrivalScanSuccessCard.spdArrival",
    defaultMessage: "Arrival recorded. Sort to crossdock staging area or PANDA.",
  },
});
