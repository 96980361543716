import React from "react";
import { BoxReset } from "@deliverr/ui";
import { IconToIcon } from "crossdock/common/components";

interface BulkTransferIconProps {
  chuteLetter: string;
}

export const BulkTransferIcon: React.FC<BulkTransferIconProps> = ({ chuteLetter }) => (
  <BoxReset marginTop="S1" marginBottom="S2">
    <IconToIcon
      leftIconProps={{
        iconType: "shopping-bag",
        accentColor: "BLUE",
      }}
      rightIconProps={{
        textIcon: chuteLetter,
        accentColor: "BLUE",
      }}
    />
  </BoxReset>
);
