import { atom } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";

export const palletIdState = atom<string>({
  key: CrossdockStateKey.TRANSFERS_SHIP_PALLET_ID,
  default: "",
});

export const trackingCodeState = atom<string>({
  key: CrossdockStateKey.TRANSFERS_SHIP_TRACKING_CODE,
  default: "",
});

export interface DestinationMismatchContext {
  currentPalletToWarehouseId: string;
  expectedDestinationWarehouseId: string;
  isMilkRun: boolean;
}

export const destinationMismatchContextState = atom<DestinationMismatchContext>({
  key: CrossdockStateKey.TRANSFERS_DESTINATION_MISMATCH_CONTEXT,
  default: {
    currentPalletToWarehouseId: "",
    expectedDestinationWarehouseId: "",
    isMilkRun: false,
  },
});
