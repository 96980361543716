import { useState } from "react";

// Returns a "loading" value which can be used to display a spinner while a request is processing.
// Pass an async function to receive a new function which sets loading to true while pending, and to false once processed.
export function useLoading() {
  const [loading, setLoading] = useState<boolean>(false);

  const loadWhilePending = <T extends (arg?: any) => Promise<any>>(fn: T) => {
    return async (arg) => {
      setLoading(true);
      await fn(arg);
      setLoading(false);
    };
  };

  return { loading, loadWhilePending };
}
