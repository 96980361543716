import { useIntl } from "react-intl";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { poNumState, palletLabelIdState, lastPoState } from "../../FreightArrivalState";
import { genericOnScannerInputChange, log, logError, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { FreightArrivalScanPalletLabels as labels } from "./FreightArrivalScanPallet.labels";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals";
import { useFreightArrival } from "../../useFreightArrival";
import { freightClient } from "crossdock/base/Clients";
import {
  createEnterPoNumCard,
  createFreightArrivalScanTransferCard,
  createScanPalletCard,
} from "../../freightArrivalCardCreators";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { FreightArrivalScanPalletError, FreightArrivalScanPalletErrorMap } from "./FreightArrivalScanPalletErrors";
import {
  ScanPalletErrorMessages,
  ScanPalletErrorTitles,
} from "./FreightArrivalScanPalletErrorModal/FreightArrivalScanPalletErrorModal.labels";
import { FreightArrivalScanPalletErrorModalProps } from "./FreightArrivalScanPalletErrorModal/FreightArrivalScanPalletErrorModal";
import { FreightArrivalPalletPreviouslyRecordedModalProps } from "../../modals/FreightArrivalPalletPreviouslyRecordedModal";
import { validateFreightPalletLabelId } from "@deliverr/ui-facility/lib-facility/utils/transferValidationUtils";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import {
  FreightPallet,
  LoadingDockScanEventScanType,
  LoadingDockScanEventResponseWithWarehouseId,
} from "@deliverr/freight-client";
import { ModalGenericProps } from "@deliverr/ui-facility";
import {
  getFreightLoadingDockScanErrorMessageDescriptor,
  getFreightLoadingDockScanErrorTitleDescriptor,
} from "crossdock/modes/utils/freightLoadingDockScanErrorUtils";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

const showModalForTransportationServiceError = (
  freightArrivalScanPalletErrorCode: FreightArrivalScanPalletError | undefined,
  palletLabelIdToSubmit: string,
  showModal: (modal: string, props: ModalGenericProps) => void,
  formatMessage: (...args: any[]) => any
) => {
  switch (freightArrivalScanPalletErrorCode) {
    case FreightArrivalScanPalletError.INVALID_PALLET_ID:
    case FreightArrivalScanPalletError.NOT_FOUND:
    case FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK:
      showModal(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
        title: formatMessage(ScanPalletErrorTitles[freightArrivalScanPalletErrorCode]),
        message: formatMessage(ScanPalletErrorMessages[freightArrivalScanPalletErrorCode]),
      });
      break;
    case FreightArrivalScanPalletError.PALLET_ALREADY_MARKED_AS_ARRIVED:
      showModal(TransfersModal.FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED, {
        palletId: palletLabelIdToSubmit,
      });
      break;
    default:
      showModal(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
        title: formatMessage(ScanPalletErrorTitles[FreightArrivalScanPalletError.UNKNOWN_ERROR]),
        message: formatMessage(ScanPalletErrorMessages[FreightArrivalScanPalletError.UNKNOWN_ERROR]),
      });
      break;
  }
};

const isFreightPallet = (object: any): object is FreightPallet => {
  return "freightShipmentId" in object;
};

export const useFreightArrivalScanPallet = () => {
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { formatMessage } = useIntl();
  const { showModal } = useCrossdockModal();
  const [palletLabelId, setPalletLabelId] = useRecoilState(palletLabelIdState);
  const resetPalletLabelId = useResetRecoilState(palletLabelIdState);
  const { restartFreightArrival, linkPalletToAsn } = useFreightArrival();
  const { warehouseId } = useRecoilValue(userState);
  const setPoNum = useSetRecoilState(poNumState);
  const setLastPoNum = useSetRecoilState(lastPoState);
  const { addFlowCard, successResponse, resetFlowCards, showFlowButton } = useScanFlow();

  const handleFreightArrivalScanError = (err: any, palletId: string): void => {
    switch (FreightArrivalScanPalletErrorMap[err.response.data]) {
      case FreightArrivalScanPalletError.NOT_FOUND:
        showModal<FreightArrivalScanPalletErrorModalProps>(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
          title: formatMessage(ScanPalletErrorTitles[FreightArrivalScanPalletError.NOT_FOUND]),
          message: formatMessage(ScanPalletErrorMessages[FreightArrivalScanPalletError.NOT_FOUND]),
        });
        return;
      case FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK:
        showModal<FreightArrivalScanPalletErrorModalProps>(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
          title: formatMessage(ScanPalletErrorTitles[FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK]),
          message: formatMessage(ScanPalletErrorMessages[FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK]),
        });
        return;
      case FreightArrivalScanPalletError.PALLET_ALREADY_MARKED_AS_ARRIVED:
        showModal<FreightArrivalPalletPreviouslyRecordedModalProps>(
          TransfersModal.FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED,
          { palletId }
        );
        return;
      case FreightArrivalScanPalletError.INVALID_PALLET_ID:
        showModal<FreightArrivalScanPalletErrorModalProps>(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
          title: formatMessage(ScanPalletErrorTitles[FreightArrivalScanPalletError.INVALID_PALLET_ID]),
          message: formatMessage(ScanPalletErrorMessages[FreightArrivalScanPalletError.INVALID_PALLET_ID]),
        });
        return;
      default:
        showModal<FreightArrivalScanPalletErrorModalProps>(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
          title: formatMessage(ScanPalletErrorTitles[FreightArrivalScanPalletError.UNKNOWN_ERROR]),
          message: formatMessage(ScanPalletErrorMessages[FreightArrivalScanPalletError.UNKNOWN_ERROR]),
        });
        return;
    }
  };

  const submitPalletLabelId = async (palletLabelIdToSubmit: string) => {
    const ctx = logStart({
      fn: "useFreightArrivalScanPallet.submitPalletLabelId",
      palletIdToSubmit: palletLabelIdToSubmit,
    });

    if (!validateFreightPalletLabelId(palletLabelIdToSubmit)) {
      inputError(formatMessage(labels.invalidFreightPalletBarcode));
      resetPalletLabelId();
      return;
    }

    try {
      const scanResponse = await freightClient.createWarehouseLoadingDockScanEvent({
        barcode: palletLabelIdToSubmit,
        scanType: LoadingDockScanEventScanType.ARRIVAL,
        warehouseId,
      });

      if (isFreightPallet(scanResponse)) {
        const freightPallet = scanResponse as FreightPallet;

        const freightShipment = await freightClient.getFreightShipmentById(freightPallet.freightShipmentId);

        if (freightShipment.poNum) {
          setPoNum(freightShipment.poNum);
          setLastPoNum(freightShipment.poNum);
          await linkPalletToAsn(+freightShipment.poNum);
          successResponse();
          log({ ctx, poNum: freightShipment.poNum }, "Freight pallet arrival recorded");
        } else {
          log({ ctx, freightShipment }, "no po found on freight shipment");
        }
      } else {
        const { barcode, nextLocation } = scanResponse as LoadingDockScanEventResponseWithWarehouseId;

        successResponse();
        log({ ctx, barcode, nextLocation }, "unbundled pallet arrival recorded");
        addFlowCard(createFreightArrivalScanTransferCard({ barcode, nextLocation }));
        showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_MOVE_UNBUNDLED_DONE_BUTTON);
      }
    } catch (err) {
      logError(ctx, err);
      restartFreightArrival();
      handleFreightArrivalScanError(err, palletLabelId);

      // Unbundled errors are objects with codes, inbounds errors are simple strings
      if (err.response?.data?.code) {
        showModal<FreightArrivalScanPalletErrorModalProps>(TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR, {
          title: formatMessage(getFreightLoadingDockScanErrorTitleDescriptor(err)),
          message: formatMessage(getFreightLoadingDockScanErrorMessageDescriptor(err)),
        });
      } else {
        showModalForTransportationServiceError(
          FreightArrivalScanPalletErrorMap[err.response?.data],
          palletLabelIdToSubmit,
          showModal,
          formatMessage
        );
      }

      resetFlowCards();
      addFlowCard(createScanPalletCard({}));
    }
  };

  const onPalletLabelIdChange = genericOnScannerInputChange(
    palletLabelId,
    setPalletLabelId,
    submitPalletLabelId,
    "upper"
  );

  const handlePalletLabelMissing = () => {
    addFlowCard(createEnterPoNumCard({}));
  };

  return {
    palletLabelId,
    onPalletLabelIdChange,
    loading,
    disabled: loading,
    onSubmit: loadWhilePending(resetErrorOnExecution(submitPalletLabelId)),
    handlePalletLabelMissing,
    title: formatMessage(labels.title),
    placeholder: formatMessage(labels.placeholder),
    labelMissingButton: formatMessage(labels.labelMissing),
    errorMessage: inputErrorMessage,
  };
};
