import React from "react";
import { useBoxBreakCountUnexpectedSkuUnits } from "../../cards/BoxBreakCountUnexpectedSkuUnitsCard";
import { CancelButton } from "@deliverr/ui-facility";

interface Props {
  disabled?: boolean;
}

export const BoxBreakUnitCountCancelButton: React.FC<Props> = ({ disabled }) => {
  const { onUnitCountCancel } = useBoxBreakCountUnexpectedSkuUnits();

  return <CancelButton onClick={onUnitCountCancel} secondary disabled={disabled} block />;
};
