import { useIntl } from "react-intl";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";
import { createEnterPoNumCard } from "../../freightArrivalCardCreators";
import { useFreightArrival } from "../../useFreightArrival";
import { FreightArrivalPalletPreviouslyRecordedModalLabels as labels } from "./FreightArrivalPalletPreviouslyRecordedModal.labels";

export interface FreightArrivalPalletPreviouslyRecordedModalProps {
  palletId: string;
}

export function useFreightArrivalPalletPreviouslyRecordedModal({
  palletId,
}: FreightArrivalPalletPreviouslyRecordedModalProps) {
  const { hideModal } = useCrossdockModal();
  const { formatMessage } = useIntl();
  const { addFlowCard } = useScanFlow();
  const { restartFreightArrival } = useFreightArrival();

  const onConfirmClick = async () => {
    addFlowCard(createEnterPoNumCard({}));
    hideModal(TransfersModal.FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED);
  };

  const onCancelClick = () => {
    restartFreightArrival();
    hideModal(TransfersModal.FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED);
  };

  return {
    title: formatMessage(labels.title),
    message: formatMessage(labels.message, { palletId }),
    confirmLabel: formatMessage(labels.confirm),
    cancelLabel: formatMessage(labels.cancel),
    onConfirmClick,
    onCancelClick,
  };
}
