import React from "react";
import { PromptModal, warningModalIconLg } from "@deliverr/ui-facility";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { FreightArrivalPalletDuplicateInboundPalletLabelModalLabels as labels } from "./FreightArrivalDuplicateInboundPalletLabelModal.labels";
import { useIntl } from "react-intl";
import { useFreightArrivalDuplicateInboundPalletLabelModal } from "./useFreightArrivalDuplicateInboundPalletLabelModal";

export interface FreightArrivalDuplicateInboundPalletLabelModalProps {
  inboundPalletLabelBarcode: string;
}

export const FreightArrivalDuplicateInboundPalletLabelModal: React.FC<
  FreightArrivalDuplicateInboundPalletLabelModalProps
> = ({ inboundPalletLabelBarcode }) => {
  const { formatMessage } = useIntl();
  const { onConfirmClick, onCancelClick } =
    useFreightArrivalDuplicateInboundPalletLabelModal(inboundPalletLabelBarcode);

  return (
    <PromptModal
      title={formatMessage(labels.title)}
      content={formatMessage(labels.message, { inboundPalletLabelBarcode })}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
      onConfirmClick={onConfirmClick}
      onCancelClick={onCancelClick}
      confirmButtonLabel={formatMessage(labels.confirm)}
      cancelButtonLabel={formatMessage(labels.cancel)}
      vertical
    />
  );
};
