import { defineMessages } from "react-intl";
import { YesNoRadioOptions } from "./radio/CrossdockRadioOptions";

export default defineMessages({
  // buttons
  cancelButton: {
    id: "crossdock.buttons.cancel",
    defaultMessage: "Cancel",
  },

  // radio options
  [YesNoRadioOptions.YesOption]: {
    id: "crossdock.radioOptions.yes",
    defaultMessage: "Yes",
  },
  [YesNoRadioOptions.NoOption]: {
    id: "crossdock.radioOptions.no",
    defaultMessage: "No",
  },

  // placeholders
  inboundPalletLabelPlaceholder: {
    id: "crossdock.placeholders.inboundPalletLabelBarcode",
    defaultMessage: "Starts with 'IBP' or 'PID'",
  },
  freightPalletLabelPlaceholder: {
    id: "crossdock.placeholders.freightPalletBarcode",
    defaultMessage: "Starts with 'FPID'",
  },
  stagingLanePlaceholder: {
    id: "crossdock.placeholders.stagingLaneBarcode",
    defaultMessage: "Starts with 'ILN'",
  },

  // input validation errors
  scanInboundPalletInvalidBarcode: {
    id: "crossdock.inputValidationErrors.invalidInboundPalletBarcode",
    defaultMessage: "Invalid inbound pallet barcode",
  },
  invalidFreightPalletBarcode: {
    id: "crossdock.inputValidationErrors.invalidFreightPalletBarcode",
    defaultMessage: "Invalid freight pallet barcode",
  },
  invalidStagingLaneBarcode: {
    id: "crossdock.inputValidationErrors.invalidStagingLaneBarcode",
    defaultMessage: "Invalid staging lane barcode",
  },
  zeroNumberError: {
    id: "crossdock.inputValidationErrors.zeroNumber",
    defaultMessage: "Enter a number > 0",
  },
  notANumberError: {
    id: "crossdock.inputValidationErrors.nonNumberError",
    defaultMessage: "Non-number value entered. Enter a number",
  },
  negativeNumberError: {
    id: "crossdock.inputValidationErrors.negativeNumberError",
    defaultMessage: "Enter a positive number",
  },

  // old
  poNum: {
    id: "poNum",
    defaultMessage: "PO# {poNum}",
  },
});
