import { useCrossdockModal } from "crossdock/common/modal";
import { CaseScannerModal } from "./CaseScannerModal";

export function useCaseScannerDuplicateLabelConfirmationModal() {
  const { hideModal } = useCrossdockModal();

  const onConfirmClick = () => {
    hideModal(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_CONFIRMATION);
  };

  return {
    onConfirmClick,
  };
}
