import { CrossdockError } from "@deliverr/commons-clients";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilState, useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { crossdockClient } from "crossdock/base/Clients";
import {
  getCountUnitsTransition,
  toBulkTransfer,
  toNewRunVerifyChute,
  toScanAnyUnit,
} from "./useBoxBreakScanUnit.transitions";
import { BoxBreakModal } from "../../modals/BoxBreakModal";
import { useBoxBreakState } from "../useBoxBreakState";
import { useCrossdockModal } from "crossdock/common/modal";
import { useSetTransferredUnits } from "../useSetTransferredUnits";
import {
  currentRunDestinationState,
  currentUnitsScannedCountState,
  lastUnitScannedState,
  runTargetCountState,
  dskuToProductBarcodesState,
} from "../../boxBreakState";
import {
  isSingleSkuBoxValue,
  singleSkuBoxDskuState,
  singleSkuBoxProductBarcodeState,
  bulkQtyTransferredState,
  bulkCurrentUnitTransferValue,
} from "../../../bulk/bulkState";
import { activeDskuValue } from "../../sidebar/boxBreakSidebarState";

export function useBoxBreakScanUnit() {
  const {
    resetErrorOnExecution,
    resetUnitBarcode,
    boxBreakStationId,
    cdsku,
    chuteByWarehouse,
    currentRunDestination,
    lastUnitScanned,
    resetCurrentUnitRun,
  } = useBoxBreakState();
  const setLastUnitScanned = useSetRecoilState(lastUnitScannedState);
  const setCurrentRunDestination = useSetRecoilState(currentRunDestinationState);
  const [currentUnitsScannedCount, setCurrentUnitsScannedCount] = useRecoilState(currentUnitsScannedCountState);
  const [currentRunTarget, setRunTarget] = useRecoilState(runTargetCountState);
  const { setTransferredUnits } = useSetTransferredUnits();
  const { transition, handleUnknownError, errorResponse } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const isSingleSkuBox = useRecoilValue(isSingleSkuBoxValue);
  const singleSkuBoxDsku = useRecoilValue(singleSkuBoxDskuState);
  const setSingleSkuBoxProductBarcode = useSetRecoilState(singleSkuBoxProductBarcodeState);
  const bulkQtyTransferred = useRecoilValue(bulkQtyTransferredState);
  const resetBulkQtyTransferred = useResetRecoilState(bulkQtyTransferredState);
  const dskuToProductBarcodes = useRecoilValue(dskuToProductBarcodesState);
  const setActiveDsku = useSetRecoilState(activeDskuValue);
  const bulkCurrentUnitTransfer = useRecoilValue(bulkCurrentUnitTransferValue);

  const handleScanUnitError = (ctx: any, err: any, newUnitBarcode: string): void => {
    resetUnitBarcode();

    // unit scanned is from the same seller but unexpected in this box according to the shipping plan.
    // This is seperate from the switch below because we don't want to use the error SFX.
    if (
      err?.code === CrossdockError.UNRECOGNIZED_ERROR &&
      err?.payload?.message === CrossdockError.NO_CHUTES_ARE_LINKED_TO_ANY_WAREHOUSES
    ) {
      log(ctx, "unexpected dsku detected", { err });
      transition(getCountUnitsTransition(newUnitBarcode));
      return;
    }

    errorResponse();
    switch (err?.code) {
      // unit barcode assigned to a product owned by a different seller than the box.
      case CrossdockError.BOX_BREAK_SELLER_MISMATCH:
        log(ctx, "box break seller mismatch", { err });
        showModal(BoxBreakModal.BOX_BREAK_SELLER_MISMATCH_MODAL, {
          unitSellerId: err?.payload?.unitSellerId,
          currentBoxSellerId: err?.payload?.boxSellerId,
          unitBarcode: err?.payload?.unitBarcode,
          currentBoxCdsku: err?.payload?.cdsku,
        });
        return;
      // unit barcode is valid, but not associated to any product.
      case CrossdockError.UNRECOGNIZED_UNIT_BARCODE:
        log(ctx, "unrecognized barcode", { err });
        showModal(BoxBreakModal.BOX_BREAK_UNRECOGNIZED_BARCODE, { unitBarcode: newUnitBarcode });
        return;
      default:
        handleUnknownError(ctx, err);
    }
  };

  // Starts a new run (sequence of same SKUs sent to a single destination).
  // This happens on the first scan of a unit, and when a run target has been reached.
  const startRun = async (unitBarcode: string) => {
    const {
      transferToWarehouseId: newRunDestination,
      remainingUnits,
      dsku,
    } = (await crossdockClient.scanUnitV2({
      boxBreakStationId,
      boxBarcode: cdsku,
      unitBarcode,
      // We need to update commons-clients to get the new response type, but we need to use the new
      // warehouse client package if we do so, but that client has a bug where it doesn't allow us to
      // pass in the warehouse service url manually yet. So we do this temporarily.
    })) as any;

    setRunTarget(remainingUnits);
    setCurrentRunDestination(newRunDestination);
    setActiveDsku(dsku);

    return {
      runTarget: remainingUnits,
      runDestination: newRunDestination,
    };
  };

  const resetRunIfTargetMet = (unitsScannedCount: number): void => {
    if (currentRunTarget && unitsScannedCount >= currentRunTarget) {
      resetCurrentUnitRun();
    }
  };

  const commitUnitTransfer = async (
    chuteBarcode: string,
    unitBarcode?: string,
    unexpectedQty?: number
  ): Promise<void> => {
    const newUnitsScannedCount = currentUnitsScannedCount + (isSingleSkuBox ? bulkQtyTransferred : 1);
    setCurrentUnitsScannedCount(newUnitsScannedCount);
    await setTransferredUnits(chuteBarcode, unexpectedQty, unitBarcode);
    resetBulkQtyTransferred();
    resetRunIfTargetMet(newUnitsScannedCount);
  };

  const scanUnit = async (newUnitBarcode: string, unexpectedQty?: number): Promise<void> => {
    const ctx = logStart({
      fn: "scanUnit",
      unitBarcode: newUnitBarcode,
      cdsku,
      boxBreakStationId,
      lastUnitScanned,
      currentRunDestination,
      currentRunTarget,
    });

    setLastUnitScanned(newUnitBarcode);

    try {
      const noRunDestination = !currentRunDestination || !currentRunTarget;
      const barcodeBelongsToProduct = (dskuToProductBarcodes[singleSkuBoxDsku] ?? []).includes(newUnitBarcode);

      if (lastUnitScanned !== newUnitBarcode || noRunDestination) {
        log(ctx, "starting new run");
        const { runDestination, runTarget } = await startRun(newUnitBarcode);
        const destinationChute = chuteByWarehouse[runDestination];

        if (
          isSingleSkuBox &&
          barcodeBelongsToProduct &&
          // prevent bulk transfer ui during overages
          // and prevent bulk transfer when we only need to transfer 1 unit
          runTarget > 1
        ) {
          log(ctx, "single sku verified, sending to bulk transfer");
          setSingleSkuBoxProductBarcode(newUnitBarcode);
          transition(toBulkTransfer());
        } else {
          log(ctx, "sending to verify chute");
          transition(toNewRunVerifyChute(newUnitBarcode, destinationChute));
        }
      } else {
        log(ctx, "continuing existing run");
        const destinationChute = chuteByWarehouse[currentRunDestination!];

        if (
          isSingleSkuBox &&
          barcodeBelongsToProduct &&
          // prevent bulk transfer ui during overages
          // and prevent bulk transfer when we only need to transfer 1 unit
          currentRunTarget - (bulkCurrentUnitTransfer?.qty ?? 0) > 1
        ) {
          log(ctx, "single sku verified, sending to bulk transfer");
          setSingleSkuBoxProductBarcode(newUnitBarcode);
          transition(toBulkTransfer());
        } else {
          await commitUnitTransfer(destinationChute.barcode, newUnitBarcode, unexpectedQty);
          transition(toScanAnyUnit(newUnitBarcode, destinationChute.letter));
        }
      }
    } catch (err) {
      handleScanUnitError(ctx, err, newUnitBarcode);
    }
  };

  return { scanUnit: resetErrorOnExecution(scanUnit), commitUnitTransfer };
}
