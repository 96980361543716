import { useCrossdockModal } from "crossdock/common/modal";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useBoxBreakEjectBox } from "../../hooks/useBoxBreakEjectBox";

export function useBoxBreakUnexpectedSkuNoCapableWarehouse() {
  const { hideAllModals } = useCrossdockModal();
  const ejectBox = useBoxBreakEjectBox();

  return async () => {
    logStart({ fn: "useBoxBreakUnexpectedSkuNoCapableWarehouse" });
    await ejectBox();
    hideAllModals();
  };
}
