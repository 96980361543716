import React from "react";
import { LinkedCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { plural } from "@deliverr/ui-facility/lib-facility/utils/stringUtils";

export interface AddCaseScanPalletSuccessCardProps {
  palletId: string;
  cdsku: string;
  totalCases: number;
}

export const AddCaseScanPalletSuccessCard: React.FC<AddCaseScanPalletSuccessCardProps> = ({
  palletId,
  cdsku,
  totalCases,
}) => (
  <LinkedCard
    icon="check"
    accentColor="GREEN"
    title="Box & Pallet Linked"
    message="Put the box on the pallet"
    topValue={palletId}
    bottomValue={cdsku}
    pillProps={{
      color: "GRAY",
      children: plural(`${totalCases} box`, totalCases, true),
    }}
  />
);
