import { Title } from "@deliverr/ui";
import * as React from "react";
import { useRecoilValue } from "recoil";
import { FormattedMessage } from "react-intl";
import { DimsAndWeightSessionItem } from "@deliverr/crossdock-service-client/lib/packages/client/src/generated-sources/openapi/models/dims-and-weight-session-item";
import {
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightProductBarcodeState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { DimsAndWeightBoxContentsItem } from "crossdock/modes/inspection/crossdock-dims-and-weight/sidebars/BoxContentsItem";
import { RightSidebarContainer } from "crossdock/common/components/SideBarContainer";
import { SpacedIcon } from "crossdock/common/components";
import { crossdockDimsAndWeightPackageItemsState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightSelectorState";

export const DimsAndWeightCompletedItemsSidebar: React.FC = () => {
  const scannedPackage = useRecoilValue(crossdockDimsAndWeightPackageState);
  const dimsAndWeightSession = useRecoilValue(crossdockDimsAndWeightSessionState);
  const currentProductBarcode = useRecoilValue(crossdockDimsAndWeightProductBarcodeState);
  const { completedPackageItems } = useRecoilValue(crossdockDimsAndWeightPackageItemsState);

  const dskus = (scannedPackage?.items ?? []).map(({ dsku }) => dsku);

  return (
    <RightSidebarContainer isDisplay={!!scannedPackage}>
      <Title as="h4" displayAs="h4">
        <SpacedIcon type="exclamation-circle" color="#139E82" />
        <FormattedMessage id="crossdock.boxBreaksChutesOverview.title" defaultMessage="Complete" />
      </Title>
      {!dimsAndWeightSession ? null : (
        <>
          {dimsAndWeightSession.items?.map((dimsAndWeightSessionItem: DimsAndWeightSessionItem) => {
            const sessionItemBarcodes = [
              dimsAndWeightSessionItem.barcode,
              ...(dimsAndWeightSessionItem.productBarcodes ?? []),
            ];
            const isActive = !!currentProductBarcode && sessionItemBarcodes.includes(currentProductBarcode);

            return (
              <DimsAndWeightBoxContentsItem
                key={dimsAndWeightSessionItem.barcode}
                dsku={dimsAndWeightSessionItem.dsku}
                isActive={isActive}
                barcode={dimsAndWeightSessionItem.barcode}
                dimsTaken={true}
                productName={dimsAndWeightSessionItem.productName}
                unexpected={!dskus.includes(dimsAndWeightSessionItem.dsku!)}
              />
            );
          })}
        </>
      )}
      {completedPackageItems.unscanned?.map((completedPackageItem, index) => {
        const barcodes = completedPackageItem.product?.barcodes ?? [];
        return (
          <DimsAndWeightBoxContentsItem
            key={index}
            dsku={completedPackageItem.dsku}
            productName={completedPackageItem.product?.name}
            isActive={!!currentProductBarcode && barcodes.includes(currentProductBarcode)}
            barcode={barcodes[0]}
            dimsTaken={true}
            unexpected={!dskus.includes(completedPackageItem.dsku)}
          />
        );
      })}
    </RightSidebarContainer>
  );
};
