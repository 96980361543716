import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { useIntl } from "react-intl";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import labels from "./StartProcessingPallet.labels";

export function useStartProcessingPalletNotifications() {
  const { formatMessage } = useIntl();
  const { addAutoCloseNotification } = useScanFlow();

  const showStartProcessingSuccessNotification = (palletBarcode: string) => {
    const notification = createSuccessNotification(
      formatMessage(labels.startProcessingSuccessNotification, {
        palletBarcode,
      })
    );
    addAutoCloseNotification(notification);
  };

  return {
    showStartProcessingSuccessNotification,
  };
}
