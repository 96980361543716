import React from "react";

import { Button } from "@deliverr/ui";
import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx/SoundFx";
import { useCaseOnNonOpenPalletWarningModal } from "./useCaseOnNonOpenPalletWarningModal";
import { TransferPalletStatus } from "@deliverr/legacy-inbound-client";
import format from "date-fns/format";
import { DateFormat } from "@deliverr/ui-facility/lib-facility/utils/dateUtils";

export interface CaseOnNonOpenPalletWarningModalProps {
  cdsku: string;
  palletLabelId: string;
  status: TransferPalletStatus;
  statusUpdatedAt: Date;
}

export const CaseOnNonOpenPalletWarningModal: React.FC<CaseOnNonOpenPalletWarningModalProps> = ({
  palletLabelId,
  status,
  statusUpdatedAt,
  cdsku,
}) => {
  const { onConfirmClick } = useCaseOnNonOpenPalletWarningModal();

  return (
    <ModalTemplate
      title={`Box on ${status.toLowerCase()} pallet!`}
      content={
        <p>
          Box <strong>{cdsku}</strong> cannot be moved from pallet <strong>{palletLabelId}</strong> because the pallet
          was {status.toLowerCase()} on {format(statusUpdatedAt, DateFormat.DateAtTime)}.
        </p>
      }
      iconProps={warningModalIcon}
      soundFx={SoundFx.ERROR}
    >
      <Button block onClick={onConfirmClick}>
        I understand
      </Button>
    </ModalTemplate>
  );
};
