import { useCrossdockModal } from "crossdock/common/modal";
import { useResetBoxAuditState } from "../useResetBoxAuditState";

export function useBoxAuditReportManuallyButton() {
  const { hideAllModals } = useCrossdockModal();
  const { restartBoxAudit } = useResetBoxAuditState();

  const onReportManuallyClick = () => {
    hideAllModals();
    restartBoxAudit();
  };

  return {
    onReportManuallyClick,
  };
}
