import React from "react";
import { crossdockModalMap } from "./CrossdockModalMap";
import { useRecoilValue } from "recoil";
import { visibleScanModalValue } from "./ScanModalState";

export const ScanModals: React.FC = () => {
  const visibleModal = useRecoilValue(visibleScanModalValue);
  if (!visibleModal) {
    return null;
  }
  const Modal = crossdockModalMap[visibleModal.type];
  return <Modal {...visibleModal.props} />;
};
