export const PALLETS_LABELS = {
  pallets: {
    caption: {
      id: "pallets.caption",
      defaultMessage: "Pallets",
    },
    columns: [
      // The last part of the column Id is same as the field name in the response payload.
      // This is used to map the response with column. Do not change arbitrarily.
      {
        id: "pallets.columns.barcode",
        defaultMessage: "Barcode",
      },
      {
        id: "pallets.columns.stage",
        defaultMessage: "Stage",
      },
      {
        id: "pallets.columns.trackingCode",
        defaultMessage: "BOL",
      },
      {
        id: "pallets.columns.hoursLapsedSinceFirstLinked",
        defaultMessage: "Hours Since Opened",
      },
      {
        id: "pallets.columns.region",
        defaultMessage: "Region",
      },
      {
        id: "pallets.columns.destinationId",
        defaultMessage: "Destination",
      },
      {
        id: "pallets.columns.cdsku",
        defaultMessage: "First CDSKU",
      },
      {
        id: "pallets.columns.itemCount",
        defaultMessage: "Items",
      },
    ],
  },

  notification: {
    error: {
      id: "pallets.notification.error",
      defaultMessage: "Error while loading pallets.",
    },
  },
};
