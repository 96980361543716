import React from "react";
import { useIntl } from "react-intl";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useShipPalletScanPallet } from "./useShipPalletScanPallet";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export interface ShipPalletScanPalletCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const ShipPalletScanPalletCard: React.FC<ShipPalletScanPalletCardProps> = ({ disabled, newest }) => {
  const { palletId, onPalletIdChange, submitScanPallet } = useShipPalletScanPallet();
  const { formatMessage } = useIntl();
  const onSubmit = async () => {
    await submitScanPallet(palletId);
  };

  return (
    <TextInputCard
      onSubmit={onSubmit}
      icon="scan"
      accentColor="BLUE"
      title={formatMessage(WarehouseMessages.palletTitleText)}
      message="Scan pallet to start linking it to a BOL#."
      placeholder={formatMessage(WarehouseMessages.palletFreightPalletMixHelpText)}
      value={palletId}
      onChange={onPalletIdChange}
      disabled={disabled}
      newest={newest}
    />
  );
};
