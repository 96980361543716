import { useMount } from "react-use";
import { useResetRecoilState } from "recoil";
import { createAddCaseScanCaseCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { TransfersModal } from "../../modals/TransfersModal";
import { cdskuState } from "../AddCaseToPalletState";

export function useCaseOnNonOpenPalletWarningModal() {
  const { hideModal } = useCrossdockModal();
  const { resetFlowCards, addFlowCard } = useScanFlow();
  const resetCdsku = useResetRecoilState(cdskuState);

  useMount(() => {
    resetCdsku();
    resetFlowCards();
    addFlowCard(createAddCaseScanCaseCard({}));
  });

  const onConfirmClick = () => {
    hideModal(TransfersModal.TRANSFERS_CASE_ON_NON_OPEN_PALLET_WARNING);
  };

  return {
    onConfirmClick,
  };
}
