import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";
import React from "react";
import { getAccentColorValue } from "@deliverr/ui-facility/lib-facility/flow/AccentColor";
import { useRecoilValue } from "recoil";
import { missingLabelCountState } from "../MissingLabelState";
import { usePrintingCaseLabel } from "./usePrintingCaseLabel";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { FixedWidthButton, ModalTemplate } from "@deliverr/ui-facility";

interface Props {
  reprinting?: boolean;
}

const buttonWidth = "128px";

const MarginButton = styled(FixedWidthButton)<Record<string, any> & { width: string }, DefaultTheme>`
  margin-left: ${({ theme }) => theme.spacing.S4};
`;

export const MissingLabelPrintingCaseLabelModal: React.FC<Props> = ({ reprinting }) => {
  const labelCount = useRecoilValue(missingLabelCountState);
  const { onCompleteClick, onReprintClick } = usePrintingCaseLabel();

  const title = reprinting ? (
    <FormattedPlural
      one={
        <FormattedMessage
          id="crossdock.missingLabelPrintingCaseLabelModal.reprintingSingleTitle"
          defaultMessage="Reprinting Label"
        />
      }
      other={
        <FormattedMessage
          id="crossdock.missingLabelPrintingCaseLabelModal.reprintingMultipleTitle"
          defaultMessage="Reprinting {labelCount} Labels"
          values={{ labelCount }}
        />
      }
      value={labelCount}
    />
  ) : (
    <FormattedPlural
      one={
        <FormattedMessage
          id="crossdock.missingLabelPrintingCaseLabelModal.printingSingleTitle"
          defaultMessage="Printing Label"
        />
      }
      other={
        <FormattedMessage
          id="crossdock.missingLabelPrintingCaseLabelModal.printingMultipleTitle"
          defaultMessage="Printing {labelCount} Labels"
          values={{ labelCount }}
        />
      }
      value={labelCount}
    />
  );

  return (
    <ModalTemplate
      title={title}
      text={
        <FormattedPlural
          one={
            <FormattedMessage
              id="crossdock.missingLabelPrintingCaseLabelModal.singleText"
              defaultMessage="Attach label and then sort box to Destination Scan area."
            />
          }
          other={
            <FormattedMessage
              id="crossdock.missingLabelPrintingCaseLabelModal.multipleText"
              defaultMessage="Attach labels and then sort boxes to Destination Scan area."
            />
          }
          value={labelCount}
        />
      }
      iconProps={{
        type: "check-circle",
        color: getAccentColorValue("GREEN"),
        size: "3x",
      }}
      buttonLayout="column"
    >
      <div>
        <FixedWidthButton width={buttonWidth} onClick={onReprintClick} secondary>
          <FormattedMessage
            id="crossdock.missingLabelPrintingCaseLabelModal.secondaryButton"
            defaultMessage="Reprint"
          />
        </FixedWidthButton>
        <MarginButton width={buttonWidth} onClick={onCompleteClick}>
          <FormattedMessage
            id="crossdock.missingLabelPrintingCaseLabelModal.continueButton"
            defaultMessage="Continue"
          />
        </MarginButton>
      </div>
    </ModalTemplate>
  );
};
