import React from "react";
import { DoneButton } from "@deliverr/ui-facility";
import { useFreightArrivalMovePalletNow } from "../cards/FreightArrivalMovePalletNowCard/useFreightArrivalMovePalletNow";
import { useMovePalletLoading } from "../cards/FreightArrivalMovePalletNowCard/useMovePalletLoading";

export const FreightArrivalMovePalletNowDoneButton: React.FC = () => {
  const { onClick } = useFreightArrivalMovePalletNow();
  const { loading } = useMovePalletLoading();

  return <DoneButton onClick={onClick} disabled={loading} block />;
};
