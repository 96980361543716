import { find } from "lodash";
import { useSetRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useMount } from "react-use";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useBoxBreakScanUnit } from "../../hooks/useBoxBreakScanUnit/useBoxBreakScanUnit";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../../modals/BoxBreakModal";
import { currentUnitBarcodeState, currentBoxBarcodeState } from "../../boxBreakState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useUpdateBoxBreakHeader } from "../../../useUpdateBoxBreakHeader";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useCheckEndBoxBreak } from "./useCheckEndBoxBreak";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useIntl } from "react-intl";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak.labels";
import { isSingleSkuBoxValue } from "../../../bulk/bulkState";

export function useBoxBreakScanAnyUnit() {
  const { setCdskuHeader } = useUpdateBoxBreakHeader();
  const { showModal } = useCrossdockModal();
  const { boxContentsAllUnitsDistributionByDsku, boxUnitTransfers, errorMessage, inputError, unitBarcode } =
    useBoxBreakState();
  const resetUnitBarcode = useResetRecoilState(currentUnitBarcodeState);
  const setUnitBarcode = useSetRecoilState(currentUnitBarcodeState);
  const { scanUnit } = useBoxBreakScanUnit();
  const { showFlowButtons, infoResponse } = useScanFlow();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  const checkEndBoxBreak = useCheckEndBoxBreak();
  const { formatMessage } = useIntl();
  const isSingleSkuBox = useRecoilValue(isSingleSkuBoxValue);

  useMount(() => {
    setCdskuHeader(currentBoxBarcode);
    showFlowButtons(
      ScanFlowButtonType.BOX_BREAK_END_SESSION,
      ScanFlowButtonType.BOX_BREAK_EJECT_BOX,
      ScanFlowButtonType.BOX_BREAK_PAUSE_SESSION
    );
    checkEndBoxBreak();
  });

  const submitUnitBarcode = async (newUnitBarcode: string) => {
    const ctx = logStart({ fn: "useBoxBreakScanAnyUnit.submitUnitBarcode", unitBarcode: newUnitBarcode });

    if (newUnitBarcode.length === 0) {
      log(ctx, "invalid unit barcode length");
      inputError(formatMessage(bbLabels.errorInvalidUnitBarcodeLength));
      return;
    }

    // If this scan represents the first overage scan of the SKU, prompt user to confirm scan before proceeding
    const dsku = find(boxUnitTransfers, ["unitBarcode", newUnitBarcode])?.dsku ?? "";
    if (
      dsku &&
      boxContentsAllUnitsDistributionByDsku?.[dsku]?.scannedUnits ===
        boxContentsAllUnitsDistributionByDsku?.[dsku]?.totalUnits
    ) {
      infoResponse();
      showModal(BoxBreakModal.BOX_BREAK_CONFIRM_OVERAGE_SCAN, { barcode: newUnitBarcode });
    } else {
      await scanUnit(newUnitBarcode);
    }
    resetUnitBarcode();
  };

  const onUnitBarcodeChange = onScannerInputChange(unitBarcode, setUnitBarcode, submitUnitBarcode);

  return {
    unitBarcode,
    onUnitBarcodeChange,
    submitUnitBarcode,
    errorMessage,
    isSingleSkuBox,
  };
}
