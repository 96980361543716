import { useResetRecoilState } from "recoil";
import { headerSubtitleState, headerTitleState, showPageLeftActionState } from "crossdock/base/headerTitleState";
import {
  fcDimsAndWeightCubiscanImagesState,
  fcDimsAndWeightProductBarcodeState,
  fcDimsAndWeightProductDimsState,
  fcDimsAndWeightProductState,
} from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";

export function useResetFcDimsAndWeightState() {
  const resetFcDimsAndWeightProductBarcode = useResetRecoilState(fcDimsAndWeightProductBarcodeState);
  const resetFcDimsAndWeightProduct = useResetRecoilState(fcDimsAndWeightProductState);
  const resetFcDimsAndWeightProductDims = useResetRecoilState(fcDimsAndWeightProductDimsState);
  const resetFcDimsAndWeightCubiscanImages = useResetRecoilState(fcDimsAndWeightCubiscanImagesState);

  const resetHeaderSubtitle = useResetRecoilState(headerSubtitleState);
  const resetShowPageLeftAction = useResetRecoilState(showPageLeftActionState);
  const resetHeaderTitle = useResetRecoilState(headerTitleState);

  const resetFcDimsAndWeightState = () => {
    resetFcDimsAndWeightProductBarcode();
    resetFcDimsAndWeightProduct();
    resetFcDimsAndWeightProductDims();
    resetFcDimsAndWeightCubiscanImages();
    resetHeaderSubtitle();
    resetShowPageLeftAction();
    resetHeaderTitle();
  };

  return {
    resetFcDimsAndWeightState,
  };
}
