import React from "react";
import { useIntl } from "react-intl";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useAddCaseScanPallet } from "./useAddCaseScanPallet";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { ADD_CASE_SCAN_PALLET_LABELS as labels } from "./AddCaseScanPalletCard.labels";

export interface AddCaseScanPalletCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const AddCaseScanPalletCard: React.FC<AddCaseScanPalletCardProps> = ({ disabled, newest }) => {
  const { cdsku, palletId, onPalletIdChange, debouncedSubmitPalletId } = useAddCaseScanPallet();
  const { formatMessage } = useIntl();

  return (
    <TextInputCard
      onSubmit={debouncedSubmitPalletId}
      newest={newest}
      icon="scan"
      accentColor="BLUE"
      title={formatMessage(WarehouseMessages.palletTitleText)}
      message={formatMessage(labels.message, { cdsku })}
      value={palletId}
      onChange={onPalletIdChange}
      disabled={disabled}
      placeholder={formatMessage(WarehouseMessages.palletIdentifierPlaceholder)}
      helpText={formatMessage(WarehouseMessages.palletHelpText)}
    />
  );
};
