import { atom } from "recoil";

import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";

export const showAddButtonState = atom<boolean>({
  key: CrossdockStateKey.PROBLEM_SHOW_ADD_SKU,
  default: false,
});

export const showBackButtonState = atom<boolean>({
  key: CrossdockStateKey.PROBLEM_SHOW_BACK_BUTTON,
  default: false,
});
