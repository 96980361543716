import { defineMessages, MessageDescriptor } from "react-intl";
import { FreightArrivalScanPalletError } from "../FreightArrivalScanPalletErrors";

export const ScanPalletErrorTitles: Record<FreightArrivalScanPalletError, MessageDescriptor> = defineMessages({
  [FreightArrivalScanPalletError.INVALID_PALLET_ID]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.invalidInput",
    defaultMessage: "Pallet can't be received",
  },
  [FreightArrivalScanPalletError.NOT_FOUND]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.palletNotFound",
    defaultMessage: "Pallet not in database",
  },
  [FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.wrongCrossdock",
    defaultMessage: "Wrong warehouse",
  },
  [FreightArrivalScanPalletError.PALLET_ALREADY_MARKED_AS_ARRIVED]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.previouslyRecorded",
    defaultMessage: "Pallet previously recorded",
  },
  [FreightArrivalScanPalletError.UNKNOWN_ERROR]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.unknownError",
    defaultMessage: "Something went wrong",
  },
  [FreightArrivalScanPalletError.NO_RECEIVABLE_PALLETS]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.title.noReceivablePallets",
    defaultMessage: "No receivable pallets",
  },
});

export const ScanPalletErrorMessages: Record<FreightArrivalScanPalletError, MessageDescriptor> = defineMessages({
  [FreightArrivalScanPalletError.INVALID_PALLET_ID]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.invalidInput",
    defaultMessage: "This pallet's shipment is not active. Report this issue to the facility manager.",
  },
  [FreightArrivalScanPalletError.NOT_FOUND]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.palletNotFound",
    defaultMessage: "This pallet couldn't be found. Report this issue to the facility manager.",
  },
  [FreightArrivalScanPalletError.SCANNED_AT_WRONG_CROSSDOCK]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.wrongCrossdock",
    defaultMessage: "This pallet is not for this warehouse. Report this issue to the facility manager.",
  },
  [FreightArrivalScanPalletError.PALLET_ALREADY_MARKED_AS_ARRIVED]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.previouslyRecorded",
    defaultMessage: "Record arrival using PO# if this is a duplicate pallet label.",
  },
  [FreightArrivalScanPalletError.UNKNOWN_ERROR]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.unknownError",
    defaultMessage: "Report this issue to the facility manager.",
  },
  [FreightArrivalScanPalletError.NO_RECEIVABLE_PALLETS]: {
    id: "crossdock.freightArrivalScanPalletErrorModal.message.noReceivablePallets",
    defaultMessage: "This PO# has no receivable pallets remaining.",
  },
});
