import { Button, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";
import { ButtonProps, FACILITY_COMMONS_BUTTON_LABELS } from "@deliverr/ui-facility";
import { COMMON_ERROR_LABELS } from "@deliverr/ui-facility/lib-facility/labels";
import { logger, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import {
  createDangerNotification,
  createSuccessNotification,
} from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import {
  PrepRequiredLabelPrintConfig,
  usePrintPrepRequiredLabel,
} from "crossdock/common/prep/usePrintPrepRequiredLabel";
import React from "react";
import { useIntl } from "react-intl";
import { useMount } from "react-use";
import { GenericModal } from "../GenericModal";
import labels from "./PrepRequiredModal.labels";

const LinkButton = styled(Button)<ButtonProps & ThemeProps>(
  ({ theme }) => `
  border: none;
  background: none;
  padding: 0;
  color: ${theme.colors.BLUE["300"]};
  line-height: ${theme.font.lineHeight.LH1};
  height: fit-content;
`
);

export interface PrepRequiredModalProps extends PrepRequiredLabelPrintConfig {
  poNum?: string;
}

export const usePrepRequiredModal = ({ poNum, cdsku }: PrepRequiredModalProps) => {
  const { hideModal } = useCrossdockModal();
  const { formatMessage } = useIntl();
  const { emitFlash, addNotification, resetNotifications } = useScanFlow();
  const { printPrepRequiredLabel } = usePrintPrepRequiredLabel();

  const notificationSuccessMessage = formatMessage(labels.notification);
  const notificationButtonLabel = formatMessage(FACILITY_COMMONS_BUTTON_LABELS.reprintButton);

  async function printPrepLabelAndNotify() {
    const ctx = logStart({ fn: "printPrepLabelAndNotify", cdsku, poNum });

    // Adds a notification with the ability to reprint.
    // If no error code is present, shows success; otherwise, shows an error notification with error code
    // Note: nested because it calls the parent function
    const showPrintNotification = (errorCode?: string) => {
      const notificationFn = errorCode ? createDangerNotification : createSuccessNotification;
      const message = errorCode ? formatMessage(labels.notificationError, { errorCode }) : notificationSuccessMessage;

      addNotification({
        ...notificationFn(
          message,
          <LinkButton onClick={printPrepLabelAndNotify}>{notificationButtonLabel}</LinkButton>
        ),
        onClose: resetNotifications,
      });
    };

    try {
      // errors already handled within the function
      await printPrepRequiredLabel({ cdsku });

      // make sure we clear older notifications
      resetNotifications();

      showPrintNotification();
    } catch (err) {
      logger.warn({ ...ctx, err }, "Failed to notify or print Prep Required label");
      showPrintNotification(err.code ?? formatMessage(COMMON_ERROR_LABELS.unknownError));
    }
  }

  const handleConfirmClick = async () => {
    hideModal(GenericModal.PREP_REQUIRED);
    await printPrepLabelAndNotify();
  };

  useMount(() => {
    emitFlash("DEFAULT");
  });

  return {
    title: formatMessage(labels.title),
    message: formatMessage(labels.message),
    poNumLabel: formatMessage(labels.poNumLabel, { poNum }),
    buttonLabel: formatMessage(FACILITY_COMMONS_BUTTON_LABELS.understandButton),
    handleConfirmClick,
  };
};
