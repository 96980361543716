import { createScanInboundPalletLabelCard } from "./freightArrivalCardCreators";
import { ScanFlowV2Props } from "crossdock/common/flow/ScanFlowV2";
import { useFreightArrival } from "./useFreightArrival";

export const useFreightArrivalPage = (): ScanFlowV2Props => {
  const { resetFreightArrival } = useFreightArrival();

  return {
    onMountCardData: createScanInboundPalletLabelCard({}),
    onUnmount: resetFreightArrival,
  };
};
