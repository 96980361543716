import React from "react";
import { PromptModal, warningModalIconLg } from "@deliverr/ui-facility";
import {
  FreightArrivalPalletPreviouslyRecordedModalProps,
  useFreightArrivalPalletPreviouslyRecordedModal,
} from "./useFreightArrivalPalletPreviouslyRecordedModal";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";

export const FreightArrivalPalletPreviouslyRecordedModal: React.FC<FreightArrivalPalletPreviouslyRecordedModalProps> = (
  props
) => {
  const { onCancelClick, onConfirmClick, title, message, confirmLabel, cancelLabel } =
    useFreightArrivalPalletPreviouslyRecordedModal(props);

  return (
    <PromptModal
      title={title}
      content={message}
      iconProps={warningModalIconLg}
      soundFx={SoundFx.INFO}
      onConfirmClick={onConfirmClick}
      onCancelClick={onCancelClick}
      confirmButtonLabel={confirmLabel}
      cancelButtonLabel={cancelLabel}
      vertical
    />
  );
};
