import React from "react";
import { FormattedMessage } from "react-intl";
import { FixedWidthButton, ModalTemplate, dangerModalIcon } from "@deliverr/ui-facility";
import { useProblemQuitModal } from "./useProblemQuitModal";
import { LocalizedMessage, ThemeProps } from "@deliverr/ui";
import styled from "@emotion/styled";

const buttonWidth = "128px";

const ButtonContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.S2};
`
);

export interface ProblemQuitModalProps {
  title: LocalizedMessage;
  quitButtonLabel?: LocalizedMessage;
}

export const ProblemQuitModal: React.FC<ProblemQuitModalProps> = ({ title, quitButtonLabel = "Quit" }) => {
  const { onCancelClick, onQuitLabelingClick } = useProblemQuitModal();

  return (
    <ModalTemplate
      title={title}
      text={
        <FormattedMessage
          id="crossdock.problemQuitModal.text"
          defaultMessage="If you leave this page, your progress will be lost."
        />
      }
      iconProps={dangerModalIcon}
    >
      <ButtonContainer>
        <FixedWidthButton width={buttonWidth} onClick={onCancelClick} secondary>
          <FormattedMessage id="crossdock.problemQuitModal.cancelBtn" defaultMessage="Cancel" />
        </FixedWidthButton>
        <FixedWidthButton width={buttonWidth} onClick={onQuitLabelingClick}>
          {quitButtonLabel ?? <FormattedMessage id="crossdock.problemQuitModal.quitBtn" defaultMessage="Quit" />}
        </FixedWidthButton>
      </ButtonContainer>
    </ModalTemplate>
  );
};
