import { useResetRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import { headerSubtitleState } from "crossdock/base/headerTitleState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import {
  poNumState,
  prepRequestState,
  palletLabelIdState,
  inboundPalletLabelIdState,
  willMovePalletState,
} from "./FreightArrivalState";
import {
  createScanInboundPalletLabelCard,
  createFreightArrivalHighPriorityPalletCard,
  createMovePalletNowCard,
  createFreightArrivalSendToFulfillmentCard,
} from "./freightArrivalCardCreators";
import { inboundClient, crossdockClient } from "crossdock/base/Clients";
import { isUndefined, isEmpty } from "lodash/fp";
import { useSetFreightArrivalSubtitle } from "./useSetFreightArrivalSubtitle";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";

export const useFreightArrival = () => {
  const setPrepRequestState = useSetRecoilState(prepRequestState);
  const inboundPalletLabelId = useRecoilValue(inboundPalletLabelIdState);
  const freightPalletLabelId = useRecoilValue(palletLabelIdState);
  const { setPoSubtitle } = useSetFreightArrivalSubtitle();
  const { showModal } = useCrossdockModal();

  const { resetFlow, addFlowCard, hideAllFlowButtons } = useScanFlow();
  const resetPoNum = useResetRecoilState(poNumState);
  const resetHeaderSubtitle = useResetRecoilState(headerSubtitleState);
  const resetPrepRequest = useResetRecoilState(prepRequestState);
  const resetPalletLabelId = useResetRecoilState(palletLabelIdState);
  const resetInboundPalletLabelId = useResetRecoilState(inboundPalletLabelIdState);
  const resetWillMovePallet = useResetRecoilState(willMovePalletState);

  const resetFreightArrivalData = () => {
    resetPoNum();
    resetHeaderSubtitle();
    resetPrepRequest();
    resetPalletLabelId();
    resetInboundPalletLabelId();
    resetWillMovePallet();
  };

  const resetFreightArrival = () => {
    resetFreightArrivalData();
    resetFlow();
  };

  const restartFreightArrival = () => {
    resetFreightArrival();
    addFlowCard(createScanInboundPalletLabelCard({}));
  };

  const linkPalletToAsn = async (poNum: number): Promise<void> => {
    const { hasOOS, sellerId, shouldWeighPallet, pallet } = await crossdockClient.linkInboundPalletToAsnV3({
      asnId: poNum,
      inboundPalletBarcode: inboundPalletLabelId,
      freightPalletLabel:
        isUndefined(freightPalletLabelId) || isEmpty(freightPalletLabelId) ? undefined : freightPalletLabelId,
    });

    if (!pallet.isDirect) {
      const prepRequest = await inboundClient.findPrepRequestByPoNum(poNum);
      if (prepRequest) {
        // ensures that the correct destination for the pallet is shown later
        setPrepRequestState(prepRequest);
      }
    }

    hideAllFlowButtons();
    setPoSubtitle(inboundPalletLabelId, poNum);

    if (pallet.isDirect) {
      addFlowCard(createFreightArrivalSendToFulfillmentCard({}));
      return;
    }

    if (shouldWeighPallet) {
      showModal(TransfersModal.FREIGHT_ARRIVAL_LOG_PALLET_WEIGHT, { sellerId });
    }

    if (hasOOS) {
      addFlowCard(createFreightArrivalHighPriorityPalletCard({}));
    } else {
      addFlowCard(createMovePalletNowCard({}));
    }
  };

  return {
    restartFreightArrival,
    resetFreightArrival,
    resetFreightArrivalData,
    linkPalletToAsn,
  };
};
