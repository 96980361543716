import { useState } from "react";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { createScanInboundPalletCard } from "../InboundPalletLabelCardCreators";
import { validateStagingLaneBarcode } from "../utils/validateStagingLaneBarcode";
import { useIntl } from "react-intl";
import labels from "./MovePallet.labels";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { inboundPalletLabelBarcodeState } from "./MovePalletState";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { crossdockClient } from "crossdock/base/Clients";
import { useMovePalletNotifications } from "./useMovePalletNotifications";

const DEFAULT_STAGING_LANE_BARCODE = "";

export function useMovePalletScanStagingLane() {
  const { showScanStagingLaneSuccessNotification } = useMovePalletNotifications();
  const inboundPalletBarcode = useRecoilValue(inboundPalletLabelBarcodeState);
  const resetInboundPalletBarcode = useResetRecoilState(inboundPalletLabelBarcodeState);
  const { formatMessage } = useIntl();
  const { successResponse, handleUnknownError, addFlowCard } = useScanFlow();
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [stagingLaneBarcode, setStagingLaneBarcode] = useState<string>(DEFAULT_STAGING_LANE_BARCODE);

  const submitStagingLaneScan = async (newStagingLaneBarcode: string): Promise<void> => {
    const ctx = logStart({ fn: "useScanStagingLane.submitStagingLaneScan", stagingLaneBarcode: newStagingLaneBarcode });

    if (!validateStagingLaneBarcode(newStagingLaneBarcode)) {
      log(ctx, "invalid staging lane barcode");
      setStagingLaneBarcode(DEFAULT_STAGING_LANE_BARCODE);
      inputError(formatMessage(labels.scanStagingLaneInvalidBarcode));
      return;
    }

    try {
      await crossdockClient.recordLaneDrop(newStagingLaneBarcode, inboundPalletBarcode);
      log(ctx, "moved pallet to new lane");

      successResponse();
      showScanStagingLaneSuccessNotification(inboundPalletBarcode, newStagingLaneBarcode);
      addFlowCard(createScanInboundPalletCard({}));
      resetInboundPalletBarcode();
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  const onStagingLaneBarcodeChange = onScannerInputChange(
    stagingLaneBarcode,
    setStagingLaneBarcode,
    submitStagingLaneScan,
    "upper"
  );

  const onCancelClick = async () => {
    log({ fn: "useScanStagingLane.onCancelClick" }, "canceled, moving back to scan inbound pallet");
    addFlowCard(createScanInboundPalletCard({}));
    resetInboundPalletBarcode();
  };

  return {
    onSubmit: loadWhilePending(resetErrorOnExecution(submitStagingLaneScan)),
    onChange: onStagingLaneBarcodeChange,
    onClick: resetErrorOnExecution(onCancelClick),
    loading,
    inputErrorMessage,
    stagingLaneBarcode,
  };
}
