import { testCrossdockWarehouseId } from "@deliverr/ui-facility/lib-facility/test";
import { Audit, AuditStatus } from "@deliverr/crossdock-service-client";

export enum BoxAuditStatus {
  OPEN = "OPEN",
  EJECTED = "EJECTED",
  RESOLVED = "RESOLVED",
}

export enum BoxAuditUnknownBarcodeNonComplianceField {
  BARCODE_PHOTO = "barcodePicture",
  BOXLABEL_PHOTO = "boxContentPicture",
  FRONT_PHOTO = "frontPicture",
  BACK_PHOTO = "backPicture",
}

// data always gets overwritten by backend
export const createBoxAudit = (dskus?: string[]): Audit => ({
  id: Math.ceil(Math.random() * 100),
  status: AuditStatus.OPEN,
  cdsku: "CD123456789",
  crossdockId: testCrossdockWarehouseId,
  varianceNoted: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  userId: "",
  userEmail: "",
  isApplied: false,

  // for testing purposes only
  items: dskus
    ? dskus.map((item, index) => ({
        id: index,
        createdAt: new Date(),
        updatedAt: new Date(),
        dsku: item,
        auditId: 1,
        qty: 1,
        barcode: item,
      }))
    : [],
});

export enum AuditError {
  CREATING = "creating",
  UPDATING = "updating",
  COMPLETING = "completing",
}
