import { useResetRecoilState } from "recoil";
import { useLifecycles } from "react-use";
import React from "react";
import { createAddCaseScanCaseCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { cdskuState, palletIdState } from "./AddCaseToPalletState";
import { ScanFlow } from "crossdock/common/flow/ScanFlow";

export const AddCaseToPallet: React.FC = () => {
  const { addFlowCard, resetFlow } = useScanFlow();
  const resetCdsku = useResetRecoilState(cdskuState);
  const resetPalletId = useResetRecoilState(palletIdState);

  useLifecycles(
    () => {
      addFlowCard(createAddCaseScanCaseCard({}));
    },
    () => {
      resetFlow();
      resetCdsku();
      resetPalletId();
    }
  );

  return <ScanFlow />;
};
