import styled from "@emotion/styled";
import React from "react";
import { Card, IconV2 } from "@deliverr/ui";
import { AltMenuConfig } from "./AltMenuConfig";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";

interface Props extends AltMenuConfig {
  hideMenu: () => void;
}

const AltMenuCard = styled(Card)`
  padding: 0;
  position: absolute;
  right: ${FACILITY_THEME.spacing.S5};
  top: 2.5rem;
  min-width: 233px;
`;

const AltMenuContainer = styled.div`
  color: ${FACILITY_THEME.colors.NEUTRAL[500]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const AltMenuItem = styled.button`
  padding: ${FACILITY_THEME.spacing.S3};
  display: flex;
  align-items: center;
  :hover {
    background: ${FACILITY_THEME.colors.NEUTRAL[40]};
  }
`;

const AltMenuIcon = styled(IconV2)`
  margin-right: ${FACILITY_THEME.spacing.S2};
`;

const AltMenuItemText = styled.span`
  font-size: ${FACILITY_THEME.font.size.F2};
`;

export const AltMenu: React.FC<Props> = ({ items, hideMenu }) => {
  return (
    <AltMenuCard as="section">
      <AltMenuContainer>
        {items.map(({ icon, text, onClick }) => {
          const onItemClick: React.MouseEventHandler<HTMLButtonElement> = () => {
            hideMenu();
            onClick();
          };

          return (
            <AltMenuItem type="button" key={text} onClick={onItemClick}>
              {icon && <AltMenuIcon type={icon} size="sm" />}
              <AltMenuItemText>{text}</AltMenuItemText>
            </AltMenuItem>
          );
        })}
      </AltMenuContainer>
    </AltMenuCard>
  );
};
