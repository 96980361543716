import {
  LoadingDockScanEventApiErrorCode,
  LoadingDockScanEventResponseWithWarehouseId,
  LoadingDockScanEventScanType,
} from "@deliverr/freight-client";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { freightClient } from "crossdock/base/Clients";
import {
  createSortPalletToMilkRunCard,
  createSortPalletToMilkRunErrorCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useIntl } from "react-intl";
import { useRecoilValue } from "recoil";
import {
  getFreightLoadingDockScanErrorMessageDescriptor,
  getFreightLoadingDockScanErrorTitleDescriptor,
} from "../utils/freightLoadingDockScanErrorUtils";

export function useSubmitFreightPalletId() {
  const { warehouseId } = useRecoilValue(userState);
  const { addFlowCard, emitFlash } = useScanFlow();
  const { formatMessage } = useIntl();

  const handleSubmitFreightPalletId = async (
    barcode: string,
    handleBeginScan: () => void,
    handleFinishScan: () => void
  ) => {
    logStart({ fn: "handleSubmitFreightPalletId", barcode });
    handleBeginScan();

    try {
      const resp = (await freightClient.createWarehouseLoadingDockScanEvent({
        scanType: LoadingDockScanEventScanType.ARRIVAL,
        warehouseId,
        barcode,
      })) as LoadingDockScanEventResponseWithWarehouseId;

      emitFlash("DEFAULT");
      addFlowCard(
        createSortPalletToMilkRunCard({
          palletId: resp.barcode,
          warehouseId: resp.nextLocation,
          scannedPalletCount: resp.palletsScannedAtLocation,
          totalPalletCount: resp.totalPallets,
        })
      );
    } catch (err) {
      if (err.response?.data?.code === LoadingDockScanEventApiErrorCode.DUPLICATE_SCAN) {
        emitFlash("DEFAULT");
        addFlowCard(
          createSortPalletToMilkRunCard({
            palletId: barcode,
            warehouseId: err.response.data.payload.nextLocation,
          })
        );
      } else {
        emitFlash("DANGER");
        addFlowCard(
          createSortPalletToMilkRunErrorCard({
            palletId: barcode,
            title: formatMessage(getFreightLoadingDockScanErrorTitleDescriptor(err)),
            message: formatMessage(getFreightLoadingDockScanErrorMessageDescriptor(err)),
          })
        );
      }
    } finally {
      handleFinishScan();
    }
  };

  return { handleSubmitFreightPalletId };
}
