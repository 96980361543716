import { useSetRecoilState } from "recoil";
import { GetWarehouseEmailFn } from "crossdock/common/flow/forms/DamagedProductForm/ComposeUseDamagedProductFormProps";
import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { useAsync } from "react-use";
import { warehouseEmailState } from "@deliverr/ui-facility/lib-facility/base/warehouseEmailState";

export const composeUseWarehouseEmailState = (getWarehouseEmail: GetWarehouseEmailFn) => (warehouseId: string) => {
  const setWarehouseIdState = useSetRecoilState(warehouseEmailState);
  return useAsync(async () => {
    const warehouseEmail = await getWarehouseEmail(warehouseId);
    log({ fn: "useWarehouseEmailState", warehouseEmail }, "Retrieved warehouse email");
    setWarehouseIdState({
      warehouseEmail,
    });
    return warehouseEmail;
  });
};
