import { PalletDetails, PalletStage, Region } from "@deliverr/crossdock-service-client";
import { userState } from "@deliverr/ui-facility";
import { crossdockClient } from "crossdock/base/Clients";
import { ReactElement, useCallback, useState } from "react";
import { useRecoilValue } from "recoil";
import { PALLETS_LABELS } from "./pallets.labels";
import { useFilter } from "../useFilter";

export function usePalletsTable() {
  const user = useRecoilValue(userState);
  const { warehouseId } = user;

  const [rows, setRows] = useState<ReactElement[][]>([]);
  const [appReady, setAppReady] = useState(false);
  const { getFilter } = useFilter();

  const getPalletDetails = useCallback(async () => {
    const {
      redirectFn,
      autoRefreshFn,
      stagesStr,
      regionsStr,
      createdFromStr,
      updatedFromStr,
      updatedToStr,
      createdToStr,
      hoursFromStr,
      hoursToStr,
    } = getFilter();

    if (redirectFn) {
      redirectFn();
      return;
    }

    autoRefreshFn();

    const palletDetails: PalletDetails[] = await crossdockClient.getPalletDetails({
      filter: {
        currentCrossdockIds: [warehouseId],
        stages: (stagesStr?.split(",") ?? []).map((stageStr) => PalletStage[stageStr]),
        regions: (regionsStr?.split(",") ?? []).map((regionStr) => Region[regionStr]),
        created: {
          from: createdFromStr ? new Date(createdFromStr) : undefined,
          to: createdToStr ? new Date(createdToStr) : undefined,
        },
        updated: {
          from: updatedFromStr ? new Date(updatedFromStr) : undefined,
          to: updatedToStr ? new Date(updatedToStr) : undefined,
        },
        hoursLapsedSinceFirstLinked: {
          from: hoursFromStr ? Number(hoursFromStr) : undefined,
          to: hoursToStr ? Number(hoursToStr) : undefined,
        },
      },
    });

    const rowsData: ReactElement[][] = [];
    palletDetails.forEach((palletDetail) => {
      const row: ReactElement[] = [];

      const palletDetailFields = PALLETS_LABELS.pallets.columns.map((column) =>
        column.id.replace("pallets.columns.", "")
      );
      palletDetailFields.forEach((field) => {
        row.push(palletDetail[field] ?? "-");
      });

      rowsData.push(row);
    });

    setRows(rowsData);
  }, [warehouseId, getFilter]);

  const getPalletDetailsOnLoad = useCallback(async () => {
    await getPalletDetails();
    setAppReady(true);
  }, [getPalletDetails]);

  return {
    rows,
    getPalletDetails,
    getPalletDetailsOnLoad,
    appReady,
    warehouseId,
  };
}
