import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { ScanPath } from "crossdock/base/routes/ScanPath";

export function useProblemQuitModal() {
  const { hideAllModals } = useCrossdockModal();
  const router = useRouter();

  const onCancelClick = () => {
    hideAllModals();
  };

  const onQuitLabelingClick = () => {
    // data will be reset on component unmount
    hideAllModals();
    router.push(ScanPath.Problem);
  };

  return {
    onCancelClick,
    onQuitLabelingClick,
  };
}
