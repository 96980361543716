import { useResetCrossdockDimsAndWeightState } from "crossdock/modes/inspection/crossdock-dims-and-weight/hooks/useResetCrossdockDimsAndWeightState";
import { toCrossdockDimsAndWeightPackageCdsku } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export const useCrossdockDimsAndWeightCancelSession = () => {
  const { resetCrossdockDimsAndWeightState } = useResetCrossdockDimsAndWeightState();
  const { transition } = useScanFlow();

  const onButtonClick = async () => {
    resetCrossdockDimsAndWeightState();
    transition(toCrossdockDimsAndWeightPackageCdsku());
  };

  return {
    onButtonClick,
  };
};
