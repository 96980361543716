import { InboundPrepRequest } from "@deliverr/commons-clients";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { atom, selector } from "recoil";
import { LaneType } from "@deliverr/commons-clients/lib/crossdock/models/Lane";

export const inboundPalletLabelIdState = atom<string>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_INBOUND_PALLET_LABEL_ID,
  default: "",
});

export const palletLabelIdState = atom<string>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_PALLET_LABEL_ID,
  default: "",
});

export const poNumState = atom<string>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_PO_NUM,
  default: "",
});

export const prepRequestState = atom<InboundPrepRequest | undefined>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_PREP_REQUEST,
  default: undefined,
});

export const willMovePalletState = atom<string>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_WILL_MOVE_PALLET,
  default: "",
});

// this state is preserved between entire flows so workers can quickly scan
// to the last used po number instead of entering it manually each time (error prone and slow).
export const lastPoState = atom<string | undefined>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_LAST_PO,
  default: undefined,
});

export const isSpdState = atom<boolean>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_IS_SPD,
  default: false,
});

export const palletDestinationLaneTypeValue = selector<LaneType>({
  key: CrossdockStateKey.FREIGHT_ARRIVAL_CLASSIFICATION,
  get: ({ get }) => {
    if (get(isSpdState)) {
      return LaneType.GENERAL_INBOUND;
    }

    if (!get(poNumState)) {
      return LaneType.PROBLEM_AREA;
    }

    if (get(prepRequestState)) {
      return LaneType.PREP;
    }

    return LaneType.GENERAL_INBOUND;
  },
});
