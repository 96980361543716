import { defineMessages } from "react-intl";

export const SEND_TO_FULFILLMENT_LABELS = defineMessages({
  title: {
    id: "crossdock.freightArrivalSendToFulfillmentCard.title",
    defaultMessage: "Sort to Fulfillment Center",
  },
  message: {
    id: "crossdock.freightArrivalSendToFulfillmentCard.message",
    defaultMessage: "Pallet belongs to a direct shipment. Send to fulfillment center receiving.",
  },
});
