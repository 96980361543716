import { atom, selector } from "recoil";
import { UnitTransfer } from "@deliverr/commons-clients";
import { every } from "lodash";
import { CrossdockStateKey } from "../../../base/CrossdockStateKey";
import { UnitTransfersByChuteBox } from "./types/UnitTransfersByChuteBox";
import { BoxBreakDistributionsByDsku } from "./types/BoxBreakDistributionsByDsku";

// unit count of box currently being broken
export const currentBoxBarcodeUnitCountState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_CURRENT_UNIT_COUNT,
  default: "0",
});

// dsku of unit currently being broken
export const currentUnitBarcodeState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_CURRENT_UNIT_BARCODE,
  default: "",
});

// cdsku of box currently being broken
export const currentBoxBarcodeState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_CURRENT_BOX_BARCODE,
  default: "",
});

// target warehouseId of the current run
export const currentRunDestinationState = atom<string | undefined>({
  key: CrossdockStateKey.BOX_BREAK_RUN_DESTINATION,
  default: undefined,
});

// number of units scanned in the current run
export const currentUnitsScannedCountState = atom<number>({
  key: CrossdockStateKey.BOX_BREAK_CURRENT_UNITS_SCANNED_COUNT,
  default: 0,
});

// Unit transfers for the box currently being broken (input box).
// Unit transfers tell us the current state of units being transferred in a box being broken.
// This allows us to resume a box break from where we left off.
export const boxUnitTransfersState = atom<UnitTransfer[]>({
  key: CrossdockStateKey.BOX_BREAK_BOX_UNIT_TRANSFERS,
  default: [],
});

// Unit transfers for the containers at a station.
// A container or ChuteBox is an output box from a box break station,
// containing products for many input boxes.
export const unitTransfersByChuteBoxState = atom<UnitTransfersByChuteBox | undefined>({
  key: CrossdockStateKey.BOX_BREAK_UNIT_TRANSFERS_BY_CHUTE_BOX,
  default: undefined,
});

export const lastUnitScannedState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_LAST_UNIT_SCANNED,
  default: "",
});

// Amount of units in the current run (units of same type going to a specific warehouse).
// We obtain this value from the crossdock service and use it to determine when to instruct the user
// to place units in a new chute
export const runTargetCountState = atom<number | undefined>({
  key: CrossdockStateKey.BOX_BREAK_RUN_TARGET_COUNT,
  default: undefined,
});

export const boxContentsAllUnitsDistributionByDskuState = atom<BoxBreakDistributionsByDsku>({
  key: CrossdockStateKey.BOX_BREAK_ALL_UNITS_DISTRIBUTION_BY_DSKU,
  default: {},
});

/**
 * If all quantities of "known" units have been scanned and placed into a chute
 * we prompt the user to end the box break
 */
export const allTargetsMetValue = selector<boolean>({
  key: CrossdockStateKey.BOX_BREAK_ALL_TARGETS_MET,
  get: ({ get }) =>
    every(
      get(boxContentsAllUnitsDistributionByDskuState),
      ({ scannedUnits, totalUnits }) => scannedUnits >= totalUnits
    ),
});

/**
 * This modal is shown whenever all the expected units have been scanned.
 * We only want to show this once to avoid annoying the user during
 * an overage scenario (more units in box than expected).
 */
export const showedEndBreakModalState = atom<boolean>({
  key: CrossdockStateKey.BOX_BREAK_SHOWED_END_BREAK_MODAL,
  default: false,
});

/**
 * A map of dskus to that product's barcodes.
 */
export const dskuToProductBarcodesState = atom<Record<string, string[]>>({
  key: CrossdockStateKey.BOX_BREAK_PRODUCTS_BARCODES,
  default: {},
});
