import React from "react";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { DamagedProductForm } from "@deliverr/ui-facility/lib-facility/flow/forms/DamagedProductForm";

import { ThemeProps } from "@deliverr/ui";
import { useDamagedProductCard } from "./useDamagedProductCard";
import styled from "@emotion/styled-base";
import { s3FileHandlerClient } from "crossdock/base/Clients";

const StyledFlowCard = styled(FlowCard)<ThemeProps>(
  ({ theme }) => `
  height: fit-content !important;

  & fieldset {
    padding-top: ${theme.spacing.S2};
  }
`
);

export const DamagedProductCard: React.FC = () => {
  const { onFailure, useDamagedProductForm } = useDamagedProductCard();

  return (
    <StyledFlowCard>
      <DamagedProductForm
        useDamagedProductForm={useDamagedProductForm}
        onFailure={onFailure}
        fileClient={s3FileHandlerClient}
        isCrossdock
      />
    </StyledFlowCard>
  );
};
