import React from "react";
import { useIntl } from "react-intl";
import { TextButton } from "@deliverr/ui-facility/lib-facility/components/TextButton";
import labels from "./FreightArrivalEnterPoNum.labels";
import { useFreightArrivalEnterPoNum } from "./useFreightArrivalEnterPoNum";

export const NoPoButton = () => {
  const { formatMessage } = useIntl();
  const { onClick } = useFreightArrivalEnterPoNum();

  return (
    <TextButton onClick={onClick} type="button">
      {formatMessage(labels.poNotFound)}
    </TextButton>
  );
};
