import React from "react";
import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import { FormattedMessage } from "react-intl";
import { MissingLabelReportManuallyButton } from "./MissingLabelReportManuallyButton";

export const MissingLabelUnsolvableBoxModal: React.FC = () => (
  <ModalTemplate
    title={<FormattedMessage id="crossdock.missingLabelUnsolvableBox.title" defaultMessage="Box Unsolvable" />}
    text={
      <FormattedMessage
        id="crossdock.missingLabelUnsolvableBox.text"
        defaultMessage="This combination of SKUs is not solvable. Report box manually."
      />
    }
    iconProps={warningModalIcon}
  >
    <MissingLabelReportManuallyButton />
  </ModalTemplate>
);
