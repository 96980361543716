import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { useIntl } from "react-intl";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import labels from "./FreightArrival.labels";

export function useFreightArrivalNotifications() {
  const { formatMessage } = useIntl();
  const { addAutoCloseNotification } = useScanFlow();

  const showScanLaneSuccessNotification = (palletId: string, laneId: string) => {
    const notification = createSuccessNotification(
      formatMessage(labels.palletDroppedOffNotification, {
        palletId,
        laneId,
      })
    );
    addAutoCloseNotification(notification);
  };

  const showFreightArrivalRecordedNotification = (palletId: string) => {
    const notification = createSuccessNotification(
      formatMessage(labels.freightArrivalRecordedNotification, {
        palletId,
      })
    );
    addAutoCloseNotification(notification);
  };

  return {
    showScanLaneSuccessNotification,
    showFreightArrivalRecordedNotification,
  };
}
