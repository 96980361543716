import { NonComplianceClient, ProductClient, S3FileHandlerClient } from "@deliverr/commons-clients";
import { WarehousePublicClient } from "@deliverr/warehouse-client";
import { InboundClient } from "@deliverr/legacy-inbound-client";
import { FreightClient } from "@deliverr/freight-client";
import { CrossdockClient } from "@deliverr/crossdock-service-client";
import { AuthEngine as LegacyAuthEngine } from "@deliverr/commons-objects";
import { AuthEngine } from "@deliverr/core-auth";

export const warehouseClient = new WarehousePublicClient({
  baseURL: process.env.WAREHOUSE_SERVICE_URL ?? process.env.REACT_APP_WAREHOUSE_SERVICE_URL,
  authEngine: LegacyAuthEngine.AUTH0,
});

export const crossdockClient = new CrossdockClient({
  baseURL: process.env.CROSSDOCK_SERVICE_URL ?? process.env.REACT_APP_CROSSDOCK_SERVICE_URL,
  authEngine: LegacyAuthEngine.AUTH0,
  directCallerServiceName: "crossdock-portal",
  deploymentEnv: process.env.ENV ?? "development",
});

export const nonComplianceClient = new NonComplianceClient({
  baseURL: process.env.NON_COMPLIANCE_SERVICE_URL! ?? process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
  authEngine: LegacyAuthEngine.AUTH0,
});

export const s3FileHandlerClient = new S3FileHandlerClient({
  baseURL: process.env.NON_COMPLIANCE_SERVICE_URL! || process.env.REACT_APP_NON_COMPLIANCE_SERVICE_URL!,
  authEngine: LegacyAuthEngine.AUTH0,
});

export const inboundClient = new InboundClient({
  baseURL: process.env.INBOUND_SERVICE_URL! ?? process.env.REACT_APP_INBOUND_SERVICE_URL!,
  authEngine: LegacyAuthEngine.AUTH0,
});

export const productClient = new ProductClient({
  baseURL: process.env.PRODUCT_SERVICE_URL! ?? process.env.REACT_APP_PRODUCT_SERVICE_URL!,
  authEngine: LegacyAuthEngine.AUTH0,
});

export const freightClient = new FreightClient({
  baseURL: process.env.FREIGHT_SERVICE_URL,
  authEngine: AuthEngine.AUTH0,
});
