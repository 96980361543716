import React from "react";
import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { format } from "date-fns";
import { DateFormat } from "@deliverr/ui-facility/lib-facility/utils/dateUtils";
import { TransferPalletStatus } from "@deliverr/legacy-inbound-client";

export interface ReopenPalletInvalidStatusCardProps {
  palletLabelId: string;
  status: TransferPalletStatus;
  statusUpdatedAt: Date;
  newest?: boolean;
}

export const ReopenPalletInvalidStatusCard: React.FC<ReopenPalletInvalidStatusCardProps> = ({
  palletLabelId,
  status,
  statusUpdatedAt,
  newest,
}) => (
  <ErrorTextCard
    title={`Cannot re-open pallet with status: ${status.toLowerCase()}!`}
    value={palletLabelId}
    message={`Pallet ${status.toLowerCase()} as of ${format(statusUpdatedAt, DateFormat.TimeAndDate)}`}
    newest={newest}
  />
);
