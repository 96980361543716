import React from "react";

import { commonUploadMessages } from "@deliverr/ui-facility/lib-facility/labels";
import { BoxAuditUnknownBarcodeUploadCard } from "./BoxAuditUnknownBarcodeUploadCard";
import { useBoxAuditUnknownBarcode } from "./useBoxAuditUnknownBarcode";
import { BoxAuditUnknownBarcodeNonComplianceField } from "../BoxAuditTypes";

export const BoxAuditUnknownBarcodeProductUploadCard: React.FC = () => {
  const { submitUnknownBarcode } = useBoxAuditUnknownBarcode();

  return (
    <BoxAuditUnknownBarcodeUploadCard
      title={commonUploadMessages.identifyProductTitle}
      uploads={[
        {
          iconText: commonUploadMessages.frontOfSku,
          iconId: "front-product-photo",
          photoType: "frontPicture",
          field: BoxAuditUnknownBarcodeNonComplianceField.FRONT_PHOTO,
        },
        {
          iconText: commonUploadMessages.backOfSku,
          iconId: "back-product-photo",
          photoType: "backPicture",
          field: BoxAuditUnknownBarcodeNonComplianceField.BACK_PHOTO,
        },
      ]}
      submitUpload={submitUnknownBarcode}
    />
  );
};
