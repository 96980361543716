import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { BulkTransferIcon } from "./BulkTransferIcon";
import { BulkTransferStepper } from "./BulkTransferStepper";
import styled from "@emotion/styled";
import { destinationChuteValue } from "../bulkState";
import { useRecoilValue } from "recoil";
import { BULK_TRANSFER_LABELS as labels } from "./BulkTransferCard.labels";
import { useBulkTransfer } from "./useBulkTransfer";

const TallFlowCard = styled(FlowCard)`
  height: 280px !important;
`;

/**
 * User specifies how many units to transfer in bulk
 */
export const BulkTransferCard: React.FC = () => {
  useBulkTransfer();
  const destinationChute = useRecoilValue(destinationChuteValue);
  const { formatMessage } = useIntl();

  return (
    <TallFlowCard
      topChildren={<BulkTransferIcon chuteLetter={destinationChute.letter} />}
      title={formatMessage(labels.bulkTransferTitle, { chuteLetter: destinationChute.letter })}
      message={formatMessage(labels.bulkTransferMessage, { chuteLetter: destinationChute.letter })}
    >
      <BulkTransferStepper />
    </TallFlowCard>
  );
};
