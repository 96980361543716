import { useMount } from "react-use";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

export function useBulkTransfer() {
  const { showFlowButtons } = useScanFlow();

  useMount(() => {
    showFlowButtons(
      ScanFlowButtonType.BOX_BREAK_BULK_TRANSFER_CONFIRM,
      ScanFlowButtonType.BOX_BREAK_BULK_TRANSFER_BACK_TO_SCAN_ANY
    );
  });
}
