import { useRecoilValue } from "recoil";
import { currentBoxBarcodeState } from "../BoxBreak/boxBreakState";
import { boxBreakStationIdState } from "./stationState";
import { crossdockClient } from "crossdock/base/Clients";

export function useGetBBStationReady() {
  const boxBreakStationId = useRecoilValue(boxBreakStationIdState);
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  return async (boxBarcode?: string) => {
    return await crossdockClient.checkStationReadyToBoxBreak(boxBreakStationId, boxBarcode ?? currentBoxBarcode);
  };
}
