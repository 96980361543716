import React from "react";
import { ScanPath } from "crossdock/base/routes";
import { PathSelectPage } from "crossdock/common/components";
import { FormattedMessage } from "react-intl";
import { BoxLabelIncidentReason } from "@deliverr/commons-clients";
import { InboundShipMethod } from "@deliverr/ui-facility/lib-facility/types";

export const Problem: React.FC = () => (
  <PathSelectPage
    title={<FormattedMessage id="crossdock.problem.title" defaultMessage="What is the problem?" />}
    paths={[
      {
        text: <FormattedMessage id="crossdock.problem.missingLabelFreight" defaultMessage="Missing Label (Freight)" />,
        path: ScanPath.MissingLabel,
        query: {
          reason: BoxLabelIncidentReason.MISSING_LABEL,
          method: InboundShipMethod.FREIGHT,
        },
      },
      {
        text: <FormattedMessage id="crossdock.problem.missingLabelSpd" defaultMessage="Missing Label (Small Parcel)" />,
        path: ScanPath.MissingLabel,
        query: {
          reason: BoxLabelIncidentReason.MISSING_LABEL,
          method: InboundShipMethod.SMALL_PARCEL,
        },
      },
      {
        text: <FormattedMessage id="crossdock.problem.duplicateLabel" defaultMessage="Duplicate Label" />,
        path: ScanPath.DuplicateLabel,
        query: {
          reason: BoxLabelIncidentReason.DUPLICATE_LABEL,
          method: InboundShipMethod.FREIGHT,
        },
      },
      {
        text: <FormattedMessage id="crossdock.problem.damagedUnits" defaultMessage="Damaged Units" />,
        path: ScanPath.DamagedUnits,
      },
    ]}
  />
);
