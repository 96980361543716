import { defineMessages } from "react-intl";
import CrossdockLabels from "crossdock/common/CrossdockLabels";

export const FreightArrivalScanPalletLabels = defineMessages({
  title: {
    id: "crossdock.freightArrivalScanPallet.title",
    defaultMessage: "Scan Freight Pallet Label",
  },
  placeholder: CrossdockLabels.freightPalletLabelPlaceholder,
  labelMissing: {
    id: "crossdock.freightArrivalScanPallet.labelMissing",
    defaultMessage: "Freight pallet label missing",
  },
  invalidFreightPalletBarcode: CrossdockLabels.invalidFreightPalletBarcode,
});
