import { useCrossdockModal } from "crossdock/common/modal";
import { useResetMissingLabelState } from "../useResetMissingLabelState";
import { useRecoilValue } from "recoil";
import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { incidentState } from "../MissingLabelState";
import { useSolveBoxLabelIncident } from "../useSolveBoxLabelIncident";

export function usePrintingCaseLabel() {
  const { returnToProblem } = useResetMissingLabelState();
  const { hideAllModals } = useCrossdockModal();
  const incident = useRecoilValue(incidentState);
  const { printIncidentLabels } = useSolveBoxLabelIncident();

  const onCompleteClick = () => {
    hideAllModals();
    returnToProblem();
  };

  const onReprintClick = async () => {
    log(
      { fn: "crossdock.usePrintingCaseLabel.onReprintClick", incidentId: incident?.id },
      "reprinting box label incident cross dock label"
    );
    hideAllModals();
    await printIncidentLabels(incident, true);
  };

  return {
    onCompleteClick,
    onReprintClick,
  };
}
