import React from "react";
import { FormattedMessage } from "react-intl";

import { useCrossdockModal } from "crossdock/common/modal";
import { FixedWidthButton, ModalTemplate, dangerModalIcon } from "@deliverr/ui-facility";
import { AuditError } from "./BoxAuditTypes";

export interface BoxAuditErrorModalProps {
  reason?: AuditError;
  title?: string;
}

export const BoxAuditErrorModal: React.FC<BoxAuditErrorModalProps> = ({ reason, title }) => {
  const { hideAllModals } = useCrossdockModal();
  const errorTitle = title ? (
    <FormattedMessage id="crossdock.auditErrorModal.errorTitle" defaultMessage={"{title}"} values={{ title }} />
  ) : (
    <FormattedMessage
      id="crossdock.auditErrorModal.genericErrorTitle"
      defaultMessage="Error {reason} audit."
      values={{ reason }}
    />
  );

  return (
    <ModalTemplate
      title={errorTitle}
      text={
        <FormattedMessage
          id="crossdock.auditErrorModal.genericErrorMessage"
          defaultMessage="Please try again. Contact Deliverr operations if this error persists."
        />
      }
      iconProps={dangerModalIcon}
    >
      <FixedWidthButton width="134px" onClick={hideAllModals}>
        <FormattedMessage id="crossdock.auditErrorModal.understandBtn" defaultMessage="I understand" />
      </FixedWidthButton>
    </ModalTemplate>
  );
};
