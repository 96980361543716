import { onScannerInputChange, log } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilValue } from "recoil";
import { inboundPalletLabelIdState, lastPoState } from "../../FreightArrivalState";
import labels from "./FreightArrivalEnterPoNum.labels";
import { useIntl } from "react-intl";
import { useMount } from "react-use";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createScanPalletCard, createMovePalletNowCard } from "../../freightArrivalCardCreators";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useSubmitPoNum } from "../../useSubmitPoNum";

export const useFreightArrivalEnterPoNum = () => {
  const { poNum, setPoNum, submitPoNum, onSubmit, loading, errorMessage } = useSubmitPoNum();
  const { formatMessage } = useIntl();
  const { addFlowCard, showFlowButton, hideAllFlowButtons } = useScanFlow();

  const lastPoNum = useRecoilValue(lastPoState);
  const inboundPalletLabelId = useRecoilValue(inboundPalletLabelIdState);

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_ENTER_PO_DONE_BUTTON);
    if (lastPoNum) {
      showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_USE_LAST_PO_BUTTON);
    }
    showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_BACK_TO_SCAN_FPL);
  });

  const onPoNumChange = onScannerInputChange(poNum, setPoNum, submitPoNum, "upper");

  const returnToScanPalletCard = () => {
    addFlowCard(createScanPalletCard({}));
  };

  const onNoPoButtonClick = async (e) => {
    log({ fn: "useFreightArrivalEnterPoNum.onNoPoButtonClick", inboundPalletLabelId }, "no po#");
    hideAllFlowButtons();
    addFlowCard(createMovePalletNowCard({}));
  };

  return {
    poNum,
    onPoNumChange,
    onSubmit,
    onClick: onNoPoButtonClick,
    loading,
    disabled: loading,
    title: formatMessage(labels.title),
    placeholder: formatMessage(labels.placeholder),
    errorMessage,
    returnToScanPalletCard,
  };
};
