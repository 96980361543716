import { defineMessages } from "react-intl";

export const DESTINATION_MISMATCH_LABELS = defineMessages({
  title: {
    id: "crossdock.shipPalletDestinationMismatchModal.title",
    defaultMessage: "Destination mismatch!",
  },
  transferMsg: {
    id: "crossdock.shipPalletDestinationMismatchModal.message.transfer",
    defaultMessage:
      "You cannot link this pallet to the BOL because it is going to {currentPalletToWarehouseId}, but other pallets on the BOL are going to {expectedDestinationWarehouseId}.",
  },
  milkRunMsg: {
    id: "crossdock.shipPalletDestinationMismatchModal.message.milkRun",
    defaultMessage:
      "You cannot link this pallet because it is going to {currentPalletToWarehouseId}, but other pallets are going to {expectedDestinationWarehouseId}.",
  },
  button: {
    id: "done",
    defaultMessage: "Done",
  },
});
