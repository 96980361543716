import { useResetRecoilState } from "recoil";
import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { ScanPath } from "crossdock/base/routes";
import { missingLabelCountState, incidentState, scannedBarcodeOrderState } from "./MissingLabelState";
import { headerSubtitleState, headerTitleState, showPageLeftActionState } from "crossdock/base/headerTitleState";
import { showAddButtonState } from "../ProblemState";
import { useCrossdockModal } from "crossdock/common/modal";
import { InboundShipMethod } from "@deliverr/ui-facility/lib-facility/types";
import { createScanTrackingCard, createScanPoCard } from "./MissingLabelCardCreators";
import { getShippingMethod } from "./utils/getShippingMethod";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export function useResetMissingLabelState() {
  const router = useRouter();
  const resetMissingLabelCount = useResetRecoilState(missingLabelCountState);
  const resetHeaderTitle = useResetRecoilState(headerTitleState);
  const resetIncident = useResetRecoilState(incidentState);
  const resetHeaderSubtitle = useResetRecoilState(headerSubtitleState);
  const resetShowAddButton = useResetRecoilState(showAddButtonState);
  const resetScannedBarcodeOrder = useResetRecoilState(scannedBarcodeOrderState);
  const resetShowPageLeftAction = useResetRecoilState(showPageLeftActionState);
  const { hideAllModals } = useCrossdockModal();
  const { resetFlowCards, addFlowCard } = useScanFlow();

  const resetMissingLabelState = () => {
    resetMissingLabelCount();
    resetIncident();
    resetHeaderTitle();
    resetHeaderSubtitle();
    resetShowAddButton();
    resetScannedBarcodeOrder();
    resetShowPageLeftAction();
  };

  const returnToProblem = () => {
    log({ fn: "returnToProblem" }, "returning to problem page");
    resetMissingLabelState();
    hideAllModals();
    router.push(ScanPath.Problem);
  };

  /**
   * Reset the Missing Box flow and state to its starting state.
   *
   * @param resetLocalFlow optional: reset any relevant state in the local flow.
   */
  const resetMissingLabelFlow = () => {
    resetMissingLabelState();
    resetFlowCards();

    const card =
      getShippingMethod() === InboundShipMethod.SMALL_PARCEL ? createScanTrackingCard({}) : createScanPoCard({});
    addFlowCard(card);
  };

  return {
    resetMissingLabelState,
    returnToProblem,
    resetMissingLabelFlow,
  };
}
