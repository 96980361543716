import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

export function useFilter() {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const getFilter = useCallback(() => {
    const params = new URLSearchParams(search);
    let createdFromStr = params.get("created-from")?.trim();
    const previousDays = parseInt(params.get("previous-days")?.trim() ?? "");
    const createdToStr = params.get("created-to")?.trim();
    const updatedFromStr = params.get("updated-from")?.trim();
    const updatedToStr = params.get("updated-to")?.trim();
    const stagesStr = params.get("stages")?.trim();
    const regionsStr = params.get("regions")?.trim();
    const hoursFromStr = params.get("hours-from")?.trim();
    const hoursToStr = params.get("hours-to")?.trim();
    const autoRefreshAfterHours = parseInt(params.get("auto-refresh-after-hours")?.trim() ?? "");
    let redirectFn: (() => void) | undefined;
    let autoRefreshFn: () => void = () => {};

    if (params.has("auto-refresh-after-hours")) {
      if (isNaN(autoRefreshAfterHours)) {
        params.set("auto-refresh-after-hours", "12");
        redirectFn = () => {
          history.replace({ pathname, search: params.toString() });
        };
      } else {
        autoRefreshFn = () => {
          setTimeout(
            () => {
              history.go(0);
            },
            autoRefreshAfterHours * 60 * 60 * 1000 // Converting hours to milliseconds
          );
        };
      }
    }

    // Remove previous-days query-param and redirect to the page preserving other query-params.
    if (Boolean(createdFromStr) || Boolean(createdToStr)) {
      if (previousDays) {
        params.delete("previous-days");
        redirectFn = () => {
          history.replace({ pathname, search: params.toString() });
        };
      }
    } else if (isNaN(previousDays)) {
      params.set("previous-days", "30");
      redirectFn = () => {
        history.replace({ pathname, search: params.toString() });
      };
    }

    if (previousDays) {
      const from = new Date();
      from.setDate(from.getDate() - previousDays);

      createdFromStr = from.toISOString();
    }

    return {
      autoRefreshFn,
      redirectFn,
      createdFromStr,
      createdToStr,
      updatedFromStr,
      updatedToStr,
      stagesStr,
      regionsStr,
      hoursFromStr,
      hoursToStr,
    };
  }, [history, pathname, search]);

  return {
    getFilter,
  };
}
