import React from "react";
import { LinkedCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface ShipPalletScanTrackingInUseCardProps {
  palletId: string;
  trackingCode: string;
}

export const ShipPalletScanTrackingInUseCard: React.FC<ShipPalletScanTrackingInUseCardProps> = ({
  palletId,
  trackingCode,
}) => (
  <LinkedCard
    icon="exclamation-circle"
    accentColor="RED"
    title="Tracking number used by another pallet"
    topValue={palletId}
    bottomValue={trackingCode}
  />
);
