import React from "react";
import { MissingLabelBackButton } from "./MissingLabelBackButton";
import { useRecoilValue } from "recoil";
import { showBackButtonState } from "../../ProblemState";
import { MissingLabelModal } from "../MissingLabelModal";
import { ModalExitButton } from "crossdock/common/components";

export const MissingLabelLeftHeaderIcon: React.FC = () => {
  const showBackButton = useRecoilValue(showBackButtonState);
  return showBackButton ? (
    <MissingLabelBackButton />
  ) : (
    <ModalExitButton modalId={MissingLabelModal.MISSING_LABEL_QUIT} />
  );
};
