import React from "react";
import { useMissingLabelScanBoxInfo } from "../useMissingLabelScanBoxInfo";
import { FormattedMessage, useIntl } from "react-intl";
import { MissingLabelBoxInfoUnknownButton } from "../MissingLabelBoxInfoUnknownButton";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";
import { MISSING_LABEL_SCAN_PO_CARD_LABELS as labels } from "./MissingLabelScanPoCard.labels";

export const MissingLabelScanBoxPoCard: React.FC = () => {
  const { onBoxInfoChange, submitBoxInfo, boxInfo, loading, errorMessage } = useMissingLabelScanBoxInfo();
  const { formatMessage } = useIntl();

  return (
    <ContentTextInputCard
      onSubmit={submitBoxInfo}
      title={<FormattedMessage id="crossdock.missingLabelScanPoCard.title" defaultMessage="Input PO #" />}
      onChange={onBoxInfoChange}
      value={boxInfo}
      disabled={loading}
      errorMessage={errorMessage}
      placeholder={formatMessage(labels.poNumber)}
    >
      <MissingLabelBoxInfoUnknownButton />
    </ContentTextInputCard>
  );
};
