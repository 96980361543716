// Don't forget to start path with forward slash!

export enum ScanPathName {
  MISSING_LABEL_FREIGHT = "MISSING_LABEL_FREIGHT",
  MISSING_LABEL_SPD = "MISSING_LABEL_SPD",
}

export enum ScanPath {
  // modes
  Scanner = "/",
  /* login redirects to /scan, so we add a duplicate entry for that path */
  Scanner2 = "/scan",
  CaseBreaker = "/break",
  ToteManager = "/tote",
  Transfers = "/transfers",
  Problem = "/problem",

  // pages
  TransfersCreatePallet = "/transfers/create",
  TransfersAddCase = "/transfers/add",
  TransfersClosePallet = "/transfers/close",
  TransfersShipPallet = "/transfers/ship",
  TransfersReopenPallet = "/transfers/reopen",

  // freight
  FreightArrival = "/transfers/arrival/freight",
  SmallParcelArrival = "/transfers/arrival/spd",
  PrintInboundPalletLabel = "/transfers/print-labels",
  MovePallet = "/freight/move-pallet",
  StartProcessingPallet = "/freight/start-processing-pallet",

  MissingLabel = "/problem/missing-label",
  DuplicateLabel = "/problem/duplicate-label",
  BoxAudit = "/problem/box-audit",
  DamagedUnits = "/problem/damaged-units",

  // box break
  BoxBreak = "/box-break/break",
  BoxBreakCreateContainers = "/box-break/create-containers",

  // dashboard
  Pallets = "/pallets",
  PalletSummary = "/pallets/summary",

  // menus
  SignIn = "/sign-in",
  ManagePrinters = "/manage-printers",

  // errors
  InvalidWarehouseId = "/invalid-warehouse-id",
  MissingWarehouseId = "/missing-warehouse-id",

  // dims-and-weight
  FcDimsAndWeight = "/dims-and-weight/fc",
  CrossdockDimsAndWeight = "/dims-and-weight/crossdock",
}
