import React from "react";
import { SkuCounterContents } from "@deliverr/ui-facility";
import { BoxAuditEmptyContents } from "crossdock/common/components/BoxAuditContents";
import { MAX_UNITS_PER_PACKAGE } from "crossdock/common/maxUnitsPerPackage";
import { useBoxContents } from "./useBoxContents";

export const MissingLabelBoxContentsCard: React.FC = () => {
  const { boxContents, onBoxContentsQtyChange } = useBoxContents();

  return (
    <SkuCounterContents
      emptyState={<BoxAuditEmptyContents />}
      items={boxContents}
      maxUnitsPerPackage={MAX_UNITS_PER_PACKAGE}
      onChange={onBoxContentsQtyChange}
    />
  );
};
