import React from "react";
import { useIntl } from "react-intl";
import { useBoxBreakUnexpectedSkuNoCapableWarehouse } from "./useBoxBreakUnexpectedSkuNoCapableWarehouse";
import { UnderstandButton, ModalTemplate, dangerModalIcon } from "@deliverr/ui-facility";
import { UNEXPECTED_SKU_NO_CAPABLE_WAREHOUSES_LABELS as labels } from "./BoxBreakUnexpectedSkuNoCapableWarehousesModal.labels";

/**
 * Unexpected SKU has no locations that are capable of accepting it.
 */
export const BoxBreakUnexpectedSkuNoCapableWarehousesModal: React.FC = () => {
  const { formatMessage } = useIntl();
  const onUnderstandClick = useBoxBreakUnexpectedSkuNoCapableWarehouse();

  return (
    <ModalTemplate title={formatMessage(labels.title)} text={formatMessage(labels.message)} iconProps={dangerModalIcon}>
      <UnderstandButton onClick={onUnderstandClick} />
    </ModalTemplate>
  );
};
