import { useLifecycles } from "react-use";
import React from "react";
import { createScannerScanCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlow } from "crossdock/common/flow/ScanFlow";

export const CaseScanner: React.FC = () => {
  const { addFlowCard, resetFlow } = useScanFlow();

  useLifecycles(
    () => {
      addFlowCard(createScannerScanCard({}));
    },
    () => {
      resetFlow();
    }
  );

  return <ScanFlow />;
};
