import { UnknownInputCard } from "@deliverr/ui-facility/lib-facility/flow";
import { PALLET_IDENTIFIER_PLACEHOLDER } from "@deliverr/ui-facility/lib-facility/utils";
import * as React from "react";

export interface InvalidPalletInputCardProps {
  value: string;
}

export const InvalidPalletInputCard: React.FC<InvalidPalletInputCardProps> = ({ value }) => {
  return <UnknownInputCard value={value} message={`Does not appear to be a valid ${PALLET_IDENTIFIER_PLACEHOLDER}`} />;
};
