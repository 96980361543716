import React from "react";
import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { createDamagedProductCard } from "./DamagedProductCardCreators";
import { useCrossdockDamagedProduct } from "./useCrossdockDamagedProduct";

export const CrossdockDamagedProduct: React.FC = () => {
  const { onUnmount } = useCrossdockDamagedProduct();

  return <ScanFlowV2 onMountCardData={createDamagedProductCard({})} onUnmount={onUnmount} />;
};
