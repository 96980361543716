import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useCreateContainersAmount } from "./useCreateContainersAmount";
import { Button } from "@deliverr/ui";
import { CREATE_CONTAINERS_LABELS as labels } from "./CreateContainersAmountCard.labels";
import { useIntl } from "react-intl";

export const CreateContainersAmountCard: React.FC = () => {
  const { submitCreateContainers, onContainerAmountChange, containersAmount, errorMessage } =
    useCreateContainersAmount();
  const { formatMessage } = useIntl();

  return (
    <TextInputCard
      title={formatMessage(labels.title)}
      icon="print"
      accentColor="BLUE"
      value={containersAmount}
      onChange={onContainerAmountChange}
      errorMessage={errorMessage}
      onSubmit={submitCreateContainers}
      newest={true}
      type="number"
    >
      <Button
        type="button"
        onClick={async () => {
          await submitCreateContainers(containersAmount);
        }}
        disabled={!Number.isInteger(parseInt(containersAmount, 10))}
        block
      >
        {formatMessage(labels.printLabelsButton)}
      </Button>
    </TextInputCard>
  );
};
