import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";
import freightArrivalLabels from "../../FreightArrival.labels";

export default defineMessages({
  title: {
    id: "crossdock.freightArrivalScanLane.title",
    defaultMessage: "Scan Lane",
  },
  placeholder: crossdockLabels.stagingLanePlaceholder,
  invalidStagingLaneBarcode: crossdockLabels.invalidStagingLaneBarcode,
  unexpectedStagingLaneError: freightArrivalLabels.unexpectedStagingLaneError,
});
