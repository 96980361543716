import React from "react";
import { ProductDetailsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ProductDetailsCard/ProductDetailsCard";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { DimsAndWeightImageUploadField } from "crossdock/modes/inspection/constants";
import { useCrossdockDimsAndWeightCaptureCubiscanImages } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/CaptureCubiscanImagesCard/useCrossdockDimsAndWeightCaptureCubiscanImages";
import { CrossdockDimsAndWeightImageUploadCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/CaptureCubiscanImagesCard/upload/CrossdockAndWeightImageUploadCard";

export const CrossdockDimsAndWeightCaptureCubiscanImagesCard: React.FC = () => {
  const { productInfo } = useCrossdockDimsAndWeightCaptureCubiscanImages();
  return (
    <>
      <ProductDetailsCard product={productInfo} />
      <CrossdockDimsAndWeightImageUploadCard
        title={DIMS_AND_WEIGHT_LABELS.dimsAndWeightImageUploadCardTitle}
        uploads={[
          {
            iconText: DIMS_AND_WEIGHT_LABELS.dimsAndWeightCubiscanScreenImageTitle,
            iconId: "cubiscan-screen-photo",
            photoType: "cubiscanScreen",
            field: DimsAndWeightImageUploadField.SCREEN_PICTURE,
          },
          {
            iconText: DIMS_AND_WEIGHT_LABELS.dimsAndWeightCubiscanProductImageTitle,
            iconId: "cubiscan-product-photo",
            photoType: "cubiscanProduct",
            field: DimsAndWeightImageUploadField.PRODUCT_PICTURE,
          },
          {
            iconText: DIMS_AND_WEIGHT_LABELS.dimsAndWeightCubiscanAllImageTitle,
            iconId: "cubiscan-all-photo",
            photoType: "cubiscanAll",
            field: DimsAndWeightImageUploadField.ALL_PICTURE,
          },
        ]}
      />
    </>
  );
};
