import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { useFcDimsAndWeightComplete } from "crossdock/modes/inspection/fc-dims-and-weight/buttons/completeButton/useFcDimsAndWeightComplete";
import { useRecoilValue } from "recoil";
import { fcDimsAndWeightCubiscanImagesState } from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";

export const FcDimsAndWeightCompleteButton: React.FC = () => {
  const { onButtonClick } = useFcDimsAndWeightComplete();
  const nonComplianceImagesMetadata = useRecoilValue(fcDimsAndWeightCubiscanImagesState);

  const { cubiscanScreen, cubiscanProduct, cubiscanAll } = nonComplianceImagesMetadata;
  const disableButton = !cubiscanScreen || !cubiscanProduct || !cubiscanAll;

  return (
    <Button onClick={onButtonClick} block disabled={disableButton}>
      <FormattedMessage id="crossdock.fcDimsAndWeightCompleteButton.title" defaultMessage="Complete" />
    </Button>
  );
};
