import { TransferPallet } from "@deliverr/commons-clients";
import { useSetRecoilState } from "recoil";
import { createClosePalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "crossdock/modes/transfers/modals/TransfersModal";
import { getPalletItemCountLabel } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";
import { palletLabelIdState } from "../ClosePalletState";
import { useClosePallet } from "../useClosePallet";
import { useIntl } from "react-intl";
import { CLOSE_PALLET_PROMPT_LABELS } from "./ClosePalletPromptModal.labels";

export function useClosePalletPromptModal(pallet: TransferPallet) {
  const palletLabelId = pallet.palletLabelId;
  const { hideModal } = useCrossdockModal();
  const { resetFlow, hideAllFlowButtons, addFlowCard } = useScanFlow();
  const setPalletLabelId = useSetRecoilState(palletLabelIdState);
  const handleClosePallet = useClosePallet(palletLabelIdState);
  const { formatMessage } = useIntl();

  const labels = {
    title: formatMessage(CLOSE_PALLET_PROMPT_LABELS.title, { palletLabelId }),
    cancel: formatMessage(CLOSE_PALLET_PROMPT_LABELS.cancel),
    confirm: formatMessage(CLOSE_PALLET_PROMPT_LABELS.confirm),
  };

  function handleBaseClick() {
    hideAllFlowButtons();
    hideModal(TransfersModal.TRANSFERS_CONFIRM_CLOSE_PALLET);
  }

  function onCancelClick() {
    resetFlow();
    setPalletLabelId("");
    addFlowCard(createClosePalletScanPalletCard({}));
    handleBaseClick();
  }

  async function onConfirmClick() {
    await handleClosePallet(palletLabelId);
    handleBaseClick();
  }

  return {
    message: getPalletItemCountLabel(pallet),
    onConfirmClick,
    onCancelClick,
    labels,
  };
}
