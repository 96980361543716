import React from "react";
import { FormattedMessage } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow";

export interface SortPalletToMilkRunCardProps {
  palletId: string;
  warehouseId?: string;
  scannedPalletCount?: number;
  totalPalletCount?: number;
}

export const SortPalletToMilkRunCard: React.FC<SortPalletToMilkRunCardProps> = ({
  palletId,
  warehouseId,
  scannedPalletCount = 0,
  totalPalletCount = 0,
}) => (
  <TextInputCard
    title={
      warehouseId ? (
        <FormattedMessage
          id="crossdock.sortPalletToMilkRunCard.title"
          values={{ warehouseId }}
          defaultMessage={`Sort for {warehouseId}`}
        />
      ) : (
        <FormattedMessage
          id="crossdock.sortPalletToMilkRunCard.titleWithoutWarehouseId"
          defaultMessage={"Sort for warehouse"}
        />
      )
    }
    value={palletId}
    accentColor="BLUE"
    icon="arrow-right"
    newest={false}
    disabled
    pillProps={
      scannedPalletCount > 0 && totalPalletCount > 0
        ? {
            color: "GRAY",
            children: (
              <FormattedMessage
                id="crossdock.sortPalletToMilkRunCard.scannedCountPill"
                values={{ scannedPalletCount, totalPalletCount }}
                defaultMessage={`{scannedPalletCount} of {totalPalletCount} scanned`}
              />
            ),
          }
        : undefined
    }
  />
);
