import { atom, selector } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { FlowCardsState, FlowButtonsState } from "@deliverr/ui-facility/lib-facility/flow/types/FlowState";
import {
  getSameActiveFlowCardForState,
  getFlowVisibleButtonsForState,
} from "@deliverr/ui-facility/lib-facility/flow/flowState";
import { ScanFlowCardType } from "./ScanFlowCardType";
import { FlowCardType, FlowButtonType } from "@deliverr/ui-facility/lib-facility/flow";
import { ScanFlowButtonType } from "./ScanFlowButtonType";

export type ScanCardType = ScanFlowCardType | FlowCardType;
export type ScanButtonType = ScanFlowButtonType | FlowButtonType;

export const scanFlowCardsState = atom<FlowCardsState>({
  key: CrossdockStateKey.FLOW_CARDS,
  default: [],
});

export const getSameActiveFlowCard = getSameActiveFlowCardForState<ScanCardType>(scanFlowCardsState);

export const scanFlowButtonsState = atom<FlowButtonsState<ScanButtonType>>({
  key: CrossdockStateKey.FLOW_BUTTONS,
  default: {} as FlowButtonsState<ScanButtonType>,
});

export const getFlowVisibleButtons = getFlowVisibleButtonsForState<ScanButtonType>(scanFlowButtonsState);
export const flowVisibleButtonsValue = selector<ScanButtonType[]>({
  key: CrossdockStateKey.FLOW_BUTTONS_VISIBLE,
  get: getFlowVisibleButtons,
});
