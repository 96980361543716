import { createFlowCard } from "@deliverr/ui-facility/lib-facility/flow/createFlowCard";
import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";
import { LinkContainerScanChuteCardProps } from "./LinkContainer/LinkContainerScanChuteCard";
import { LinkContainerSuccessCardProps } from "./LinkContainer/LinkContainerSuccessCard";
import { BoxBreakReturnUnitCardProps } from "./BoxBreak/cards/BoxBreakReturnUnitCard";
import { BoxBreakUnitInChuteCardProps } from "./BoxBreak/cards/BoxBreakUnitInChuteCard";
import { BoxBreakVerifyChuteCardProps } from "./BoxBreak/cards/BoxBreakVerifyChuteCard";
import { RemoveContainerCardProps } from "./RemoveContainer/RemoveContainerCard";

export const createCreateContainersAmountCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_CREATE_CONTAINERS_AMOUNT
);
export const createBoxBreakLoginCard = createFlowCard<Record<string, any>>(ScanFlowCardType.BOX_BREAK_LOGIN);
export const createLinkContainerScanChuteCard = createFlowCard<LinkContainerScanChuteCardProps>(
  ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SCAN_CHUTE
);
export const createLinkContainerScanContainerCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SCAN_CONTAINER
);
export const createLinkContainerSuccessCard = createFlowCard<LinkContainerSuccessCardProps>(
  ScanFlowCardType.BOX_BREAK_LINK_CONTAINER_SUCCESS
);
export const createBoxBreakScanBoxCard = createFlowCard<Record<string, any>>(ScanFlowCardType.BOX_BREAK_SCAN_BOX);
export const createBoxBreakScanBoxSuccessCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_SCAN_BOX_SUCCESS
);
export const createBoxBreakScanAnyUnitCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_SCAN_ANY_UNIT
);
export const createBoxBreakCountUnexpectedSkuUnitsCard = createFlowCard<{ unitBarcode: string }>(
  ScanFlowCardType.BOX_BREAK_COUNT_UNEXPECTED_SKU_UNITS
);
export const createBoxBreakReturnUnitCard = createFlowCard<BoxBreakReturnUnitCardProps>(
  ScanFlowCardType.BOX_BREAK_RETURN_UNIT
);
export const createBoxBreakUnitInChuteCard = createFlowCard<BoxBreakUnitInChuteCardProps>(
  ScanFlowCardType.BOX_BREAK_UNIT_IN_CHUTE
);
export const createBoxBreakVerifyChuteCard = createFlowCard<BoxBreakVerifyChuteCardProps>(
  ScanFlowCardType.BOX_BREAK_VERIFY_CHUTE
);
export const createRemoveContainerCard = createFlowCard<RemoveContainerCardProps>(
  ScanFlowCardType.BOX_BREAK_REMOVE_CONTAINER
);
export const createRemoveContainerSuccessCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_REMOVE_CONTAINER_SUCCESS
);
export const createBoxBreakEndEarlyCard = createFlowCard<Record<string, any>>(
  ScanFlowCardType.BOX_BREAK_END_EARLY_CARD
);
export const createBulkTransferCard = createFlowCard<Record<string, any>>(ScanFlowCardType.BOX_BREAK_BULK_TRANSFER);
