import * as React from "react";
import { FormattedMessage, FormattedPlural } from "react-intl";
import { Text } from "@deliverr/ui";

interface BoxBreakChuteContainerInfoProps {
  chuteCdsku: string;
  chuteUnitsTotal: number;
  chuteSKUsTotal: number;
  overview?: boolean;
}

export const BoxBreakChuteContainerInfo: React.FC<BoxBreakChuteContainerInfoProps> = ({
  chuteCdsku,
  chuteSKUsTotal = 0,
  chuteUnitsTotal = 0,
  overview = false,
}) => {
  return (
    <>
      <Text bold appearance={overview ? "INFO" : undefined}>
        {chuteCdsku}
      </Text>
      <Text>
        <FormattedPlural
          one={
            <FormattedMessage
              id="crossdock.boxBreaksChutesOverview.unitsSingle"
              defaultMessage="{chuteUnitsTotal} unit"
              values={{ chuteUnitsTotal }}
            />
          }
          other={
            <FormattedMessage
              id="crossdock.boxBreaksChutesOverview.unitsPlural"
              defaultMessage="{chuteUnitsTotal} units"
              values={{ chuteUnitsTotal }}
            />
          }
          value={chuteUnitsTotal}
        />
        ,{" "}
        <FormattedPlural
          one={
            <FormattedMessage
              id="crossdock.boxBreaksChutesOverview.skusSingle"
              defaultMessage="{chuteSKUsTotal} SKU"
              values={{ chuteSKUsTotal }}
            />
          }
          other={
            <FormattedMessage
              id="crossdock.boxBreaksChutesOverview.skusPlural"
              defaultMessage="{chuteSKUsTotal} SKUs"
              values={{ chuteSKUsTotal }}
            />
          }
          value={chuteSKUsTotal}
        />
      </Text>
    </>
  );
};
