import React from "react";
import styled from "@emotion/styled";
import { createMovePalletNowCard } from "../freightArrivalCardCreators";
import { FLOW_CARD_CONTAINER_SECTION_WIDTH } from "@deliverr/ui-facility/lib-facility/flow/styleValues";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { CancelButton } from "@deliverr/ui-facility";
import { useResetRecoilState } from "recoil";
import { willMovePalletState } from "../FreightArrivalState";

const StyledButton = styled(CancelButton)`
  width: ${FLOW_CARD_CONTAINER_SECTION_WIDTH};
`;

export const FreightArrivalBackToMovePalletNowButton: React.FC = () => {
  const { addFlowCard, hideAllFlowButtons } = useScanFlow();
  const resetWillMovePallet = useResetRecoilState(willMovePalletState);

  const onClick = () => {
    resetWillMovePallet();
    hideAllFlowButtons();
    addFlowCard(createMovePalletNowCard({}));
  };

  return <StyledButton onClick={onClick} secondary />;
};
