import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useBoxAuditSubtitle } from "./useBoxAuditSubtitle";
import { useShowAddSku } from "../hooks";
import { showPageLeftActionState } from "crossdock/base/headerTitleState";
import { isValidCdsku, log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { createBoxContentsCard, createOpenCaseCard } from "./BoxAuditCardCreators";
import { FlowTransitionDuration } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { BoxAuditModal } from "./BoxAuditModal";
import { useCrossdockModal } from "crossdock/common/modal";
import { scannedBoxAuditState } from "./BoxAuditState";
import { AuditError } from "./BoxAuditTypes";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { Audit } from "@deliverr/crossdock-service-client";
import { crossdockClient } from "crossdock/base/Clients";

export function useCreateAudit() {
  const [errorMessage, setErrorMessage] = useState("");
  const { warehouseId: crossDockWarehouseId } = useRecoilValue(userState);
  const { successResponse, errorResponse, handleUnknownError, transition, hideAllFlowButtons, emitFlash } =
    useScanFlow();
  const updateBoxAudit = useSetRecoilState(scannedBoxAuditState);
  const { setBoxAuditSubtitle } = useBoxAuditSubtitle();
  const { showAddSku } = useShowAddSku();
  const { showModal } = useCrossdockModal();

  const setShowPageLeftAction = useSetRecoilState(showPageLeftActionState);

  const onCreateAuditSubmit = async (boxInfo: string) => {
    const ctx = logStart({ fn: "onCreateAuditSubmit", boxInfo });

    if (!isValidCdsku(boxInfo)) {
      log(ctx, "invalid cdsku");
      emitFlash("DANGER");
      errorResponse();
      setErrorMessage("Invalid box ID");
      return;
    }

    try {
      const scanResponse: Audit = await crossdockClient.createAudit({
        crossdockId: crossDockWarehouseId,
        cdsku: boxInfo,
      });
      updateBoxAudit(scanResponse);
      setBoxAuditSubtitle(boxInfo!);
      successResponse();
      hideAllFlowButtons();

      transition([
        {
          duration: FlowTransitionDuration.SHORT,
          card: createOpenCaseCard({}),
        },
        {
          card: createBoxContentsCard({}),
          onTransition: () => {
            showAddSku();
            setShowPageLeftAction(true);
          },
        },
      ]);
    } catch (err) {
      log(ctx, "error creating audit");
      showModal(BoxAuditModal.BOX_AUDIT_ERROR, { reason: AuditError.CREATING });
      handleUnknownError(ctx, err);
      errorResponse();
    }
  };

  return { onCreateAuditSubmit, errorMessage };
}
