import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

import { useClosePalletScanPallet } from "./useClosePalletScanPallet";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export interface ClosePalletScanPalletCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const ClosePalletScanPalletCard: React.FC<ClosePalletScanPalletCardProps> = ({ disabled, newest }) => {
  const { palletLabelId, onPalletLabelIdChange, submitPalletLabelId } = useClosePalletScanPallet();
  const { formatMessage } = useIntl();
  const onSubmit = async () => {
    await submitPalletLabelId(palletLabelId);
  };

  return (
    <TextInputCard
      onSubmit={onSubmit}
      newest={newest}
      icon="scan"
      accentColor="BLUE"
      title={formatMessage(WarehouseMessages.palletTitleText)}
      message="After closing the pallet, you cannot add or remove boxes."
      value={palletLabelId}
      onChange={onPalletLabelIdChange}
      disabled={disabled}
      placeholder={formatMessage(WarehouseMessages.palletIdentifierPlaceholder)}
    />
  );
};
