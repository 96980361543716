import React from "react";
import { Button } from "@deliverr/ui";
import { some } from "lodash";
import { FormattedMessage } from "react-intl";

import { useBoxBreakEndSession } from "./useBoxBreakEndSession";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";

interface Props {
  disabled?: boolean;
}

// Brings up a modal which gives the user an option to end or continue the box break,
// with warnings for shortages and overages in expected unit quantities.
// This is used in scenarios where there are no more units to be scanned (box should be empty),
// and has the same effect as "pausing" a box break session.
export const BoxBreakEndSessionButton: React.FC<Props> = ({ disabled }) => {
  const { boxContentsAllUnitsDistributionByDsku } = useBoxBreakState();
  const isShortage = some(boxContentsAllUnitsDistributionByDsku, (item) => item.scannedUnits < item.totalUnits);
  const onClick = useBoxBreakEndSession(isShortage);
  return (
    <Button onClick={onClick} block disabled={disabled}>
      <FormattedMessage id="crossdock.boxBreakEndSessionButton" defaultMessage="End box break" />
    </Button>
  );
};
