import React from "react";
import { LinkedCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface AddCaseHasPalletCardProps {
  cdsku: string;
  palletLabelId: string;
}

export const AddCaseHasPalletCard: React.FC<AddCaseHasPalletCardProps> = ({ cdsku, palletLabelId }) => (
  <LinkedCard
    icon="eye"
    accentColor="YELLOW"
    message="You can move this box by scanning a different pallet."
    title="Box is already on a pallet"
    topValue={palletLabelId}
    bottomValue={cdsku}
  />
);
