import { log, logStart, userState } from "@deliverr/ui-facility";
import { REGIONS } from "./palletsSummary.labels";
import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { crossdockClient } from "crossdock/base/Clients";
import {
  AggregationFunctionType,
  PalletDetailsAggregation,
  PalletStage,
  Region,
} from "@deliverr/crossdock-service-client";
import { useClientsWithAuth } from "crossdock/base/useClientsWithAuth";
import { Link, useLocation } from "react-router-dom";
import { useFilter } from "../useFilter";
import { isNumber } from "lodash";

export function usePalletsSummaryTable() {
  const [palletsSummaryRows, setPalletsSummaryRows] = useState<ReactElement[][]>([]);
  const [appReady, setAppReady] = useState(false);
  const user = useRecoilValue(userState);
  const { warehouseId } = user;
  const setAuth0AccessTokens = useClientsWithAuth();
  const { getFilter } = useFilter();
  const { search } = useLocation();

  useEffect(() => {
    setAuth0AccessTokens();
  });

  const sortPalletsSummary = useCallback(
    (
      palletsSummary: PalletDetailsAggregation[]
    ): Record<
      number,
      {
        [region in Region]: {
          [stage in PalletStage]: number;
        };
      }
    > => {
      const ctx = logStart({ fn: "sortPalletsSummary", palletsSummary });

      if ((palletsSummary?.length ?? 0) === 0) {
        log(ctx, "There is no pallet summary, returning");
        return {};
      }

      const sortedPalletsSummary = palletsSummary.reduce((sortedData, currentAggregation) => {
        const timeWindowEnd: number = currentAggregation.timeWindow.end ?? Number.MAX_SAFE_INTEGER;
        sortedData[timeWindowEnd] ??= {};

        const region = currentAggregation.region;
        sortedData[timeWindowEnd][region] ??= {};

        const stage = currentAggregation.stage;
        sortedData[timeWindowEnd][region][stage] = currentAggregation.value;

        return sortedData;
      }, {});

      log({ ...ctx, sortedPalletsSummary }, "Sorted pallet summary data");

      return sortedPalletsSummary;
    },
    []
  );

  const buildPalletsSummaryRows = useCallback(
    (
      sortedPalletsSummary: Record<
        number,
        {
          [region in Region]: {
            [stage in PalletStage]: number;
          };
        }
      >
    ): ReactElement[][] => {
      const ctx = logStart({ fn: "buildPalletsSummaryRows", sortedPalletsSummary });
      const highlightPerUnit = 1 / 30; // Anything above 30 will be fully highlighted

      if (Object.keys(sortedPalletsSummary).length === 0) {
        log(ctx, "No sorted pallet summary data, returning");
        return [];
      }

      const regions = Object.keys(REGIONS);
      const stages = [PalletStage.OPENED, PalletStage.CLOSED];
      const timeWindows = [24, 48, Number.MAX_SAFE_INTEGER];
      const timeWindowFilters = [
        {
          to: 24,
        },
        {
          from: 24,
          to: 48,
        },
        {
          from: 48,
        },
      ];

      log(ctx, "Building pallet summary rows");
      const rows = stages.flatMap((stage) => {
        const stageRegionTotalDataRow = Array(regions.length).fill(0);
        const stageTimeRows = timeWindows.map((timeWindow, twIndex) => {
          let stageTimeTotal = 0;
          const stageTimeRow: ReactElement[] = regions.map((region, index) => {
            const count = sortedPalletsSummary[timeWindow]?.[region]?.[stage];
            // Total for the row
            stageTimeTotal += count ?? 0;
            // Total for the column
            stageRegionTotalDataRow[index] += count ?? 0;

            return palletDetailsLink({
              text: count ?? "-",
              search,
              region,
              stage,
              timeWindow: timeWindowFilters[twIndex],
              highlightPerUnit,
            });
          });

          stageTimeRow.push(
            palletDetailsLink({ text: stageTimeTotal, search, stage, timeWindow: timeWindowFilters[twIndex] })
          );
          return stageTimeRow;
        });

        const regionTotalRow = stageRegionTotalDataRow.map((total, regionIndex) =>
          palletDetailsLink({ text: total, search, stage, region: regions[regionIndex], highlightPerUnit })
        );
        const stageTotal = stageRegionTotalDataRow.reduce((acc, curr) => acc + curr, 0);
        regionTotalRow.push(palletDetailsLink({ text: stageTotal, search, stage }));

        stageTimeRows.push(regionTotalRow);

        return stageTimeRows;
      });

      log({ ...ctx, rows }, "Built pallet summary rows");
      return rows;
    },
    [search]
  );

  const getPalletsSummary = useCallback(async (): Promise<ReactElement[][]> => {
    const ctx = logStart({ fn: "getPalletsSummary" });
    const { redirectFn, autoRefreshFn, createdFromStr, createdToStr, updatedFromStr, updatedToStr } = getFilter();

    if (redirectFn) {
      redirectFn();
      return [];
    }

    autoRefreshFn();

    const response: PalletDetailsAggregation[] = await crossdockClient.getAggregation({
      aggrFn: AggregationFunctionType.CountDistinct,
      aggrKey: "id",
      groupBy: ["stage", "region"],
      timeWindowBreaks: [0, 24, 48],
      filter: {
        currentCrossdockIds: [warehouseId],
        stages: [PalletStage.OPENED, PalletStage.CLOSED],
        created: {
          from: createdFromStr ? new Date(createdFromStr) : undefined,
          to: createdToStr ? new Date(createdToStr) : undefined,
        },
        updated: {
          from: updatedFromStr ? new Date(updatedFromStr) : undefined,
          to: updatedToStr ? new Date(updatedToStr) : undefined,
        },
      },
    });

    log({ ...ctx, response }, "Got pallet summary response");

    const sortedPalletsSummary = sortPalletsSummary(response);
    return buildPalletsSummaryRows(sortedPalletsSummary);
  }, [sortPalletsSummary, buildPalletsSummaryRows, warehouseId, getFilter]);

  const getAndSetPalletsSummary = useCallback(async () => {
    setAppReady(false);
    try {
      const palletsSummary = await getPalletsSummary();
      setPalletsSummaryRows(palletsSummary);
    } finally {
      setAppReady(true);
    }
  }, [getPalletsSummary]);

  const setPalletsSummary = useCallback((palletsSummary) => {
    setAppReady(false);
    try {
      setPalletsSummaryRows(palletsSummary);
    } finally {
      setAppReady(true);
    }
  }, []);

  return {
    getPalletsSummary,
    setPalletsSummary,
    getAndSetPalletsSummary,
    palletsSummaryRows,
    appReady,
    warehouseId,
  };
}

function palletDetailsLink({
  text,
  search = "",
  region,
  stage,
  timeWindow,
  highlightPerUnit = 0,
}: {
  text: string | number;
  search?: string;
  region?: string;
  stage?: string;
  timeWindow?: {
    from?: number;
    to?: number;
  };
  highlightPerUnit?: number;
}) {
  const params = new URLSearchParams(search);
  if (region) {
    params.set("regions", region);
  }
  if (stage) {
    params.set("stages", stage);
  }

  if (timeWindow?.from) {
    params.set("hours-from", String(timeWindow.from));
  }

  if (timeWindow?.to) {
    params.set("hours-to", String(timeWindow.to));
  }

  const url = "/pallets?" + params.toString();

  const highlightOpacity = highlightPerUnit * (isNumber(text) && text >= 5 ? text : 0);

  return (
    <Link
      key={url}
      to={url}
      style={{
        display: "block",
        backgroundColor: `rgba(255, 0, 0, ${highlightOpacity})`,
      }}
    >
      {text}
    </Link>
  );
}
