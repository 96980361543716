import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { FreightArrivalScanInboundPalletLabels as labels } from "./FreightArrivalScanInboundPalletLabelCard.labels";
import { useScanInboundPalletLabel } from "./useScanInboundPalletLabel";

export const FreightArrivalScanInboundPalletLabelCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, inputErrorMessage, inboundPalletLabelBarcode, onSubmit, onChange } = useScanInboundPalletLabel();

  return (
    <TextInputCard
      icon="scan"
      onSubmit={onSubmit}
      accentColor="BLUE"
      title={formatMessage(labels.title)}
      placeholder={formatMessage(labels.placeholder)}
      errorMessage={inputErrorMessage}
      value={inboundPalletLabelBarcode}
      onChange={onChange}
      disabled={loading}
    />
  );
};
