import React from "react";
import { FormattedMessage } from "react-intl";
import { useBoxInfoUnknownButton } from "./useBoxInfoUnknownButton";
import { TextButton } from "@deliverr/ui-facility/lib-facility/components/TextButton";

export const MissingLabelBoxInfoUnknownButton = () => {
  const onClick = useBoxInfoUnknownButton();
  return (
    <TextButton onClick={onClick} type="button">
      <FormattedMessage id="crossdock.missingLabelBoxInfoUnknownButton" defaultMessage="Box information unknown" />
    </TextButton>
  );
};
