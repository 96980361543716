import React from "react";

import { DoneScanningButton } from "@deliverr/ui-facility";
import { useBoxAuditScanningComplete } from "./useBoxAuditDoneScanningButton";

export const BoxAuditDoneScanningButton: React.FC = () => {
  const { onButtonClick, disabled } = useBoxAuditScanningComplete();

  return <DoneScanningButton onClick={onButtonClick} disabled={disabled} />;
};
