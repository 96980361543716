import React from "react";

import { ReportManuallyButton } from "../../components/ReportManuallyButton";
import { useMissingLabelReportManuallyButton } from "./useMissingLabelReportManuallyButton";

export const MissingLabelReportManuallyButton: React.FC = () => {
  const { onReportManuallyClick } = useMissingLabelReportManuallyButton();

  return <ReportManuallyButton onReportManuallyClick={onReportManuallyClick} />;
};
