import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "crossdock.freightArrivalLogPalletWeightModal.title",
    defaultMessage: "Record Pallet Weight",
  },
  message: {
    id: "crossdock.freightArrivalLogPalletWeightModal.message",
    defaultMessage: "Pallet belongs to {sellerId}. Measure and record the pallet weight before processing.",
  },
  button: {
    id: "crossdock.freightArrivalLogPalletWeightModal.button",
    defaultMessage: "Done weighing pallet",
  },
});
