import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export default defineMessages({
  title: {
    id: "crossdock.startProcessingPallet.title",
    defaultMessage: "Scan Inbound Pallet Label",
  },
  placeholder: crossdockLabels.inboundPalletLabelPlaceholder,
  scanInboundPalletInvalidBarcode: crossdockLabels.scanInboundPalletInvalidBarcode,
  startProcessingSuccessNotification: {
    id: "crossdock.startProcessingPallet.successNotification",
    defaultMessage: "Pallet {palletBarcode} marked processing.",
  },
});
