import React from "react";
import { FacilityModals } from "@deliverr/ui-facility/lib-facility/components/modals";
import { Page, PageContent, PageInner } from "@deliverr/ui-facility/lib-facility/components";
import { AppName } from "@deliverr/ui-facility/lib-facility/types";
import { ScanPath } from "crossdock/base/routes";
import { ScanHeader } from "crossdock/base/ScanHeader";
import { ScanModals } from "crossdock/common/modal/ScanModals";
import { ScanMenuPage } from "crossdock/menu/ScanMenu/ScanMenuPage";
import { FacilityMenuItemData } from "@deliverr/ui-facility/lib-facility/components/FacilityMenu";

export interface ScanPageProps {
  getVisibleMenuItems: (currentMenuPage: string) => FacilityMenuItemData[];
  isAdmin: boolean;
  leftHeaderAction?: React.ReactNode;
  pageName?: string;
  path: ScanPath;
  previousPage?: ScanPath;
  rightHeaderAction?: React.ReactNode;
}

export const ScanPage: React.FC<ScanPageProps> = ({
  path,
  pageName,
  previousPage,
  children,
  leftHeaderAction,
  rightHeaderAction,
  getVisibleMenuItems,
  isAdmin,
}) => (
  <Page>
    <PageContent>
      <ScanHeader
        appName={AppName.CD}
        defaultMenuPage={ScanMenuPage.MAIN}
        getVisibleMenuItems={getVisibleMenuItems}
        isAdmin={isAdmin}
        path={path}
        pageName={pageName}
        previousPage={previousPage}
        leftHeaderAction={leftHeaderAction}
        rightHeaderAction={rightHeaderAction}
      />
      <PageInner>{children}</PageInner>
    </PageContent>
    <ScanModals />
    <FacilityModals />
  </Page>
);
