import React from "react";
import { FormattedMessage } from "react-intl";
import { useEjectBoxButton } from "./useEjectBoxButton";
import { LoadingButton } from "@deliverr/ui-facility";

// An ejected box is removed from the box breaks process and sent back to PANDA to be forwarded.
// Any units already transferred to a container remain there, and the package quantities are adjusted
// with what remains in the box.
// Box ejection also happens when scanning an unexpected SKU.
export const BoxBreakEjectBoxButton: React.FC = () => {
  const onClick = useEjectBoxButton();
  return (
    <LoadingButton onClick={onClick} secondary block>
      <FormattedMessage id="crossdock.boxBreakEjectBoxButton.label" defaultMessage="Eject box" />
    </LoadingButton>
  );
};
