import { useState } from "react";
import { isNull } from "lodash/fp";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useSolveBoxLabelIncident } from "./useSolveBoxLabelIncident";
import { FlowTransitionDuration } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { createOpenCaseCard, createBoxContentsCard } from "./MissingLabelCardCreators";
import { LogContext } from "@deliverr/ui-logging";
import { incidentIsSolvable } from "./incidentIsSolvable";
import { useMissingLabelSubtitle } from "./useMissingLabelSubtitle";
import { useShowAddSku } from "../hooks/useShowAddSku";
import { MissingLabelModal } from "./MissingLabelModal";
import { useCrossdockModal } from "crossdock/common/modal";
import { showPageLeftActionState } from "crossdock/base/headerTitleState";
import { useSetRecoilState } from "recoil";

export function useCreateIncident() {
  const { showModal } = useCrossdockModal();
  const { successResponse, errorResponse, handleUnknownError, transition, hideAllFlowButtons } = useScanFlow();
  const { createIncident, solveIncident, setLabelCount, printIncidentLabels } = useSolveBoxLabelIncident();
  const { setPoSubtitle } = useMissingLabelSubtitle();
  const [errorMessage, setErrorMessage] = useState("");
  const { showAddSku } = useShowAddSku();
  const setShowPageLeftAction = useSetRecoilState(showPageLeftActionState);

  const onCreateIncidentSubmit = async (
    ctx: LogContext,
    boxInfo: string | null,
    setBoxInfo?: (boxInfo: string) => void
  ) => {
    setErrorMessage("");

    try {
      const incident = await createIncident(boxInfo);

      if (!isNull(boxInfo)) {
        if (incident.trackingNumber && incidentIsSolvable(incident)) {
          const incidentWithLabelCount = await setLabelCount(1, incident);
          const solvedIncident = await solveIncident(incidentWithLabelCount);
          showModal(MissingLabelModal.MISSING_LABEL_PRINTING_LABELS, {});
          await printIncidentLabels(solvedIncident);
        } else if (incident.asnId) {
          setPoSubtitle(incident);
        } else {
          errorResponse();
          setErrorMessage("Unrecognized box info. Try again or proceed without box info.");
          return;
        }
      }

      successResponse();
      hideAllFlowButtons();

      transition([
        {
          duration: FlowTransitionDuration.SHORT,
          card: createOpenCaseCard({}),
        },
        {
          card: createBoxContentsCard({}),
          onTransition: () => {
            showAddSku();
            setShowPageLeftAction(true);
          },
        },
      ]);
    } catch (err) {
      handleUnknownError(ctx, err);
    } finally {
      setBoxInfo?.("");
    }
  };

  return {
    errorMessage,
    onCreateIncidentSubmit,
  };
}
