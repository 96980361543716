import React from "react";
import { ToastContainer } from "@deliverr/ui";

import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { createScanBoxCard } from "./BoxAuditCardCreators";
import { useResetBoxAuditState } from "./useResetBoxAuditState";

export const BoxAudit: React.FC = () => {
  const { resetFlow } = useScanFlow();
  const { resetBoxAuditState } = useResetBoxAuditState();

  const onUnmount = () => {
    resetBoxAuditState();
    resetFlow();
  };

  return (
    <>
      <ScanFlowV2 onMountCardData={createScanBoxCard({})} onUnmount={onUnmount} />
      <ToastContainer position="bottom-center" />
    </>
  );
};
