import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { FormattedMessage } from "react-intl";
import { LOADING_DOCK_SCAN_LABELS } from "crossdock/modes/utils/freightLoadingDockScan.labels";

export interface FreightArrivalScanTransferCardProps {
  barcode: string;
  nextLocation?: string;
}

export const FreightArrivalScanTransferCard: React.FC<FreightArrivalScanTransferCardProps> = ({
  barcode,
  nextLocation,
}) => (
  <TextInputCard
    icon="arrow-right"
    size="lg"
    accentColor="BLUE"
    title={
      nextLocation ? (
        <FormattedMessage {...LOADING_DOCK_SCAN_LABELS.palletTransferInstructionTitle} values={{ nextLocation }} />
      ) : (
        <FormattedMessage {...LOADING_DOCK_SCAN_LABELS.defaultPalletTransferInstructionTitle} />
      )
    }
    message={
      nextLocation ? (
        <FormattedMessage {...LOADING_DOCK_SCAN_LABELS.palletTransferInstruction} values={{ nextLocation }} />
      ) : (
        <FormattedMessage {...LOADING_DOCK_SCAN_LABELS.defaultPalletTransferInstruction} />
      )
    }
    value={barcode}
    disabled
  />
);
