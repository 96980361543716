import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createBoxBreakScanBoxCard } from "../../../boxBreakCardCreators";
import { getBoxEndedEarlyNotification } from "crossdock/modes/break/boxBreakNotifications";
import { currentBoxBarcodeState } from "../../boxBreakState";
import { useResetCurrentBreakState } from "../../hooks/useResetCurrentBreakState";

export function useBoxBreakEndBreakComplete() {
  const { transition, hideAllFlowButtons } = useScanFlow();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  const { resetCurrentBreak } = useResetCurrentBreakState();

  return () => {
    transition({
      card: createBoxBreakScanBoxCard({}),
      notification: getBoxEndedEarlyNotification(currentBoxBarcode),
      onTransition: () => {
        resetCurrentBreak();
        hideAllFlowButtons();
      },
    });
  };
}
