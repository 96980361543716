import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { ThemeProps } from "@deliverr/ui";

import { useBoxBreakEnd } from "./useBoxBreakEnd";
import { FixedWidthButton, dangerModalIcon, ModalTemplate, successModalIcon } from "@deliverr/ui-facility";
import { BUTTON_WIDTH } from "../../utils/styleValues";

const ButtonContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.S2};
`
);

interface BoxBreakEndModalProps {
  isShortage: boolean;
}

export const BoxBreakEndModal: React.FC<BoxBreakEndModalProps> = ({ isShortage }) => {
  const { onCancelClick, onEndClick } = useBoxBreakEnd();
  const cancelButtonText = isShortage ? "Cancel" : "Not yet";
  const modalTitle = isShortage ? "End with shortage?" : "Is the box empty?";
  const modalText = isShortage ? "You scanned less than the expected quantity." : "";
  const iconProps = isShortage ? dangerModalIcon : successModalIcon;
  return (
    <ModalTemplate
      title={
        <FormattedMessage id="crossdock.boxBreakEndModal.title" defaultMessage="{modalTitle}" values={{ modalTitle }} />
      }
      text={
        <FormattedMessage id="crossdock.boxBreakEndModal.text" defaultMessage="{modalText}" values={{ modalText }} />
      }
      iconProps={iconProps}
    >
      <ButtonContainer>
        <FixedWidthButton width={BUTTON_WIDTH} onClick={onCancelClick} secondary>
          <FormattedMessage
            id="crossdock.boxBreakEndModal.cancelEndBoxBreakButton"
            defaultMessage="{cancelButtonText}"
            values={{ cancelButtonText }}
          />
        </FixedWidthButton>
        <FixedWidthButton width={BUTTON_WIDTH} onClick={onEndClick}>
          <FormattedMessage
            id="crossdock.boxBreakEndModal.confirmEndBoxBreakButton"
            defaultMessage="Yes, end box break"
          />
        </FixedWidthButton>
      </ButtonContainer>
    </ModalTemplate>
  );
};
