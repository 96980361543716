import { useCallback, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";

import { isZebraScanner, log, setLogUser } from "@deliverr/ui-facility/lib-facility/utils";
import { clientIdState } from "crossdock/base/clientIdState";
import { usePrinterZebra } from "@deliverr/ui-facility/lib-facility/printer";
import { flashState } from "@deliverr/ui-facility/lib-facility/flow/flowState";
import { useUserState } from "@deliverr/ui-facility/lib-facility/hooks/auth/useUserState";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { AppName } from "@deliverr/ui-facility/lib-facility/types";
import { hubMenuPageMap, crossdockMenuPageMap, multiFacilityMenuPageMap } from "../menu/ScanMenu/scanMenuPageMap";
import { useClientsWithAuth } from "crossdock/base/useClientsWithAuth";
import { useInterval } from "react-use";

const REFRESH_AUTH0_TOKEN_INTERVAL_MS = 1 * 60 * 60 * 1000; // 1 hr

// tasks on app load
export function useScanApp() {
  const [appReady, setAppReady] = useState<boolean>(false);
  useUserState(AppName.CD);
  const clientId = useRecoilValue(clientIdState);
  const user = useRecoilValue(userState);
  const { warehouseId, isAdmin, email } = user;
  const { initiatePrinterSearch } = usePrinterZebra();
  const flash = useRecoilValue(flashState);
  const setAuth0AccessTokens = useClientsWithAuth();
  const ctx = { fn: "useScanApp" };

  useInterval(setAuth0AccessTokens, REFRESH_AUTH0_TOKEN_INTERVAL_MS);

  useEffect(() => {
    if (warehouseId) {
      setAuth0AccessTokens();
      setAppReady(true);
      log(ctx, "setting user log props", { warehouseId, clientId });
      setLogUser({ warehouseId, clientId, email });
      if (isZebraScanner) {
        log(ctx, "zebra scanner, searching for printers");
        initiatePrinterSearch();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId]);

  const getVisibleMenuItems = useCallback(
    (currentMenuPage: string) => {
      const pageMap =
        user.isCrossdock && user.isHub
          ? multiFacilityMenuPageMap
          : user.isCrossdock
            ? crossdockMenuPageMap
            : hubMenuPageMap;

      const currentMenuPageConfig = pageMap[currentMenuPage];
      const visibleMenuItems = currentMenuPageConfig.items
        .filter(
          (menuItem) =>
            !menuItem.isHidden &&
            ((user.isCrossdock && !menuItem.hideFromCrossdock) || (user.isHub && !menuItem.hideFromHub))
        )
        .map(({ onClick, ...item }) => {
          return {
            ...item,
            // pass-in the user state so onClick can have more dynamic behavior
            onClick: onClick
              ? () => {
                  onClick(user);
                }
              : undefined,
          };
        });
      return visibleMenuItems;
    },
    [user]
  );

  return { appReady, flash, getVisibleMenuItems, isAdmin };
}
