import React from "react";
import { FormattedMessage } from "react-intl";
import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards/TransitionCard";
import OpenBoxIcon from "@deliverr/ui-facility/lib-facility/common/assets/openBox.svg";

export const OpenCaseCard: React.FC = () => (
  <TransitionCard
    title={<FormattedMessage id="crossdock.openCaseCard.title" defaultMessage="Open Box" />}
    message={
      <FormattedMessage id="crossdock.openCaseCard.message" defaultMessage="Open the box to start scanning SKUs" />
    }
  >
    <img src={OpenBoxIcon} alt="open box icon" />
  </TransitionCard>
);
