import { toCrossdockDimsAndWeightScanProductBarcode } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetRecoilState } from "recoil";
import {
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightProductBarcodeState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

export const useCrossdockDimsAndWeightCancelProductDimsScan = () => {
  const resetCrossdockDimsAndWeightProductBarcode = useResetRecoilState(crossdockDimsAndWeightProductBarcodeState);
  const resetCrossdockDimsAndWeightCurrentProduct = useResetRecoilState(crossdockDimsAndWeightCurrentProductState);

  const { transition, hideAllFlowButtons, showFlowButton } = useScanFlow();

  const onButtonClick = async () => {
    hideAllFlowButtons();

    resetCrossdockDimsAndWeightProductBarcode();
    resetCrossdockDimsAndWeightCurrentProduct();
    transition(toCrossdockDimsAndWeightScanProductBarcode());

    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON);
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON);
  };

  return {
    onButtonClick,
  };
};
