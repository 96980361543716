import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { useCrossdockDimsAndWeightCancelProductDimsScan } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightCancelProductDimsScanButton/useCrossdockDimsAndWeightCancelProductDimsScan";

export const CrossdockDimsAndWeightCancelProductDimsScanButton: React.FC = () => {
  const { onButtonClick } = useCrossdockDimsAndWeightCancelProductDimsScan();

  return (
    <Button onClick={onButtonClick} block secondary>
      <FormattedMessage
        id="crossdock.crossdockDimsAndWeightCancelProductDimsScanButton.title"
        defaultMessage="Cancel Product Dims Scan"
      />
    </Button>
  );
};
