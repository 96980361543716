import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useLoading } from "crossdock/common/useLoading";
import {
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightProductBarcodeState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { crossdockDimsAndWeightPackageItemsState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightSelectorState";
import { toCrossdockDimsAndWeightScanProductDims } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { useState } from "react";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { crossdockClient } from "crossdock/base/Clients";
import { Product } from "@deliverr/crossdock-service-client";
import { useMount } from "react-use";

export function useCrossdockDimsAndWeightScanProductBarcode() {
  const { inputErrorMessage: errorMessage, resetErrorOnExecution } = useInputErrorMessage();
  const { loading, loadWhilePending } = useLoading();
  const [productBarcode, setProductBarcode] = useState<string>("");
  const setCurrentProductBarcode = useSetRecoilState(crossdockDimsAndWeightProductBarcodeState);
  const pkg = useRecoilValue(crossdockDimsAndWeightPackageState);
  const { pendingPackageItems, completedPackageItems } = useRecoilValue(crossdockDimsAndWeightPackageItemsState);
  const setCurrentProduct = useSetRecoilState(crossdockDimsAndWeightCurrentProductState);

  const { transition, successResponse, hideAllFlowButtons, showFlowButton } = useScanFlow();

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON);
    showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON);
  });

  const submitProductBarcode = async (barcode: string) => {
    const ctx = logStart({ fn: "submitProductBarcode", barcode });

    const packageItems = [...pendingPackageItems, ...completedPackageItems.scanned, ...completedPackageItems.unscanned];

    log({ ctx, pkg, packageItems }, "checking if barcode is in package items");

    const packageItem = packageItems.find((item) => (item.product?.barcodes ?? []).includes(barcode));

    if (packageItem?.product) {
      setCurrentProduct(packageItem.product);
    } else {
      try {
        const product = (await crossdockClient.getProductByBarcode({ barcode })) as Product;
        setCurrentProduct(product);
      } catch (e) {
        log(ctx, "unknown barcode");
      }
    }

    setCurrentProductBarcode(barcode);

    log(ctx, "product scan successful");
    successResponse();

    transition(toCrossdockDimsAndWeightScanProductDims());
  };

  return {
    productBarcode,
    submitProductBarcode: loadWhilePending(resetErrorOnExecution(submitProductBarcode)),
    onProductBarcodeChange: genericOnScannerInputChange(
      productBarcode,
      setProductBarcode,
      submitProductBarcode,
      "upper"
    ),
    loading,
    errorMessage,
  };
}
