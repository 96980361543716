import React from "react";
import { Button } from "@deliverr/ui";
import { useBoxBreakRemoveContainer } from "./useBoxBreakRemoveContainer";

interface Props {
  disabled?: boolean;
}

export const BoxBreakRemoveContainerButton: React.FC<Props> = ({ disabled }) => {
  const onClick = useBoxBreakRemoveContainer();

  return (
    <Button onClick={onClick} secondary block disabled={disabled}>
      Remove Container
    </Button>
  );
};
