import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export const FreightArrivalScanInboundPalletLabels = defineMessages({
  title: {
    id: "crossdock.freightArrivalScanInboundPalletLabels.title",
    defaultMessage: "Apply Inbound Pallet Label & Scan",
  },
  placeholder: crossdockLabels.inboundPalletLabelPlaceholder,
  scanInboundPalletInvalidBarcode: crossdockLabels.scanInboundPalletInvalidBarcode,
});
