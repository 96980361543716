import React from "react";
import styled from "@emotion/styled";
import { createScanPalletCard } from "../freightArrivalCardCreators";
import { FLOW_CARD_CONTAINER_SECTION_WIDTH } from "@deliverr/ui-facility/lib-facility/flow/styleValues";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { CancelButton } from "@deliverr/ui-facility";
import { useResetRecoilState } from "recoil";
import { poNumState } from "../FreightArrivalState";

const StyledButton = styled(CancelButton)`
  width: ${FLOW_CARD_CONTAINER_SECTION_WIDTH};
`;

export const FreightArrivalBackToScanFPLButton: React.FC = () => {
  const { addFlowCard, hideAllFlowButtons } = useScanFlow();
  const resetPoNum = useResetRecoilState(poNumState);

  const onClick = () => {
    resetPoNum();
    hideAllFlowButtons();
    addFlowCard(createScanPalletCard({}));
  };

  return <StyledButton onClick={onClick} secondary />;
};
