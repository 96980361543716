import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import labels from "./FreightArrivalScanLane.labels";
import { useFreightArrivalScanLane } from "./useFreightArrivalScanLane";
import { FreightArrivalScanSuccessCard, FreightArrivalScanSuccessCardProps } from "../FreightArrivalScanSuccessCard";
import { Stack } from "@deliverr/ui";

export const FreightArrivalScanLaneCard: React.FC<FreightArrivalScanSuccessCardProps> = (props) => {
  const { formatMessage } = useIntl();
  const { inputErrorMessage, value, loading, onChange, onSubmit } = useFreightArrivalScanLane();

  return (
    <Stack>
      <FreightArrivalScanSuccessCard {...props} />
      <TextInputCard
        accentColor="BLUE"
        icon="scan"
        title={formatMessage(labels.title)}
        placeholder={formatMessage(labels.placeholder)}
        errorMessage={inputErrorMessage}
        value={value}
        disabled={loading}
        onChange={onChange}
        onSubmit={onSubmit}
      ></TextInputCard>
    </Stack>
  );
};
