import { atom } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";

export const cdskuState = atom({
  key: CrossdockStateKey.TRANSFERS_ADD_CASE_CDSKU,
  default: "",
});

export const palletIdState = atom({
  key: CrossdockStateKey.TRANSFERS_ADD_CASE_PALLET_ID,
  default: "",
});
