import { useLoading } from "crossdock/common/useLoading";
import { crossdockClient } from "crossdock/base/Clients";
import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { ProductDimsAPIRequestV2 } from "@deliverr/crossdock-service-client";
import { toast } from "@deliverr/ui";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { toScanProductBarcode } from "crossdock/modes/inspection/fc-dims-and-weight/transitions";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import {
  fcDimsAndWeightCubiscanImagesState,
  fcDimsAndWeightProductBarcodeState,
  fcDimsAndWeightProductDimsState,
} from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";
import { useResetFcDimsAndWeightState } from "crossdock/modes/inspection/fc-dims-and-weight/hooks/useResetFcDimsAndWeightState";
import { useIntl } from "react-intl";

export const useFcDimsAndWeightComplete = () => {
  const { loading, loadWhilePending } = useLoading();

  const { warehouseId } = useRecoilValue(userState);
  const productBarcode = useRecoilValue(fcDimsAndWeightProductBarcodeState);
  const dims = useRecoilValue(fcDimsAndWeightProductDimsState);
  const nonComplianceImagesMetadata = useRecoilValue(fcDimsAndWeightCubiscanImagesState);

  const { resetFcDimsAndWeightState } = useResetFcDimsAndWeightState();
  const { formatMessage } = useIntl();
  const { hideAllFlowButtons, successResponse, transition, handleUnknownError } = useScanFlow();

  const { cubiscanScreen, cubiscanProduct, cubiscanAll } = nonComplianceImagesMetadata;

  const onButtonClick = async () => {
    const ctx = logStart({ fn: "onButtonClick#FcDimsAndWeightComplete" });

    const productDimsAPIRequest: ProductDimsAPIRequestV2 = {
      barcode: productBarcode!,
      warehouseId,
      ...dims!,
      nonComplianceImagesMetadata,
    };

    try {
      await crossdockClient.createProductDimsV2(productDimsAPIRequest);

      successResponse();
      toast.success(formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsRecorded, { ...dims! }), { autoClose: 5000 });
      resetFcDimsAndWeightState();
      transition(toScanProductBarcode());
      hideAllFlowButtons();
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  return {
    onButtonClick: loadWhilePending(onButtonClick),
    loading,
    disableButton: !dims || !cubiscanScreen || !cubiscanProduct || !cubiscanAll,
  };
};
