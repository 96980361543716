import React from "react";
import { Button } from "@deliverr/ui";
import labels from "../FreightArrival.labels";
import { useIntl } from "react-intl";
import { lastPoState, poNumState } from "../FreightArrivalState";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useSubmitPoNum } from "../useSubmitPoNum";

export const FreightArrivalUseLastPoButton: React.FC = () => {
  const { formatMessage } = useIntl();
  const lastPoNum = useRecoilValue(lastPoState);
  const { onSubmit, loading } = useSubmitPoNum();
  const setPoNum = useSetRecoilState(poNumState);

  const onClick = async () => {
    // lastPoNum will exist because we check for it before showing this button
    setPoNum(lastPoNum!);
    await onSubmit(lastPoNum!);
  };

  return (
    <Button onClick={onClick} disabled={loading} block>
      {formatMessage(labels.useLastPoButton, { lastPoNum })}
    </Button>
  );
};
