import { useRecoilValue } from "recoil";

import { log, logStart, isZebraScanner, printPdf } from "@deliverr/ui-facility/lib-facility/utils";
import { dpiState, usePrintZpl } from "@deliverr/ui-facility/lib-facility/printer";
import { inboundClient } from "crossdock/base/Clients";

export function usePrintBoxLabel() {
  const dpi = useRecoilValue(dpiState);
  const { printZpl } = usePrintZpl();

  return async (cdsku: string): Promise<void> => {
    const ctx = logStart({ fn: "printBoxLabel" });

    if (isZebraScanner) {
      const zpl = await inboundClient.getBoxLabelByCdskuZpl(cdsku, dpi);

      log(ctx, "retrieved box label zpl");
      await printZpl(zpl);
    } else {
      const pdf = await inboundClient.getBoxLabelByCdskuPdf(cdsku);

      log(ctx, "retrieved box label pdf");
      printPdf(pdf);
    }

    log(ctx, "printed box label");
  };
}
