import React from "react";
import styled from "@emotion/styled";
import { IconV2, ThemeProps } from "@deliverr/ui";

import { useProblemBackButton } from "./useProblemBackButton";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow";

export const IconContainer = styled.button<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE[300]};
`
);

export interface ProblemBackButtonProps {
  flowButtonType: ScanFlowButtonType;
  flowCard: FlowCardData<any>;
}

export const ProblemBackButton: React.FC<ProblemBackButtonProps> = (props) => {
  const { onClick } = useProblemBackButton(props);

  return (
    <IconContainer onClick={onClick}>
      <IconV2 type="arrow-left" />
    </IconContainer>
  );
};
