import React from "react";
import { DefaultTheme } from "@deliverr/ui";

import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilValue } from "recoil";
import { headerSubtitleState } from "crossdock/base/headerTitleState";
import { useHistory } from "react-router";
import { useLifecycles } from "react-use";
import { ScanPath } from "crossdock/base/routes";
import styled from "@emotion/styled";
import { useResetCrossdockDimsAndWeightState } from "crossdock/modes/inspection/crossdock-dims-and-weight/hooks/useResetCrossdockDimsAndWeightState";
import { createCrossdockDimsAndWeightScanPackageCdskuCard } from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/CardCreators";
import { DimsAndWeightCompletedItemsSidebar } from "crossdock/modes/inspection/crossdock-dims-and-weight/sidebars/CompletedItemsSidebar";
import { DimsAndWeightContentsOverview } from "crossdock/modes/inspection/crossdock-dims-and-weight/sidebars/IncompleteItemsSidebar";

const DimsAndWeightFlexContainer = styled.div<{ thinNavbar: boolean }, DefaultTheme>(
  ({ theme, thinNavbar }) => `
  display: flex;
  position: relative;
  height: calc(100vh - ${thinNavbar ? "50px" : "70px"}); // height of screen minus header
  margin-right: -.65rem;
  padding-bottom: ${theme.spacing.S4};
`
);

let unlisten;

export const CrossdockDimsAndWeight: React.FC = () => {
  const headerSubtitle = useRecoilValue(headerSubtitleState);
  const { resetCrossdockDimsAndWeightState } = useResetCrossdockDimsAndWeightState();
  const { addFlowCard, resetFlow } = useScanFlow();
  const useThinNavbar = !headerSubtitle || headerSubtitle.length === 0;
  const history = useHistory();

  useLifecycles(
    () => {
      unlisten = history.listen((location) => {
        if (location.pathname === ScanPath.FcDimsAndWeight) {
          resetCrossdockDimsAndWeightState();
          addFlowCard(createCrossdockDimsAndWeightScanPackageCdskuCard({}));
        }
      });
    },
    () => {
      unlisten();
    }
  );

  const onUnmount = () => {
    resetCrossdockDimsAndWeightState();
    resetFlow();
  };

  return (
    <DimsAndWeightFlexContainer thinNavbar={useThinNavbar}>
      <DimsAndWeightContentsOverview />
      <ScanFlowV2 onMountCardData={createCrossdockDimsAndWeightScanPackageCdskuCard({})} onUnmount={onUnmount} />
      <DimsAndWeightCompletedItemsSidebar />
    </DimsAndWeightFlexContainer>
  );
};
