import React, { useState } from "react";
import { MessageDescriptor, useIntl } from "react-intl";

import { UploadItem } from "@deliverr/ui-facility/lib-facility/components";
import { NcCardStack } from "./NcCardStack";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilState } from "recoil";
import { boxAuditUnknownBarcodeState } from "../BoxAuditState";
import { useBoxAuditUnknownBarcode } from "./useBoxAuditUnknownBarcode";
import { FormProvider } from "react-hook-form";
import { s3FileHandlerClient } from "crossdock/base/Clients";

interface UnknownBarcodeUploadItemProps {
  iconText: MessageDescriptor;
  photoType: string;
  iconId: string;
  field: string;
}

export interface BoxAuditUnknownBarcodeUploadCardProps {
  title: MessageDescriptor;
  uploads: UnknownBarcodeUploadItemProps[];
  nextCard?: FlowCardData<any>;
  submitUpload?: () => void;
}

export const BoxAuditUnknownBarcodeUploadCard: React.FC<BoxAuditUnknownBarcodeUploadCardProps> = ({
  title,
  uploads,
  nextCard,
  submitUpload,
}) => {
  const { formatMessage } = useIntl();
  const { addFlowCard } = useScanFlow();
  const [boxAuditUnknownBarcode, setBoxAuditUnknownBarcode] = useRecoilState(boxAuditUnknownBarcodeState);
  const { formHandler } = useBoxAuditUnknownBarcode();
  const [disabled, setDisabled] = useState<boolean>(false);

  const onContinueClick = () => {
    if (nextCard) {
      addFlowCard(nextCard);
    }
    submitUpload?.();
    setDisabled(true);
  };

  const generateOnFileChange = (photoType: string) => (fileName: string) => {
    setBoxAuditUnknownBarcode({ ...boxAuditUnknownBarcode, [photoType]: fileName });
  };

  return (
    <FormProvider {...formHandler}>
      <NcCardStack
        continueDisabled={disabled || !uploads.every((upload) => !!boxAuditUnknownBarcode[upload.photoType])}
        continueOnClick={onContinueClick}
        title={formatMessage(title)}
      >
        {uploads.map(({ iconText, iconId, photoType, field }) => {
          return (
            <UploadItem
              {...formHandler.register[field]}
              key={photoType}
              name={photoType}
              iconText={formatMessage(iconText)}
              id={`${iconId}`}
              onSuccessfulUpload={generateOnFileChange(photoType)}
              fileClient={s3FileHandlerClient}
            />
          );
        })}
      </NcCardStack>
    </FormProvider>
  );
};
