import React from "react";
import { FormattedMessage } from "react-intl";

import { useCrossdockModal } from "crossdock/common/modal";
import {
  FACILITY_COMMONS_BUTTON_LABELS,
  FixedWidthButton,
  ModalTemplate,
  dangerModalIcon,
} from "@deliverr/ui-facility";
import { LocalizedMessage } from "@deliverr/ui";

export interface FreightArrivalScanPalletErrorModalProps {
  title: LocalizedMessage;
  message: LocalizedMessage;
}

export const FreightArrivalScanPalletErrorModal: React.FC<FreightArrivalScanPalletErrorModalProps> = ({
  title,
  message,
}) => {
  const { hideAllModals } = useCrossdockModal();
  return (
    <ModalTemplate title={title} text={message} iconProps={dangerModalIcon}>
      <FixedWidthButton width="134px" onClick={hideAllModals}>
        <FormattedMessage {...FACILITY_COMMONS_BUTTON_LABELS.understandButton} />
      </FixedWidthButton>
    </ModalTemplate>
  );
};
