import { defineMessages } from "react-intl";

export const CONFIRM_OVERAGE_LABELS = defineMessages({
  title: {
    id: "crossdock.boxBreakConfirmOverageScanModal.title",
    defaultMessage: "Confirm unit overage?",
  },
  message: {
    id: "crossdock.boxBreakConfirmOverageScanModal.text",
    defaultMessage: "You scanned more than the expected quantity for this SKU.",
  },
  overageSku: {
    id: "crossdock.boxBreakConfirmOverageScanModal.overageSku",
    defaultMessage: "SKU: {barcode}",
  },
  cancelButton: {
    id: "crossdock.boxBreakConfirmOverageScanModal.cancelScanButton",
    defaultMessage: "Cancel scan",
  },
  confirmButton: {
    id: "crossdock.boxBreakConfirmOverageScanModal.confirmScanButton",
    defaultMessage: "Yes, confirm scan",
  },
});
