import * as React from "react";
import { FormattedMessage } from "react-intl";
import { DefaultTheme, Title } from "@deliverr/ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import { useRecoilValue } from "recoil";

import { BoxBreakBoxContentsItem } from "./BoxBreakBoxContentsItem";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { activeDskuValue, boxBreakBoxContentsBarcodesState } from "../boxBreakSidebarState";
import { SpacedIcon } from "crossdock/common/components";

const BoxBreakBoxContentsOverviewContainer = styled.section<{ isDisplay: boolean }, DefaultTheme>(
  ({ isDisplay, theme }) => `
  position: absolute;
  left: ${isDisplay ? 0 : "-320px"};
  top: -.65rem;
  transition: left .5s ease-in-out;
  min-width: 320px;
  padding: ${theme.spacing.S5};
  background:  ${theme.colors.NEUTRAL["00"]}};
  overflow-y: scroll;
  height: 100%;
  margin-left: -.65rem;
  `
);

export const BoxBreakBoxContentsOverview: React.FC = () => {
  const boxBreakBoxContentsBarcodes = useRecoilValue(boxBreakBoxContentsBarcodesState);
  const activeDsku = useRecoilValue(activeDskuValue);
  const { boxContentsAllUnitsDistributionByDsku } = useBoxBreakState();

  return (
    <BoxBreakBoxContentsOverviewContainer isDisplay={!isEmpty(boxContentsAllUnitsDistributionByDsku)}>
      <Title as="h4" displayAs="h4">
        <SpacedIcon type="box" />
        <FormattedMessage id="crossdock.boxBreaksBoxContentsOverview.title" defaultMessage="Box Contents" />
      </Title>
      {isEmpty(boxContentsAllUnitsDistributionByDsku) ? null : (
        <>
          {Object.keys(boxContentsAllUnitsDistributionByDsku).map((dsku) => (
            <BoxBreakBoxContentsItem
              key={dsku}
              dsku={dsku}
              activeDsku={activeDsku === dsku}
              barcode={boxBreakBoxContentsBarcodes[dsku]}
              scannedUnits={boxContentsAllUnitsDistributionByDsku[dsku].scannedUnits ?? 0}
              totalUnits={boxContentsAllUnitsDistributionByDsku[dsku].totalUnits}
              unexpected={boxContentsAllUnitsDistributionByDsku[dsku].unexpected}
            />
          ))}
        </>
      )}
    </BoxBreakBoxContentsOverviewContainer>
  );
};
