export class NotFoundError extends Error {
  constructor(m: string) {
    super(m);
    this.name = "NotFoundError";
  }
}

export function throwIfNotFound<V>(message: string, value?: V): V {
  if (value === undefined) {
    throw new NotFoundError(message);
  } else {
    return value;
  }
}
