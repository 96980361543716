import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useFreightArrivalMovePalletNow } from "./useFreightArrivalMovePalletNow";
import { RadioTileGroup } from "@deliverr/ui";
import labels from "./FreightArrivalMovePalletNowCard.labels";

export const FreightArrivalMovePalletNowCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { loading, onChange, radioOptions, willMovePallet, message } = useFreightArrivalMovePalletNow();

  return (
    <FlowCard title={formatMessage(labels.title)} message={message}>
      <RadioTileGroup
        name="crossdock.freightArrivalMovePalletNowCard.radioGroup"
        disabled={loading}
        currentValue={willMovePallet}
        block
        onChange={onChange}
        direction="VERTICAL"
        options={radioOptions}
      />
    </FlowCard>
  );
};
