import { TransferPalletActionSource, InboundError } from "@deliverr/legacy-inbound-client";
import {
  createUnknownErrorCard,
  createUnknownInputCard,
} from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import { logError, logStart, log } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  createClosePalletScanPalletSuccessCard,
  createClosePalletInvalidStatusCard,
  createClosePalletScanPalletCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { palletLabelIdState as closePalletLabelIdState } from "./ClosePalletState";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { crossdockClient } from "crossdock/base/Clients";

/**
 * @param palletLabelIdState The recoil state for palletLabelId for the relevant flow
 */
export function useClosePallet(palletLabelIdState = closePalletLabelIdState) {
  const { addFlowCard } = useScanFlow();
  const setPalletLabelId = useSetRecoilState(palletLabelIdState);
  const { warehouseId } = useRecoilValue(userState);

  /**
   * Attempts closing a pallet and handles errors that may arise.
   *
   * @param palletLabelId label of pallet to close
   */
  async function handleClosePallet(palletLabelId: string) {
    const ctx = logStart({ fn: "handleClosePallet", palletLabelId });

    try {
      const pallet = await crossdockClient.closeOutboundPallet(palletLabelId, warehouseId, {
        actionSource: TransferPalletActionSource.TRANSFERS_CLOSE_PALLET,
      });
      addFlowCard(createClosePalletScanPalletSuccessCard({ palletLabelId }));
      return pallet;
    } catch (err) {
      if (err.code === InboundError.INVALID_PALLET_SCAN) {
        log(ctx, "invalid pallet label id");
        addFlowCard(createUnknownInputCard({ value: palletLabelId, message: "Does not appear to be a valid Pallet." }));
      } else if (err.code === InboundError.PALLET_ALREADY_IN_STATUS) {
        const { statusUpdatedAt, status } = err.payload;
        log(ctx, "attempted to close a closed pallet");
        addFlowCard(
          createClosePalletInvalidStatusCard({ palletLabelId, status, statusUpdatedAt: new Date(statusUpdatedAt) })
        );
      } else {
        logError(ctx, err);
        addFlowCard(createUnknownErrorCard({ value: palletLabelId }));
      }
    } finally {
      // Reset pallet label and continue with allowing scanning a new pallet
      setPalletLabelId("");
      addFlowCard(createClosePalletScanPalletCard({}));
    }
  }

  return handleClosePallet;
}
