import { defineMessages } from "react-intl";

export const BULK_TRANSFER_LABELS = defineMessages({
  bulkTransferTitle: {
    id: "crossdock.bulkTransferCard.bulkTransferTitle",
    defaultMessage: "Place units into chute {chuteLetter}",
  },
  bulkTransferMessage: {
    id: "crossdock.bulkTransferCard.bulkTransferMessage",
    defaultMessage: "Enter the amount of units to place in chute {chuteLetter}",
  },
});
