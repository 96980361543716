export const invalidInput = (inputTypeName: string, directions?: string) =>
  `${inputTypeName} invalid. ${directions ?? "Try again"}.`;
export const wrongInput = (inputTypeName: string) => `Wrong ${inputTypeName}. Scan a ${inputTypeName}.`;
export const unknownInput = (directions: string) => `Unknown input. ${directions}`;
export const notFoundInput = (inputTypeName: string) => `${inputTypeName} not found.`;

export const INVALID_AMOUNT_POSITIVE_QTY_ERROR = invalidInput("Amount", "Should be > 0");
export const INVALID_AMOUNT_INTEGER_ERROR = invalidInput("Amount", "Should be an integer");
export const INVALID_CHUTE_ERROR = invalidInput("Chute");
export const INVALID_BOX_ERROR = invalidInput("Box");

export const WRONG_CHUTE_ERROR = wrongInput("chute");

export const UNKNOWN_INPUT_CONTAINER = unknownInput(`Barcode must start with 'CD'.`);

export const NOT_FOUND_BOX = notFoundInput("Box");
export const NOT_FOUND_UNIT_BARCODE = notFoundInput("Unit barcode");
