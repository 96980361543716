import React from "react";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { Button } from "@deliverr/ui";
import { usePrintPalletLabel } from "./usePrintPalletLabel";
import { NumericInput } from "@deliverr/ui-facility/lib-facility/components";
import { useIntl } from "react-intl";
import labels from "./PrintPalletLabelCard.labels";
import { MIN_INBOUND_LABEL_COUNT, MAX_INBOUND_LABEL_COUNT } from "./printInboundPalletLabels.constants";

export const PrintPalletLabelCard: React.FC = () => {
  const { value, palletCount, updatePalletCount, requestInboundPalletLabels, inputErrorMessage, loading } =
    usePrintPalletLabel();
  const { formatMessage } = useIntl();

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await requestInboundPalletLabels({});
  };

  return (
    <FlowCard title={formatMessage(labels.title)} icon="print" accentColor="BLUE">
      <form onSubmit={onSubmit}>
        <NumericInput
          autoFocus
          value={value}
          placeholder={formatMessage(labels.labelCount)}
          onChange={updatePalletCount}
          errorMessage={inputErrorMessage}
          hasError={Boolean(inputErrorMessage)}
          min={MIN_INBOUND_LABEL_COUNT}
          max={MAX_INBOUND_LABEL_COUNT}
          disabled={loading}
        />
      </form>
      <Button
        type="button"
        onClick={requestInboundPalletLabels}
        disabled={loading || Number.isNaN(palletCount) || palletCount <= 0}
      >
        {formatMessage(labels.printLabels)}
      </Button>
    </FlowCard>
  );
};
