import React from "react";
import { useIntl } from "react-intl";
import { Stack, Text } from "@deliverr/ui";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { useCrossdockModal } from "crossdock/common/modal";
import { dangerModalIcon, PromptModal } from "@deliverr/ui-facility";
import { BoxBreakModal } from "../BoxBreakModal";
import { useBoxBreakScanUnit } from "../../hooks/useBoxBreakScanUnit/useBoxBreakScanUnit";
import { CONFIRM_OVERAGE_LABELS as labels } from "./BoxBreakConfirmOverageScanModal.labels";

interface BoxBreakConfirmOverageScanModalProps {
  barcode: string;
}

export const BoxBreakConfirmOverageScanModal: React.FC<BoxBreakConfirmOverageScanModalProps> = ({ barcode }) => {
  const { hideModal } = useCrossdockModal();
  const { scanUnit } = useBoxBreakScanUnit();
  const { formatMessage } = useIntl();

  const onCancelClick = () => {
    hideModal(BoxBreakModal.BOX_BREAK_CONFIRM_OVERAGE_SCAN);
  };

  const onConfirmClick = async () => {
    await scanUnit(barcode);
    onCancelClick();
  };

  return (
    <PromptModal
      title={formatMessage(labels.title)}
      content={
        <Stack center>
          <Text textAlign={TextAlign.center}>{formatMessage(labels.message)}</Text>
          <Text bold>{formatMessage(labels.overageSku, { barcode })}</Text>
        </Stack>
      }
      iconProps={dangerModalIcon}
      onConfirmClick={onConfirmClick}
      onCancelClick={onCancelClick}
      confirmButtonLabel={formatMessage(labels.confirmButton)}
      cancelButtonLabel={formatMessage(labels.cancelButton)}
    />
  );
};
