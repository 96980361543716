import React from "react";

import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow/cards/ErrorTextCard";

export interface AddCaseToClosedPalletProps {
  value: string;
  newest?: boolean;
}

export const AddCaseToClosedPalletErrorCard: React.FC<AddCaseToClosedPalletProps> = (props) => {
  const { value, newest } = props;

  return <ErrorTextCard title="You cannot add boxes to a closed pallet" value={value} newest={newest} />;
};
