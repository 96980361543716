import React from "react";

import { InputBarcodeModal, useInputBarcodeModal } from "@deliverr/ui-facility/lib-facility/components/modals";
import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { BoxAuditModal, useScanBoxAuditSku } from "crossdock/modes/problem/BoxAudit";

export const BoxAuditInputBarcodeModal: React.FC = () => {
  const { onBoxContentsQtyChange } = useScanBoxAuditSku();
  const { hideModal } = useCrossdockModal();

  const { barcode, onSubmit, onChange, onModalExit, onNextClick, errorMessage, loading } = useInputBarcodeModal({
    handleClose: () => {
      hideModal(BoxAuditModal.BOX_AUDIT_INPUT_BARCODE_MODAL);
    },
    onBoxContentsQtyChange,
  });

  return (
    <InputBarcodeModal
      barcode={barcode}
      onSubmit={onSubmit}
      onChange={onChange}
      onModalExit={onModalExit}
      onNextClick={onNextClick}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};
