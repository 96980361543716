import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";
import { useRecoilValue } from "recoil";

import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { useBoxBreakPage } from "./hooks/useBoxBreakPage";
import { BoxBreakChuteSidebar } from "./sidebar/BoxBreakChutesSidebar";
import { BoxBreakBoxContentsOverview } from "./sidebar/BoxBreakBoxContentsSidebar";
import { headerSubtitleState } from "crossdock/base/headerTitleState";
import { useHistory } from "react-router";
import { useResetCurrentBreakState } from "./hooks/useResetCurrentBreakState";
import { useResetStationState } from "../station/useResetStationState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createBoxBreakLoginCard } from "../boxBreakCardCreators";
import { ScanPath } from "crossdock/base/routes";
import { useLifecycles } from "react-use";

const BoxBreakFlexContainer = styled.div<{ thinNavbar: boolean }, DefaultTheme>(
  ({ theme, thinNavbar }) => `
  display: flex;
  position: relative;
  height: calc(100vh - ${thinNavbar ? "50px" : "70px"}); // height of screen minus header
  margin-right: -.65rem;
  padding-bottom: ${theme.spacing.S4};
`
);

let unlisten;

export const BoxBreak: React.FC = () => {
  const headerSubtitle = useRecoilValue(headerSubtitleState);
  const boxBreakProps = useBoxBreakPage();
  const { resetCurrentBreak } = useResetCurrentBreakState();
  const { addFlowCard } = useScanFlow();
  const resetStationState = useResetStationState();
  const useThinNavbar = !headerSubtitle || headerSubtitle.length === 0;
  const history = useHistory();

  // if user tries to get back to box break home from the menu while in the middle of a break
  // make sure the whole flow resets without re-rendering
  useLifecycles(
    () => {
      unlisten = history.listen((location) => {
        if (location.pathname === ScanPath.BoxBreak) {
          resetCurrentBreak();
          resetStationState();
          addFlowCard(createBoxBreakLoginCard({}));
        }
      });
    },
    () => {
      unlisten();
    }
  );
  useEffect(() => {}, [history]);

  return (
    <BoxBreakFlexContainer thinNavbar={useThinNavbar}>
      <BoxBreakBoxContentsOverview />
      <ScanFlowV2 {...boxBreakProps}></ScanFlowV2>
      <BoxBreakChuteSidebar />
    </BoxBreakFlexContainer>
  );
};
