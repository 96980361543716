import { useIntl } from "react-intl";
import labels from "./PrintPalletLabelCard.labels";
import { useRecoilValue } from "recoil";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { usePrintZpl } from "@deliverr/ui-facility";
import { printPdf, isZebraScanner, log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { crossdockClient } from "crossdock/base/Clients";
import { useState } from "react";
import { MAX_INBOUND_LABEL_COUNT } from "./printInboundPalletLabels.constants";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { usePrintInboundPalletLabelsNotification } from "./usePrintInboundPalletLabelsNotification";

const DEFAULT_PALLET_COUNT = "0";

export function usePrintPalletLabel() {
  const { successResponse, handleUnknownError } = useScanFlow();
  const { formatMessage } = useIntl();
  const { printZpl } = usePrintZpl();
  const { warehouseId } = useRecoilValue(userState);
  const [palletCount, setPalletCount] = useState<string>(DEFAULT_PALLET_COUNT);
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { showInboundPalletLabelsGeneratedSuccessNotification } = usePrintInboundPalletLabelsNotification();

  const updatePalletCount = (eventValue) => {
    setPalletCount(eventValue.target.value);
  };

  const generateAndPrintInboundPalletLabel = async (palletCountNum: number): Promise<void> => {
    if (isZebraScanner) {
      const doc = await crossdockClient.generateInboundPalletLabelZpl(palletCountNum, warehouseId);
      await printZpl(doc);
    } else {
      const doc = await crossdockClient.generateInboundPalletLabelPdf(palletCountNum, warehouseId);
      printPdf(doc);
    }
  };

  const requestInboundPalletLabels = async (): Promise<void> => {
    const ctx = logStart({ fn: "requestInboundPalletLabels", palletCount, warehouseId });
    log(ctx, "getting inbound pallet label data");

    const palletCountNum = Number(palletCount);

    // shouldn't happen ever due to restriction in NumericInput
    if (Number.isNaN(palletCountNum)) {
      inputError(formatMessage(labels.notANumber));
      return;
    }

    if (palletCountNum > MAX_INBOUND_LABEL_COUNT) {
      inputError(formatMessage(labels.limitError, { maxLabelCount: MAX_INBOUND_LABEL_COUNT }));
      return;
    }

    if (palletCountNum <= 0) {
      inputError(formatMessage(labels.zeroNumberError));
      return;
    }

    try {
      await generateAndPrintInboundPalletLabel(palletCountNum);

      log(ctx, "generated inbound pallet label");
      successResponse();
      showInboundPalletLabelsGeneratedSuccessNotification(palletCountNum);
      setPalletCount(DEFAULT_PALLET_COUNT);
    } catch (e) {
      handleUnknownError(ctx, e);
    }
  };

  return {
    requestInboundPalletLabels: loadWhilePending(resetErrorOnExecution(requestInboundPalletLabels)),
    value: palletCount,
    palletCount: Number(palletCount),
    updatePalletCount,
    loading,
    inputErrorMessage,
  };
}
