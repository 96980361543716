import React from "react";
import { useIntl } from "react-intl";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { SEND_TO_FULFILLMENT_LABELS } from "./FreightArrivalSendToFulfillmentCard.labels";
import { useMount } from "react-use";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

export const FreightArrivalSendToFulfillmentCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { showFlowButtons } = useScanFlow();

  useMount(() => {
    showFlowButtons(ScanFlowButtonType.FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT_DONE_BUTTON);
  });

  return (
    <FlowCard
      accentColor="YELLOW"
      icon="exclamation-circle"
      size="lg"
      title={formatMessage(SEND_TO_FULFILLMENT_LABELS.title)}
      message={formatMessage(SEND_TO_FULFILLMENT_LABELS.message)}
    />
  );
};
