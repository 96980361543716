import { defineMessages } from "react-intl";

export const SEND_TO_FULFILLMENT_LABELS = defineMessages({
  title: {
    id: "crossdock.startProcessingSendToFulfillmentModal.title",
    defaultMessage: "Send to Fulfillment Center",
  },
  message: {
    id: "crossdock.startProcessingSendToFulfillmentModal.message",
    defaultMessage:
      "Pallet belongs to a direct shipment. " + "Do not process. " + "Send to fulfillment center receiving.",
  },
});
