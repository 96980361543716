import React from "react";
import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { IconToIcon } from "crossdock/common/components";
import { FormattedMessage } from "react-intl";

export const RemoveContainerSuccessCard: React.FC = () => (
  <TransitionCard
    title={<FormattedMessage id="crossdock.removeContainerSuccessCard.title" defaultMessage="Remove Full Container" />}
    message={
      <FormattedMessage
        id="crossdock.removeContainerSuccessCard.message"
        defaultMessage="Close the container and place it on a pallet for sorting"
      />
    }
  >
    <IconToIcon
      leftIconProps={{
        iconType: "package",
        accentColor: "BLUE",
      }}
      rightIconProps={{
        iconType: "truck",
        accentColor: "BLUE",
      }}
    />
  </TransitionCard>
);
