import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface SortByWarehouseCardProps {
  cdsku: string;
  warehouseId: string;
  newest?: boolean;
}

export const SortByWarehouseCard: React.FC<SortByWarehouseCardProps> = ({ warehouseId, cdsku, newest }) => (
  <TextInputCard
    title={`Attach new label; sort box to ${warehouseId}`}
    value={cdsku}
    accentColor="BLUE"
    icon="arrow-right"
    newest={newest}
    disabled
  />
);
