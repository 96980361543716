import { atom, selector } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { BoxLabelIncident, BoxLabelIncidentDsku } from "@deliverr/commons-clients";
import { sortIncidentItemsByLastScanned } from "./utils/sortIncidentItemsByLastScanned";

export const missingLabelCountState = atom<number>({
  key: CrossdockStateKey.MISSING_LABEL_COUNT,
  default: 1,
});

export const incidentState = atom<BoxLabelIncident | undefined>({
  key: CrossdockStateKey.BOX_LABEL_INCIDENT,
  default: undefined,
});

export const scannedBarcodeOrderState = atom<string[]>({
  key: CrossdockStateKey.MISSING_LABEL_SCANNED_BARCODE_ORDER,
  default: [],
});

export const sortedIncidentDskusValue = selector<BoxLabelIncidentDsku[]>({
  key: CrossdockStateKey.MISSING_LABEL_SORTED_INCIDENT_DSKUS,
  get: ({ get }) => {
    const scannedBarcodeOrder = get(scannedBarcodeOrderState);
    const incident = get(incidentState);
    return sortIncidentItemsByLastScanned(scannedBarcodeOrder, incident);
  },
});

export const showAddButtonState = atom<boolean>({
  key: CrossdockStateKey.MISSING_LABEL_SHOW_ADD_SKU,
  default: false,
});

export const allSkusHaveQtyValue = selector<boolean>({
  key: CrossdockStateKey.MISSING_LABEL_ALL_SKUS_HAVE_QTY,
  get: ({ get }) => {
    const incidentDskus = get(sortedIncidentDskusValue);
    return incidentDskus.every(({ qty }) => Boolean(qty && qty > 0));
  },
});
