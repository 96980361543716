import React from "react";
import { useIntl } from "react-intl";

import { ProblemQuitModal } from "../components/modals/ProblemQuitModal/ProblemQuitModal";

export const BoxAuditQuitModal: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ProblemQuitModal
      title={formatMessage({ id: "crossdock.boxAuditQuitModal.title", defaultMessage: "Quitting audit" })}
    />
  );
};
