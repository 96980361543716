import React from "react";
import { FormattedMessage } from "react-intl";

import { FixedWidthButton } from "@deliverr/ui-facility";

export interface ReportManuallyButtonProps {
  onReportManuallyClick: () => void;
}

export const ReportManuallyButton: React.FC<ReportManuallyButtonProps> = ({ onReportManuallyClick }) => (
  <FixedWidthButton width="134px" secondary onClick={onReportManuallyClick}>
    <FormattedMessage id="crossdock.reportManuallyButton.reportProblem" defaultMessage="Report problem" />
  </FixedWidthButton>
);
