import { useRecoilValue } from "recoil";

import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { useCrossdockModal } from "crossdock/common/modal";
import { scannedBoxAuditState } from "../BoxAuditState";
import { useResetBoxAuditState } from "../useResetBoxAuditState";
import { useBoxAuditConfirmAudit } from "../BoxAuditConfirmAuditButton";

export function useBoxAuditPrintingCaseLabelModal() {
  const boxAudit = useRecoilValue(scannedBoxAuditState);
  const { restartBoxAudit } = useResetBoxAuditState();
  const { hideAllModals } = useCrossdockModal();

  const { printAuditedLabel } = useBoxAuditConfirmAudit();

  const onCompleteClick = async () => {
    hideAllModals();
    restartBoxAudit();
  };

  const onReprintClick = async () => {
    log(
      { fn: "crossdock.useBoxAuditPrintingCaseLabelModal.onReprintClick", cdsku: boxAudit.cdsku },
      "reprinting audited box cross dock label"
    );
    await printAuditedLabel();
    await onCompleteClick();
  };

  return { onCompleteClick, onReprintClick };
}
