import * as React from "react";
import styled from "@emotion/styled";
import { DefaultTheme, IconV2, Text } from "@deliverr/ui";
import { TextAlign, ThemeProps } from "@deliverr/ui/lib-esm/shared";
import { FormattedMessage, FormattedPlural } from "react-intl";

import { getAccentColorValue } from "@deliverr/ui-facility/lib-facility/flow";
import { SpacedIcon } from "crossdock/common/components";

const BoxContentsItemContainer = styled.div<{ activeDsku: boolean; completedDsku: boolean }, DefaultTheme>(
  ({ theme, activeDsku, completedDsku }) => `
  width: 100%;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
  border-radius: ${theme.border.radius.R5};
  
  background-color: ${
    activeDsku ? theme.colors.BLUE["500"] : completedDsku ? theme.colors.NEUTRAL["00"] : theme.colors.NEUTRAL["40"]
  };
  padding: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S4};
  align-items: center;
`
);

const BoxContentsText = styled(Text)<{ activeDsku: boolean }, DefaultTheme>`
  ${({ activeDsku, theme }) => (activeDsku ? `color: ${theme.colors.NEUTRAL["00"]}` : "")};
`;

const FlexContainer = styled.div<ThemeProps>`
  margin: 0 0 ${({ theme }) => theme.spacing.S2} 0;
  display: flex;
  justify-content: space-between;
`;

const OverageOrUnexpectedSkuContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors[theme.config.colorByAppearance.PRIMARY]["50"]};
  background-color: ${theme.colors[theme.config.colorByAppearance.PRIMARY]["50"]};
  margin: 0 -${theme.spacing.S4} -${theme.spacing.S4};
  padding: ${theme.spacing.S1} 0;
  color: ${theme.colors.NEUTRAL["300"]};
  font-size: ${theme.font.size.F2};
`
);

interface BoxBreakBoxContentsItemProps {
  scannedUnits?: number;
  totalUnits?: number;
  dsku: string;
  barcode: string;
  activeDsku: boolean;
  unexpected?: boolean;
}

export const BoxBreakBoxContentsItem: React.FC<BoxBreakBoxContentsItemProps> = ({
  scannedUnits = 0,
  totalUnits = 0,
  dsku,
  barcode,
  activeDsku,
  unexpected = false,
}) => {
  const overage = scannedUnits - totalUnits;
  return (
    <BoxContentsItemContainer completedDsku={overage >= 0} activeDsku={activeDsku}>
      <FlexContainer>
        <BoxContentsText activeDsku={activeDsku} appearance="INFO" size="label">
          {dsku}
        </BoxContentsText>
        {scannedUnits >= totalUnits ? <IconV2 type="check-circle" color={getAccentColorValue("GREEN")} /> : null}
      </FlexContainer>
      <FlexContainer>
        <BoxContentsText activeDsku={activeDsku} bold size="bodyLarge">
          {barcode}
        </BoxContentsText>
        <BoxContentsText
          activeDsku={activeDsku}
          textAlign={TextAlign.end}
        >{`${scannedUnits}/${totalUnits}`}</BoxContentsText>
      </FlexContainer>
      {overage > 0 || unexpected ? (
        <OverageOrUnexpectedSkuContainer>
          <SpacedIcon type="info-circle" />
          {unexpected ? (
            <FormattedMessage id="crossdock.boxBreaksBoxContentsItem.unexpectedSku" defaultMessage="Unexpected SKU" />
          ) : (
            <FormattedPlural
              one={
                <FormattedMessage
                  id="crossdock.boxBreaksBoxContentsItem.overageSingle"
                  defaultMessage="{overage} overage unit"
                  values={{ overage }}
                />
              }
              other={
                <FormattedMessage
                  id="crossdock.boxBreaksContentsItem.overageMultiple"
                  defaultMessage="{overage} overage units"
                  values={{ overage }}
                />
              }
              value={overage}
            />
          )}
        </OverageOrUnexpectedSkuContainer>
      ) : null}
    </BoxContentsItemContainer>
  );
};
