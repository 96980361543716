import React from "react";
import { useRecoilState } from "recoil";
import { ReviewSkusCard, ReviewSkusCardProps } from "@deliverr/ui-facility";
import { missingLabelCountState } from "../MissingLabelState";

/**
 * Component wrapper that renders the `ReviewSkusCard` component with`missingLabelCountState` Recoil
 * state to keep track of the number of missing labels. This was created after encountering issues
 * with passing state setter and value as props from a custom hook via the createFlowCard flow.
 */
export const MissingLabelReviewSkusCard: React.FC<ReviewSkusCardProps> = (props) => {
  const [missingLabelsCount, setMissingLabelsCount] = useRecoilState(missingLabelCountState);

  const updateLabelsCount = (newCount: number) => {
    setMissingLabelsCount(newCount);
  };

  return <ReviewSkusCard {...props} labelCount={missingLabelsCount} setLabelCount={updateLabelsCount} />;
};
