import React from "react";
import { ModalTemplate, dangerModalIcon, FixedWidthButton } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { useIntl } from "react-intl";
import { NO_CASE_SCAN_FOR_PALLET_LABELS } from "./NoCaseScanForPalletModal.labels";

interface NoCaseScanForPalletModalProps {
  lastOpenedPalletId: string;
  lastOpenedPalletAt: Date;
  submitStartProcessingPalletOverrideCaseUnloadedCheck: () => Promise<void>;
}

const buttonWidth = "128px";

export const NoCaseScanForPalletModal: React.FC<NoCaseScanForPalletModalProps> = ({
  lastOpenedPalletId,
  lastOpenedPalletAt,
  submitStartProcessingPalletOverrideCaseUnloadedCheck,
}) => {
  const { hideAllModals } = useCrossdockModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.title)}
      text={formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.message, {
        lastOpenedPalletId,
        lastOpenedPalletAt: new Date(lastOpenedPalletAt).toLocaleString(),
      })}
      iconProps={dangerModalIcon}
    >
      <FixedWidthButton width={buttonWidth} onClick={hideAllModals} secondary>
        {formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.cancelButton)}
      </FixedWidthButton>
      <FixedWidthButton width={buttonWidth} onClick={submitStartProcessingPalletOverrideCaseUnloadedCheck}>
        {formatMessage(NO_CASE_SCAN_FOR_PALLET_LABELS.overrideButton)}
      </FixedWidthButton>
    </ModalTemplate>
  );
};
