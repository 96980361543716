import React, { useState } from "react";
import { ScanPath } from "crossdock/base/routes/ScanPath";
import { IconV2 } from "@deliverr/ui";
import { AltMenu } from "./AltMenu";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { useRecoilValue } from "recoil";
import { activeAltMenuState } from "./altMenuState";

interface Props {
  path: ScanPath;
}

export const AltMenuIcon: React.FC<Props> = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const activeAltMenu = useRecoilValue(activeAltMenuState);

  if (!activeAltMenu?.icon) {
    return <div />;
  }

  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setShowMenu(!showMenu);
        }}
      >
        <IconV2 type={activeAltMenu.icon} color={FACILITY_THEME.colors.NEUTRAL["00"]} />
      </button>
      {showMenu && (
        <AltMenu
          {...activeAltMenu}
          hideMenu={() => {
            setShowMenu(false);
          }}
        />
      )}
    </div>
  );
};
