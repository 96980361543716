import { defineMessages } from "react-intl";

export const REMOVE_CONTAINER_LABELS = defineMessages({
  titleSpecificChute: {
    id: "crossdock.removeContainerCard.titleSpecificChute",
    defaultMessage: "Scan Container on Chute {letter}",
  },
  titleAnyChute: {
    id: "crossdock.removeContainerCard.titleAnyChute",
    defaultMessage: "Scan Full Container",
  },
  message: {
    id: "crossdock.removeContainerCard.message",
    defaultMessage: "Scan the full container to remove it",
  },
  errorContainerNotLinkedToChute: {
    id: "crossdock.removeContainerCard.errorContainerNotLinkedToChute",
    defaultMessage: "Scanned container is not linked to a chute",
  },
  errorChuteEmpty: {
    id: "crossdock.removeContainerCard.errorChuteEmpty",
    defaultMessage: "Scanned chute has no container to remove",
  },
  errorEmptyContainer: {
    id: "crossdock.removeContainerCard.errorEmptyContainer",
    defaultMessage: "Scanned container does not contain any units",
  },
});
