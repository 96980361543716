import { useResetRecoilState } from "recoil";
import {
  currentBoxBarcodeState,
  boxUnitTransfersState,
  currentRunDestinationState,
  lastUnitScannedState,
  currentUnitBarcodeState,
  boxContentsAllUnitsDistributionByDskuState,
  currentUnitsScannedCountState,
  showedEndBreakModalState,
  dskuToProductBarcodesState,
} from "../boxBreakState";
import { useUpdateBoxBreakHeader } from "../../useUpdateBoxBreakHeader";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { selectedSidebarChuteIdState, activeDskuValue } from "../sidebar/boxBreakSidebarState";
import { bulkQtyTransferredState, singleSkuBoxProductBarcodeState, singleSkuBoxDskuState } from "../../bulk/bulkState";

export function useResetCurrentBreakState() {
  const resetCurrentBoxBarcode = useResetRecoilState(currentBoxBarcodeState);
  const resetBoxUnitTransfers = useResetRecoilState(boxUnitTransfersState);
  const resetCurrentRunDestination = useResetRecoilState(currentRunDestinationState);
  const resetLastUnitScanned = useResetRecoilState(lastUnitScannedState);
  const resetSelectedSidebarChuteId = useResetRecoilState(selectedSidebarChuteIdState);
  const resetUnitBarcode = useResetRecoilState(currentUnitBarcodeState);
  const { setBoxBreakHeader } = useUpdateBoxBreakHeader();
  const resetCurrentUnitsScannedCount = useResetRecoilState(currentUnitsScannedCountState);
  const resetBoxContentsAllUnitsDistributionByDskuState = useResetRecoilState(
    boxContentsAllUnitsDistributionByDskuState
  );
  const resetShowedEndBreakModalState = useResetRecoilState(showedEndBreakModalState);
  const resetBulkQtyTransferred = useResetRecoilState(bulkQtyTransferredState);
  const resetSingleSkuBoxProductBarcode = useResetRecoilState(singleSkuBoxProductBarcodeState);
  const resetSingleSkuBoxDsku = useResetRecoilState(singleSkuBoxDskuState);
  const resetDskuToProductBarcodes = useResetRecoilState(dskuToProductBarcodesState);
  const resetActiveDsku = useResetRecoilState(activeDskuValue);
  const { hideAllFlowButtons } = useScanFlow();

  const resetCurrentUnitRun = (): void => {
    resetCurrentRunDestination();
    resetLastUnitScanned();
    resetCurrentUnitsScannedCount();
    resetActiveDsku();
  };

  const resetCurrentBreak = (): void => {
    resetCurrentUnitRun();
    resetBoxUnitTransfers();
    resetCurrentBoxBarcode();
    setBoxBreakHeader();
    resetSelectedSidebarChuteId();
    hideAllFlowButtons();
    resetUnitBarcode();
    resetBoxContentsAllUnitsDistributionByDskuState();
    resetShowedEndBreakModalState();
    resetBulkQtyTransferred();
    resetSingleSkuBoxProductBarcode();
    resetSingleSkuBoxDsku();
    resetDskuToProductBarcodes();
  };

  return {
    resetCurrentUnitRun,
    resetCurrentBreak,
  };
}
