import { compact } from "lodash/fp";
import { BoxLabelIncident, BoxLabelIncidentDsku } from "@deliverr/commons-clients";

export const sortIncidentItemsByLastScanned = (
  barcodeOrder: string[],
  incident?: BoxLabelIncident
): BoxLabelIncidentDsku[] => {
  const incidentDskus = incident?.dskus ?? [];
  const sortedIncidentDskus = barcodeOrder.map((barcodeToFind) =>
    incidentDskus.find(({ barcode }) => barcode === barcodeToFind)
  );
  return compact(sortedIncidentDskus);
};
