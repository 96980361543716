import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { atom } from "recoil";
import { Dims, NonComplianceImagesMetadata, Product } from "@deliverr/crossdock-service-client";

export const fcDimsAndWeightProductBarcodeState = atom<string>({
  key: CrossdockStateKey.FC_DIMS_AND_WEIGHT_PRODUCT_BARCODE,
  default: "",
});

export const fcDimsAndWeightProductState = atom<Product | undefined>({
  key: CrossdockStateKey.FC_DIMS_AND_WEIGHT_PRODUCT,
  default: undefined,
});

export const fcDimsAndWeightProductDimsState = atom<Dims | undefined>({
  key: CrossdockStateKey.FC_DIMS_AND_WEIGHT_PRODUCT_DIMS,
  default: undefined,
});

export const fcDimsAndWeightCubiscanImagesState = atom<NonComplianceImagesMetadata>({
  key: CrossdockStateKey.FC_DIMS_AND_WEIGHT_CUBISCAN_IMAGES,
  default: {} as NonComplianceImagesMetadata,
});
