import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "crossdock.freightArrivalEnterPoNumCard.title",
    defaultMessage: "Enter PO number",
  },
  placeholder: {
    id: "crossdock.freightArrivalEnterPoNumCard.placeholder",
    defaultMessage: "PO number",
  },
  invalidInput: {
    id: "crossdock.freightArrivalEnterPoNumCard.invalidInput",
    defaultMessage: "PO# has an invalid number of digits",
  },
  doesNotExist: {
    id: "crossdock.freightArrivalEnterPoNumCard.doesNotExist",
    defaultMessage: "PO# {poNum} not found",
  },
  poNotFound: {
    id: "crossdock.freightArrivalEnterPoNumCard.poNotFound",
    defaultMessage: "PO# not found",
  },
});
