import { useSetRecoilState, useRecoilState, useResetRecoilState } from "recoil";
import { fromPairs, keyBy, omit } from "lodash/fp";
import { Chute, ChuteBox } from "@deliverr/commons-clients";
import { crossdockClient } from "crossdock/base/Clients";
import { ChuteBoxByBarcode } from "../../station/ChuteBoxByBarcode";
import { chuteByBarcodeState, chuteBoxByBarcodeState, emptyChuteState } from "../../station/stationState";
import { unitTransfersByChuteBoxState } from "../boxBreakState";

// Updates state related to chutes and chute boxes (containers).
export function useBoxBreakChute() {
  const [emptyChute, setEmptyChute] = useRecoilState(emptyChuteState);
  const resetEmptyChute = useResetRecoilState(emptyChuteState);
  const [currentChuteByBarcode, setChuteByBarcode] = useRecoilState(chuteByBarcodeState);
  const setChuteBoxByBarcode = useSetRecoilState(chuteBoxByBarcodeState);
  const setUnitTransfersByChuteBox = useSetRecoilState(unitTransfersByChuteBoxState);

  // For each chute, refreshes that chute, the container/chutebox on it,
  // and the unit transfers for that container
  const refreshChutes = async (chutes: Chute[]) => {
    const chuteByBarcode = keyBy("barcode", chutes);
    setChuteByBarcode((prev) => ({ ...prev, ...chuteByBarcode }));

    const chuteBoxPairRequests = chutes.map(async ({ barcode }) => [
      barcode,
      await crossdockClient.getBoxLinkedToChute(barcode),
    ]);

    const chuteBoxPairs = (await Promise.all(chuteBoxPairRequests)).filter(([, chuteBox]) => !!chuteBox) as (
      | string
      | ChuteBox
    )[][];
    const chuteBoxByBarcode: ChuteBoxByBarcode = Object.fromEntries(chuteBoxPairs);
    setChuteBoxByBarcode((prev) => ({ ...prev, ...chuteBoxByBarcode }));

    // set overall state of chute containers for sidebar display
    const chuteBoxBarcodeArray = chuteBoxPairs
      .filter((chute) => !!(chute[1] as ChuteBox)?.cdsku)
      .map((chute) => (chute[1] as ChuteBox).cdsku);

    const chuteBoxUnitTransfersRequests = chuteBoxBarcodeArray.map(async (chuteCdsku) => [
      chuteCdsku,
      await crossdockClient.listTransferredUnits({ toCdsku: chuteCdsku }),
    ]);
    const chuteBoxUnitTransfers = fromPairs(await Promise.all(chuteBoxUnitTransfersRequests));
    setUnitTransfersByChuteBox((prev) => ({ ...prev, ...chuteBoxUnitTransfers }));

    // if the empty chute is in the list of chutes, clear it
    if (chutes.some((chute) => chute.barcode === emptyChute?.barcode)) {
      resetEmptyChute();
    }

    return {
      chuteByBarcode,
      chuteBoxByBarcode,
      chuteBoxUnitTransfers,
    };
  };

  const refreshAllChutes = async () => {
    const chutes = Object.values(currentChuteByBarcode);
    return await refreshChutes(chutes);
  };

  // Clears of chute of data when we remove a container from that chute.
  const clearChute = (chute: Chute): void => {
    setChuteByBarcode(omit([chute.barcode]));
    setChuteBoxByBarcode(omit([chute.barcode]));
    setUnitTransfersByChuteBox(omit([chute.barcode]));
    setEmptyChute(chute);
  };

  return {
    refreshChutes,
    refreshAllChutes,
    clearChute,
  };
}
