import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilState, useResetRecoilState } from "recoil";
import { inboundPalletLabelBarcodeState } from "./MovePalletState";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { createScanStagingLaneCard } from "../InboundPalletLabelCardCreators";
import { validatePalletBarcode } from "../utils/validateInboundPalletBarcode";
import { useIntl } from "react-intl";
import labels from "./MovePallet.labels";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";

export function useScanInboundPallet() {
  const { formatMessage } = useIntl();
  const { successResponse, addFlowCard } = useScanFlow();
  const [inboundPalletLabelBarcode, setInboundPalletLabelBarcode] = useRecoilState(inboundPalletLabelBarcodeState);
  const resetInboundPalletLabelBarcode = useResetRecoilState(inboundPalletLabelBarcodeState);
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();

  const submitInboundPalletLabelScan = async (newInboundPalletLabelBarcode: string): Promise<void> => {
    const ctx = logStart({
      fn: "submitInboundPalletLabelScan",
      inboundPalletLabelBarcode: newInboundPalletLabelBarcode,
    });

    if (!validatePalletBarcode(newInboundPalletLabelBarcode)) {
      log(ctx, "invalid pallet barcode");
      resetInboundPalletLabelBarcode();
      inputError(formatMessage(labels.scanInboundPalletInvalidBarcode));
      return;
    }

    addFlowCard(createScanStagingLaneCard({}));
    successResponse();
  };

  const onInboundPalletLabelChange = onScannerInputChange(
    inboundPalletLabelBarcode,
    setInboundPalletLabelBarcode,
    submitInboundPalletLabelScan,
    "upper"
  );

  return {
    onSubmit: loadWhilePending(resetErrorOnExecution(submitInboundPalletLabelScan)),
    onChange: onInboundPalletLabelChange,
    loading,
    inputErrorMessage,
    inboundPalletLabelBarcode,
  };
}
