import React from "react";
import { LinkedCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface ShipPalletHasTrackingCardProps {
  palletId: string;
  trackingCode: string;
}

export const ShipPalletHasTrackingCard: React.FC<ShipPalletHasTrackingCardProps> = ({ palletId, trackingCode }) => (
  <LinkedCard
    icon="eye"
    accentColor="YELLOW"
    title="Pallet has tracking"
    topValue={palletId}
    bottomValue={trackingCode}
  />
);
