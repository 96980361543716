import { useAuth0 } from "@auth0/auth0-react";
import {
  crossdockClient,
  inboundClient,
  nonComplianceClient,
  productClient,
  warehouseClient,
  s3FileHandlerClient,
  freightClient,
} from "./Clients";

export const useClientsWithAuth = () => {
  const { getAccessTokenSilently } = useAuth0();

  return () => {
    const clientsArray = [
      nonComplianceClient,
      warehouseClient,
      inboundClient,
      productClient,
      crossdockClient,
      s3FileHandlerClient,
      freightClient,
    ];

    const accessToken = getAccessTokenSilently({ audience: "deliverr-api" });
    /* eslint-disable-next-line @typescript-eslint/no-floating-promises */
    accessToken?.then((token) => {
      clientsArray.forEach((client) => client.withAccessToken(token));
    });
    clientsArray.forEach((client) => {
      client.addBeforeEach(async () => {
        await accessToken;
      });
    });
  };
};
