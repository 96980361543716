import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { createMovePalletNowCard } from "../../freightArrivalCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export function useFreightArrivalHighPriorityPalletDoneButton() {
  const { addFlowCard, hideAllFlowButtons } = useScanFlow();

  const onClick = async (): Promise<void> => {
    log({ fn: "useFreightArrivalHighPriorityPalletDoneButton" }, "high priority label applied");
    hideAllFlowButtons();
    addFlowCard(createMovePalletNowCard({}));
  };

  return {
    onClick,
  };
}
