import React from "react";
import { useIntl } from "react-intl";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useAddCaseScanCase } from "./useAddCaseScanCase";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export interface AddCaseScanCaseCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const AddCaseScanCaseCard: React.FC<AddCaseScanCaseCardProps> = ({ disabled, newest }) => {
  const { cdsku, onCdskuChange, submitScanCase } = useAddCaseScanCase();
  const { formatMessage } = useIntl();

  const onSubmit = async () => {
    await submitScanCase(cdsku);
  };

  return (
    <TextInputCard
      onSubmit={onSubmit}
      newest={newest}
      icon="scan"
      accentColor="BLUE"
      title={formatMessage(WarehouseMessages.boxTitleText)}
      message="Scan box to start to link it to a pallet."
      value={cdsku}
      onChange={onCdskuChange}
      disabled={disabled}
      placeholder={formatMessage(WarehouseMessages.boxIdentifierPlaceholder)}
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
    />
  );
};
