import React from "react";

import { ReportManuallyButton } from "../../components/ReportManuallyButton";
import { useBoxAuditReportManuallyButton } from "./useBoxAuditReportManuallyButton";

export const BoxAuditReportManuallyButton: React.FC = () => {
  const { onReportManuallyClick } = useBoxAuditReportManuallyButton();

  return <ReportManuallyButton onReportManuallyClick={onReportManuallyClick} />;
};
