import { useLoading } from "crossdock/common/useLoading";
import { useCrossdockModal } from "crossdock/common/modal";
import { DimsAndWeightModal } from "crossdock/modes/inspection/crossdock-dims-and-weight/modals/DimsAndWeightModal";
import { crossdockClient } from "crossdock/base/Clients";
import { useRecoilValue } from "recoil";
import { crossdockDimsAndWeightSessionState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { useResetCrossdockDimsAndWeightState } from "crossdock/modes/inspection/crossdock-dims-and-weight/hooks/useResetCrossdockDimsAndWeightState";
import { toCrossdockDimsAndWeightPackageCdsku } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";

export const useCrossdockDimsAndWeightEndSession = () => {
  const { loading, loadWhilePending } = useLoading();
  const { showModal, hideModal } = useCrossdockModal();
  const dimsAndWeightSession = useRecoilValue(crossdockDimsAndWeightSessionState);
  const { resetCrossdockDimsAndWeightState } = useResetCrossdockDimsAndWeightState();

  const { transition, hideAllFlowButtons } = useScanFlow();

  const completeDimsAndWeightSession = async () => {
    await crossdockClient.completeDimsAndWeightSession(dimsAndWeightSession!.id);
    resetCrossdockDimsAndWeightState();

    hideAllFlowButtons();
    transition(toCrossdockDimsAndWeightPackageCdsku());
  };

  const onButtonClick = async () => {
    const ctx = logStart({ fn: "onButtonClick#crossdockDimsAndWeightEndSession" });

    const dimsSessionCompletionReport = await crossdockClient.getDimsAndWeightSessionCompletionReport(
      dimsAndWeightSession!.id
    );
    log({ ...ctx }, "dimsSessionCompletionReport");

    if (dimsSessionCompletionReport?.missingItemDskus.length > 0) {
      showModal(DimsAndWeightModal.COMPLETE_SESSION_MODAL, {
        numberOfPendingItems: 1,
        modalCancelClick: () => {
          hideModal(DimsAndWeightModal.COMPLETE_SESSION_MODAL);
        },
        modalEndClick: async () => {
          await completeDimsAndWeightSession();
          hideModal(DimsAndWeightModal.COMPLETE_SESSION_MODAL);
        },
      });
    } else {
      await completeDimsAndWeightSession();
    }
  };

  return {
    onButtonClick: loadWhilePending(onButtonClick),
    loading,
  };
};
