import { IconV2, Navbar } from "@deliverr/ui";
import styled from "@emotion/styled";
import { useRecoilValue } from "recoil";
import React from "react";
import { Link } from "react-router-dom";
import { AppName } from "@deliverr/ui-facility/lib-facility/types";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { AltMenuIcon } from "crossdock/menu/AltMenu";
import { ScanMenuPage } from "crossdock/menu/ScanMenu/ScanMenuPage";
import { FacilityMenuVisibilityToggle } from "@deliverr/ui-facility/lib-facility/components/FacilityMenu";
import { headerTitleState, headerSubtitleState, showPageLeftActionState } from "./headerTitleState";
import { ScanPageProps } from "./ScanPage";

interface ScanHeaderProps extends ScanPageProps {
  appName: AppName;
  defaultMenuPage: ScanMenuPage;
}

// temp until we update dimensions
const ManualHeightNavbar = styled(Navbar)<{ thinNavbar: boolean }>(
  ({ thinNavbar }) => `
  height: ${thinNavbar ? "50px" : "70px"};
  min-height: ${thinNavbar ? "50px" : "70px"};
`
);

export const ScanHeader: React.FC<ScanHeaderProps> = ({
  appName,
  defaultMenuPage,
  getVisibleMenuItems,
  isAdmin,
  leftHeaderAction,
  pageName,
  path,
  previousPage,
  rightHeaderAction,
}) => {
  const headerTitle = useRecoilValue(headerTitleState);
  const headerSubtitle = useRecoilValue(headerSubtitleState);
  const showPageLeftAction = useRecoilValue(showPageLeftActionState);

  const useThinNavbar = !headerSubtitle || headerSubtitle.length === 0;

  const defaultLeftAction = previousPage ? (
    <Link to={previousPage}>
      <IconV2 type="angle-left" color={FACILITY_THEME.colors.BLUE["300"]} />
    </Link>
  ) : (
    <FacilityMenuVisibilityToggle
      appName={appName}
      defaultMenuPage={defaultMenuPage}
      getVisibleMenuItems={getVisibleMenuItems}
      isAdmin={isAdmin}
      previousPage={previousPage}
      shouldShowLanguageSelector={false}
    />
  );

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  const leftAction = (showPageLeftAction && leftHeaderAction) || defaultLeftAction;

  return (
    <ManualHeightNavbar
      leftAction={leftAction}
      title={headerTitle ?? pageName}
      subtitle={headerSubtitle}
      rightAction={rightHeaderAction ?? <AltMenuIcon path={path} />}
      thinNavbar={useThinNavbar}
    />
  );
};
