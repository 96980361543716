import { useIntl } from "react-intl";
import { RadioTileProps } from "@deliverr/ui";
import { YesNoRadioOptions } from "./CrossdockRadioOptions";
import crossdockLabels from "../CrossdockLabels";

export function useCrossdockRadioOptions() {
  const { formatMessage } = useIntl();

  const getRadioProps = <T extends Record<string, string>>(optionsEnum: T): RadioTileProps[] =>
    Object.keys(optionsEnum).map((option) => ({
      value: option,
      label: formatMessage(crossdockLabels[option]),
    }));

  return {
    yesNoRadioOptionsProps: getRadioProps(YesNoRadioOptions),
  };
}
