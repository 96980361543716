import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { useCrossdockDimsAndWeightCancelSession } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightCancelSessionButton/useCrossdockDimsAndWeightCancelSession";

export const CrossdockDimsAndWeightCancelSessionButton: React.FC = () => {
  const { onButtonClick } = useCrossdockDimsAndWeightCancelSession();

  return (
    <Button onClick={onButtonClick} block secondary>
      <FormattedMessage
        id="crossdock.crossdockDimsAndWeightCancelSessionButton.title"
        defaultMessage="Cancel Session"
      />
    </Button>
  );
};
