import { useRecoilValue } from "recoil";
import { useMount } from "react-use";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { inboundPalletLabelIdState } from "../../FreightArrivalState";

export function useFreightArrivalHighPriorityPalletCard() {
  const inboundPalletLabelId = useRecoilValue(inboundPalletLabelIdState);
  const { hideAllFlowButtons, showFlowButton } = useScanFlow();

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET_DONE_BUTTON);
  });

  return { inboundPalletLabelId };
}
