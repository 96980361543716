import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useForm } from "react-hook-form";
import { toast } from "@deliverr/ui";
import { useIntl } from "react-intl";
import { boxAuditUnknownBarcodeState, scannedBoxAuditState } from "../BoxAuditState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useShowAddSku } from "../../hooks";
import { createBoxContentsCard } from "../BoxAuditCardCreators";
import { useBoxAuditSubtitle } from "../useBoxAuditSubtitle";
import { crossdockClient } from "crossdock/base/Clients";

export const useBoxAuditUnknownBarcode = () => {
  const { formatMessage } = useIntl();
  const { showAddSku } = useShowAddSku();
  const { addFlowCard, showFlowButton, successResponse, errorResponse } = useScanFlow();
  const boxAuditUnknownBarcode = useRecoilValue(boxAuditUnknownBarcodeState);
  const resetBoxAuditUnknownBarcode = useResetRecoilState(boxAuditUnknownBarcodeState);
  const updateBoxAudit = useSetRecoilState(scannedBoxAuditState);
  const { setBoxAuditCountsSubtitle } = useBoxAuditSubtitle();

  const formHandler = useForm({
    mode: "onBlur",
  });

  const submitUnknownBarcode = async () => {
    try {
      const updatedAudit = await crossdockClient.addUnknownBarcode(boxAuditUnknownBarcode);
      updateBoxAudit(updatedAudit);
      setBoxAuditCountsSubtitle(updatedAudit);
      successResponse();
      toast.success(
        formatMessage(
          {
            id: "crossdock.boxAuditUnknownBarcode.success",
            defaultMessage: "{barcode} successfully added to audit!",
          },
          {
            barcode: boxAuditUnknownBarcode.barcode,
          }
        ),
        {
          autoClose: 5000,
          toastId: "boxAuditUnknownBarcodeSuccess",
        }
      );
    } catch (err) {
      errorResponse();
      toast.error(
        formatMessage(
          {
            id: "crossdock.boxAuditUnknownBarcode.error",
            defaultMessage: "There was an error adding {barcode} to this audit. Please contact Deliverr.",
          },
          {
            barcode: boxAuditUnknownBarcode.barcode,
          }
        ),
        {
          autoClose: 5000,
          toastId: "boxAuditUnknownBarcodeError",
        }
      );
    } finally {
      addFlowCard(createBoxContentsCard({}));
      showAddSku();
      resetBoxAuditUnknownBarcode();
      showFlowButton(ScanFlowButtonType.BOX_AUDIT_SCANNING_COMPLETE);
    }
  };

  return {
    submitUnknownBarcode,
    formHandler,
  };
};
