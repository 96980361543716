import { Chute, UnitTransfer } from "@deliverr/commons-clients";
import { atom, selector } from "recoil";
import { CrossdockStateKey } from "../../../base/CrossdockStateKey";
import {
  currentBoxBarcodeState,
  currentRunDestinationState,
  unitTransfersByChuteBoxState,
  runTargetCountState,
  boxContentsAllUnitsDistributionByDskuState,
} from "../BoxBreak/boxBreakState";
import { activeDskuValue } from "../BoxBreak/sidebar/boxBreakSidebarState";
import { chuteByWarehouseState, chuteBoxByBarcodeState } from "../station/stationState";

/**
 * Expected DSKU for the only product type in a single SKU box.
 */
export const singleSkuBoxDskuState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_SINGLE_SKU_BOX_DSKU,
  default: "",
});

/**
 * Scanned barcode value for the only product type in a single SKU box.
 */
export const singleSkuBoxProductBarcodeState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_SINGLE_SKU_BOX_PRODUCT_BARCODE,
  default: "",
});

/**
 * Controls whether bulk transfer is an enabled option
 */
export const isSingleSkuBoxValue = selector<boolean>({
  key: CrossdockStateKey.BOX_BREAK_IS_SINGLE_SKU_BOX,
  get: ({ get }) => Boolean(get(singleSkuBoxDskuState)),
});

/**
 * Chute of the current destination
 */
export const destinationChuteValue = selector<Chute>({
  key: CrossdockStateKey.BOX_BREAK_CURRENT_DESTINATION_CHUTE,
  get: ({ get }) => get(chuteByWarehouseState)[get(currentRunDestinationState)!],
});

/**
 * How much of the same sku is being transferred at once.
 * Defaults to the expected amount transferred to a location.
 */
export const bulkQtyTransferredState = atom<number>({
  key: CrossdockStateKey.BOX_BREAK_BULK_QUANTITY_TRANSFERRED,
  default: 1,
});

export const bulkCurrentUnitTransferValue = selector<UnitTransfer | undefined>({
  key: CrossdockStateKey.BOX_BREAK_BULK_CURRENT_UNIT_TRANSFER,
  get: ({ get }) => {
    const destinationChute = get(destinationChuteValue);
    const chuteBox = get(chuteBoxByBarcodeState)?.[destinationChute?.barcode];
    const currentBoxBarcode = get(currentBoxBarcodeState);
    return get(unitTransfersByChuteBoxState)?.[chuteBox?.cdsku]?.find((ut) => ut.fromCdsku === currentBoxBarcode);
  },
});

/**
 * Max number of units that can be bulk transferred. We also default to this value.
 */
export const bulkTransferMaxUnitsValue = selector<number>({
  key: CrossdockStateKey.BOX_BREAK_BULK_TRANSFER_MAX_UNITS,
  get: ({ get }) => {
    const unitTransfer = get(bulkCurrentUnitTransferValue);
    const runTarget = get(runTargetCountState);
    const activeDsku = get(activeDskuValue);
    const boxContentsAllUnitsDistributionByDsku = get(boxContentsAllUnitsDistributionByDskuState);
    const expectedQty = boxContentsAllUnitsDistributionByDsku[activeDsku].totalUnits;

    return Math.min(runTarget ?? 0, expectedQty) - (unitTransfer?.qty ?? 0);
  },
});
