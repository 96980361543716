import { useIntl, defineMessages } from "react-intl";
import { BoxLabelIncident } from "@deliverr/commons-clients";
import { sumBy } from "lodash/fp";
import { useProblemSubtitle } from "../hooks/useProblemSubtitle";

const missingLabelSubtitleMessages = defineMessages({
  poSubtitle: {
    id: "crossdock.missingLabel.poSubtitle",
    defaultMessage: "PO # {po}",
  },
});

export function useMissingLabelSubtitle() {
  const { setProblemSubtitle, setCountsSubtitle } = useProblemSubtitle();
  const { formatMessage } = useIntl();

  const setPoSubtitle = (incident: BoxLabelIncident): void => {
    const po = incident.asnId;
    setProblemSubtitle(formatMessage(missingLabelSubtitleMessages.poSubtitle, { po }));
  };

  // react-intl does not make getting plural i18 strings easy
  const setPoCountsSubtitle = (incident: BoxLabelIncident): void => {
    const po = incident.asnId;
    const skusCount = (incident.dskus ?? []).length;
    const unitsCount = sumBy("qty", incident.dskus ?? []);

    setCountsSubtitle({
      mainSubtitle: po ? formatMessage(missingLabelSubtitleMessages.poSubtitle, { po }) : undefined,
      skusCount,
      unitsCount,
    });
  };

  return {
    setPoSubtitle,
    setPoCountsSubtitle,
  };
}
