import { useRecoilValue } from "recoil";
import { log, logStart, isZebraScanner, printPdf } from "@deliverr/ui-facility/lib-facility/utils";
import { dpiState, usePrintZpl } from "@deliverr/ui-facility/lib-facility/printer";
import { crossdockClient } from "crossdock/base/Clients";

export interface PrepRequiredLabelPrintConfig {
  cdsku?: string;
}
/**
 * Handle functionality related to printing PrepRequired labels. A PrepRequired label
 * is necessary when an Inbound object (Box, ASN, etc) that requires Prep but the prep is not completed.
 * It signals to warehouse employees / Ops team members that the object is not ready for forwarding downstream.
 *
 * @returns function for printing PrepRequired label.
 */
export function usePrintPrepRequiredLabel() {
  const dpi = useRecoilValue(dpiState);
  const { printZpl } = usePrintZpl();

  /**
   * Prints a Prep Required label. If cdsku is supplied, it will include the prepTypes.
   */
  async function printPrepRequiredLabel({ cdsku }: PrepRequiredLabelPrintConfig): Promise<void> {
    const ctx = logStart({ fn: "printPrepRequiredLabel", cdsku });

    if (isZebraScanner) {
      const zpl = await crossdockClient.generatePrepRequiredLabelZpl(cdsku, dpi);
      log(ctx, "retrieved label zpl");
      await printZpl(zpl);
    } else {
      const pdf = await crossdockClient.generatePrepRequiredLabelPdf(cdsku);
      log(ctx, "retrieved label pdf");
      printPdf(pdf);
    }
    log(ctx, "printed label");
  }

  return {
    printPrepRequiredLabel,
  };
}
