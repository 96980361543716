import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import { useDestinationMismatchModal } from "./useDestinationMismatchModal";

export const ShipPalletDestinationMismatchModal: React.FC = () => {
  const { labels, onDoneClick } = useDestinationMismatchModal();

  return (
    <ModalTemplate title={labels.title} text={labels.message} iconProps={warningModalIcon}>
      <Button onClick={onDoneClick}>{labels.button}</Button>
    </ModalTemplate>
  );
};
