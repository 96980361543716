import { Chute } from "@deliverr/commons-clients";
import { FlowTransition, FlowTransitionDuration } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { getVerifyChuteTransition } from "crossdock/modes/break/transitions";
import {
  createBoxBreakScanAnyUnitCard,
  createBoxBreakCountUnexpectedSkuUnitsCard,
  createBoxBreakUnitInChuteCard,
  createBulkTransferCard,
} from "crossdock/modes/break/boxBreakCardCreators";
import {
  getUnitInChuteNotification,
  getNewDskuDetectedNotification,
} from "crossdock/modes/break/boxBreakNotifications";

export const getCountUnitsTransition = (unitBarcode: string): FlowTransition => ({
  card: createBoxBreakCountUnexpectedSkuUnitsCard({ unitBarcode }),
  notification: getNewDskuDetectedNotification(unitBarcode),
  sfx: SoundFx.INFO,
  flash: "DEFAULT",
});

export const getUnitInChuteTransition = (unitBarcode: string, chute: string): FlowTransition => ({
  duration: FlowTransitionDuration.SHORT,
  card: createBoxBreakUnitInChuteCard({ unitBarcode, chute }),
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
});

export const toBulkTransfer = (): FlowTransition => ({
  sfx: SoundFx.SUCCESS,
  flash: "SUCCESS",
  card: createBulkTransferCard({}),
});

export const toNewRunVerifyChute = (unitBarcode: string, chute: Chute): FlowTransition[] => [
  getUnitInChuteTransition(unitBarcode, chute.letter),
  getVerifyChuteTransition({ chute, unitBarcode }),
];

export const toScanAnyUnit = (unitBarcode: string, chuteLetter: string): FlowTransition[] => [
  getUnitInChuteTransition(unitBarcode, chuteLetter),
  {
    card: createBoxBreakScanAnyUnitCard({}),
    notification: getUnitInChuteNotification(unitBarcode, chuteLetter),
  },
];
