import { useState } from "react";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { isValidCdsku } from "@deliverr/ui-facility/lib-facility/utils/cdskuUtils";
import { useMount } from "react-use";
import { useUpdateBoxBreakHeader } from "../../useUpdateBoxBreakHeader";
import { useLinkContainerError } from "../useLinkContainerError";
import { toLinkContainerScanChute } from "../LinkContainer.transitions";
import { useIntl } from "react-intl";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak/BoxBreak.labels";

export function useLinkContainerScanContainer() {
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useLinkContainerError();
  const [containerBarcode, setContainerBarcode] = useState<string>("");
  const resetContainerBarcode = () => {
    setContainerBarcode("");
  };
  const { transition } = useScanFlow();
  const { setLinkContainerHeader } = useUpdateBoxBreakHeader();
  const { formatMessage } = useIntl();

  useMount(() => {
    setLinkContainerHeader();
  });

  const submitLinkContainerScanContainer = async (newContainerBarcode: string): Promise<void> => {
    const ctx = logStart({ fn: "submitLinkContainerScanContainer", containerBarcode });

    if (!isValidCdsku(newContainerBarcode)) {
      log(ctx, "invalid container barcode");
      inputError(formatMessage(bbLabels.errorInvalidCdskuFormat), resetContainerBarcode);
      return;
    }

    log(ctx, "container scanned");
    transition(toLinkContainerScanChute(newContainerBarcode));
  };

  const onContainerBarcodeChange = onScannerInputChange(
    containerBarcode,
    setContainerBarcode,
    submitLinkContainerScanContainer,
    "upper"
  );

  return {
    submitLinkContainerScanContainer: resetErrorOnExecution(submitLinkContainerScanContainer),
    onContainerBarcodeChange,
    containerBarcode,
    errorMessage: inputErrorMessage,
  };
}
