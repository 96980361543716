import { defineMessages } from "react-intl";

export const PALLET_NO_BOXES_LABELS_CLOSE = defineMessages({
  title: {
    id: "crossdock.palletNoBoxesErrorCard.close.title",
    defaultMessage: "Pallet cannot be closed",
  },
  message: {
    id: "crossdock.palletNoBoxesErrorCard.close.message",
    defaultMessage: "This pallet cannot be closed because there are no boxes on it.",
  },
});

export const PALLET_NO_BOXES_LABELS_SHIP = defineMessages({
  title: {
    id: "crossdock.palletNoBoxesErrorCard.ship.title",
    defaultMessage: "Pallet cannot be shipped",
  },
  message: {
    id: "crossdock.palletNoBoxesErrorCard.ship.message",
    defaultMessage: "This pallet cannot be shipped because there are no boxes on it.",
  },
});
