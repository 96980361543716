import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import labels from "./StartProcessingPallet.labels";
import { useStartProcessingPallet } from "./useStartProcessingPallet";

export const StartProcessingPalletCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { inputErrorMessage, value, loading, onChange, onSubmit } = useStartProcessingPallet();

  return (
    <TextInputCard
      accentColor="BLUE"
      icon="scan"
      title={formatMessage(labels.title)}
      placeholder={formatMessage(labels.placeholder)}
      errorMessage={inputErrorMessage}
      value={value}
      disabled={loading}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
