import { FC } from "react";

import { BoxAuditModal } from "./BoxAuditModal";
import { BoxAuditInputBarcodeModal } from "./BoxAuditInputBarcodeModal/BoxAuditInputBarcodeModal";
import { BoxAuditUnexpectedSkuModal } from "./BoxAuditUnexpectedSkuModal";
import { BoxAuditQuitModal } from "./BoxAuditQuitModal";
import { BoxAuditPrintingCaseLabelModal } from "./BoxAuditPrintingCaseLabelModal/BoxAuditPrintingCaseLabelModal";
import { BoxAuditErrorModal } from "./BoxAuditErrorModal";
import { BoxAuditAccurateLabelModal } from "./BoxAuditAccurateLabelModal";

export const boxAuditModalMap: Record<BoxAuditModal, FC<any>> = {
  [BoxAuditModal.BOX_AUDIT_UNEXPECTED_SKU]: BoxAuditUnexpectedSkuModal,
  [BoxAuditModal.BOX_AUDIT_INPUT_BARCODE_MODAL]: BoxAuditInputBarcodeModal,
  [BoxAuditModal.BOX_AUDIT_QUIT]: BoxAuditQuitModal,
  [BoxAuditModal.BOX_AUDIT_PRINT_LABEL]: BoxAuditPrintingCaseLabelModal,
  [BoxAuditModal.BOX_AUDIT_ACCURATE_LABEL]: BoxAuditAccurateLabelModal,
  [BoxAuditModal.BOX_AUDIT_ERROR]: BoxAuditErrorModal,
};
