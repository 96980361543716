import { useRecoilState, useResetRecoilState } from "recoil";
import { CrossdockError } from "@deliverr/commons-clients";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { currentBoxBarcodeState } from "../../boxBreakState";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { isValidCdsku } from "@deliverr/ui-facility/lib-facility/utils/cdskuUtils";
import { UNKNOWN_INPUT_CONTAINER, NOT_FOUND_BOX } from "crossdock/common/flow/inputErrorMessages";
import { useUpdateBoxBreakHeader } from "../../../useUpdateBoxBreakHeader";
import { useMount } from "react-use";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useBoxBreakBox } from "../../hooks/useBoxBreakBox";
import { useBoxBreakStationReadyTransition } from "../../../station/useBoxBreakStationReadyTransition";
import { useGetBBStationReady } from "../../../station/useGetBBStationReady";
import { useBoxBreakChute } from "../../hooks/useBoxBreakChute";

export function useBoxBreakScanBoxCard() {
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { transition, handleUnknownError, hideAllFlowButtons } = useScanFlow();
  const [boxBarcode, setBoxBarcode] = useRecoilState(currentBoxBarcodeState);
  const resetBoxBarcode = useResetRecoilState(currentBoxBarcodeState);
  const { setScanBoxHeader } = useUpdateBoxBreakHeader();
  const { scanBox } = useBoxBreakBox();
  const getBoxBreakStationReadyTransition = useBoxBreakStationReadyTransition();
  const getBBStationReady = useGetBBStationReady();
  const { refreshAllChutes } = useBoxBreakChute();

  useMount(() => {
    setScanBoxHeader();
    hideAllFlowButtons();
  });

  const handleSubmitBoxError = (ctx: any, err: any, newBoxBarcode: string) => {
    switch (err?.code) {
      case CrossdockError.PACKAGE_NOT_FOUND:
        log(ctx, "box not found");
        inputError(NOT_FOUND_BOX, resetBoxBarcode);
        return;
      case CrossdockError.BOX_SHOULD_NOT_BE_BROKEN:
        log(ctx, "box should not be broken");
        inputError(`Box ${newBoxBarcode} should be linked to a pallet.`, resetBoxBarcode);
        return;
      case CrossdockError.BOX_NOT_RECEIVE_SCANNED:
        log(ctx, "box should be receive scanned");
        inputError(`Box ${newBoxBarcode} not yet receive scanned.`, resetBoxBarcode);
        return;
      default:
        handleUnknownError(ctx, err, resetBoxBarcode);
    }
  };

  const submitBoxBarcode = async (newBoxBarcode: string): Promise<void> => {
    const ctx = logStart({ fn: "submitBoxBarcode", boxBarcode: newBoxBarcode });

    if (!isValidCdsku(newBoxBarcode)) {
      log(ctx, "invalid container barcode");
      inputError(UNKNOWN_INPUT_CONTAINER, resetBoxBarcode);
      return;
    }

    try {
      await scanBox(newBoxBarcode);
      await refreshAllChutes();
      log(ctx, "box scanned");

      transition(await getBoxBreakStationReadyTransition(await getBBStationReady(newBoxBarcode)));
    } catch (err) {
      handleSubmitBoxError(ctx, err, newBoxBarcode);
    }
  };

  const onBoxBarcodeChange = onScannerInputChange(boxBarcode, setBoxBarcode, submitBoxBarcode, "upper");

  return {
    boxBarcode,
    onBoxBarcodeChange,
    submitBoxBarcode: resetErrorOnExecution(submitBoxBarcode),
    errorMessage: inputErrorMessage,
  };
}
