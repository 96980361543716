import React from "react";

import { InputBarcodeModal } from "@deliverr/ui-facility/lib-facility/components/modals";
import { useInputBarcodeModal } from "./useInputBarcodeModal";

export const MissingLabelInputBarcodeModal: React.FC = () => {
  const { barcode, onSubmit, onChange, onModalExit, onNextClick, errorMessage } = useInputBarcodeModal();

  return (
    <InputBarcodeModal
      barcode={barcode}
      onSubmit={onSubmit}
      onChange={onChange}
      onModalExit={onModalExit}
      onNextClick={onNextClick}
      errorMessage={errorMessage}
    />
  );
};
