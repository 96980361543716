import React from "react";
import { IconV2, ThemeProps } from "@deliverr/ui";
import { useExitButton } from "./useExitButton";
import styled from "@emotion/styled";

export const IconContainer = styled.button<ThemeProps>(
  ({ theme }) => `
  color: ${theme.colors.BLUE[300]};
`
);

export interface ModalExitButtonProps {
  modalId: string;
}

export const ModalExitButton: React.FC<ModalExitButtonProps> = ({ modalId }) => {
  const { onClick } = useExitButton(modalId);

  return (
    <IconContainer onClick={onClick}>
      <IconV2 type="close" />
    </IconContainer>
  );
};
