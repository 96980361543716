import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { BoxLabelIncident } from "@deliverr/commons-clients";

import { ReviewSkusCardProps } from "@deliverr/ui-facility";
import { incidentState, scannedBarcodeOrderState, sortedIncidentDskusValue } from "./MissingLabelState";
import { createReviewSkusCard } from "./MissingLabelCardCreators";
import { useCrossdockModal } from "crossdock/common/modal";
import { MissingLabelModal } from "./MissingLabelModal";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { usePrintCrossdockLabel } from "crossdock/modes/caseScanner/usePrintCrossdockLabel";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { nonComplianceClient as ncClient } from "crossdock/base/Clients";
import { parseIncidentReason } from "./parseIncidentReason";
import { InvalidDataError } from "crossdock/common/errors/InvalidDataError";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { findMatchingIncidentItem } from "./utils/findMatchingIncidentItem";
import { addMostRecent } from "@deliverr/ui-facility/lib-facility/utils/immutabilityUtils";
import { useMissingLabelSubtitle } from "./useMissingLabelSubtitle";
import { useShowAddSku } from "../hooks";
import { showBackButtonState } from "../ProblemState";
import { mapIncidentItemsToBoxContents } from "./MissingLabelBoxContentsCard/useBoxContents";
import { MAX_LABELS_TO_PRINT } from "./MaxLabelsToPrint";

export function useSolveBoxLabelIncident() {
  const { addFlowCard, handleUnknownError, showFlowButton, hideAllFlowButtons } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const [incident, updateIncident] = useRecoilState(incidentState);
  const { printNcCrossdockLabel } = usePrintCrossdockLabel();
  const setScannedBarcodeOrder = useSetRecoilState(scannedBarcodeOrderState);
  const { hideAddSku } = useShowAddSku();
  const setShowBackButton = useSetRecoilState(showBackButtonState);
  const { setPoCountsSubtitle } = useMissingLabelSubtitle();

  const items = mapIncidentItemsToBoxContents(useRecoilValue(sortedIncidentDskusValue) ?? []);

  // null = no box info
  const createIncident = async (boxInfo: string | null): Promise<BoxLabelIncident> => {
    const reason = parseIncidentReason(window.location.pathname);
    logStart({ fn: "createIncident", reason });

    // avoid recreating if one already exists, but update failed
    const incidentId = incident?.id ?? (await ncClient.createBoxLabelIncident({ reason })).id;
    const updatedIncident = await ncClient.updateBoxLabelIncidentWithBoxInfo(incidentId, boxInfo ?? null);
    updateIncident(updatedIncident);
    return updatedIncident;
  };

  const setIncidentDskuQty = async (barcode: string, qty?: number): Promise<BoxLabelIncident> => {
    if (!incident) {
      throw new InvalidDataError("Missing box label incident");
    }
    const matchingItem = findMatchingIncidentItem(barcode, incident);
    const updatedQty = qty ?? (matchingItem?.qty ?? 0) + 1;

    const updatedIncident = await ncClient.setBoxLabelIncidentDsku(incident.id, { barcode, qty: updatedQty });

    if (!qty && qty !== 0) {
      // only want to update this for scans, not qty adjustments
      setScannedBarcodeOrder(addMostRecent(barcode));
    }
    setPoCountsSubtitle(updatedIncident);
    updateIncident(updatedIncident);

    return updatedIncident;
  };

  const setLabelCount = async (labelCount: number, manualIncident?: BoxLabelIncident): Promise<BoxLabelIncident> => {
    if (!manualIncident && !incident) {
      throw new InvalidDataError("Missing box label incident");
    }
    const updatedIncident = await ncClient.updateBoxLabelIncident((manualIncident ?? incident)!.id, {
      numPackages: labelCount,
    });
    updateIncident(updatedIncident);
    return updatedIncident;
  };

  const printIncidentLabels = async (incidentToPrint?: BoxLabelIncident, reprint = false): Promise<void> => {
    const ctx = logStart({
      fn: "printIncidentLabels",
      incidentId: incidentToPrint?.id,
      status: incidentToPrint?.status,
    });
    if (!incidentToPrint?.packages || incidentToPrint.packages.length === 0) {
      handleUnknownError(ctx, new InvalidDataError("Missing box label incident packages"));
      return;
    }

    showModal(MissingLabelModal.MISSING_LABEL_PRINTING_LABELS, { reprint });

    for (const { cdsku } of incidentToPrint.packages) {
      await printNcCrossdockLabel(cdsku, reprint);
    }
  };

  const handleDoneScanning = async () => {
    logStart({ fn: "handleDoneScanning", incidentId: incident?.id, status: incident?.status });
    if (!incident?.dskus) {
      throw new InvalidDataError("Missing incident dskus");
    }

    const updatedIncident = await ncClient.updateBoxLabelIncident(incident.id, { numDskus: incident.dskus.length });

    hideAllFlowButtons();

    if (incident.missingBoxInfo) {
      await ncClient.finalizeBoxContentsForSolvingWhenNoBoxInfo(updatedIncident.id);
    }

    let createReviewSkusCardArgs: ReviewSkusCardProps = {
      items,
    };
    // if there is only one dsku the user can print multiple of the same label and we need additional props for that
    if (incident.dskus?.length === 1) {
      createReviewSkusCardArgs = {
        ...createReviewSkusCardArgs,
        minLabels: 1,
        maxLabels: MAX_LABELS_TO_PRINT,
        shouldCollectLabelsCount: true,
      };
    }

    addFlowCard(createReviewSkusCard({ ...createReviewSkusCardArgs }));
    setShowBackButton(true);
    showFlowButton(ScanFlowButtonType.MISSING_LABEL_PRINT_LABELS_BUTTON);
    hideAddSku();
  };

  const solveIncident = async (incidentToSolve: BoxLabelIncident): Promise<BoxLabelIncident> => {
    logStart({ fn: "solveIncident", incidentId: incidentToSolve.id, status: incidentToSolve.status });
    const solvedIncident = await ncClient.solveBoxLabelIncident(incidentToSolve.id, { forceSolve: true });
    updateIncident(solvedIncident);
    return solvedIncident;
  };

  return {
    createIncident,
    setIncidentDskuQty,
    setLabelCount,
    handleDoneScanning,
    solveIncident,
    printIncidentLabels,
  };
}
