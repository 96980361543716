import React from "react";
import { warningModalIcon, PromptModal } from "@deliverr/ui-facility";
import { useShipPalletNotClosedModal } from "./useShipPalletNotClosedModal";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { TransferPallet } from "@deliverr/commons-clients";
import { getPalletItemCountLabel } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";

export interface ShipPalletNotClosedModalProps {
  pallet: TransferPallet;
}
export const ShipPalletNotClosedModal: React.FC<ShipPalletNotClosedModalProps> = ({ pallet }) => {
  const { onCancelClick, onConfirmClick } = useShipPalletNotClosedModal();

  return (
    <PromptModal
      title="Pallet not closed!"
      content={
        <>
          <p>You must close the pallet before you can ship it. Do you want to close the pallet?</p>
          <br />
          <p>{getPalletItemCountLabel(pallet)}</p>
        </>
      }
      iconProps={warningModalIcon}
      soundFx={SoundFx.INFO}
      onConfirmClick={onConfirmClick}
      onCancelClick={onCancelClick}
      confirmButtonLabel="Yes, close the pallet"
      cancelButtonLabel="No, keep the pallet open"
      vertical
    />
  );
};
