import React from "react";

import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { ProblemBackButton } from "../../components/ProblemBackButton/ProblemBackButton";
import { createBoxContentsCard } from "../BoxAuditCardCreators";

export const BoxAuditBackButton: React.FC = () => (
  <ProblemBackButton
    flowCard={createBoxContentsCard({})}
    flowButtonType={ScanFlowButtonType.BOX_AUDIT_SCANNING_COMPLETE}
  />
);
