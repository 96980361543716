import React from "react";
import { DefaultTheme } from "@deliverr/ui";

import { ScanFlowV2 } from "crossdock/common/flow/ScanFlowV2";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilValue } from "recoil";
import { headerSubtitleState } from "crossdock/base/headerTitleState";
import { useHistory } from "react-router";
import { useLifecycles } from "react-use";
import { ScanPath } from "crossdock/base/routes";
import styled from "@emotion/styled";
import { useResetFcDimsAndWeightState } from "./hooks/useResetFcDimsAndWeightState";
import { createFcDimsAndWeightScanProductBarcodeCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/CardCreators";

const DimsAndWeightFlexContainer = styled.div<{ thinNavbar: boolean }, DefaultTheme>(
  ({ theme, thinNavbar }) => `
  display: flex;
  position: relative;
  height: calc(100vh - ${thinNavbar ? "50px" : "70px"}); // height of screen minus header
  margin-right: -.65rem;
  padding-bottom: ${theme.spacing.S4};
`
);

let unlisten;

export const FcDimsAndWeight: React.FC = () => {
  const headerSubtitle = useRecoilValue(headerSubtitleState);
  const { resetFcDimsAndWeightState } = useResetFcDimsAndWeightState();
  const { addFlowCard, resetFlow } = useScanFlow();
  const useThinNavbar = !headerSubtitle || headerSubtitle.length === 0;
  const history = useHistory();

  useLifecycles(
    () => {
      unlisten = history.listen((location) => {
        if (location.pathname === ScanPath.FcDimsAndWeight) {
          resetFcDimsAndWeightState();
          addFlowCard(createFcDimsAndWeightScanProductBarcodeCard({}));
        }
      });
    },
    () => {
      unlisten();
    }
  );

  const onUnmount = () => {
    resetFcDimsAndWeightState();
    resetFlow();
  };

  return (
    <DimsAndWeightFlexContainer thinNavbar={useThinNavbar}>
      <ScanFlowV2 onMountCardData={createFcDimsAndWeightScanProductBarcodeCard({})} onUnmount={onUnmount} />
    </DimsAndWeightFlexContainer>
  );
};
