import React from "react";
import { MessageDescriptor, useIntl } from "react-intl";

import { UploadItem } from "@deliverr/ui-facility/lib-facility/components";
import { useRecoilState } from "recoil";
import { FormProvider, useForm } from "react-hook-form";
import { s3FileHandlerClient } from "crossdock/base/Clients";
import styled from "@emotion/styled";
import { FlowCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { Stack, Text, ThemeProps } from "@deliverr/ui";
import { fcDimsAndWeightCubiscanImagesState } from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";
import { NonComplianceImagesMetadata } from "@deliverr/crossdock-service-client";

interface UnknownBarcodeUploadItemProps {
  iconText: MessageDescriptor;
  photoType: keyof NonComplianceImagesMetadata;
  iconId: string;
  field: string;
}

export interface DimsAndWeightImageUploadCardProps {
  title: MessageDescriptor;
  uploads: UnknownBarcodeUploadItemProps[];
}

export const DimsAndWeightImageUploadCard: React.FC<DimsAndWeightImageUploadCardProps> = ({ title, uploads }) => {
  const { formatMessage } = useIntl();

  const [fcDimsAndWeightCubiscanImages, setFcDimsAndWeightCubiscanImages] = useRecoilState(
    fcDimsAndWeightCubiscanImagesState
  );

  const formHandler = useForm({
    mode: "onBlur",
  });

  const StyledFlowCard = styled(FlowCard)<ThemeProps>(
    () => `
  height: fit-content !important;
`
  );

  const generateOnFileChange = (photoType: keyof NonComplianceImagesMetadata) => (fileName: string) => {
    setFcDimsAndWeightCubiscanImages({ ...fcDimsAndWeightCubiscanImages, [photoType]: fileName });
  };

  return (
    <FormProvider {...formHandler}>
      <StyledFlowCard>
        <Stack>
          <Text bold>{formatMessage(title)}</Text>
          {uploads.map(({ iconText, iconId, photoType, field }) => {
            return (
              <UploadItem
                {...formHandler.register[field]}
                key={photoType}
                name={photoType}
                iconText={formatMessage(iconText)}
                id={`${iconId}`}
                onSuccessfulUpload={generateOnFileChange(photoType)}
                fileClient={s3FileHandlerClient}
              />
            );
          })}
        </Stack>
      </StyledFlowCard>
    </FormProvider>
  );
};
