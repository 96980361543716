import React from "react";
import { Button } from "@deliverr/ui";
import { FormattedMessage } from "react-intl";

import { useBoxAuditConfirmAudit } from "./useBoxAuditConfirmAudit";

export const BoxAuditConfirmAuditButton: React.FC = () => {
  const { onButtonClick, loading } = useBoxAuditConfirmAudit();

  return (
    <Button onClick={onButtonClick} block loading={loading}>
      <FormattedMessage id="crossdock.boxAuditConfirmAuditButton.printLabels" defaultMessage="Confirm" />
    </Button>
  );
};
