import { TransferPallet } from "@deliverr/commons-clients";

/**
 * Takes in a pallet and returns the code/id
 * for the most recent scan between milkRunId
 * and trackingCode
 */
export const mostRecentTrackingScan = (pallet: TransferPallet): string | undefined => {
  if (pallet.milkRunScannedAt && pallet.trackingUpdatedAt) {
    return pallet.milkRunScannedAt > pallet.trackingUpdatedAt ? pallet.milkRunId : pallet.trackingCode;
  } else if (pallet.milkRunScannedAt) {
    return pallet.milkRunId;
  } else if (pallet.trackingUpdatedAt) {
    return pallet.trackingCode;
  } else {
    return undefined;
  }
};
