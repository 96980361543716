import { useResetRecoilState } from "recoil";
import { useLifecycles } from "react-use";
import React from "react";
import { createShipPalletScanPalletCard } from "crossdock/common/flow/scanFlowCardCreators";
import { ScanFlow } from "crossdock/common/flow/ScanFlow";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { palletIdState, trackingCodeState } from "./ShipPalletState";

export const ShipPallet: React.FC = () => {
  const { addFlowCard, resetFlow } = useScanFlow();
  const resetPalletId = useResetRecoilState(palletIdState);
  const resetTrackingCode = useResetRecoilState(trackingCodeState);

  useLifecycles(
    () => {
      addFlowCard(createShipPalletScanPalletCard({}));
    },
    () => {
      resetFlow();
      resetPalletId();
      resetTrackingCode();
    }
  );

  return <ScanFlow />;
};
