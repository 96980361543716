import React, { ReactElement, Suspense, useEffect } from "react";
import { useIntl } from "react-intl";

import { toast } from "@deliverr/ui";
import { CenterSpinner, logError } from "@deliverr/ui-facility";
import { PALLETS_SUMMARY_LABELS } from "./palletsSummary.labels";
import { PalletsSummaryPivotTable } from "./PalletsSummaryPivotTable";

export const PalletsSummaryTable: React.FC<PalletsSummaryTableProps> = ({ appReady, rows, onLoad }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    (async function () {
      try {
        await onLoad();
      } catch (err) {
        logError({ fn: "PalletsSummaryTable#onLoad" }, err);
        toast.error(formatMessage(PALLETS_SUMMARY_LABELS.notification.dataLoadError));
        throw err;
      }
    })().catch((err) => {
      throw err;
    });
  }, [onLoad, formatMessage]);

  return (
    <Suspense fallback={<CenterSpinner loading={true} />}>
      <CenterSpinner loading={!appReady}>
        <PalletsSummaryPivotTable
          caption={formatMessage(PALLETS_SUMMARY_LABELS.palletsSummary.caption)}
          rowHeadingsColumnWidthPct={20}
          rowColHeading={formatMessage(PALLETS_SUMMARY_LABELS.palletsSummary.rowColHeading)}
          colHeadings={Object.values(PALLETS_SUMMARY_LABELS.palletsSummary.columns).map((descriptor) =>
            formatMessage(descriptor)
          )}
          rowHeadings={Object.values(PALLETS_SUMMARY_LABELS.palletsSummary.categories).map((descriptor) =>
            formatMessage(descriptor)
          )}
          rows={rows}
        />
      </CenterSpinner>
    </Suspense>
  );
};

export interface PalletsSummaryTableProps {
  onLoad: () => Promise<void>;
  rows: ReactElement[][];
  appReady: boolean;
}
