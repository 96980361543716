import * as React from "react";
import styled from "@emotion/styled";
import { Text, ThemeProps } from "@deliverr/ui";
import { UnitTransfer } from "@deliverr/commons-clients";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { sumBy, uniqBy } from "lodash";

const BoxBreakChuteDetailInfoContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL["60"]};
  padding: ${theme.spacing.S4} 0;
`
);

const BarcodesContainer = styled.div`
  flex-basis: 100%;
`;

interface BoxBreakChuteDetailInfoProps {
  item: UnitTransfer[];
}

export const BoxBreakChuteDetailInfo: React.FC<BoxBreakChuteDetailInfoProps> = ({ item }) => (
  <BoxBreakChuteDetailInfoContainer>
    <Text bold>{item[0].dsku}</Text>
    <Text textAlign={TextAlign.end}>{sumBy(item, "qty")}</Text>
    <BarcodesContainer>
      {uniqBy(item, "unitBarcode").map((barcodeItem) => (
        <Text key={barcodeItem.unitBarcode} appearance="INFO">
          {barcodeItem.unitBarcode}
        </Text>
      ))}
    </BarcodesContainer>
  </BoxBreakChuteDetailInfoContainer>
);
