import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useBoxBreakScanAnyUnit } from "./useBoxBreakScanAnyUnit";
import { SCAN_ANY_UNIT_LABELS as labels } from "./BoxBreakScanAnyUnitCard.labels";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak.labels";
import { useIntl } from "react-intl";

export const BoxBreakScanAnyUnitCard: React.FC = () => {
  const { unitBarcode, onUnitBarcodeChange, submitUnitBarcode, errorMessage, isSingleSkuBox } =
    useBoxBreakScanAnyUnit();
  const { formatMessage } = useIntl();

  return (
    <TextInputCard
      title={formatMessage(labels.title)}
      message={formatMessage(isSingleSkuBox ? labels.singleSkuMessage : labels.multiSkuMessage)}
      value={unitBarcode}
      placeholder={formatMessage(bbLabels.placeholderUnitBarcode)}
      icon="scan"
      onChange={onUnitBarcodeChange}
      accentColor="BLUE"
      errorMessage={errorMessage}
      onSubmit={submitUnitBarcode}
      newest={true}
    />
  );
};
