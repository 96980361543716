import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Title } from "@deliverr/ui";
import { useRecoilValue } from "recoil";
import { SpacedIcon } from "crossdock/common/components";
import {
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightProductBarcodeState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { DimsAndWeightBoxContentsItem } from "crossdock/modes/inspection/crossdock-dims-and-weight/sidebars/BoxContentsItem";
import { crossdockDimsAndWeightPackageItemsState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightSelectorState";
import { LeftSideBarContainer } from "crossdock/common/components/SideBarContainer";

export const DimsAndWeightContentsOverview: React.FC = () => {
  const scannedPackage = useRecoilValue(crossdockDimsAndWeightPackageState);
  const currentProductBarcode = useRecoilValue(crossdockDimsAndWeightProductBarcodeState);

  const { pendingPackageItems } = useRecoilValue(crossdockDimsAndWeightPackageItemsState);

  return (
    <LeftSideBarContainer isDisplay={!!scannedPackage}>
      <Title as="h4" displayAs="h4">
        <SpacedIcon type="exclamation-circle" color="#FFCF0E" />
        <FormattedMessage id="crossdock.boxBreaksBoxContentsOverview.title" defaultMessage="Incomplete" />
      </Title>
      {!scannedPackage ? null : (
        <>
          {pendingPackageItems?.map(({ dsku, product }, index: number) => {
            const barcodes = product?.barcodes ?? [];
            return (
              <DimsAndWeightBoxContentsItem
                key={index}
                dsku={dsku}
                productName={product?.name}
                isActive={!!currentProductBarcode && barcodes.includes(currentProductBarcode)}
                barcode={barcodes?.[0]}
              />
            );
          })}
        </>
      )}
    </LeftSideBarContainer>
  );
};
