import { useRecoilState } from "recoil";
import { palletLabelIdState } from "../ClosePalletState";
import { log, logStart, isScanInput } from "@deliverr/ui-facility/lib-facility/utils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { validatePalletLabelId } from "@deliverr/ui-facility/lib-facility/utils/commonsTransferValidationUtils";
import {
  createClosePalletInvalidStatusCard,
  createPalletNoBoxesCard,
  createClosePalletScanPalletCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { inboundClient } from "crossdock/base/Clients";
import { TransfersModal } from "../../modals";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransferPalletStatus } from "@deliverr/legacy-inbound-client";
import { useClosePallet } from "../useClosePallet";
import { createUnknownInputCard } from "@deliverr/ui-facility/lib-facility/flow";

const MINIMUM_CASE_THRESHOLD = 4;

export function useClosePalletScanPallet() {
  const { addFlowCard, emitFlash } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const [curPalletLabelId, setPalletLabelId] = useRecoilState(palletLabelIdState);
  const handleClosePallet = useClosePallet(palletLabelIdState);

  const submitPalletLabelId = async (palletLabelId: string) => {
    const ctx = logStart({ fn: "ClosePallet.submitPalletLabelId", palletLabelId });

    if (!validatePalletLabelId(palletLabelId)) {
      log(ctx, "invalid pallet label id");
      emitFlash("DANGER");
      setPalletLabelId("");
      addFlowCard(createUnknownInputCard({ value: palletLabelId, message: "Does not appear to be a valid Pallet." }));
      addFlowCard(createClosePalletScanPalletCard({}));
      return;
    }

    try {
      const pallet = await inboundClient.getPalletByLabel(palletLabelId);
      if (pallet.status === TransferPalletStatus.CLOSED) {
        emitFlash("DANGER");
        addFlowCard(
          createClosePalletInvalidStatusCard({
            palletLabelId,
            status: pallet.status,
            statusUpdatedAt: new Date(pallet.statusUpdatedAt),
          })
        );
        addFlowCard(createClosePalletScanPalletCard({}));
        setPalletLabelId("");
        return;
      }

      // Disallow closing any pallet without boxes
      if (pallet.items.length === 0) {
        emitFlash("DANGER");
        addFlowCard(createPalletNoBoxesCard({ palletLabelId, sourceFlow: "close" }));
        addFlowCard(createClosePalletScanPalletCard({}));
        setPalletLabelId("");
        return;
      } else if (pallet.items.length < MINIMUM_CASE_THRESHOLD) {
        // Show a prompt only if the case count on a pallet is below a threshold
        emitFlash("DANGER");
        showModal(TransfersModal.TRANSFERS_CONFIRM_CLOSE_PALLET, { pallet });
      } else {
        emitFlash("SUCCESS");
        await handleClosePallet(palletLabelId);
      }
    } catch (err) {
      log(ctx, "invalid pallet id");
      emitFlash("DANGER");
      addFlowCard(createUnknownInputCard({ value: palletLabelId, message: "Does not appear to be a valid Pallet" }));
      addFlowCard(createClosePalletScanPalletCard({}));
    }
  };

  async function onPalletLabelIdChange(newPalletId: string) {
    const formattedPalletLabelId = newPalletId.trim().toUpperCase();
    setPalletLabelId(formattedPalletLabelId);
    if (isScanInput(formattedPalletLabelId, curPalletLabelId)) {
      await submitPalletLabelId(formattedPalletLabelId);
    }
  }

  return {
    palletLabelId: curPalletLabelId,
    onPalletLabelIdChange,
    submitPalletLabelId,
  };
}
