import { defineMessages } from "react-intl";

export const BOX_BREAK_COMMON_LABELS = defineMessages({
  returnUnitCardTitle: {
    id: "crossdock.boxBreakReturnUnitCard.title",
    defaultMessage: "Return Unit To Box",
  },
  returnUnitCardMessage: {
    id: "crossdock.boxBreakReturnUnitCard.message",
    defaultMessage: "Put {unitBarcode} back into the box",
  },
  scanBoxSuccessCardTitle: {
    id: "crossdock.boxBreakScanBoxSuccessCard.title",
    defaultMessage: "Open Box",
  },
  scanBoxSuccessCardMessage: {
    id: "crossdock.boxBreakScanBoxSuccessCard.message",
    defaultMessage: "Open the box to start breaking",
  },
  loginCardTitle: {
    id: "crossdock.boxBreakLoginCard.title",
    defaultMessage: "Hi, welcome back",
  },
  loginCardMessage: {
    id: "crossdock.boxBreakLoginCard.message",
    defaultMessage: "Please scan the station barcode",
  },
  placeholderContainer: {
    id: "crossdock.boxBreak.placeholders.containerBarcode",
    defaultMessage: "Container barcode",
  },
  placeholderUnitBarcode: {
    id: "crossdock.boxBreak.placeholders.unitBarcode",
    defaultMessage: "Unit barcode",
  },
  errorInvalidCdskuFormat: {
    id: "crossdock.boxBreak.errors.invalidCdskuFormat",
    defaultMessage: "Barcode must start with 'CD'.",
  },
  errorContainerMismatch: {
    id: "crossdock.boxBreak.errors.containerMismatch",
    defaultMessage: "Container does not match expected. Scan container on chute {letter}",
  },
  errorInvalidUnitBarcodeLength: {
    id: "crossdock.errorMessages.unitBarcode",
    defaultMessage: "Unit barcode should be at least 1 character long",
  },
});
