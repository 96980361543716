import { useCrossdockRadioOptions, YesNoRadioOptions } from "crossdock/common/radio";
import { useMovePalletLoading } from "./useMovePalletLoading";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { createScanLaneCard } from "../../freightArrivalCardCreators";
import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  poNumState,
  palletLabelIdState,
  willMovePalletState,
  inboundPalletLabelIdState,
  palletDestinationLaneTypeValue,
} from "../../FreightArrivalState";
import { useIntl } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";
import { useFreightArrival } from "../../useFreightArrival";
import { useFreightArrivalNotifications } from "../../useFreightArrivalNotifications";
import labels from "./FreightArrivalMovePalletNowCard.labels";
import laneTypeToLocationMessages from "../../laneTypeToLocationMessages";
import { isEmpty } from "lodash/fp";

export function useFreightArrivalMovePalletNow() {
  const { showFlowButton, addFlowCard, hideAllFlowButtons } = useScanFlow();
  const [willMovePallet, setWillMovePallet] = useRecoilState(willMovePalletState);
  const { loading, loadWhilePending } = useMovePalletLoading();
  const { yesNoRadioOptionsProps } = useCrossdockRadioOptions();
  const poNum = useRecoilValue(poNumState);
  const palletLabelId = useRecoilValue(palletLabelIdState);
  const inboundPalletLabelId = useRecoilValue(inboundPalletLabelIdState);
  const { formatMessage } = useIntl();
  const { restartFreightArrival } = useFreightArrival();
  const { showFreightArrivalRecordedNotification } = useFreightArrivalNotifications();
  const palletDestinationLaneType = useRecoilValue(palletDestinationLaneTypeValue);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setWillMovePallet(event.target.value);
    showFlowButton(ScanFlowButtonType.FREIGHT_ARRIVAL_MOVE_PALLET_NOW_DONE);
  };

  const onDoneClick = async () => {
    const ctx = { fn: "useMovePalletNow.onDoneClick", willMovePallet };
    if (willMovePallet === YesNoRadioOptions.YesOption) {
      log(ctx, "moving pallet");
      hideAllFlowButtons();
      addFlowCard(
        createScanLaneCard({
          label: palletLabelId || formatMessage(crossdockLabels.poNum, { poNum }),
          noPo: isEmpty(poNum),
        })
      );
    } else {
      log(ctx, "not moving pallet, restarting flow");
      restartFreightArrival();
      showFreightArrivalRecordedNotification(inboundPalletLabelId);
    }
  };

  return {
    loading,
    onChange,
    onClick: loadWhilePending(onDoneClick),
    radioOptions: yesNoRadioOptionsProps,
    willMovePallet,
    message: formatMessage(labels.message, {
      dropLocation: formatMessage(laneTypeToLocationMessages[palletDestinationLaneType]),
    }),
  };
}
