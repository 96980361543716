import { defineMessages } from "react-intl";

export const END_EARLY_LABELS = defineMessages({
  title: {
    id: "crossdock.boxBreakEndEarlyCard.title",
    defaultMessage: "End Box Break Early",
  },
  message: {
    id: "crossdock.boxBreakEndEarlyCard.message",
    defaultMessage: "Close box and move box to Box Scanning Area",
  },
});
