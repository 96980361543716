import React from "react";
import { useRecoilValue } from "recoil";

import { BoxAuditBackButton } from "./BoxAuditBackButton";
import { showBackButtonState } from "../../ProblemState";
import { BoxAuditModal } from "../BoxAuditModal";
import { ModalExitButton } from "crossdock/common/components";

export const BoxAuditLeftHeaderIcon: React.FC = () => {
  const showBackButton = useRecoilValue(showBackButtonState);
  return showBackButton ? <BoxAuditBackButton /> : <ModalExitButton modalId={BoxAuditModal.BOX_AUDIT_QUIT} />;
};
