import { defineMessages } from "react-intl";

export const UNEXPECTED_BARCODE_LABELS = defineMessages({
  title: {
    id: "crossdock.boxBreakUnexpectedBarcodeModal.title",
    defaultMessage: "Unexpected Unit Barcode",
  },
  message: {
    id: "crossdock.boxBreakUnexpectedBarcodeModal.message",
    defaultMessage:
      "{unitBarcode} is valid but not associated with any product. Select ‘Cancel’ to scan different unit. Select ‘End Box Break’ to end box break early.",
  },
  endBoxBreakButton: {
    id: "crossdock.boxBreakUnexpectedBarcodeModal.endBoxBreakButton",
    defaultMessage: "End Box Break",
  },
});
