import { useState } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  fcDimsAndWeightProductBarcodeState,
  fcDimsAndWeightProductDimsState,
  fcDimsAndWeightProductState,
} from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";
import { useResetFcDimsAndWeightState } from "crossdock/modes/inspection/fc-dims-and-weight/hooks/useResetFcDimsAndWeightState";
import { useMount, useUnmount } from "react-use";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { crossdockClient } from "crossdock/base/Clients";
import {
  toCaptureCubiscanImages,
  toScanProductBarcode,
} from "crossdock/modes/inspection/fc-dims-and-weight/transitions";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { toast } from "@deliverr/ui";
import { useIntl } from "react-intl";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { getCubiscanDimsFromQRCode } from "crossdock/modes/inspection/utilities/getCubiscanDimsFromQRCode";
import { DIMS_AND_WEIGHT_ERRORS } from "crossdock/modes/inspection/constants";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useLoading } from "crossdock/common/useLoading";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { ProductDimsAPIRequest } from "@deliverr/crossdock-service-client";

export const useFcDimsAndWeightProductDims = () => {
  const { warehouseId } = useRecoilValue(userState);
  const { inputErrorMessage: errorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const productBarcode = useRecoilValue(fcDimsAndWeightProductBarcodeState);
  const product = useRecoilValue(fcDimsAndWeightProductState);
  const setProductDims = useSetRecoilState(fcDimsAndWeightProductDimsState);
  const { loading, loadWhilePending } = useLoading();
  const [productDimsQr, setProductDimsQr] = useState<string>("");

  const { resetFcDimsAndWeightState } = useResetFcDimsAndWeightState();
  const { formatMessage } = useIntl();

  const { showFlowButton, hideAllFlowButtons, successResponse, infoResponse, transition, handleUnknownError } =
    useScanFlow();

  useUnmount(() => {
    hideAllFlowButtons();
  });

  useMount(() => {
    hideAllFlowButtons();
    showFlowButton(ScanFlowButtonType.FC_DIMS_AND_WEIGHT_CANCEL_BUTTON);
  });

  const submitProductDims = async (qrCode: string) => {
    const ctx = logStart({ fn: "submitProductDims#fc", qrCode });

    try {
      const dims = getCubiscanDimsFromQRCode(qrCode);

      const productDimsAPIRequest: ProductDimsAPIRequest = {
        barcode: productBarcode!,
        warehouseId,
        ...dims,
      };

      const shouldCaptureImages = await crossdockClient.getShouldCreateDimsIssue(productDimsAPIRequest);

      if (shouldCaptureImages) {
        setProductDims(dims);
        infoResponse();
        transition(toCaptureCubiscanImages());
        hideAllFlowButtons();
        return;
      }

      const { height, length, width, weight, lengthUnit, weightUnit } = dims;

      await crossdockClient.createProductDimsV2(productDimsAPIRequest);

      log(ctx, "product dims scan successful");

      successResponse();
      toast.success(
        formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsRecorded, {
          width,
          length,
          height,
          weight,
          lengthUnit,
          weightUnit,
        }),
        { autoClose: 5000 }
      );
      resetFcDimsAndWeightState();
      transition(toScanProductBarcode());
      hideAllFlowButtons();
    } catch (err) {
      if (err.code === DIMS_AND_WEIGHT_ERRORS.INVALID_DIMS_QR_CODE) {
        log(ctx, "invalid QR code");
        setProductDimsQr("");
        inputError(formatMessage(DIMS_AND_WEIGHT_LABELS.invalidQrCode));
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return {
    productBarcode,
    productInfo: product,
    productDimsQr,
    submitProductDims: loadWhilePending(resetErrorOnExecution(submitProductDims)),
    onProductDimsChange: genericOnScannerInputChange(productDimsQr, setProductDimsQr, submitProductDims, "upper"),
    loading,
    errorMessage,
  };
};
