import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { validProdASNPattern } from "@deliverr/ui-facility/lib-facility/utils/config";
import { GenericModal, useCrossdockModal } from "crossdock/common/modal";
import { PrepRequiredModalProps } from "crossdock/common/modal/PrepRequiredModal/usePrepRequiredModal";
import { inboundClient } from "crossdock/base/Clients";

export const useHandlePrepIntercept = () => {
  const { showModal } = useCrossdockModal();

  /** determine if the given input has Prep associated with it */
  const getHasIncompletePrepByPoNum = async (input: string): Promise<boolean> => {
    logStart({ fn: "getHasIncompletePrepByPoNum", input });

    // exit early if the input is not actually a PO #
    if (!validProdASNPattern.test(input)) {
      return false;
    }

    const prepRequest = await inboundClient.findPrepRequestByPoNum(+input);
    return !!prepRequest && !prepRequest.completedAt;
  };

  const handlePrepForPoNum = (poNum: string) => {
    showModal<PrepRequiredModalProps>(GenericModal.PREP_REQUIRED, { poNum });
  };

  return {
    getHasIncompletePrepByPoNum,
    handlePrepForPoNum,
  };
};
