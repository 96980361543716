import React from "react";
import { useBoxBreakRemoveContainerCancel } from "./useBoxBreakRemoveContainerCancel";
import { CancelButton } from "@deliverr/ui-facility";

interface Props {
  disabled?: boolean;
}

export const BoxBreakRemoveContainerCancelButton: React.FC<Props> = ({ disabled }) => {
  const onClick = useBoxBreakRemoveContainerCancel();

  return <CancelButton onClick={onClick} secondary block disabled={disabled} />;
};
