import { useRecoilValue } from "recoil";
import { crossdockClient } from "crossdock/base/Clients";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { activeDskuValue } from "../../sidebar/boxBreakSidebarState";
import { lastUnitScannedState } from "../../boxBreakState";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../../modals/BoxBreakModal";
import { CrossdockError } from "@deliverr/commons-clients";

export function useHandleUnexpectedBoxBreakDskus() {
  const activeDsku = useRecoilValue(activeDskuValue);
  const { boxBreakStationId, cdsku, setBoxBreakBoxContentsBarcodes } = useBoxBreakState();
  const lastUnitScanned = useRecoilValue(lastUnitScannedState);
  const { showModal } = useCrossdockModal();

  return async (unitCount: number) => {
    try {
      await crossdockClient.handleUnexpectedBoxBreakDskusV2(cdsku, lastUnitScanned, unitCount, boxBreakStationId);
    } catch (err) {
      if (err?.code === CrossdockError.UNEXPECTED_SKU_NO_CAPABLE_WAREHOUSES) {
        showModal(BoxBreakModal.BOX_BREAK_UNEXPECTED_SKU_NO_CAPABLE_WAREHOUSES, {});
      } else {
        throw err;
      }
    }
    setBoxBreakBoxContentsBarcodes((previous) => ({ ...previous, [activeDsku]: lastUnitScanned }));
  };
}
