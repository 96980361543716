import { useSetRecoilState } from "recoil";

import { showPageLeftActionState } from "crossdock/base/headerTitleState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useShowAddSku } from "../../hooks";
import { showBackButtonState } from "../../ProblemState";

export function useProblemBackButton({ flowButtonType, flowCard }) {
  const { showAddSku } = useShowAddSku();
  const setShowPageLeftAction = useSetRecoilState(showPageLeftActionState);
  const { addFlowCard, hideAllFlowButtons, showFlowButton } = useScanFlow();
  const setShowBackButton = useSetRecoilState(showBackButtonState);

  const onClick = () => {
    hideAllFlowButtons();
    addFlowCard(flowCard);
    showAddSku();
    setShowBackButton(false);
    setShowPageLeftAction(true);
    showFlowButton(flowButtonType);
  };

  return { onClick };
}
