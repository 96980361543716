import React from "react";
import { useBoxBreakEndBreakComplete } from "./useBoxBreakEndBreakComplete";
import { DoneButton } from "@deliverr/ui-facility";

interface Props {
  disabled?: boolean;
}

export const BoxBreakEndBreakCompleteButton: React.FC<Props> = ({ disabled }) => {
  const onClick = useBoxBreakEndBreakComplete();

  return <DoneButton onClick={onClick} block disabled={disabled} />;
};
