import { FC } from "react";
import { CaseScannerDuplicateLabelConfirmationModal } from "./CaseScannerDuplicateLabelConfirmationModal";
import { CaseScannerDuplicateLabelPalletModal } from "./CaseScannerDuplicateLabelPalletModal";
import { CaseScannerDuplicateLabelPromptModal } from "./CaseScannerDuplicateLabelPromptModal";
import { CaseScannerModal } from "./CaseScannerModal";

export const caseScannerModalMap: Record<CaseScannerModal, FC<any>> = {
  [CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PALLET]: CaseScannerDuplicateLabelPalletModal,
  [CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_CONFIRMATION]: CaseScannerDuplicateLabelConfirmationModal,
  [CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PROMPT]: CaseScannerDuplicateLabelPromptModal,
};
