import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { useRecoilState, SetterOrUpdater, useRecoilValue } from "recoil";
import { log, logError, logStart, isScanInput } from "@deliverr/ui-facility/lib-facility/utils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { freightClient, inboundClient } from "crossdock/base/Clients";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types";
import { palletIdState } from "crossdock/modes/transfers/ShipPallet/ShipPalletState";
import {
  validateFreightPalletLabelId,
  validateLoadingDockPalletLabelScan,
  validatePalletId,
  ModalGenericProps,
} from "@deliverr/ui-facility";
import { createUnknownErrorCard } from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import {
  createShipPalletScanPalletCard,
  createShipPalletHasTrackingCard,
  createShipPalletScanPalletSuccessCard,
  createShipPalletScanTrackingCard,
  createClosePalletScanPalletSuccessCard,
  createInvalidPalletInputCard,
  createPalletNoBoxesCard,
  createPalletTrackingScanErrorCard,
  createShipUnbundledPalletScanPalletSuccessCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { ScanButtonType } from "crossdock/common/flow/scanFlowState";
import { TransferPalletStatus } from "@deliverr/legacy-inbound-client";
import { TransfersModal } from "../../modals/TransfersModal";
import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { ShipPalletNotClosedModalProps } from "../ShipPalletNotClosedModal";
import { FlashType } from "@deliverr/ui-facility/lib-facility/components/Flash";
import { mostRecentTrackingScan } from "./shipPalletScanPalletUtils";
import { LoadingDockScanEventResponseWithWarehouseId, LoadingDockScanEventScanType } from "@deliverr/freight-client";
import { MessageDescriptor, useIntl } from "react-intl";
import { getFreightLoadingDockScanErrorMessageDescriptor } from "crossdock/modes/utils/freightLoadingDockScanErrorUtils";

export function handleScanPidPalletSuccess(
  addFlowCard: (card: FlowCardData<any>) => void,
  showFlowButton: (type: ScanButtonType) => void,
  emitFlash: (type: FlashType) => void,
  palletId: string,
  trackingCode?: string,
  isNewlyClosedPallet: boolean = false // was the pallet closed during Ship Pallets flow
) {
  emitFlash("SUCCESS");
  addFlowCard(
    isNewlyClosedPallet // pallets closed during the Ship Pallets flow should never have tracking already associated
      ? createClosePalletScanPalletSuccessCard({ palletLabelId: palletId })
      : trackingCode
        ? createShipPalletHasTrackingCard({ palletId, trackingCode })
        : createShipPalletScanPalletSuccessCard({ palletId })
  );
  addFlowCard(createShipPalletScanTrackingCard({}));
  showFlowButton(ScanFlowButtonType.UNDO_SHIP_PALLET);
}

export function submitScanPallet(
  addFlowCard: (card: FlowCardData<any>) => void,
  showFlowButton: (type: ScanButtonType) => void,
  setPalletId: SetterOrUpdater<string>,
  showModal: <P extends ModalGenericProps>(modalType: string, props: P) => void,
  emitFlash: (type: FlashType) => void,
  formatMessage: (descriptor: MessageDescriptor, values?) => string,
  warehouseId: string
) {
  return async (palletId: string) => {
    const ctx = logStart({ fn: "submitScanPallet", palletId });

    if (!validateLoadingDockPalletLabelScan(palletId)) {
      log(ctx, "invalid pallet id");
      emitFlash("DANGER");
      setPalletId("");
      addFlowCard(createInvalidPalletInputCard({ value: palletId }));
      addFlowCard(createShipPalletScanPalletCard({}));
      return;
    }
    validateFreightPalletLabelId(palletId)
      ? await submitScanFpidPallet(palletId, warehouseId, addFlowCard, setPalletId, emitFlash, formatMessage)
      : await submitScanPidPallet(palletId, addFlowCard, showFlowButton, setPalletId, showModal, emitFlash);
  };
}

export function useShipPalletScanPallet() {
  const { formatMessage } = useIntl();
  const { addFlowCard, showFlowButton, emitFlash } = useScanFlow();
  const [palletId, setPalletId] = useRecoilState(palletIdState);
  const { showModal } = useCrossdockModal();
  const { warehouseId } = useRecoilValue(userState);

  async function onPalletIdChange(newPalletId: string) {
    const formattedPalletId = newPalletId.trim().toUpperCase();
    setPalletId(formattedPalletId);
    if (isScanInput(formattedPalletId, palletId)) {
      await submitScanPallet(
        addFlowCard,
        showFlowButton,
        setPalletId,
        showModal,
        emitFlash,
        formatMessage,
        warehouseId
      )(formattedPalletId);
    }
  }

  return {
    palletId,
    onPalletIdChange,
    submitScanPallet: submitScanPallet(
      addFlowCard,
      showFlowButton,
      setPalletId,
      showModal,
      emitFlash,
      formatMessage,
      warehouseId
    ),
  };
}

async function submitScanPidPallet(
  palletId: string,
  addFlowCard: (card: FlowCardData<any>) => void,
  showFlowButton: (type: ScanButtonType) => void,
  setPalletId: SetterOrUpdater<string>,
  showModal: (modal: string, props: ModalGenericProps) => void,
  emitFlash: (type: FlashType) => void
) {
  const ctx = logStart({ fn: "submitScanPidPallet", palletId });

  try {
    const pallet = validatePalletId(palletId)
      ? await inboundClient.getPalletById(Number(palletId))
      : await inboundClient.getPalletByLabel(palletId);

    const trackingCode = mostRecentTrackingScan(pallet);

    // Disallow closing any pallet without boxes
    if (pallet.items.length === 0) {
      emitFlash("DANGER");
      addFlowCard(createPalletNoBoxesCard({ palletLabelId: palletId, sourceFlow: "ship" }));
      addFlowCard(createShipPalletScanPalletCard({}));
      setPalletId("");
      return;
    } else if (pallet.status === TransferPalletStatus.OPEN) {
      emitFlash("DANGER");
      showModal<ShipPalletNotClosedModalProps>(TransfersModal.TRANSFERS_SHIP_PALLET_NOT_CLOSED, { pallet });
      return; // escape the flow as conditional flow logic is handled in the modal
    }

    handleScanPidPalletSuccess(addFlowCard, showFlowButton, emitFlash, palletId, trackingCode);
  } catch (err) {
    logError(ctx, err);
    emitFlash("DANGER");
    setPalletId("");
    addFlowCard(createUnknownErrorCard({ value: palletId }));
    addFlowCard(createShipPalletScanPalletCard({}));
  }
}

async function submitScanFpidPallet(
  palletId: string,
  warehouseId: string,
  addFlowCard: (card: FlowCardData<any>) => void,
  setPalletId: SetterOrUpdater<string>,
  emitFlash: (type: FlashType) => void,
  formatMessage: (descriptor: MessageDescriptor, values?) => string
) {
  try {
    (await freightClient.createWarehouseLoadingDockScanEvent({
      barcode: palletId,
      scanType: LoadingDockScanEventScanType.DEPARTURE,
      warehouseId,
    })) as LoadingDockScanEventResponseWithWarehouseId;
    emitFlash("DEFAULT");
    addFlowCard(
      createShipUnbundledPalletScanPalletSuccessCard({
        palletId,
        isUnbundledPallet: true,
      })
    );
    addFlowCard(createShipPalletScanTrackingCard({ isUnbundled: true }));
  } catch (err) {
    emitFlash("DANGER");
    addFlowCard(
      createPalletTrackingScanErrorCard({
        palletId,
        errorText: formatMessage(getFreightLoadingDockScanErrorMessageDescriptor(err)),
      })
    );
    setPalletId("");
    addFlowCard(createShipPalletScanPalletCard({}));
  }
}
