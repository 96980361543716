import { defineMessages } from "react-intl";
import { ScanPath, ScanPathName } from "./ScanPath";

export const ROUTE_LABELS = defineMessages({
  [ScanPath.Scanner]: {
    id: "crossdock.routes.scanner",
    defaultMessage: "Box and Pallet Scanner",
  },
  [ScanPath.Transfers]: {
    id: "crossdock.routes.transfers",
    defaultMessage: "Transfers",
  },
  [ScanPath.Problem]: {
    id: "crossdock.routes.problem",
    defaultMessage: "Problem",
  },
  [ScanPath.TransfersCreatePallet]: {
    id: "crossdock.routes.transfersCreatePallet",
    defaultMessage: "Create Pallets",
  },
  [ScanPath.TransfersAddCase]: {
    id: "crossdock.routes.transfersAddCase",
    defaultMessage: "Build Pallets",
  },
  [ScanPath.TransfersShipPallet]: {
    id: "crossdock.routes.transfersShipPallet",
    defaultMessage: "Transfer Pallets",
  },
  [ScanPath.TransfersClosePallet]: {
    id: "crossdock.routes.transfersClosePallet",
    defaultMessage: "Close Pallets",
  },
  [ScanPath.TransfersReopenPallet]: {
    id: "crossdock.routes.transfersReopenPallet",
    defaultMessage: "Re-open Pallets",
  },
  [ScanPath.ManagePrinters]: {
    id: "crossdock.routes.managePrinters",
    defaultMessage: "Manage Printers",
  },
  [ScanPathName.MISSING_LABEL_FREIGHT]: {
    id: "crossdock.routes.missingLabelFreight",
    defaultMessage: "Missing Label (Freight)",
  },
  [ScanPathName.MISSING_LABEL_SPD]: {
    id: "crossdock.routes.missingLabelSpd",
    defaultMessage: "Missing Label (Small Parcel)",
  },
  [ScanPath.DuplicateLabel]: {
    id: "crossdock.routes.duplicateLabel",
    defaultMessage: "Duplicate Label",
  },
  [ScanPath.BoxAudit]: {
    id: "crossdock.routes.BoxAudit",
    defaultMessage: "Box Audit",
  },
  [ScanPath.DamagedUnits]: {
    id: "crossdock.routes.damagedUnits",
    defaultMessage: "Damaged Units",
  },
  [ScanPath.BoxBreakCreateContainers]: {
    id: "crossdock.routes.boxBreakCreateContainers",
    defaultMessage: "Create Containers",
  },
  [ScanPath.BoxBreak]: {
    id: "crossdock.routes.boxBreak",
    defaultMessage: "Box Break",
  },
  [ScanPath.FreightArrival]: {
    id: "crossdock.routes.freightArrival",
    defaultMessage: "Freight Arrival",
  },
  [ScanPath.SmallParcelArrival]: {
    id: "crossdock.routes.smallParcelArrival",
    defaultMessage: "Small Parcel Arrival",
  },
  [ScanPath.PrintInboundPalletLabel]: {
    id: "crossdock.routes.printInboundPallets",
    defaultMessage: "Print Inbound Pallet Labels",
  },
  [ScanPath.MovePallet]: {
    id: "crossdock.routes.movePallet",
    defaultMessage: "Move Pallet",
  },
  [ScanPath.StartProcessingPallet]: {
    id: "crossdock.routes.startProcessingPallet",
    defaultMessage: "Start Processing Pallet",
  },
  [ScanPath.FcDimsAndWeight]: {
    id: "crossdock.routes.fcDimsAndWeight",
    defaultMessage: "Dims and Weight (FC)",
  },
  [ScanPath.CrossdockDimsAndWeight]: {
    id: "crossdock.routes.crossdockDimsAndWeight",
    defaultMessage: "Dims and Weight (Crossdock)",
  },
});
