import { useMount } from "react-use";
import { log } from "@deliverr/ui-facility/lib-facility/utils/logger";
import { isZebraScanner } from "@deliverr/ui-facility/lib-facility/utils/zebraUtils";
import { useCrossdockModal, GenericModal } from "crossdock/common/modal";
import { useRecoilValue } from "recoil";
import { connectedPrinterState } from "@deliverr/ui-facility/lib-facility/printer/PrinterZebraState";

export function useConnectToPrinterWarning(onMount = false) {
  const { showModal } = useCrossdockModal();
  const connectedPrinter = useRecoilValue(connectedPrinterState);
  const printerConnected = Boolean(connectedPrinter);

  function verifyPrinterConnected(): boolean {
    return !isZebraScanner || printerConnected;
  }

  function showPrinterConnectWarning(): void {
    const ctx = { fn: "useConnectToPrinterWarning", isZebraScanner, printerConnected };
    log(ctx, "no printer connected. warning user");
    showModal(GenericModal.CONNECT_TO_PRINTER_WARNING, {});
  }

  function verifyAndShowPrinterConnected(): boolean {
    const isConnected = verifyPrinterConnected();
    if (!isConnected) {
      showPrinterConnectWarning();
    }
    return isConnected;
  }

  useMount(() => {
    if (onMount) {
      verifyAndShowPrinterConnected();
    }
  });

  return { verifyAndShowPrinterConnected };
}
