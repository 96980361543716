import { defineMessages } from "react-intl";

export const SCAN_ANY_UNIT_LABELS = defineMessages({
  title: {
    id: "crossdock.boxBreakScanAnyUnitCard.title",
    defaultMessage: "Scan Any Unit",
  },
  multiSkuMessage: {
    id: "crossdock.boxBreakScanAnyUnitCard.multiSkuMessage",
    defaultMessage: "Scan any unit in the box",
  },
  singleSkuMessage: {
    id: "crossdock.boxBreakScanAnyUnitCard.singleSkuMessage",
    defaultMessage: "Scan a unit to confirm the box contents",
  },
});
