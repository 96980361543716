import { useLoading } from "crossdock/common/useLoading";
import { crossdockClient } from "crossdock/base/Clients";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { useIntl } from "react-intl";
import { CreateDimsAndWeightSessionItemRequestV2 } from "@deliverr/crossdock-service-client/lib/packages/client/src/generated-sources/openapi";
import {
  crossdockDimsAndWeightCubiscanImagesState,
  crossdockDimsAndWeightCurrentProductState,
  crossdockDimsAndWeightProductBarcodeState,
  crossdockDimsAndWeightProductDimsState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { toCrossdockDimsAndWeightScanProductBarcode } from "crossdock/modes/inspection/crossdock-dims-and-weight/transitions";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";

export const useCrossdockDimsAndWeightSubmitImages = () => {
  const { loading, loadWhilePending } = useLoading();

  const productBarcode = useRecoilValue(crossdockDimsAndWeightProductBarcodeState);
  const dims = useRecoilValue(crossdockDimsAndWeightProductDimsState);
  const nonComplianceImagesMetadata = useRecoilValue(crossdockDimsAndWeightCubiscanImagesState);
  const [dimsSession, setDimsSession] = useRecoilState(crossdockDimsAndWeightSessionState);

  const resetBarcode = useResetRecoilState(crossdockDimsAndWeightProductBarcodeState);
  const resetCurrentProduct = useResetRecoilState(crossdockDimsAndWeightCurrentProductState);
  const resetCubiscanImages = useResetRecoilState(crossdockDimsAndWeightCubiscanImagesState);
  const resetProductDims = useResetRecoilState(crossdockDimsAndWeightProductDimsState);
  const { formatMessage } = useIntl();
  const { hideAllFlowButtons, showFlowButton, transition, handleUnknownError } = useScanFlow();

  const { cubiscanScreen, cubiscanProduct, cubiscanAll } = nonComplianceImagesMetadata;

  const onButtonClick = async () => {
    const ctx = logStart({ fn: "onButtonClick#CrossdockDimsAndWeightComplete" });

    try {
      const { height, length, width, weight, lengthUnit, weightUnit } = dims!;

      const dimsSessionItemRequest: CreateDimsAndWeightSessionItemRequestV2 = {
        barcode: productBarcode!,
        sessionId: dimsSession!.id,
        ...dims!,
        nonComplianceImagesMetadata,
      };

      const updatedSession = await crossdockClient.createDimsAndWeightsSessionItemV2(dimsSessionItemRequest);

      setDimsSession(updatedSession);

      createSuccessNotification(
        formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsRecorded, {
          width,
          length,
          height,
          weight,
          lengthUnit,
          weightUnit,
        })
      );

      resetBarcode();
      resetCurrentProduct();
      resetProductDims();
      resetCubiscanImages();

      hideAllFlowButtons();
      transition(toCrossdockDimsAndWeightScanProductBarcode());

      showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_END_SESSION_BUTTON);
      showFlowButton(ScanFlowButtonType.CROSSDOCK_DIMS_AND_WEIGHT_CANCEL_SESSION_BUTTON);
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  return {
    onClick: loadWhilePending(onButtonClick),
    loading,
    disableButton: !dims || !cubiscanScreen || !cubiscanProduct || !cubiscanAll,
  };
};
