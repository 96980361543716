import * as React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { groupBy, values } from "lodash";
import { UnitTransfer } from "@deliverr/commons-clients";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { DefaultTheme, IconV2, Text, ThemeProps, Title } from "@deliverr/ui";

import { BoxBreakChutesOverviewItemProps } from "./BoxBreakChutesOverviewItem";
import { BoxBreakChuteContainerInfo } from "./BoxBreakChuteContainerInfo";
import { BoxBreakRemoveContainerButton } from "./BoxBreakRemoveContainerButton";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { BoxBreakChuteDetailInfo } from "./BoxBreakChuteDetailInfo";

const SLIDE_DURATION_MS = 300;
const SLIDE_RENDER_GAP_MS = 100;

const BoxBreakChuteDetailViewContainer = styled.section<{ hide: boolean }, DefaultTheme>(
  ({ hide, theme }) => `
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  height: 100%;
  width: 100%;
  left: ${hide ? "100%" : "0"};
  transition: left ${SLIDE_DURATION_MS}ms ease;
  background-color: ${theme.colors.NEUTRAL["00"]};
`
);

const HeaderContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    position: relative;
    color: ${theme.colors.NEUTRAL["00"]};
    padding-left: ${theme.spacing.S5};
    padding-bottom: ${theme.spacing.S4};
    background-color: ${theme.colors[theme.config.colorByAppearance.PRIMARY]["300"]};
`
);

const CloseButton = styled.button<ThemeProps>(
  ({ theme }) => `
  position: absolute;
  top: ${theme.spacing.S5};
  right: ${theme.spacing.S5};
  z-index: ${theme.zindex.Z1};
`
);

const ContentContainer = styled.div<ThemeProps>(
  ({ theme }) => `
    padding: ${theme.spacing.S4} ${theme.spacing.S5};
    flex-grow: 1;
    overflow: auto;
  `
);

const SpacedTitle = styled(Title)<ThemeProps>`
  padding: ${({ theme }) => `${theme.spacing.S5} 0 ${theme.spacing.S2}`};
`;

const ButtonContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  padding: ${theme.spacing.S4};
`
);

const SpacedEmptyText = styled(Text)<ThemeProps>`
  margin-top: ${({ theme }) => theme.spacing.S6};
`;

interface BoxBreakChuteDetailViewProps extends Omit<BoxBreakChutesOverviewItemProps, "activeChute" | "chuteBarcode"> {
  chuteLetter: string;
  chuteData?: UnitTransfer[];
}

export const BoxBreakChuteDetailView: React.FC<BoxBreakChuteDetailViewProps> = ({
  chuteData,
  chuteLetter,
  chuteCdsku,
  chuteUnitsTotal,
  chuteSKUsTotal,
}) => {
  const [hide, setHide] = React.useState<boolean>(true);
  const { selectedSidebarChuteId, resetSelectedSidebarChuteId } = useBoxBreakState();

  React.useEffect(() => {
    setHide(!selectedSidebarChuteId);
  }, [selectedSidebarChuteId]);

  const itemsByDsku = values(groupBy(chuteData, "dsku"));

  // change class for css animation, delay hiding component altogether
  const closeChute = () => {
    setTimeout(() => {
      resetSelectedSidebarChuteId();
    }, SLIDE_RENDER_GAP_MS);
  };

  return (
    <BoxBreakChuteDetailViewContainer hide={hide}>
      <HeaderContainer>
        <SpacedTitle>
          <FormattedMessage
            id="crossdock.boxBreaksChuteDetailView.title"
            defaultMessage="Chute {chuteLetter}"
            values={{ chuteLetter }}
          />
        </SpacedTitle>
        <BoxBreakChuteContainerInfo
          chuteCdsku={chuteCdsku}
          chuteUnitsTotal={chuteUnitsTotal}
          chuteSKUsTotal={chuteSKUsTotal}
        />
        <CloseButton onClick={closeChute}>
          <IconV2 type="close" color="white" />
        </CloseButton>
      </HeaderContainer>
      <ContentContainer>
        {itemsByDsku.length ? (
          itemsByDsku.map((itemByDsku) => <BoxBreakChuteDetailInfo key={itemByDsku[0].dsku} item={itemByDsku} />)
        ) : (
          <SpacedEmptyText textAlign={TextAlign.center} appearance="INFO">
            <FormattedMessage
              id="crossdock.boxBreaksChuteDetailView.emptyContainerMessage"
              defaultMessage="Container is empty."
            />
          </SpacedEmptyText>
        )}
      </ContentContainer>
      {itemsByDsku.length ? (
        <ButtonContainer>
          <BoxBreakRemoveContainerButton />
        </ButtonContainer>
      ) : null}
    </BoxBreakChuteDetailViewContainer>
  );
};
