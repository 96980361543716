import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface AddCaseScanCaseSuccessCardProps {
  cdsku: string;
  newest?: boolean;
}

export const AddCaseScanCaseSuccessCard: React.FC<AddCaseScanCaseSuccessCardProps> = ({ cdsku, newest }) => (
  <TextInputCard icon="check" accentColor="GREEN" title="Box scanned" value={cdsku} newest={newest} disabled />
);
