import { useState } from "react";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useConnectToPrinterWarning } from "crossdock/common/zebra";
import { useCreateIncident } from "./useCreateIncident";
import { validProdASNPattern } from "@deliverr/ui-facility/lib-facility/utils/config";
import { getShippingMethod } from "./utils/getShippingMethod";
import { InboundShipMethod } from "@deliverr/ui-facility/lib-facility/types";
import { useHandlePrepIntercept } from "../../../common/prep/useHandlePrepIntercept";
import { useResetMissingLabelState } from "./useResetMissingLabelState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export function useMissingLabelScanBoxInfo() {
  const { onCreateIncidentSubmit, errorMessage } = useCreateIncident();
  const { handlePrepForPoNum, getHasIncompletePrepByPoNum } = useHandlePrepIntercept();
  const [boxInfo, setBoxInfo] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { resetMissingLabelFlow } = useResetMissingLabelState();
  const { handleUnknownError } = useScanFlow();

  const { verifyAndShowPrinterConnected } = useConnectToPrinterWarning(true);

  const resetLocalState = () => {
    setBoxInfo("");
    setLoading(false);
  };

  const submitBoxInfo = async (newBoxInfo: string) => {
    const ctx = logStart({ fn: "submitBoxInfo", boxInfo: newBoxInfo });
    setLoading(true);

    try {
      // check for prep if the box info is a PO #
      // handle prep labels and reset the flow if the scanned PO # has incomplete prep
      if (
        getShippingMethod() === InboundShipMethod.FREIGHT &&
        validProdASNPattern.test(newBoxInfo) &&
        (await getHasIncompletePrepByPoNum(newBoxInfo))
      ) {
        handlePrepForPoNum(newBoxInfo);
        resetMissingLabelFlow();
        resetLocalState();
        return;
      }
    } catch (err) {
      handleUnknownError(ctx, err);
      resetLocalState();
      return;
    }

    if (verifyAndShowPrinterConnected() && newBoxInfo !== "") {
      await onCreateIncidentSubmit(ctx, newBoxInfo, setBoxInfo);
    }
    setLoading(false);
  };

  const onBoxInfoChange = onScannerInputChange(boxInfo, setBoxInfo, submitBoxInfo);

  return {
    boxInfo,
    submitBoxInfo,
    onBoxInfoChange,
    loading,
    errorMessage,
  };
}
