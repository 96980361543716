import { useRecoilValue } from "recoil";
import { useState } from "react";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { crossdockClient } from "crossdock/base/Clients";
import { emptyChuteState } from "../../station/stationState";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { INVALID_CHUTE_ERROR, WRONG_CHUTE_ERROR } from "crossdock/common/flow/inputErrorMessages";
import { CrossdockError } from "@deliverr/commons-clients";
import { useLinkContainerError } from "../useLinkContainerError";
import { backToLinkContainerScanContainer, toLinkContainerSuccess } from "../LinkContainer.transitions";
import { LINK_CONTAINER_LABELS as lcLabels } from "../LinkContainer.labels";
import { useIntl } from "react-intl";
import { useBoxBreakStationReadyTransition } from "../../station/useBoxBreakStationReadyTransition";
import { useGetBBStationReady } from "../../station/useGetBBStationReady";
import { isValidBoxBreakChuteBarcode } from "@deliverr/crossdock-service-client";
import { useBoxBreakChute } from "../../BoxBreak/hooks";
import { currentBoxBarcodeState } from "../../BoxBreak/boxBreakState";

export function useLinkContainerScanChute(containerBarcode: string) {
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useLinkContainerError();
  const [chuteId, setChuteId] = useState<string>("");
  const resetChuteId = () => {
    setChuteId("");
  };
  const { transition, handleUnknownError } = useScanFlow();
  const emptyChute = useRecoilValue(emptyChuteState);
  const { formatMessage } = useIntl();
  const getBoxBreakStationReadyTransition = useBoxBreakStationReadyTransition();
  const getBBStationReady = useGetBBStationReady();
  const { refreshChutes } = useBoxBreakChute();

  const handleLinkContainerError = (ctx: any, err: any, newChuteId: string) => {
    // For some of these cases we send back to scan container because there's
    // an issue with container scanned. This is a limitation of not having an
    // intermediate endpoint we can call to validate the container.
    switch (err?.code) {
      case CrossdockError.CHUTE_NOT_FOUND:
        log(ctx, "non-existent chute entered", { err });
        inputError(formatMessage(lcLabels.errorChuteNotFound));
        return;

      case CrossdockError.PACKAGE_NOT_FOUND:
        log(ctx, "non-existent package entered", { err });
        inputError(formatMessage(lcLabels.errorContainerNotFound));
        transition(backToLinkContainerScanContainer());
        return;

      case CrossdockError.BOX_PREVIOUSLY_LINKED_TO_CHUTE:
        log(ctx, "container already linked", { err });
        inputError(
          formatMessage(
            err?.payload?.chuteId === newChuteId
              ? lcLabels.errorUsedContainer
              : lcLabels.errorContainerLinkedToDifferentChute
          )
        );
        transition(backToLinkContainerScanContainer());
        return;
      case CrossdockError.PACKAGE_CAN_NOT_BE_LINKED_TO_CHUTE:
        log(ctx, "seller box scanned instead of empty container");
        inputError("Seller box scanned. Scan an empty container.");
        transition(backToLinkContainerScanContainer());
        return;

      default:
        handleUnknownError(ctx, err);
        transition(backToLinkContainerScanContainer());
        return;
    }
  };

  const submitLinkContainerScanChute = async (newChuteId: string): Promise<void> => {
    const ctx = logStart({ fn: "submitLinkContainerScanChute", chuteId: newChuteId, containerBarcode });

    if (!isValidBoxBreakChuteBarcode(newChuteId)) {
      log(ctx, "invalid chute id");
      inputError(INVALID_CHUTE_ERROR, resetChuteId);
      return;
    } else if (newChuteId !== emptyChute?.barcode) {
      log({ ...ctx, emptyChuteBarcode: emptyChute?.barcode }, "scanned different chute than expected");
      inputError(WRONG_CHUTE_ERROR, resetChuteId);
      return;
    }

    try {
      await crossdockClient.linkContainerToChuteV2(newChuteId, currentBoxBarcode, containerBarcode);
      log(ctx, "linked container to chute");

      await refreshChutes([emptyChute]);

      transition([
        toLinkContainerSuccess(emptyChute?.letter),
        {
          ...(await getBoxBreakStationReadyTransition(await getBBStationReady())),
          sfx: undefined,
        },
      ]);
    } catch (err) {
      handleLinkContainerError(ctx, err, newChuteId);
    }
  };

  const onChuteIdChange = onScannerInputChange(chuteId, setChuteId, submitLinkContainerScanChute, "upper");

  return {
    submitLinkContainerScanChute: resetErrorOnExecution(submitLinkContainerScanChute),
    onChuteIdChange,
    chuteId,
    emptyChute,
    errorMessage: inputErrorMessage,
  };
}
