import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import {
  createCrossdockDimsAndWeightCaptureCubiscanImagesCard,
  createCrossdockDimsAndWeightScanPackageCdskuCard,
  createCrossdockDimsAndWeightScanProductBarcodeCard,
  createCrossdockDimsAndWeightScanProductDimsCard,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/cards/CardCreators";

export const toCrossdockDimsAndWeightPackageCdsku = (): FlowTransition => ({
  card: createCrossdockDimsAndWeightScanPackageCdskuCard({}),
});

export const toCrossdockDimsAndWeightScanProductBarcode = (): FlowTransition => ({
  card: createCrossdockDimsAndWeightScanProductBarcodeCard({}),
});

export const toCrossdockDimsAndWeightScanProductDims = (): FlowTransition => ({
  card: createCrossdockDimsAndWeightScanProductDimsCard({}),
});

export const toCrossdockCaptureCubiscanImages = (): FlowTransition => ({
  card: createCrossdockDimsAndWeightCaptureCubiscanImagesCard({}),
});
