import { useState } from "react";
import { useIntl } from "react-intl";

import { useScanMissingLabelSku } from "../useScanMissingLabelSku";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { MissingLabelModal } from "../MissingLabelModal";
import { isValidUnitBarcode } from "@deliverr/ui-facility";

export function useInputBarcodeModal() {
  const { formatMessage } = useIntl();
  const [barcode, setBarcode] = useState<string>("");
  const { onBoxContentsQtyChange } = useScanMissingLabelSku();
  const { hideModal } = useCrossdockModal();
  const [errorMessage, setErrorMessage] = useState<string>("");

  const submitBarcode = async (newBarcode: string): Promise<void> => {
    if (newBarcode.length === 0 || !isValidUnitBarcode(newBarcode)) {
      setErrorMessage(
        formatMessage({
          id: "crossdock.missingLabelInputBarcodeModal.errorMessage",
          defaultMessage: "Please input a valid SKU barcode.",
        })
      );
      return;
    }
    await onBoxContentsQtyChange(newBarcode);
    hideModal(MissingLabelModal.MISSING_LABEL_INPUT_BARCODE_MODAL);
  };

  const onBarcodeChange = onScannerInputChange(barcode, setBarcode, submitBarcode, "upper");

  const onModalExit = () => {
    hideModal(MissingLabelModal.MISSING_LABEL_INPUT_BARCODE_MODAL);
  };

  const onNextClick = async () => {
    await submitBarcode(barcode);
  };

  return {
    barcode,
    onSubmit: submitBarcode,
    onChange: onBarcodeChange,
    onModalExit,
    onNextClick,
    errorMessage,
  };
}
