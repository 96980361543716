export enum TransfersModal {
  TRANSFERS_DESTINATION_MISMATCH = "TRANSFERS_DESTINATION_MISMATCH",
  TRANSFERS_CASE_ON_NON_OPEN_PALLET_WARNING = "TRANSFERS_CASE_ON_NON_OPEN_PALLET_WARNING",
  TRANSFERS_SHIP_PALLET_NOT_CLOSED = "TRANSFERS_SHIP_PALLET_NOT_CLOSED",
  TRANSFERS_CONFIRM_CLOSE_PALLET = "TRANSFERS_CONFIRM_CLOSE_PALLET",
  FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED = "FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED",
  FREIGHT_ARRIVAL_PALLET_ERROR = "FREIGHT_ARRIVAL_PALLET_ERROR",
  FREIGHT_ARRIVAL_DUPLICATE_INBOUND_PALLET_LABEL_MODAL = "FREIGHT_ARRIVAL_DUPLICATE_INBOUND_PALLET_LABEL_MODAL",
  FREIGHT_ARRIVAL_LOG_PALLET_WEIGHT = "FREIGHT_ARRIVAL_LOG_PALLET_WEIGHT",
  START_PROCESSING_SEND_TO_FULFILLMENT = "START_PROCESSING_SEND_TO_FULFILLMENT",
  START_PROCESSING_OVERLAPPING_SHIPPING_PLAN_SAME_SELLER = "START_PROCESSING_OVERLAPPING_SHIPPING_PLAN_SAME_SELLER",
  NO_CASE_SCAN_FOR_PALLET = "NO_CASE_SCAN_FOR_PALLET",
}
