import { IconV2Types } from "@deliverr/ui";
import { AccentColor } from "@deliverr/ui-facility/lib-facility/flow";
import { LaneType } from "@deliverr/commons-clients/lib/crossdock/models/Lane";

// we have more lane types than we actually use right now, therefore we use Partial
export const ICONS: Partial<Record<LaneType, IconV2Types>> = {
  [LaneType.GENERAL_INBOUND]: "arrow-right",
  [LaneType.PREP]: "arrow-right",
  [LaneType.PROBLEM_AREA]: "exclamation-circle",
};

export const ACCENT_COLORS: Partial<Record<LaneType, AccentColor>> = {
  [LaneType.GENERAL_INBOUND]: "BLUE",
  [LaneType.PREP]: "BLUE",
  [LaneType.PROBLEM_AREA]: "YELLOW",
};
