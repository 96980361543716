import { defineMessages, MessageDescriptor } from "react-intl";
import { LaneType } from "@deliverr/commons-clients/lib/crossdock/models/Lane";

const laneTypeToLocationMessages: Record<LaneType, MessageDescriptor> = {
  [LaneType.GENERAL_INBOUND]: {
    id: "crossdock.freightArrivalScanLane.generalInboundLocation",
    defaultMessage: "crossdock staging area or PANDA",
  },
  [LaneType.PANDA]: {
    id: "crossdock.freightArrivalScanLane.pandaLocation",
    defaultMessage: "PANDA area",
  },
  [LaneType.PREP]: {
    id: "crossdock.freightArrivalScanLane.prepLocation",
    defaultMessage: "prep staging area",
  },
  [LaneType.PROBLEM_AREA]: {
    id: "crossdock.freightArrivalScanLane.problemAreaLocation",
    defaultMessage: "problem solve area",
  },
  [LaneType.RETURNS]: {
    id: "crossdock.freightArrivalScanLane.returnsLocation",
    defaultMessage: "returns area",
  },
  [LaneType.STORAGE]: {
    id: "crossdock.freightArrivalScanLane.storageLocation",
    defaultMessage: "reserve storage",
  },
  [LaneType.TRANSLOAD]: {
    id: "crossdock.freightArrivalScanLane.transloadLocation",
    defaultMessage: "transload area",
  },
};

export default defineMessages(laneTypeToLocationMessages);
