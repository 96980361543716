import { defineMessages } from "react-intl";

export const DIMS_AND_WEIGHT_LABELS = defineMessages({
  scanProductBarcodeCardTitle: {
    id: "crossdock.dimsAndWeight.scanProductBarcodeCardTitle",
    defaultMessage: "Scan Product barcode",
  },
  scanPackageCdskuCardTitle: {
    id: "crossdock.dimsAndWeight.scanPackageCdskuCardTitle",
    defaultMessage: "Scan Package CDSKU",
  },
  invalidCdsku: {
    id: "crossdock.dimsAndWeight.invalidCdsku",
    defaultMessage: "Invalid CDSKU format",
  },
  invalidBarcode: {
    id: "crossdock.dimsAndWeight.invalidBarcode",
    defaultMessage: "Invalid barcode format",
  },
  invalidQrCode: {
    id: "crossdock.dimsAndWeight.invalidQrCode",
    defaultMessage: "Invalid dims, please scan the cubiscan QR code",
  },
  unknownBarcode: {
    id: "crossdock.dimsAndWeight.unknownBarcode",
    defaultMessage: "Barcode not recognized",
  },
  packageNotFound: {
    id: "crossdock.dimsAndWeight.packageNotFound",
    defaultMessage: "Package not found",
  },
  productNotFound: {
    id: "crossdock.dimsAndWeight.productNotFound",
    defaultMessage: "Product not found",
  },
  productBarcode: {
    id: "crossdock.dimsAndWeight.productBarcodeHelpText",
    defaultMessage: "Product barcode",
  },
  packageCdsku: {
    id: "crossdock.dimsAndWeight.packageCdskuHelpText",
    defaultMessage: 'Starts with "CD"',
  },
  productDimsQR: {
    id: "crossdock.dimsAndWeight.productDimsQRHelpText",
    defaultMessage: "Product dims QR code",
  },
  productDimsRecorded: {
    id: "crossdock.dimsAndWeight.productDimsRecorded.successNotification",
    defaultMessage:
      "Product dims was recorded successfully [{width}{lengthUnit} x {length}{lengthUnit} x {height}{lengthUnit} x {weight}{weightUnit}]",
  },
  takeDimsCardTitle: {
    id: "crossdock.dimsAndWeight.productDimsQRHelpText",
    defaultMessage: "Place item on scanner",
  },
  scanProductDimsCardTitle: {
    id: "crossdock.FcDimsAndWeightScanProductDimsCard.title",
    defaultMessage: "Confirm that the dimensions are correct and scan the QR code",
  },
  dimsAndWeightCompleteSessionModalTitle: {
    id: "crossdock.DimsAndWeightCompleteSessionModal.title",
    defaultMessage: "Unmeasured product detected?",
  },
  singularDimsAndWeightCompleteSessionModalText: {
    id: "crossdock.dimsAndWeightCompleteSessionModal.text.singular",
    defaultMessage: "There is 1 product yet to be measured. Are you sure you cannot find it?",
  },
  pluralDimsAndWeightCompleteSessionModalText: {
    id: "crossdock.dimsAndWeightCompleteSessionModal.text.plural",
    defaultMessage: "There are {numberOfPendingItems} products yet to be measured. Are you sure you cannot find them?",
  },
  dimsAndWeightKnownProductDetailsTitle: {
    id: "crossdock.DimsAndWeightKnownProductDetails.title",
    defaultMessage: "Product Details",
  },
  dimsAndWeightUnknownProductDetailsTitle: {
    id: "crossdock.DimsAndWeightUnknownProductDetails.title",
    defaultMessage: "Unknown Product",
  },
  dimsAndWeightImageUploadCardTitle: {
    id: "crossdock.DimsAndWeightImageUploadCard.title",
    defaultMessage: "Upload Cubiscan Images",
  },
  dimsAndWeightCubiscanScreenImageTitle: {
    id: "crossdock.DimsAndWeightCubiscanScreenImage.title",
    defaultMessage: "Cubiscan Screen",
  },
  dimsAndWeightCubiscanAllImageTitle: {
    id: "crossdock.DimsAndWeightCubiscanAllImage.title",
    defaultMessage: "Cubiscan All",
  },
  dimsAndWeightCubiscanProductImageTitle: {
    id: "crossdock.DimsAndWeightCubiscanProductImage.title",
    defaultMessage: "Cubiscan Product",
  },

  crossdockDimsAndWeightSubmitImagesButtonTitle: {
    id: "crossdock.CrossdockDimsAndWeightSubmitImagesButton.title",
    defaultMessage: "Submit Images",
  },
});
