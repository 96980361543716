import React from "react";
import { Button } from "@deliverr/ui";
import { usePrintLabelsButton } from "./usePrintLabelsButton";
import { FormattedMessage } from "react-intl";

export const MissingLabelPrintLabelsButton: React.FC = () => {
  const { onButtonClick } = usePrintLabelsButton();

  return (
    <Button onClick={onButtonClick} block>
      <FormattedMessage id="crossdock.missingLabelPrintLabelsButton.printLabels" defaultMessage="Print labels" />
    </Button>
  );
};
