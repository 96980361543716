export enum CrossdockStateKey {
  WAREHOUSE_ID = "SCAN_WAREHOUSE_ID",
  WAREHOUSE_NAME = "SCAN_WAREHOUSE_NAME",
  IS_HUB_WAREHOUSE = "IS_HUB_WAREHOUSE",
  CLIENT_ID = "SCAN_CLIENT_ID",
  HEADER_TITLE = "HEADER_TITLE",
  HEADER_SUBTITLE = "HEADER_SUBTITLE",
  HEADER_SHOW_LEFT_ACTION = "HEADER_SHOW_LEFT_ACTION",
  ALT_MENU = "ALT_MENU",
  FLOW_CARDS = "SCAN_FLOW_CARDS",
  SAME_ACTIVE_FLOW_CARD = "SCAN_SAME_ACTIVE_FLOW_CARD",
  FLOW_BUTTONS = "SCAN_FLOW_BUTTONS",
  FLOW_BUTTONS_VISIBLE = "SCAN_FLOW_BUTTONS_VISIBLE",
  LAST_SCAN_RESPONSE = "SCAN_LAST_SCAN_RESPONSE",
  CASE_SCANNER_CDSKU = "SCAN_CASE_SCANNER_CDSKU",
  SCAN_INPUT = "SCAN_INPUT",
  SCAN_INPUT_DISABLED = "SCAN_INPUT_DISABLED",
  SHOW_PRINTER_WARNING = "SCAN_SHOW_PRINTER_WARNING",
  TRANSFERS_DESTINATIONS = "SCAN_TRANSFERS_DESTINATIONS",
  SELECTED_TRANSFERS_DESTINATIONS = "SCAN_SELECTED_TRANSFERS_DESTINATIONS",
  SELECTED_TRANSFERS_DESTINATIONS_ARRAY = "SCAN_SELECTED_TRANSFERS_DESTINATIONS_ARRAY",
  SELECTED_TRANSFER_WAREHOUSE_IDS = "SCAN_SELECTED_TRANSFER_WAREHOUSE_IDS",
  SELECTED_TRANSFER_PALLET_BUILD_STATION_BARCODES = "SELECTED_TRANSFER_PALLET_BUILD_STATION_BARCODES",
  PALLET_LABELS_PER_WAREHOUSE = "SCAN_PALLET_LABELS_PER_WAREHOUSE",
  TOTAL_PALLET_LABELS = "SCAN_TOTAL_PALLET_LABELS",
  TRANSFERS_ADD_CASE_CDSKU = "SCAN_TRANSFERS_ADD_CASE_CDSKU",
  TRANSFERS_ADD_CASE_PALLET_ID = "SCAN_TRANSFERS_ADD_CASE_PALLET_ID",
  TRANSFERS_SHIP_PALLET_ID = "SCAN_TRANSFERS_SHIP_PALLET_ID",
  TRANSFERS_SHIP_TRACKING_CODE = "SCAN_TRANSFERS_SHIP_TRACKING_CODE",
  TRANSFERS_CREATE_PALLET_LOADING = "SCAN_TRANSFERS_CREATE_PALLET_LOADING",
  TRANSFERS_CLOSE_PALLET_LABEL_ID = "SCAN_TRANSFERS_CLOSE_PALLET_LABEL_ID",
  TRANSFERS_REOPEN_PALLET_LABEL_ID = "SCAN_TRANSFERS_REOPEN_PALLET_LABEL_ID",
  TRANSFERS_DESTINATION_MISMATCH_CONTEXT = "TRANSFERS_DESTINATION_MISMATCH_CONTEXT",
  PROBLEM_SHOW_ADD_SKU = "PROBLEM_SHOW_ADD_SKU",
  PROBLEM_SHOW_BACK_BUTTON = "PROBLEM_SHOW_BACK_BUTTON",
  MISSING_LABEL_COUNT = "SCAN_MISSING_LABEL_COUNT",
  MISSING_LABEL_SCANNED_BARCODE_ORDER = "MISSING_LABEL_SCANNED_BARCODE_ORDER",
  MISSING_LABEL_SORTED_INCIDENT_DSKUS = "MISSING_LABEL_SORTED_INCIDENT_DSKUS",
  MISSING_LABEL_SHOW_ADD_SKU = "MISSING_LABEL_SHOW_ADD_SKU",
  MISSING_LABEL_SHOW_BACK_BUTTON = "MISSING_LABEL_SHOW_BACK_BUTTON",
  MISSING_LABEL_ALL_SKUS_HAVE_QTY = "MISSING_LABEL_ALL_SKUS_HAVE_QTY",
  BOX_AUDIT_SCANNED_AUDIT = "BOX_AUDIT_SCANNED_AUDIT",
  BOX_AUDIT_ALL_SKUS_HAVE_QTY = "BOX_AUDIT_ALL_SKUS_HAVE_QTY",
  BOX_AUDIT_UNKNOWN_BARCODE = "BOX_AUDIT_UNKNOWN_BARCODE",
  BOX_AUDIT_CONTENTS = "BOX_AUDIT_CONTENTS",
  BOX_LABEL_INCIDENT = "BOX_LABEL_INCIDENT",
  MODALS = "SCAN_MODALS",
  VISIBLE_MODAL = "SCAN_VISIBLE_MODAL",
  BOX_BREAK_CHUTE_BY_BARCODE = "BOX_BREAK_CHUTE_BY_BARCODE",
  BOX_BREAK_CHUTE_BY_WAREHOUSE = "BOX_BREAK_CHUTE_BY_WAREHOUSE",
  BOX_BREAK_CHUTE_BOX_BY_BARCODE = "BOX_BREAK_CHUTE_BOX_BY_BARCODE",
  BOX_BREAK_CHUTE_BOX_BY_CDSKU = "BOX_BREAK_CHUTE_BOX_BY_CDSKU",
  BOX_BREAK_EMPTY_CHUTE = "BOX_BREAK_EMPTY_CHUTE",
  BOX_BREAK_CURRENT_BOX_BARCODE = "BOX_BREAK_CURRENT_BOX_BARCODE",
  BOX_BREAK_CURRENT_UNIT_BARCODE = "BOX_BREAK_CURRENT_UNIT_BARCODE",
  BOX_BREAK_CURRENT_UNIT_COUNT = "BOX_BREAK_CURRENT_UNIT_COUNT",
  BOX_BREAK_STATION_ID = "BOX_BREAK_STATION_ID",
  BOX_BREAK_BOX_UNIT_TRANSFERS = "BOX_BREAK_BOX_UNIT_TRANSFERS",
  BOX_BREAK_RUN_DESTINATION = "BOX_BREAK_RUN_DESTINATION",
  BOX_BREAK_LAST_UNIT_SCANNED = "BOX_BREAK_LAST_UNIT_SCANNED",
  BOX_BREAK_UNIT_TRANSFERS_BY_CHUTE_BOX = "BOX_BREAK_UNIT_TRANSFERS_BY_CHUTE_BOX",
  BOX_BREAK_ACTIVE_CHUTE_BARCODE = "BOX_BREAK_ACTIVE_CHUTE_BARCODE",
  BOX_BREAK_UNIT_TRANSFER_UNIT_TOTALS = "BOX_BREAK_UNIT_TRANSFER_UNIT_TOTALS",
  BOX_BREAK_UNIT_TRANSFER_SKU_TOTALS = "BOX_BREAK_UNIT_TRANSFER_SKU_TOTALS",
  BOX_BREAK_ACTIVE_DSKU = "BOX_BREAK_ACTIVE_DSKU",
  BOX_BREAK_SELECTED_SIDEBAR_CHUTE_ID = "BOX_BREAK_SELECTED_SIDEBAR_CHUTE_ID",
  BOX_BREAK_SELECTED_SIDEBAR_CHUTE = "BOX_BREAK_SELECTED_SIDEBAR_CHUTE",
  BOX_BREAK_BOX_CONTENTS_BARCODES = "BOX_BREAK_BOX_CONTENTS_BARCODES",
  BOX_BREAK_ALL_UNITS_DISTRIBUTION_BY_DSKU = "BOX_BREAK_ALL_UNITS_DISTRIBUTION_BY_DSKU",
  BOX_BREAK_CURRENT_UNITS_SCANNED_COUNT = "BOX_BREAK_CURRENT_UNITS_SCANNED_COUNT",
  BOX_BREAK_RUN_TARGET_COUNT = "BOX_BREAK_RUN_TARGET_COUNT",
  BOX_BREAK_ALL_TARGETS_MET = "BOX_BREAK_ALL_TARGETS_MET",
  BOX_BREAK_SHOWED_END_BREAK_MODAL = "BOX_BREAK_SHOWED_END_BREAK_MODAL",
  BOX_BREAK_IS_SINGLE_SKU_BOX = "BOX_BREAK_IS_SINGLE_SKU_BOX",
  BOX_BREAK_BULK_QUANTITY_TRANSFERRED = "BOX_BREAK_BULK_QUANTITY_TRANSFERRED",
  BOX_BREAK_BULK_TRANSFER_MAX_UNITS = "BOX_BREAK_BULK_TRANSFER_MAX_UNITS",
  BOX_BREAK_CURRENT_DESTINATION_CHUTE = "BOX_BREAK_CURRENT_DESTINATION_CHUTE",
  BOX_BREAK_SINGLE_SKU_BOX_DSKU = "BOX_BREAK_SINGLE_SKU_BOX_DSKU",
  BOX_BREAK_SINGLE_SKU_BOX_PRODUCT_BARCODE = "BOX_BREAK_SINGLE_SKU_BOX_PRODUCT_BARCODE",
  BOX_BREAK_PRODUCTS_BARCODES = "BOX_BREAK_PRODUCTS_BARCODES",
  BOX_BREAK_BULK_CURRENT_UNIT_TRANSFER = "BOX_BREAK_BULK_CURRENT_UNIT_TRANSFER",
  FREIGHT_ARRIVAL_LAST_PO = "FREIGHT_ARRIVAL_LAST_PO",
  FREIGHT_ARRIVAL_INBOUND_PALLET_LABEL_ID = "FREIGHT_ARRIVAL_INBOUND_PALLET_LABEL_ID",
  FREIGHT_ARRIVAL_PALLET_LABEL_ID = "FREIGHT_ARRIVAL_PALLET_LABEL_ID",
  FREIGHT_ARRIVAL_PO_NUM = "SCAN_FREIGHT_ARRIVAL_PO_NUM",
  FREIGHT_ARRIVAL_BOX_COUNT = "SCAN_FREIGHT_ARRIVAL_BOX_COUNT",
  FREIGHT_ARRIVAL_PREP_REQUEST = "SCAN_FREIGHT_ARRIVAL_PREP_REQUEST",
  FREIGHT_ARRIVAL_WILL_MOVE_PALLET = "FREIGHT_ARRIVAL_WILL_MOVE_PALLET",
  FREIGHT_ARRIVAL_CLASSIFICATION = "FREIGHT_ARRIVAL_CLASSIFICATION",
  FREIGHT_ARRIVAL_IS_SPD = "FREIGHT_ARRIVAL_IS_SPD",
  INBOUNDS_PRINT_PALLET_LABELS_COUNT = "INBOUNDS_PRINT_PALLET_LABELS_COUNT",
  INBOUND_CREATE_PALLET_COUNT = "INBOUND_CREATE_PALLET_COUNT",
  INBOUND_PALLET_SCAN_LABEL = "INBOUND_PALLET_SCAN_LABEL",
  INBOUND_PALLET_SCAN_INFO = "INBOUND_PALLET_SCAN_INFO",
  INBOUND_CREATE_PALLET_LOADING = "INBOUND_CREATE_PALLET_LOADING",
  INBOUND_CREATE_ERROR = "INBOUND_CREATE_ERROR",
  MOVE_PALLET_INBOUND_PALLET_LABEL_BARCODE = "MOVE_PALLET_INBOUND_PALLET_LABEL_BARCODE",

  FC_DIMS_AND_WEIGHT_PRODUCT_BARCODE = "FC_DIMS_AND_WEIGHT_PRODUCT_BARCODE",
  FC_DIMS_AND_WEIGHT_PRODUCT = "FC_DIMS_AND_WEIGHT_PRODUCT",
  FC_DIMS_AND_WEIGHT_PRODUCT_DIMS = "FC_DIMS_AND_WEIGHT_PRODUCT_DIMS",
  FC_DIMS_AND_WEIGHT_CUBISCAN_IMAGES = "FC_DIMS_AND_WEIGHT_CUBISCAN_IMAGES",

  CROSSDOCK_DIMS_AND_WEIGHT_SESSION = "CROSSDOCK_DIMS_AND_WEIGHT_SESSION",
  CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_CDSKU = "CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_CDSKU",
  CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE = "CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE",
  CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_BARCODE = "CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_BARCODE",
  CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PACKAGE_ITEM = "CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PACKAGE_ITEM",
  CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PRODUCT = "CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PRODUCT",
  CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PRODUCT_INFO_MAP = "CROSSDOCK_DIMS_AND_WEIGHT_CURRENT_PRODUCT_INFO_MAP",

  CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_DIMS = "CROSSDOCK_DIMS_AND_WEIGHT_PRODUCT_DIMS",
  CROSSDOCK_DIMS_AND_WEIGHT_CUBISCAN_IMAGES = "CROSSDOCK_DIMS_AND_WEIGHT_CUBISCAN_IMAGES",

  CROSSDOCK_DIMS_AND_WEIGHT_SCANNED_DSKUS = "CROSSDOCK_DIMS_AND_WEIGHT_SCANNED_DSKUS",
  CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_ITEMS = "CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_ITEMS",
  CROSSDOCK_DIMS_AND_WEIGHT_PENDING_PACKAGE_ITEMS = "CROSSDOCK_DIMS_AND_WEIGHT_PENDING_PACKAGE_ITEMS",
  CROSSDOCK_DIMS_AND_WEIGHT_COMPLETED_PACKAGE_ITEMS = "CROSSDOCK_DIMS_AND_WEIGHT_COMPLETED_PACKAGE_ITEMS",
}
