import { atom } from "recoil";
import { CrossdockStateKey } from "./CrossdockStateKey";

export const headerTitleState = atom<string | undefined>({
  key: CrossdockStateKey.HEADER_TITLE,
  default: undefined,
});

export const headerSubtitleState = atom<string | undefined>({
  key: CrossdockStateKey.HEADER_SUBTITLE,
  default: undefined,
});

export const showPageLeftActionState = atom<boolean>({
  key: CrossdockStateKey.HEADER_SHOW_LEFT_ACTION,
  default: false,
});
