import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { PivotTable, PivotTableProps } from "crossdock/common/components/PivotTable";

export const PalletsSummaryPivotTable = styled(PivotTable)<PivotTableProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S3};

  thead tr {
    background-color: ${theme.colors.BLUE[600]};
  }
  thead th {
    color: ${theme.colors.NEUTRAL["00"]};
    padding: ${theme.spacing.S6} ${theme.spacing.S4};
    text-align: center;
    vertical-align: baseline;
    white-space: pre;
  }
  tbody tr:nth-of-type(odd) {
    background-color: ${theme.colors.NEUTRAL["00"]};
  }
  tbody tr:nth-of-type(even) {
    background-color: ${theme.colors.BLUE[50]};
  }
  tbody tr:nth-of-type(4n) {
    font-weight: ${theme.font.weight.BOLD};
  }
  tbody td {
    text-align: center;
    padding: ${theme.spacing.S4};
  }
  tbody td:first-of-type {
    text-align: left;
  }
  tbody td:last-child {
    font-weight: ${theme.font.weight.BOLD};
    border-left: 1px solid ${theme.colors.SHADOW};
  }
  tbody tr:nth-of-type(4n) td:last-child {
    text-decoration: underline;
  }
`
);
