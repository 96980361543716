import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { FormattedMessage } from "react-intl";
import { SHIP_PALLET_SCAN_PALLET_SUCCESS_CARD_LABELS } from "./ShipPalletScanPalletSuccessCard.labels";

export interface ShipPalletScanPalletSuccessCardProps {
  palletId: string;
  newest?: boolean;
  instruction?: string;
  isUnbundledPallet?: boolean;
}

export const ShipPalletScanPalletSuccessCard: React.FC<ShipPalletScanPalletSuccessCardProps> = ({
  palletId,
  newest,
  instruction,
  isUnbundledPallet,
}) => (
  <TextInputCard
    icon="check"
    accentColor="GREEN"
    title={
      !isUnbundledPallet ? (
        <FormattedMessage {...SHIP_PALLET_SCAN_PALLET_SUCCESS_CARD_LABELS.bundledTitle} />
      ) : (
        <FormattedMessage {...SHIP_PALLET_SCAN_PALLET_SUCCESS_CARD_LABELS.unbundledTitle} />
      )
    }
    value={palletId}
    newest={newest}
    disabled
    message={instruction}
  />
);
