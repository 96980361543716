import React from "react";
import { useIntl } from "react-intl";
import { useBoxBreakUnexpectedBarcode } from "./useBoxBreakUnexpectedBarcode";
import { CancelButton, LoadingButton, ModalTemplate, dangerModalIcon } from "@deliverr/ui-facility";
import { UNEXPECTED_BARCODE_LABELS as labels } from "./BoxBreakUnrecognizedBarcodeModal.labels";

export interface BoxBreakUnrecognizedBarcodeModalProps {
  unitBarcode: string;
}

// Shown when a scanned unit barcode is valid but not associated with any product.
export const BoxBreakUnrecognizedBarcodeModal: React.FC<BoxBreakUnrecognizedBarcodeModalProps> = ({ unitBarcode }) => {
  const { formatMessage } = useIntl();
  const { onCancelClick, onEndBreakClick } = useBoxBreakUnexpectedBarcode(unitBarcode);

  return (
    <ModalTemplate
      title={formatMessage(labels.title)}
      text={formatMessage(labels.message, { unitBarcode })}
      iconProps={dangerModalIcon}
    >
      <CancelButton onClick={onCancelClick} secondary />
      <LoadingButton onClick={onEndBreakClick} appearance="DANGER">
        {formatMessage(labels.endBoxBreakButton)}
      </LoadingButton>
    </ModalTemplate>
  );
};
