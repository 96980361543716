import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { genericOnScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useIntl } from "react-intl";
import { crossdockClient } from "crossdock/base/Clients";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  fcDimsAndWeightProductBarcodeState,
  fcDimsAndWeightProductState,
} from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeightState";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { toScanProductDims } from "crossdock/modes/inspection/fc-dims-and-weight/transitions";
import { toast } from "@deliverr/ui";
import { CrossdockError } from "@deliverr/commons-clients";
import { CrossdockErrors } from "@deliverr/crossdock-service-client";
import { useLoading } from "crossdock/common/useLoading";

export function useFcDimsAndWeightScanProductBarcode() {
  const { inputErrorMessage: errorMessage, resetErrorOnExecution } = useInputErrorMessage();
  const { loading, loadWhilePending } = useLoading();
  const [productBarcode, setProductBarcode] = useRecoilState(fcDimsAndWeightProductBarcodeState);
  const resetProductBarcode = useResetRecoilState(fcDimsAndWeightProductState);
  const setProduct = useSetRecoilState(fcDimsAndWeightProductState);

  const { handleUnknownError, transition, successResponse, errorResponse } = useScanFlow();
  const { formatMessage } = useIntl();

  const submitProductBarcode = async (barcode: string) => {
    const ctx = logStart({ fn: "submitProductBarcode", barcode });

    try {
      const product = await crossdockClient.getProductByBarcode({ barcode });

      setProductBarcode(barcode);
      setProduct(product);

      log(ctx, "product scan successful");

      successResponse();

      transition(toScanProductDims());
    } catch (err) {
      resetProductBarcode();
      if (err.code === CrossdockErrors.BARCODE_NOT_FOUND) {
        errorResponse();
        toast.error(formatMessage(DIMS_AND_WEIGHT_LABELS.unknownBarcode), {
          autoClose: 5000,
        });
      } else if (err.code === CrossdockError.CROSS_DOCK_PRODUCT_NOT_FOUND) {
        errorResponse();
        toast.error(formatMessage(DIMS_AND_WEIGHT_LABELS.productNotFound), {
          autoClose: 5000,
        });
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return {
    barcode: productBarcode,
    submitProductBarcode: loadWhilePending(resetErrorOnExecution(submitProductBarcode)),
    onProductBarcodeChange: genericOnScannerInputChange(
      productBarcode,
      setProductBarcode,
      submitProductBarcode,
      "upper"
    ),
    loading,
    errorMessage,
  };
}
