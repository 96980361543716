import { atom, selector, ReadOnlySelectorOptions } from "recoil";
import { CrossdockStateKey } from "../../base/CrossdockStateKey";
import { ModalInstance, ModalsState } from "@deliverr/ui-facility";

export const visibleScanModalsState = atom<ModalsState>({
  key: CrossdockStateKey.MODALS,
  default: {},
});

export const getVisibleModal: ReadOnlySelectorOptions<ModalInstance | undefined>["get"] = ({ get }) => {
  const visibleModals = get(visibleScanModalsState);
  const modalInstance = Object.entries(visibleModals).find(([modalType, props]) => !!props);

  if (!modalInstance) {
    return;
  }
  return {
    type: modalInstance[0],
    props: modalInstance[1],
  };
};

export const visibleScanModalValue = selector<ModalInstance | undefined>({
  key: CrossdockStateKey.VISIBLE_MODAL,
  get: getVisibleModal,
});
