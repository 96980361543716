import { CrossdockError } from "@deliverr/commons-clients";
import { useIntl } from "react-intl";
import { useMount } from "react-use";
import { log, logStart, onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils";
import { INVALID_AMOUNT_POSITIVE_QTY_ERROR } from "crossdock/common/flow/inputErrorMessages";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { UNEXPECTED_SKU_UNITS_LABELS as labels } from "./BoxBreakCountUnexpectedSkuUnitsCard.labels";
import { createBoxBreakScanAnyUnitCard } from "../../../boxBreakCardCreators";
import { isValidPositiveInteger } from "../../../validation";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { useBoxBreakScanUnit } from "../../hooks/useBoxBreakScanUnit/useBoxBreakScanUnit";
import { useHandleUnexpectedBoxBreakDskus } from "./useHandleUnexpectedBoxBreakDskus";
import { lastUnitScannedState } from "../../boxBreakState";
import { useRecoilValue } from "recoil";

export const useBoxBreakCountUnexpectedSkuUnits = () => {
  const { formatMessage } = useIntl();
  const handleUnexpectedBoxBreakDskus = useHandleUnexpectedBoxBreakDskus();
  const { handleUnknownError, transition, showFlowButtons } = useScanFlow();
  const lastUnitScanned = useRecoilValue(lastUnitScannedState);

  const {
    errorMessage,
    currentUnitCount,
    setCurrentUnitCount,
    resetCurrentUnitCount,
    inputError,
    resetErrorOnExecution,
  } = useBoxBreakState();
  const { scanUnit } = useBoxBreakScanUnit();

  useMount(() => {
    showFlowButtons(ScanFlowButtonType.BOX_BREAK_UNIT_COUNT_COMPLETE, ScanFlowButtonType.BOX_BREAK_UNIT_COUNT_CANCEL);
  });

  const onUnitCountSubmit = async (): Promise<void> => {
    const ctx = logStart({ fn: "submitBoxUnitCount", currentUnitCount });

    if (!isValidPositiveInteger(currentUnitCount)) {
      log(ctx, "invalid box break station id");
      inputError(INVALID_AMOUNT_POSITIVE_QTY_ERROR, resetCurrentUnitCount);
      return;
    }

    try {
      const unitCount = Number(currentUnitCount);
      await handleUnexpectedBoxBreakDskus(unitCount);
      await scanUnit(lastUnitScanned, unitCount);
    } catch (err) {
      switch (err?.code) {
        case CrossdockError.VALIDATION_ERROR:
          log(ctx, "validation error", { err });
          inputError(formatMessage(labels.greaterThanZero));
          break;
        default:
          handleUnknownError(ctx, err);
      }
    }
  };

  const onUnitCountCancel = async (): Promise<void> => {
    resetCurrentUnitCount();
    transition({
      card: createBoxBreakScanAnyUnitCard({}),
      shouldClearNotifications: true,
    });
  };

  const onUnitCountChange = onScannerInputChange(currentUnitCount, setCurrentUnitCount, onUnitCountSubmit, "preserve");

  return {
    currentUnitCount,
    onUnitCountChange,
    onUnitCountSubmit: resetErrorOnExecution(onUnitCountSubmit),
    onUnitCountCancel: resetErrorOnExecution(onUnitCountCancel),
    errorMessage,
  };
};
