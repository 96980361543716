import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export default defineMessages({
  title: {
    id: "crossdock.printInboundPalletLabels.title",
    defaultMessage: "Print Labels",
  },
  printLabels: {
    id: "crossdock.printInboundPalletLabels.printLabels",
    defaultMessage: "Print Labels",
  },
  labelCount: {
    id: "crossdock.printInboundPalletLabels.labelCount",
    defaultMessage: "Label Count",
  },
  limitError: {
    id: "crossdock.printInboundPalletLabels.limitError",
    defaultMessage: "Label count must be less than {maxLabelCount}",
  },
  notANumber: {
    id: "crossdock.printInboundPalletLabels.limitError",
    defaultMessage: "Label count must be less than {maxLabelCount}",
  },
  zeroNumberError: crossdockLabels.zeroNumberError,
  successNotification: {
    id: "crossdock.printInboundPalletLabels.successNotification",
    defaultMessage: "Successfully generated {palletCount} inbound pallet labels.",
  },
});
