import { Chute } from "@deliverr/commons-clients";
import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useRemoveContainer } from "./useRemoveContainer";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak/BoxBreak.labels";
import { REMOVE_CONTAINER_LABELS as rcLabels } from "./RemoveContainerCard.labels";

export interface RemoveContainerCardProps {
  chuteToRemoveFrom?: Chute;
  allowCancelRemoval?: boolean;
}

export const RemoveContainerCard: React.FC<RemoveContainerCardProps> = ({ chuteToRemoveFrom, allowCancelRemoval }) => {
  const { formatMessage } = useIntl();
  const { containerBarcode, submitRemoveContainer, onContainerBarcodeChange, errorMessage } = useRemoveContainer(
    chuteToRemoveFrom,
    allowCancelRemoval
  );

  return (
    <TextInputCard
      title={
        chuteToRemoveFrom
          ? formatMessage(rcLabels.titleSpecificChute, { letter: chuteToRemoveFrom.letter })
          : formatMessage(rcLabels.titleAnyChute)
      }
      message={formatMessage(rcLabels.message)}
      value={containerBarcode}
      placeholder={formatMessage(bbLabels.placeholderContainer)}
      icon="scan"
      onChange={onContainerBarcodeChange}
      accentColor="BLUE"
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
      errorMessage={errorMessage}
      onSubmit={submitRemoveContainer}
      newest={true}
    />
  );
};
