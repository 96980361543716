import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { selector } from "recoil";
import _ from "lodash";
import {
  crossdockDimsAndWeightPackageState,
  crossdockDimsAndWeightSessionState,
} from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { DimSourceType, PackageItem } from "@deliverr/crossdock-service-client";

export const crossdockDimsAndWeightPackageItemsState = selector<{
  pendingPackageItems: PackageItem[];
  completedPackageItems: { scanned: PackageItem[]; unscanned: PackageItem[] };
}>({
  key: CrossdockStateKey.CROSSDOCK_DIMS_AND_WEIGHT_PACKAGE_ITEMS,
  get: ({ get }) => {
    const scannedPackage = get(crossdockDimsAndWeightPackageState);
    const session = get(crossdockDimsAndWeightSessionState);
    const scannedDskus = _(session?.items ?? [])
      .map(({ dsku }) => dsku)
      .compact()
      .value();

    const [pendingPackageItems, completedPackageItems] = _.partition(
      scannedPackage?.items ?? [],
      ({ dsku, product }) => {
        return (
          !scannedDskus.includes(dsku) &&
          !!product &&
          !!product.dimSourceType &&
          !([DimSourceType.Crossdock, DimSourceType.FcDimsAndWeight] as string[]).includes(product.dimSourceType)
        );
      }
    );

    const [scannedCompletedPackageItems, unscannedCompletedPackageItems] = _.partition(
      completedPackageItems,
      ({ dsku }) => scannedDskus.includes(dsku)
    );

    return {
      pendingPackageItems,
      completedPackageItems: { scanned: scannedCompletedPackageItems, unscanned: unscannedCompletedPackageItems },
    };
  },
});
