import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";
import { poNumState, lastPoState } from "./FreightArrivalState";
import labels from "./cards/FreightArrivalEnterPoNumCard/FreightArrivalEnterPoNum.labels";
import { useIntl } from "react-intl";
import { validProdASNPattern } from "@deliverr/ui-facility/lib-facility/utils/config";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useFreightArrival } from "./useFreightArrival";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { cleanNumericString } from "@deliverr/ui-facility";

export function useSubmitPoNum() {
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { formatMessage } = useIntl();
  const { handleUnknownError, successResponse } = useScanFlow();
  const { linkPalletToAsn } = useFreightArrival();

  const [poNum, setPoNum] = useRecoilState(poNumState);
  const resetPoNum = useResetRecoilState(poNumState);
  const setLastPoNum = useSetRecoilState(lastPoState);

  const submitPoNum = async (poNumToSubmit: string) => {
    const cleanedPoNum = cleanNumericString(poNumToSubmit);
    const ctx = logStart({ fn: "useFreightArrivalEnterPoNum.submitPoNum", poNum: cleanedPoNum });

    if (!validProdASNPattern.test(cleanedPoNum)) {
      inputError(formatMessage(labels.invalidInput));
      resetPoNum();
      return;
    }

    setLastPoNum(cleanedPoNum);

    try {
      await linkPalletToAsn(+cleanedPoNum);
      successResponse();
    } catch (err) {
      if (["NotFoundError", "ASN_NOT_FOUND"].includes(err?.code)) {
        inputError(formatMessage(labels.doesNotExist, { poNum: cleanedPoNum }));
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return {
    poNum,
    setPoNum,
    submitPoNum,
    onSubmit: loadWhilePending(resetErrorOnExecution(submitPoNum)),
    handleClear: resetPoNum,
    loading,
    disabled: loading,
    errorMessage: inputErrorMessage,
  };
}
