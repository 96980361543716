import { TransferPallet } from "@deliverr/commons-clients";
import { PromptModal, questionModalIcon } from "@deliverr/ui-facility";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import React from "react";
import { useClosePalletPromptModal } from "./useClosePalletPromptModal";

export interface ClosePalletPromptModalProps {
  pallet: TransferPallet;
}

export const ClosePalletPromptModal: React.FC<ClosePalletPromptModalProps> = ({ pallet }) => {
  const { onConfirmClick, onCancelClick, message, labels } = useClosePalletPromptModal(pallet);

  return (
    <PromptModal
      title={labels.title}
      text={message}
      iconProps={questionModalIcon}
      soundFx={SoundFx.INFO}
      onCancelClick={onCancelClick}
      onConfirmClick={onConfirmClick}
      cancelButtonLabel={labels.cancel}
      confirmButtonLabel={labels.confirm}
    />
  );
};
