import React from "react";
import { ScanPath } from "crossdock/base/routes";
import { PathSelectPage } from "crossdock/common/components";
import { FormattedMessage } from "react-intl";

export const Transfers: React.FC = () => (
  <PathSelectPage
    title="What do you want to do?"
    paths={[
      {
        text: <FormattedMessage id="crossdock.transfers.createPallets" defaultMessage="Create Pallets" />,
        path: ScanPath.TransfersCreatePallet,
      },
      {
        text: <FormattedMessage id="crossdock.transfers.buildPallets" defaultMessage="Build Pallets" />,
        path: ScanPath.TransfersAddCase,
      },
      {
        text: <FormattedMessage id="crossdock.transfers.closePallets" defaultMessage="Close Pallets" />,
        path: ScanPath.TransfersClosePallet,
      },
      {
        text: <FormattedMessage id="crossdock.transfers.transferPallets" defaultMessage="Transfer Pallets" />,
        path: ScanPath.TransfersShipPallet,
      },
      {
        text: <FormattedMessage id="crossdock.transfers.reopenPallets" defaultMessage="Re-open Pallets" />,
        path: ScanPath.TransfersReopenPallet,
      },
    ]}
  />
);
