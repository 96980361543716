import { defineMessages } from "react-intl";

export const labels = defineMessages({
  title: {
    id: "crossdock.boxBreakSellerMismatchModal.title",
    defaultMessage: "Seller ID Mismatch",
  },
  message: {
    id: "crossdock.boxBreakSellerMismatchModal.message",
    defaultMessage: `Seller ID of the unit scanned <boldtext>{unitSellerId}</boldtext> differs from the seller ID of the current box <boldtext>{currentBoxSellerId}</boldtext>. 
      {br}{br}If this unit <boldtext>{unitBarcode}</boldtext> belongs to a separate box, finish the current box <boldtext>{currentBoxCdsku}</boldtext> and scan the new one. 
      {br}{br}If this unit <boldtext>{unitBarcode}</boldtext> belongs to the current box <boldtext>{currentBoxCdsku}</boldtext>, eject and send to non-compliance.`,
  },
});
