import { plural } from "@deliverr/ui-facility/lib-facility/utils/stringUtils";
import {
  createDangerNotification,
  createInfoNotification,
  createSuccessNotification,
} from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";

export const getBarcodeScannedNotification = (barcode: string) => createSuccessNotification(`${barcode} scanned`);
export const getBarcodeRemovedNotification = (barcode: string) => createSuccessNotification(`${barcode} removed`);

export const getContainerScannedNotification = (containerBarcode: string) =>
  createSuccessNotification(`Container ${containerBarcode} scanned`);

export const getContainerChuteConnectedNotification = (emptyChuteLetter: string) =>
  createSuccessNotification(`Container and Chute ${emptyChuteLetter} connected`);
export const getContainerInChuteNotification = (emptyChuteLetter: string) =>
  createSuccessNotification(`Container placed in Chute ${emptyChuteLetter}`);
export const containerNotLinkedNotification = createDangerNotification("Container not linked");

export const getContainersPrintedNotification = (containersAmount: number) =>
  createSuccessNotification(`${containersAmount} container barcodes printed`);

export const getPlacedInBoxNotification = (unitBarcode: string) =>
  createSuccessNotification(`${unitBarcode} placed in box`);

export const getUnitInChuteNotification = (unitBarcode: string, chute?: string) =>
  createSuccessNotification(`Unit ${unitBarcode}${chute ? ` in Chute ${chute}` : ""}`);

export const getUnitsScannedNotification = (unitBarcode: string, units: number) =>
  createSuccessNotification(`${units} ${plural("unit", units)} ${unitBarcode} scanned`);
export const getNewDskuDetectedNotification = (dsku: string) => createSuccessNotification(`New Dsku ${dsku} detected`);
export const getUnitNotScannedNotification = (unitBarcode: string) =>
  createInfoNotification(`${unitBarcode} was not scanned`);
export const getReturnToBoxNotification = (unitBarcode: string) =>
  createSuccessNotification(`${unitBarcode} returned to box`);

export const getChuteScannedNotification = (chuteLetter: string) =>
  createSuccessNotification(`Chute ${chuteLetter} scanned`);

export const getBoxScannedNotification = (boxBarcode: string) => createSuccessNotification(`Box ${boxBarcode} scanned`);

export const getBoxEndedNotification = (boxBarcode: string) => createSuccessNotification(`Box ${boxBarcode} ended`);

export const unitPlacedInboxNotification = createSuccessNotification("Unit placed in box");
export const getSignOutNotification = (boxBreakStationId: string) =>
  createSuccessNotification(`Signed out of station ${boxBreakStationId}`);
export const getRemoveContainerNotification = (letter: string) =>
  createInfoNotification(`Chute ${letter}'s destination was changed. Please unlink the box on chute ${letter}`);

export const getBoxEndedEarlyNotification = (boxBarcode: string) =>
  createSuccessNotification(`Box ${boxBarcode} ended early`);

export const getBoxPausedNotification = () => createSuccessNotification(`Box break paused`);
