import { Table } from "@deliverr/ui";
import React, { ReactElement } from "react";

export const PivotTable: React.FC<PivotTableProps> = ({
  caption,
  colHeadings,
  rowHeadings,
  rows,
  rowColHeading = "",
  rowHeadingsColumnWidthPct = 15,
  widthsPct = [],
  defaultCellValue = "-",
  ...props
}) => {
  const getEqualColumnWidths = (nCols: number) => {
    const remainingWidthPct = 100 - rowHeadingsColumnWidthPct;
    const remainingColumnWidth = remainingWidthPct / nCols;

    const columnWidths = Array(nCols).fill(remainingColumnWidth);

    return columnWidths;
  };

  const rowData = rows.map((row, index) => {
    const rowHeading = rowHeadings[index] ?? "";
    return [rowHeading, ...row.map((cell) => cell ?? defaultCellValue)];
  });

  if ((widthsPct ?? []).length === 0) {
    widthsPct = getEqualColumnWidths(rowHeadings.length);
  }

  return (
    <Table
      caption={caption}
      headings={[rowColHeading, ...colHeadings]}
      rows={rowData}
      widths={[`${rowHeadingsColumnWidthPct}%`, ...widthsPct.map((width) => `${width}%`)]}
      {...props}
    />
  );
};

export interface PivotTableProps extends React.TableHTMLAttributes<HTMLTableElement> {
  caption: string;
  rowColHeading: string;
  colHeadings: string[];
  rowHeadings: string[];
  rows: ReactElement[][];
  defaultCellValue?: string;
  rowHeadingsColumnWidthPct?: number;
  widthsPct?: number[];
}
