import { useState } from "react";
import { useIntl } from "react-intl";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { crossdockClient } from "crossdock/base/Clients";
import { useStartProcessingPalletNotifications } from "./useStartProcessingPalletNotifications";
import { validatePalletBarcode } from "../utils/validateInboundPalletBarcode";
import labels from "./StartProcessingPallet.labels";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "../../modals/TransfersModal";
import { CrossdockError } from "@deliverr/crossdock-service-client/lib/packages/client/src/legacy-crossdock-client";

const DEFAULT_INBOUND_PALLET_ID_VALUE = "";

export function useStartProcessingPallet() {
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const { formatMessage } = useIntl();
  const { successResponse, handleUnknownError, infoResponse, errorResponse } = useScanFlow();
  const [inboundPalletId, setInboundPalletId] = useState<string>(DEFAULT_INBOUND_PALLET_ID_VALUE);
  const { showStartProcessingSuccessNotification } = useStartProcessingPalletNotifications();
  const { showModal, hideAllModals } = useCrossdockModal();

  const submitStartProcessingPallet = resetErrorOnExecution(
    async (
      newInboundPalletId: string,
      { skipOverlapCheck, skipOverlapSameSellerCheck, skipCaseUnloadedCheck } = {
        skipOverlapCheck: true,
        skipOverlapSameSellerCheck: false,
        skipCaseUnloadedCheck: false,
      }
    ): Promise<void> => {
      const ctx = logStart({ fn: "useStartProcessingPallet.submitStartProcessingPallet", newInboundPalletId });

      if (!validatePalletBarcode(newInboundPalletId)) {
        log(ctx, "invalid pallet barcode");
        inputError(formatMessage(labels.scanInboundPalletInvalidBarcode));
        setInboundPalletId(DEFAULT_INBOUND_PALLET_ID_VALUE);
        return;
      }

      try {
        await crossdockClient.inboundPalletStartProcessing(
          newInboundPalletId,
          skipOverlapCheck,
          skipOverlapSameSellerCheck,
          skipCaseUnloadedCheck
        );

        log(ctx, "Pallet marked processing");
        successResponse();
        showStartProcessingSuccessNotification(newInboundPalletId);
      } catch (err) {
        setInboundPalletId(DEFAULT_INBOUND_PALLET_ID_VALUE);
        throw err;
      }
    }
  );

  const handleSubmitStartProcessingPallet = async (newInboundPalletId: string): Promise<void> => {
    const ctx = logStart({ fn: "useStartProcessingPallet.handleSubmitStartProcessingPallet", newInboundPalletId });

    try {
      await submitStartProcessingPallet(newInboundPalletId);
    } catch (err) {
      if (err?.code === CrossdockError.ATTEMPTING_TO_PROCESS_DIRECT_PALLET) {
        infoResponse();
        setInboundPalletId(DEFAULT_INBOUND_PALLET_ID_VALUE);
        showModal(TransfersModal.START_PROCESSING_SEND_TO_FULFILLMENT, {});
      } else if (err?.code === CrossdockError.OVERLAPPING_SHIPPING_PLAN_FOR_SAME_SELLER) {
        errorResponse();
        setInboundPalletId(DEFAULT_INBOUND_PALLET_ID_VALUE);
        showModal(TransfersModal.START_PROCESSING_OVERLAPPING_SHIPPING_PLAN_SAME_SELLER, err?.payload);
      } else if (err?.code === CrossdockError.NO_CASE_SCAN_FOR_PALLET) {
        errorResponse();
        setInboundPalletId(DEFAULT_INBOUND_PALLET_ID_VALUE);

        const submitStartProcessingPalletOverrideCaseUnloadedCheck = async () => {
          logStart({
            fn: "useStartProcessingPallet.handleSubmitStartProcessingPallet.submitStartProcessingPalletOverrideCaseUnloadedCheck",
            newInboundPalletId,
          });

          try {
            await submitStartProcessingPallet(newInboundPalletId, {
              skipOverlapCheck: true,
              skipOverlapSameSellerCheck: false,
              skipCaseUnloadedCheck: true,
            });
          } catch (resubmitErr) {
            handleUnknownError(ctx, resubmitErr);
          } finally {
            hideAllModals();
          }
        };
        showModal(TransfersModal.NO_CASE_SCAN_FOR_PALLET, {
          ...err?.payload,
          submitStartProcessingPalletOverrideCaseUnloadedCheck,
        });
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  const onStagingLaneBarcodeChange = onScannerInputChange(
    inboundPalletId,
    setInboundPalletId,
    handleSubmitStartProcessingPallet,
    "upper"
  );

  return {
    onSubmit: loadWhilePending(handleSubmitStartProcessingPallet),
    onChange: onStagingLaneBarcodeChange,
    value: inboundPalletId,
    loading,
    inputErrorMessage,
  };
}
