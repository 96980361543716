import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { routes } from "crossdock/base/routes";
import { ScanPage } from "crossdock/base/ScanPage";
import { VersionCheck } from "@deliverr/ui-facility/lib-facility/base/VersionCheck";
import { Flash } from "@deliverr/ui-facility/lib-facility/components/Flash";
import { AppName } from "@deliverr/ui-facility/lib-facility/types";
import { useScanApp } from "./useScanApp";
import { CenterSpinner } from "@deliverr/ui-facility/lib-facility/components";
import { useAdminCurrentWarehouseModal } from "@deliverr/ui-facility/lib-facility/components/modals";
import { useIntl } from "react-intl";
import { ToastContainer } from "@deliverr/ui";

export const ScanApp: React.FC = () => {
  const { appReady, flash, getVisibleMenuItems, isAdmin } = useScanApp();
  const { formatMessage } = useIntl();
  useAdminCurrentWarehouseModal({ app: AppName.CD });

  return (
    <>
      <ToastContainer
        position="bottom-center"
        style={{ margin: "0.5rem" }}
        toastStyle={{ maxWidth: "95vw", margin: "0.5rem 0" }}
      />
      <Switch>
        {routes.map(({ path, page, name, nameDescriptor, previousPage, leftHeaderAction, rightHeaderAction }, i) => (
          <Route path={path} exact={true} key={i}>
            {/* Need Suspense for async selectors not to break render */}
            <Suspense fallback={<CenterSpinner loading={true} />}>
              <>
                <Flash flash={flash} />
                <VersionCheck />
                {/* Show spinner while performing on app load tasks like set warehouseId */}
                <CenterSpinner loading={!appReady}>
                  <ScanPage
                    getVisibleMenuItems={getVisibleMenuItems}
                    pageName={nameDescriptor ? formatMessage(nameDescriptor) : name}
                    path={path}
                    previousPage={previousPage}
                    leftHeaderAction={leftHeaderAction}
                    rightHeaderAction={rightHeaderAction}
                    isAdmin={isAdmin}
                  >
                    {page}
                  </ScanPage>
                </CenterSpinner>
              </>
            </Suspense>
          </Route>
        ))}
      </Switch>
    </>
  );
};
