import { useIntl } from "react-intl";
import { useRecoilState, useResetRecoilState } from "recoil";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useLoading } from "crossdock/common/useLoading";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { validatePalletBarcode } from "../../../InboundPalletLabels/utils/validateInboundPalletBarcode";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { FreightArrivalScanInboundPalletLabels as labels } from "./FreightArrivalScanInboundPalletLabelCard.labels";
import { inboundPalletLabelIdState } from "../../FreightArrivalState";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { CrossdockError } from "@deliverr/commons-clients";
import { useLogPalletArrival } from "../../useLogPalletArrival";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "../../../modals/TransfersModal";

export function useScanInboundPalletLabel() {
  const { handleUnknownError } = useScanFlow();
  const { formatMessage } = useIntl();
  const { loading, loadWhilePending } = useLoading();
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [inboundPalletLabelBarcode, setInboundPalletLabelBarcode] = useRecoilState(inboundPalletLabelIdState);
  const resetInboundPalletLabelBarcode = useResetRecoilState(inboundPalletLabelIdState);
  const logPalletArrival = useLogPalletArrival();
  const { showModal } = useCrossdockModal();

  const submitInboundPalletLabelScan = async (newInboundPalletLabelBarcode: string): Promise<void> => {
    const ctx = logStart({
      fn: "submitInboundPalletLabelScan",
      inboundPalletLabelBarcode: newInboundPalletLabelBarcode,
    });

    if (!validatePalletBarcode(newInboundPalletLabelBarcode)) {
      log(ctx, "invalid pallet barcode");
      inputError(formatMessage(labels.scanInboundPalletInvalidBarcode));
      resetInboundPalletLabelBarcode();
      return;
    }

    try {
      await logPalletArrival(newInboundPalletLabelBarcode);
    } catch (err) {
      if (err?.code === CrossdockError.DUPLICATE_INBOUND_PALLET_ARRIVAL) {
        log(ctx, "duplicate inbound pallet label scanned");
        showModal(TransfersModal.FREIGHT_ARRIVAL_DUPLICATE_INBOUND_PALLET_LABEL_MODAL, {
          inboundPalletLabelBarcode: newInboundPalletLabelBarcode,
        });
        return;
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  const onInboundPalletLabeChange = onScannerInputChange(
    inboundPalletLabelBarcode,
    setInboundPalletLabelBarcode,
    submitInboundPalletLabelScan,
    "upper"
  );

  return {
    loading,
    inputErrorMessage,
    inboundPalletLabelBarcode,
    onSubmit: loadWhilePending(resetErrorOnExecution(submitInboundPalletLabelScan)),
    onChange: onInboundPalletLabeChange,
  };
}
