import { useResetRecoilState } from "recoil";
import { boxBreakStationIdState, chuteByBarcodeState, chuteBoxByBarcodeState, emptyChuteState } from "./stationState";

export function useResetStationState() {
  const resetBoxBreakStationId = useResetRecoilState(boxBreakStationIdState);
  const resetChuteByBarcode = useResetRecoilState(chuteByBarcodeState);
  const resetChuteBoxByBarcode = useResetRecoilState(chuteBoxByBarcodeState);
  const resetEmptyChute = useResetRecoilState(emptyChuteState);

  return () => {
    resetBoxBreakStationId();
    resetChuteByBarcode();
    resetChuteBoxByBarcode();
    resetEmptyChute();
  };
}
