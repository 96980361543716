import { useLifecycles } from "react-use";
import React from "react";
import { FlowV2 } from "@deliverr/ui-facility/lib-facility/flow";
import { scanFlowCardsState, flowVisibleButtonsValue } from "./scanFlowState";
import { scanFlowCardMap } from "./maps/scanFlowCardMap";
import { scanFlowButtonMap } from "./maps/scanFlowButtonMap";
import { useRecoilValue } from "recoil";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types/FlowCardData";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export interface ScanFlowV2Props {
  onMountCardData?: FlowCardData<any>;
  onMount?: () => void;
  onUnmount?: () => void;
  stopResetOnUnmount?: boolean;
  showMultipleCards?: boolean;
}

export const ScanFlowV2: React.FC<ScanFlowV2Props> = ({
  onMountCardData,
  onMount,
  onUnmount,
  showMultipleCards,
  stopResetOnUnmount,
}) => {
  const { addFlowCard, resetFlow } = useScanFlow();

  useLifecycles(
    () => {
      if (onMountCardData) {
        addFlowCard(onMountCardData);
      }
      onMount?.();
    },
    () => {
      if (!stopResetOnUnmount) {
        resetFlow();
      }
      onUnmount?.();
    }
  );

  return (
    <FlowV2
      cardsState={scanFlowCardsState}
      cardMap={scanFlowCardMap}
      visibleButtons={useRecoilValue(flowVisibleButtonsValue)}
      buttonMap={scanFlowButtonMap}
      showMultipleCards={showMultipleCards}
    />
  );
};
