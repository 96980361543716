import { isInteger, isNaN } from "lodash/fp";
import { useRecoilValue, useResetRecoilState } from "recoil";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { missingLabelCountState } from "../MissingLabelState";
import { useSolveBoxLabelIncident } from "../useSolveBoxLabelIncident";
import { MAX_LABELS_TO_PRINT } from "../MaxLabelsToPrint";

export function usePrintLabelsButton() {
  const missingLabelCount = useRecoilValue(missingLabelCountState);
  const resetMissingLabelCount = useResetRecoilState(missingLabelCountState);
  const { handleUnknownError, errorResponse } = useScanFlow();
  const { setLabelCount, printIncidentLabels, solveIncident } = useSolveBoxLabelIncident();

  const onButtonClick = async () => {
    const ctx = logStart({
      fn: "submitMissingLabelCount",
      labelCount: missingLabelCount,
      labelLimit: MAX_LABELS_TO_PRINT,
    });

    if (isNaN(missingLabelCount)) {
      log(ctx, "container amount not a number");
      errorResponse(resetMissingLabelCount);
      return;
    } else if (!isInteger(missingLabelCount)) {
      log(ctx, "non-integer entered");
      errorResponse(resetMissingLabelCount);
      return;
    } else if (missingLabelCount <= 0) {
      log(ctx, "negative container amount");
      errorResponse(resetMissingLabelCount);
      return;
    } else if (missingLabelCount > MAX_LABELS_TO_PRINT || missingLabelCount < 0) {
      log(ctx, "invalid count", { missingLabelCount, MAX_LABELS_TO_PRINT });
      errorResponse(resetMissingLabelCount);
      return;
    }

    try {
      const incidentWithLabelCount = await setLabelCount(missingLabelCount);
      const solvedIncident = await solveIncident(incidentWithLabelCount);
      await printIncidentLabels(solvedIncident);
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  return { onButtonClick };
}
