import React from "react";
import { useIntl } from "react-intl";
import styled from "@emotion/styled";

import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { SquareIcon } from "crossdock/common/components";
import { BOX_BREAK_COMMON_LABELS } from "../BoxBreak.labels";

export interface BoxBreakReturnUnitCardProps {
  unitBarcode: string;
}

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6px;
`;

export const BoxBreakReturnUnitCard: React.FC<BoxBreakReturnUnitCardProps> = ({ unitBarcode }) => {
  const { formatMessage } = useIntl();
  return (
    <TransitionCard
      title={formatMessage(BOX_BREAK_COMMON_LABELS.returnUnitCardTitle)}
      message={formatMessage(BOX_BREAK_COMMON_LABELS.returnUnitCardMessage, { unitBarcode })}
    >
      <IconContainer>
        <SquareIcon iconType="history" accentColor="BLUE" />
      </IconContainer>
    </TransitionCard>
  );
};
