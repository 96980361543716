import { defineMessages } from "react-intl";

export const ADD_CASE_SCAN_PALLET_LABELS = defineMessages({
  message: {
    id: "crossdock.addCaseScanPalletCard.message",
    defaultMessage: "Scan pallet to link {cdsku} to it.",
  },
  destinationMismatchTitle: {
    id: "crossdock.addCaseScanPalletCard.destinationMismatchTitle",
    defaultMessage: "Destination Mismatch",
  },
  destinationMismatchMessage: {
    id: "crossdock.addCaseScanPalletCard.destinationMismatchMessage",
    defaultMessage: "The box and pallet are transferring to different destinations.",
  },
  boxNeedsMeasurementTitle: {
    id: "crossdock.addCaseScanPalletCard.boxNeedsMeasurementTitle",
    defaultMessage: "Box needs measurements",
  },
  boxNeedsAuditTitle: {
    id: "crossdock.addCaseScanPalletCard.boxNeedsAuditTitle",
    defaultMessage: "Box needs audit",
  },
  unrouteablePalletTitle: {
    id: "crossdock.addCaseScanPalletCard.unrouteablePalletTitle",
    defaultMessage: "Unrouteable Pallet",
  },
  unrouteablePalletMessage: {
    id: "crossdock.addCaseScanPalletCard.unrouteablePalletMessage",
    defaultMessage:
      "Linking this box would result in an unrouteable pallet due to SKU capability restrictions. Please link to different pallet.",
  },
});
