import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../modals/BoxBreakModal";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { currentBoxBarcodeState } from "../boxBreakState";
import { usePrintBoxLabel } from "crossdock/common/printer/usePrintBoxLabel";
import { crossdockClient } from "crossdock/base/Clients";
import { ChosenScanResponseReason } from "@deliverr/commons-clients/lib/crossdock/PrecomputedScanResponse";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { useBoxBreakBox } from "./useBoxBreakBox";

// Ejecting a box assigns it a new destination for that box to be forwarded to.
// The box will stop being broken and sent to PANDA to be processed further.
// Any units removed prior to ejection will remain in their containers, and the ejected
// box will have different item values as a result. Ejection commonly takes place
// when the box cannot be broken further for some reason, such as for an unexpected SKU.
export function useBoxBreakEjectBox() {
  const { hideAllFlowButtons, handleUnknownError } = useScanFlow();
  const { hideModal } = useCrossdockModal();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  const { warehouseId: crossDockWarehouseId } = useRecoilValue(userState);
  const printBoxLabel = usePrintBoxLabel();
  const { removeBox } = useBoxBreakBox();

  return async () => {
    const ctx = logStart({ fn: "useBoxBreakEjectBox.onEndBreakClick" });

    try {
      await crossdockClient.forceTransferToPallet(
        {
          cdsku: currentBoxBarcode,
          warehouseId: crossDockWarehouseId,
        },
        ChosenScanResponseReason.EJECTION
      );
      await removeBox();
      await printBoxLabel(currentBoxBarcode);
    } catch (err) {
      handleUnknownError(ctx, err);
      return;
    } finally {
      hideModal(BoxBreakModal.BOX_BREAK_UNRECOGNIZED_BARCODE);
      hideAllFlowButtons();
    }
  };
}

// Emitted whenever we link an inbound pallet at the crossdock to an ASN.
// ASN is determined that by looking it up by freight pallet label or collecting from the user directly.
export interface CrossdockPalletArrivalEvent {
  palletId: string; // A crossdock pallet ID
  crossdockId: string; // Where the pallet was received at
  asnId?: string; // The ASN that the pallet was linked to
  arrivedAt: Date; // When the pallet was first arrival scanned
}
