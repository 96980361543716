import { Dims } from "@deliverr/crossdock-service-client";
import { DIMS_AND_WEIGHT_ERRORS } from "crossdock/modes/inspection/constants";
import { DeliverrError } from "@deliverr/commons-objects";

export enum AsciiChars {
  HORIZONTAL_TAB = "0009",
  LINE_FEED = "0010",
  CARRIAGE_RETURN = "0013",
}

/**
 * This is the first format of the QR code, which is a string of 4 numbers with no separator.
 * - 11.208.459.453.310 => [11.2, 8.45, 9.45, 3.31]
 */
export const splitQRCodeInCompressedFormat = (qrCode: string): number[] | undefined => {
  const regex = /^[0-9.]+$/;
  if (!regex.test(qrCode)) {
    return undefined;
  }

  if (
    [AsciiChars.HORIZONTAL_TAB, AsciiChars.LINE_FEED, AsciiChars.CARRIAGE_RETURN].some((element) =>
      qrCode.includes(element)
    )
  ) {
    return undefined;
  }

  try {
    let cursor = 0;
    const returnVal: string[] = [];

    for (let i = 0; i < 4; i++) {
      const numberOfDecimalPlaces = i < 3 ? 2 : 3;
      const indexOfNextPeriod = qrCode.indexOf(".", cursor);
      returnVal.push(qrCode.substring(cursor, indexOfNextPeriod + numberOfDecimalPlaces + 1));
      cursor = indexOfNextPeriod + 3;
    }
    return returnVal.map((element) => parseFloat(element));
  } catch (e) {
    return undefined;
  }
};

/**
 * This is the second format of the QR code, which is a string of 4 numbers separated by a horizontal tab character, followed by a carriage return and line feed.
 * Some scanner hardware includes a space character as a separator and will miss some of the horizontal tab character.
 * - 11.200009 8.45 9.450009 3.31000130010 => [11.2, 8.45, 9.45, 3.31]
 * - 2.1200093.4500095.9800097.12500130010 => [2.12, 3.45, 5.98, 7.125]
 */
export const splitQRCodeInExpandedFormat = (qrCode: string): number[] | undefined => {
  try {
    const textSuffix = `${AsciiChars.CARRIAGE_RETURN}${AsciiChars.LINE_FEED}`;
    const separator = qrCode.includes(" ") ? " " : AsciiChars.HORIZONTAL_TAB;

    return qrCode.split(separator).map((element) => {
      if (element.endsWith(textSuffix)) {
        element = element.replace(textSuffix, "");
      }
      if (element.endsWith(AsciiChars.HORIZONTAL_TAB)) {
        element = element.replace(AsciiChars.HORIZONTAL_TAB, "");
      }
      return parseFloat(element);
    });
  } catch (e) {
    return undefined;
  }
};

/**
 * The QR code is a string of 4 numbers separated by a horizontal tab character, followed by a carriage return and line feed.
 * i.e. "200093000950009700130010" is a valid QR code, which translates to:
 * length: 2, width: 3, height: 5, weight: 7
 */
export const getCubiscanDimsFromQRCode = (qrCode: string): Dims => {
  const dimsComponents = splitQRCodeInCompressedFormat(qrCode) ?? splitQRCodeInExpandedFormat(qrCode);

  if (
    !dimsComponents ||
    dimsComponents.length !== 4 ||
    dimsComponents.some((element) => isNaN(element) || element < 0)
  ) {
    throw new DeliverrError({ code: DIMS_AND_WEIGHT_ERRORS.INVALID_DIMS_QR_CODE });
  }

  return {
    lengthUnit: "in",
    weightUnit: "lb",
    length: dimsComponents[0],
    width: dimsComponents[1],
    height: dimsComponents[2],
    weight: dimsComponents[3],
  };
};
