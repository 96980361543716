import { ScanPath } from "crossdock/base/routes";
import { BoxLabelIncidentReason } from "@deliverr/commons-clients";

export function parseIncidentReason(pathname: string): BoxLabelIncidentReason {
  if (pathname.includes(ScanPath.MissingLabel)) {
    return BoxLabelIncidentReason.MISSING_LABEL;
  } else if (pathname.includes(ScanPath.DuplicateLabel)) {
    return BoxLabelIncidentReason.DUPLICATE_LABEL;
  } else {
    throw new Error("UNABLE_TO_PARSE_INCIDENT_REASON");
  }
}
