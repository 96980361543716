import { useRecoilValue, useResetRecoilState } from "recoil";

import { log } from "@deliverr/ui-facility/lib-facility/utils";
import { scannedBoxAuditState } from "./BoxAuditState";
import { headerSubtitleState, headerTitleState, showPageLeftActionState } from "crossdock/base/headerTitleState";
import { showAddButtonState } from "../ProblemState";
import { useCrossdockModal } from "crossdock/common/modal";
import { boxAuditUnknownBarcodeState } from ".";
import { createScanBoxCard } from "./BoxAuditCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createSuccessNotification } from "@deliverr/ui-facility/lib-facility/utils/notificationUtils";
import { useIntl } from "react-intl";
import { AUDIT_LABELS } from "./BoxAudit.labels";

export function useResetBoxAuditState() {
  const { addFlowCard, successResponse, hideAllFlowButtons, addAutoCloseNotification } = useScanFlow();
  const { hideAllModals } = useCrossdockModal();
  const scannedBoxAudit = useRecoilValue(scannedBoxAuditState);
  const resetScannedBoxAudit = useResetRecoilState(scannedBoxAuditState);
  const resetHeaderSubtitle = useResetRecoilState(headerSubtitleState);
  const resetShowAddButton = useResetRecoilState(showAddButtonState);
  const resetShowPageLeftAction = useResetRecoilState(showPageLeftActionState);
  const resetHeaderTitle = useResetRecoilState(headerTitleState);
  const resetBoxAuditUnknownBarcode = useResetRecoilState(boxAuditUnknownBarcodeState);
  const { formatMessage } = useIntl();

  const resetBoxAuditState = () => {
    resetScannedBoxAudit();
    resetHeaderSubtitle();
    resetShowAddButton();
    resetShowPageLeftAction();
    resetHeaderTitle();
    resetBoxAuditUnknownBarcode();
  };

  const restartBoxAudit = () => {
    log({ fn: "restartBoxAudit" }, "restarting box audit");
    resetBoxAuditState();
    hideAllFlowButtons();
    addFlowCard(createScanBoxCard({}));
    successResponse();
    addAutoCloseNotification(
      createSuccessNotification(formatMessage(AUDIT_LABELS.auditComplete, { cdsku: scannedBoxAudit.cdsku }))
    );
    hideAllModals();
  };

  return {
    resetBoxAuditState,
    restartBoxAudit,
  };
}
