import { useRouter } from "@deliverr/ui-facility/lib-facility/hooks";
import { ScanPath } from "crossdock/base/routes/ScanPath";
import { useSetRecoilState } from "recoil";
import { isSpdState } from "./FreightArrivalState";

export function useIsSpd() {
  const { pathname } = useRouter();
  const setIsSpd = useSetRecoilState(isSpdState);

  return () => {
    const isSpd = pathname.includes(ScanPath.SmallParcelArrival);
    setIsSpd(isSpd);
    return isSpd;
  };
}
