import styled from "@emotion/styled";
import { IconV2 } from "@deliverr/ui";
import React from "react";
import { NumberSelect } from "crossdock/common/components";
import { MAX_PALLETS_PER_WAREHOUSE, MIN_PALLETS_PER_WAREHOUSE } from "../TransfersDestinationsState";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { LoadingButton } from "@deliverr/ui-facility";
import { usePrintPalletLabels } from "./usePrintPalletLabels";

const PrintPalletLabelsSection = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: ${FACILITY_THEME.zindex.Z2};
  position: sticky;
  bottom: 0;
  background: ${FACILITY_THEME.colors.NEUTRAL["00"]};
  padding: ${FACILITY_THEME.spacing.S2};
  border-top: 1px solid ${FACILITY_THEME.colors.NEUTRAL[80]};
  margin: 0;
`;

const PrintIcon = styled(IconV2)`
  margin-right: ${FACILITY_THEME.spacing.S2};
`;

const PrintButton = styled(LoadingButton)`
  margin-top: ${FACILITY_THEME.spacing.S2};
  width: 300px;
`;

export const PrintPalletLabels: React.FC = () => {
  const {
    createPalletsAndPrintLabels,
    palletLabelsPerWarehouse,
    setPalletLabelsPerWarehouse,
    totalLabelCount,
    printButtonDisabled,
  } = usePrintPalletLabels();

  return (
    <PrintPalletLabelsSection>
      <NumberSelect
        value={palletLabelsPerWarehouse}
        onChange={setPalletLabelsPerWarehouse}
        min={MIN_PALLETS_PER_WAREHOUSE}
        max={MAX_PALLETS_PER_WAREHOUSE}
      />
      <PrintButton onClick={createPalletsAndPrintLabels} disabled={printButtonDisabled}>
        <PrintIcon type="print" />
        <span>Print Labels{totalLabelCount}</span>
      </PrintButton>
    </PrintPalletLabelsSection>
  );
};
