import { DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { isEmpty } from "lodash";
import * as React from "react";
import { useRecoilValue } from "recoil";

import { unitTransferUnitTotalsByChuteState, unitTransferSkuTotalsByChuteState } from "../boxBreakSidebarState";
import { useBoxBreakState } from "../../hooks/useBoxBreakState";
import { BoxBreakChutesOverview } from "./BoxBreakChutesOverview";
import { BoxBreakChuteDetailView } from "./BoxBreakChuteDetailView";

const BoxBreakChuteSidebarContainer = styled.aside<{ isDisplay: boolean }, DefaultTheme>(
  ({ isDisplay, theme }) => `
  position: absolute;
  right: ${isDisplay ? 0 : "-320px"};
  top: -.65rem;
  transition: right .5s ease-in-out;
  min-width: 320px;
  padding: ${theme.spacing.S5};
  background:  ${theme.colors.NEUTRAL["00"]}};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  `
);

export const BoxBreakChuteSidebar: React.FC = () => {
  const { unitTransfersByChute, chuteBoxByBarcode, selectedSidebarChuteId } = useBoxBreakState();
  const unitTransferUnitTotalsByChute = useRecoilValue(unitTransferUnitTotalsByChuteState);
  const unitTransferSkuTotalsByChute = useRecoilValue(unitTransferSkuTotalsByChuteState);

  return (
    <BoxBreakChuteSidebarContainer isDisplay={!isEmpty(chuteBoxByBarcode)}>
      <BoxBreakChutesOverview />

      <BoxBreakChuteDetailView
        chuteLetter={selectedSidebarChuteId.slice(-1)}
        chuteData={unitTransfersByChute?.[chuteBoxByBarcode[selectedSidebarChuteId]?.cdsku]}
        chuteCdsku={chuteBoxByBarcode[selectedSidebarChuteId]?.cdsku ?? ""}
        chuteUnitsTotal={unitTransferUnitTotalsByChute[chuteBoxByBarcode[selectedSidebarChuteId]?.cdsku]}
        chuteSKUsTotal={unitTransferSkuTotalsByChute[chuteBoxByBarcode[selectedSidebarChuteId]?.cdsku]}
      />
    </BoxBreakChuteSidebarContainer>
  );
};
