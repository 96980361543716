import React from "react";
import { useIntl } from "react-intl";
import { Button } from "@deliverr/ui";
import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import labels from "./FreightArrivalLogPalletWeight.labels";
import { useCrossdockModal } from "crossdock/common/modal";

export interface FreightArrivalLogPalletWeightModalProps {
  sellerId: string;
}

// Prompts the user to log the pallet weight,
// since the pallet belongs to a seller we should weight pallets for.
export const FreightArrivalLogPalletWeightModal: React.FC<FreightArrivalLogPalletWeightModalProps> = ({ sellerId }) => {
  const { hideAllModals } = useCrossdockModal();
  const { formatMessage } = useIntl();

  return (
    <ModalTemplate
      title={formatMessage(labels.title)}
      text={formatMessage(labels.message, { sellerId })}
      iconProps={warningModalIcon}
    >
      <Button onClick={hideAllModals}>{formatMessage(labels.button)}</Button>
    </ModalTemplate>
  );
};
