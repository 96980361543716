import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import labels from "./MovePallet.labels";
import { useScanInboundPallet } from "./useScanInboundPallet";
import commonLabels from "crossdock/common/CrossdockLabels";

export const MovePalletScanInboundPalletCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { inputErrorMessage, inboundPalletLabelBarcode, loading, onChange, onSubmit } = useScanInboundPallet();

  return (
    <TextInputCard
      accentColor="BLUE"
      icon="scan"
      title={formatMessage(labels.scanInboundPalletTitle)}
      placeholder={formatMessage(commonLabels.inboundPalletLabelPlaceholder)}
      errorMessage={inputErrorMessage}
      value={inboundPalletLabelBarcode}
      disabled={loading}
      onChange={onChange}
      onSubmit={onSubmit}
    />
  );
};
