import React from "react";
import { FormattedMessage } from "react-intl";
import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui/lib-esm/shared";

import { getAccentColorValue } from "@deliverr/ui-facility/lib-facility/flow";
import { FixedWidthButton, ModalTemplate } from "@deliverr/ui-facility";
import { useBoxAuditPrintingCaseLabelModal } from "./useBoxAuditPrintingCaseLabelModal";

const buttonWidth = "134px";

const ButtonContainer = styled.div<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${theme.spacing.S2};
`
);

export const BoxAuditPrintingCaseLabelModal: React.FC = () => {
  const { onCompleteClick, onReprintClick } = useBoxAuditPrintingCaseLabelModal();

  return (
    <ModalTemplate
      title={<FormattedMessage id="crossdock.boxAuditPrintingCaseLabelModal.title" defaultMessage="Printing label" />}
      text={
        <FormattedMessage
          id="crossdock.boxAuditPrintingCaseLabelModal.text"
          defaultMessage="This box's contents were not accurate, so first attach the new label and then scan this box to a pallet."
        />
      }
      iconProps={{
        type: "check-circle",
        color: getAccentColorValue("GREEN"),
        size: "3x",
      }}
    >
      <ButtonContainer>
        <FixedWidthButton width={buttonWidth} onClick={onReprintClick} secondary>
          <FormattedMessage id="crossdock.boxAuditPrintingCaseLabelModal.printBtn" defaultMessage="Reprint" />
        </FixedWidthButton>
        <FixedWidthButton width={buttonWidth} onClick={onCompleteClick}>
          <FormattedMessage id="crossdock.boxAuditPrintingCaseLabelModal.continueBtn" defaultMessage="Continue" />
        </FixedWidthButton>
      </ButtonContainer>
    </ModalTemplate>
  );
};
