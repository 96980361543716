import React from "react";
import { useIntl } from "react-intl";

import { ProblemQuitModal } from "../../components/modals/ProblemQuitModal/ProblemQuitModal";

export const MissingLabelQuitModal: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ProblemQuitModal
      title={formatMessage({ id: "crossdock.missingLabelQuitModal.title", defaultMessage: "Quit Creating Label" })}
      quitButtonLabel={formatMessage({
        id: "crossdock.missingLabelQuitModal.quitBtn",
        defaultMessage: "Quit labeling",
      })}
    />
  );
};
