import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createScanPalletCard, createMovePalletNowCard } from "./freightArrivalCardCreators";
import { crossdockClient } from "crossdock/base/Clients";
import { useIsSpd } from "./useIsSpd";
import { useSetFreightArrivalSubtitle } from "./useSetFreightArrivalSubtitle";

export function useLogPalletArrival() {
  const { addFlowCard, successResponse } = useScanFlow();
  const getIsSpd = useIsSpd();
  const { setInboundPalletSubtitle } = useSetFreightArrivalSubtitle();

  return async (inboundPalletLabelBarcode: string, force?: boolean): Promise<void> => {
    const isSpd = getIsSpd();
    await crossdockClient.logPalletArrival(inboundPalletLabelBarcode, isSpd, force);
    setInboundPalletSubtitle(inboundPalletLabelBarcode);
    successResponse();

    // SPD pallets will not have freight pallet labels, and also have multiple POs, so we skip collecting this data.
    if (isSpd) {
      addFlowCard(createMovePalletNowCard({}));
    } else {
      addFlowCard(createScanPalletCard({}));
    }
  };
}
