import { CountdownProgress, toast } from "@deliverr/ui";
import { logError } from "@deliverr/ui-facility";
import React, { ReactElement, useState } from "react";
import { PALLETS_SUMMARY_LABELS } from "./palletsSummary.labels";
import { useIntl } from "react-intl";
import { useWindowFocus } from "crossdock/common/WindowFocusHandler";

export const PalletsSummaryCountdown: React.FC<PalletsSummaryCountdownProps<ReactElement[][]>> = ({
  duration,
  expectedApiResponseTime,
  appReady,
  fetchData,
  updateData,
}) => {
  const { formatMessage } = useIntl();
  const [fetchDataPromise, setFetchDataPromise] = useState<Promise<ReactElement[][]>>(Promise.resolve([]));
  const [key, setKey] = useState<number>(0);
  const [isPaused, setIsPaused] = useState<boolean>(false);

  const refreshCountdownTimer = () => {
    setKey(key + 1);
  };

  useWindowFocus(
    () => {
      setIsPaused(false);
    },
    () => {
      setIsPaused(true);
    }
  );

  return (
    <CountdownProgress
      key={key}
      paused={!appReady || isPaused}
      duration={duration}
      translation={(count) => `Refreshing in ${count}s ${isPaused ? "(Paused)" : ""}`}
      onCountdown={(count) => {
        if (count === expectedApiResponseTime) {
          const promise = (async function () {
            try {
              const data = await fetchData();
              return data;
            } catch (err) {
              logError({ fn: "PalletsSummaryCountdown#fetchData" }, err);
              toast.error(formatMessage(PALLETS_SUMMARY_LABELS.notification.dataLoadError));
              throw err;
            }
          })();
          setFetchDataPromise(promise);
        }
      }}
      onComplete={() => {
        (async function () {
          try {
            const palletsSummary = await fetchDataPromise;
            updateData(palletsSummary);
            toast.dismiss();
          } catch (err) {
            logError({ fn: "PalletsSummaryCountdown#updateData" }, err);
            toast.error(formatMessage(PALLETS_SUMMARY_LABELS.notification.dataUpdateError));
            throw err;
          } finally {
            refreshCountdownTimer();
          }
        })().catch((err) => {
          throw err;
        });
      }}
    />
  );
};

export interface PalletsSummaryCountdownProps<T> {
  duration: number;
  expectedApiResponseTime: number;
  appReady: boolean;
  fetchData: () => Promise<T>;
  updateData: (data: T) => void;
}
