import { createFlowCard } from "@deliverr/ui-facility/lib-facility/flow/createFlowCard";
import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";
import { ReviewSkusCardProps } from "@deliverr/ui-facility";

export const createOpenCaseCard = createFlowCard<Record<string, unknown>>(ScanFlowCardType.BOX_AUDIT_OPEN_CASE);
export const createScanBoxCard = createFlowCard<Record<string, unknown>>(ScanFlowCardType.BOX_AUDIT_SCAN_BOX);
export const createBoxContentsCard = createFlowCard<Record<string, unknown>>(ScanFlowCardType.BOX_AUDIT_BOX_CONTENTS);
export const createReviewSkusCard = createFlowCard<ReviewSkusCardProps>(ScanFlowCardType.BOX_AUDIT_REVIEW_SKUS);
export const createUploadBclCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_BCL_UPLOAD
);
export const createUploadBarcodeCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_BARCODE_UPLOAD
);
export const createUploadProductCard = createFlowCard<Record<string, unknown>>(
  ScanFlowCardType.BOX_AUDIT_UNKNOWN_BARCODE_PRODUCT_UPLOAD
);
