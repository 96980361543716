import { Chute, CrossdockError } from "@deliverr/commons-clients";
import { useRecoilValue } from "recoil";
import { useState } from "react";
import { onScannerInputChange } from "@deliverr/ui-facility/lib-facility/utils/scanUtils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { crossdockClient } from "crossdock/base/Clients";
import { chuteBoxByBarcodeState, chuteBoxByCdskuState } from "../../station/stationState";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { isValidCdsku } from "@deliverr/ui-facility/lib-facility/utils/cdskuUtils";
import { useUpdateBoxBreakHeader } from "../../useUpdateBoxBreakHeader";
import { useBoxBreakChute } from "../../BoxBreak/hooks";
import { useMount } from "react-use";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useIntl } from "react-intl";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak/BoxBreak.labels";
import { REMOVE_CONTAINER_LABELS as rcLabels } from "./RemoveContainerCard.labels";
import { useBoxBreakStationReadyTransition } from "../../station/useBoxBreakStationReadyTransition";
import { useGetBBStationReady } from "../../station/useGetBBStationReady";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { currentBoxBarcodeState } from "../../BoxBreak/boxBreakState";

export function useRemoveContainer(chuteToRemoveFrom?: Chute, allowCancelRemoval = false) {
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [containerBarcode, setContainerBarcode] = useState<string>("");
  const resetContainerBarcode = () => {
    setContainerBarcode("");
  };
  const { transition, handleUnknownError, hideAllFlowButtons, showFlowButton } = useScanFlow();
  const chuteBoxByCdsku = useRecoilValue(chuteBoxByCdskuState);
  const chuteBoxByBarcode = useRecoilValue(chuteBoxByBarcodeState);
  const { setRemoveContainerHeader } = useUpdateBoxBreakHeader();
  const { formatMessage } = useIntl();
  const getBoxBreakStationReadyTransition = useBoxBreakStationReadyTransition();
  const getBBStationReady = useGetBBStationReady();
  const { clearChute } = useBoxBreakChute();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);

  useMount(() => {
    setRemoveContainerHeader();
    hideAllFlowButtons();
    if (allowCancelRemoval) {
      showFlowButton(ScanFlowButtonType.BOX_BREAK_REMOVE_CONTAINER_CANCEL);
    }
  });

  const handleRemoveContainerError = (ctx: any, err: any) => {
    switch (err?.code) {
      case CrossdockError.CHUTE_IS_NOT_LINKED:
        log(ctx, "scanned chute is not linked to a container", { err });
        inputError(formatMessage(rcLabels.errorChuteEmpty), resetContainerBarcode);
        return;

      case CrossdockError.CHUTE_CONTAINER_EMPTY:
        log(ctx, "scanned chute contains an empty container");
        inputError(formatMessage(rcLabels.errorEmptyContainer), resetContainerBarcode);
        return;

      default:
        handleUnknownError(ctx, err, resetContainerBarcode);
    }
  };

  const submitRemoveContainer = async (newContainerBarcode: string): Promise<void> => {
    const ctx = logStart({
      fn: "submitRemoveContainer",
      containerBarcode: newContainerBarcode,
      chuteToRemoveFrom,
    });

    if (!isValidCdsku(newContainerBarcode)) {
      log(ctx, "invalid container barcode");
      inputError(formatMessage(bbLabels.errorInvalidCdskuFormat), resetContainerBarcode);
      return;
    }

    if (chuteToRemoveFrom) {
      const chuteBoxToRemove = chuteBoxByBarcode[chuteToRemoveFrom.barcode];

      if (chuteBoxToRemove.cdsku !== newContainerBarcode) {
        log(ctx, "container barcode does not match expected");
        inputError(
          formatMessage(bbLabels.errorContainerMismatch, { letter: chuteToRemoveFrom.letter }),
          resetContainerBarcode
        );
        return;
      }
    }

    const chuteBox = chuteBoxByCdsku[newContainerBarcode];
    if (!chuteBox) {
      log(ctx, "attempted to remove container not linked to a chute");
      inputError(formatMessage(rcLabels.errorContainerNotLinkedToChute), resetContainerBarcode);
      return;
    }

    try {
      await crossdockClient.unlinkContainerFromChuteV2(chuteBox.chuteId, chuteBox.cdsku);
      clearChute(chuteToRemoveFrom!);
      log(ctx, "unlinked container");

      const stationReadyResponse =
        currentBoxBarcode && currentBoxBarcode.length > 0 ? await getBBStationReady() : undefined;

      transition(await getBoxBreakStationReadyTransition(stationReadyResponse));
    } catch (err) {
      handleRemoveContainerError(ctx, err);
    }
  };

  const onContainerBarcodeChange = onScannerInputChange(
    containerBarcode,
    setContainerBarcode,
    submitRemoveContainer,
    "upper"
  );

  return {
    containerBarcode,
    submitRemoveContainer: resetErrorOnExecution(submitRemoveContainer),
    onContainerBarcodeChange,
    errorMessage: inputErrorMessage,
  };
}
