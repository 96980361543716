import React from "react";
import { Flow } from "@deliverr/ui-facility/lib-facility/flow";
import { scanFlowCardsState, flowVisibleButtonsValue } from "./scanFlowState";
import { scanFlowCardMap } from "./maps/scanFlowCardMap";
import { scanFlowButtonMap } from "./maps/scanFlowButtonMap";
import { useRecoilValue } from "recoil";

export const ScanFlow = () => (
  <Flow
    cardsState={scanFlowCardsState}
    cardMap={scanFlowCardMap}
    visibleButtons={useRecoilValue(flowVisibleButtonsValue)}
    buttonMap={scanFlowButtonMap}
  />
);
