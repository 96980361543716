import { defineMessages } from "react-intl";

export const BOX_BREAK_LOGIN_LABELS = defineMessages({
  stationNotSetup: {
    id: "crossdock.boxBreakLoginCard.stationNotSetup",
    defaultMessage: "Station not set up. No chutes added.",
  },
  invalidStationId: {
    id: "crossdock.boxBreakLoginCard.invalidStationId",
    defaultMessage: "Barcode must start with 'BBS'",
  },
  stationNotFound: {
    id: "crossdock.boxBreakLoginCard.stationNotFound",
    defaultMessage: "Unable to find box break station. Did we forget to configure one for this ID?",
  },
});
