import { InboundError, TransferPalletStatus } from "@deliverr/legacy-inbound-client";
import { useRecoilState, SetterOrUpdater } from "recoil";
import { cdskuState } from "crossdock/modes/transfers/AddCaseToPallet/AddCaseToPalletState";
import { isValidCdsku, formatCdsku } from "@deliverr/ui-facility/lib-facility/utils/cdskuUtils";
import { log, logError, logStart, isScanInput } from "@deliverr/ui-facility/lib-facility/utils";
import {
  createUnknownInputCard,
  createUnknownErrorCard,
} from "@deliverr/ui-facility/lib-facility/flow/flowCardCreators";
import { inboundClient } from "crossdock/base/Clients";
import {
  createAddCaseScanCaseCard,
  createAddCaseScanCaseSuccessCard,
  createAddCaseHasPalletCard,
  createAddCaseScanPalletCard,
  createCaseUnreceivedCard,
} from "crossdock/common/flow/scanFlowCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { ScanButtonType } from "crossdock/common/flow/scanFlowState";
import { useCrossdockModal } from "crossdock/common/modal";
import { TransfersModal } from "../../modals/TransfersModal";
import { CaseOnNonOpenPalletWarningModalProps } from "../CaseOnNonOpenPalletWarningModal/CaseOnNonOpenPalletWarningModal";
import { FlashType } from "@deliverr/ui-facility/lib-facility/components/Flash";

export function submitScanCase(
  addFlowCard: (card: FlowCardData<any>) => void,
  showFlowButton: (type: ScanButtonType) => void,
  setCdsku: SetterOrUpdater<string>,
  showModal: <T>(modal: string, props: T) => void,
  emitFlash: (type: FlashType) => void
) {
  return async (cdsku: string) => {
    const ctx = logStart({ fn: "submitScanCase", cdsku });

    if (!isValidCdsku(cdsku)) {
      log(ctx, "invalid cdsku");
      setCdsku("");
      emitFlash("DANGER");
      addFlowCard(
        createUnknownInputCard({
          message: "Does not appear to be a valid box barcode",
          value: cdsku,
        })
      );
      addFlowCard(createAddCaseScanCaseCard({}));
      return;
    }

    try {
      const palletItem = await inboundClient.getPalletItem(cdsku);
      if (!palletItem) {
        emitFlash("SUCCESS");
        addFlowCard(createAddCaseScanCaseSuccessCard({ cdsku }));
      } else {
        const { status, palletLabelId, statusUpdatedAt } = await inboundClient.getPalletById(palletItem.palletId);

        // Can only remove a box from a pallet with a status of OPEN
        if (status === TransferPalletStatus.OPEN) {
          emitFlash("DEFAULT");
          addFlowCard(createAddCaseHasPalletCard({ cdsku, palletLabelId }));
        } else {
          log(ctx, "cdsku already linked to a closed pallet");
          emitFlash("DANGER");
          showModal<CaseOnNonOpenPalletWarningModalProps>(TransfersModal.TRANSFERS_CASE_ON_NON_OPEN_PALLET_WARNING, {
            cdsku,
            status,
            palletLabelId,
            statusUpdatedAt: new Date(statusUpdatedAt),
          });
          return;
        }
      }
      addFlowCard(createAddCaseScanPalletCard({}));
      showFlowButton(ScanFlowButtonType.UNDO_ADD_CASE);
    } catch (err) {
      setCdsku("");
      emitFlash("DANGER");

      if (err.code === InboundError.CDSKU_NOT_ON_TRANSFER) {
        log(ctx, "cdsku not on transfer");
        addFlowCard(createCaseUnreceivedCard({ value: cdsku }));
      } else if (err.code === InboundError.INVALID_CDSKU_SCAN) {
        log(ctx, "invalid cdsku");
        addFlowCard(
          createUnknownInputCard({
            message: "Does not appear to be a valid box barcode",
            value: cdsku,
          })
        );
      } else {
        logError(ctx, err);
        addFlowCard(createUnknownErrorCard({ value: cdsku }));
      }

      addFlowCard(createAddCaseScanCaseCard({}));
    }
  };
}

export function useAddCaseScanCase() {
  const { addFlowCard, showFlowButton, emitFlash } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const [cdsku, setCdsku] = useRecoilState(cdskuState);

  async function onCdskuChange(newCdsku: string) {
    const formattedCdsku = formatCdsku(newCdsku);
    setCdsku(formattedCdsku);
    if (isScanInput(formattedCdsku, cdsku)) {
      await submitScanCase(addFlowCard, showFlowButton, setCdsku, showModal, emitFlash)(formattedCdsku);
    }
  }

  return {
    cdsku,
    onCdskuChange,
    submitScanCase: submitScanCase(addFlowCard, showFlowButton, setCdsku, showModal, emitFlash),
  };
}
