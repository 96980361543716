import { useBoxBreakEjectBox } from "../../hooks/useBoxBreakEjectBox";
import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../BoxBreakModal";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { createBoxBreakReturnUnitCard, createBoxBreakEndEarlyCard } from "../../../boxBreakCardCreators";
import { getReturnToBoxNotification, getChuteScannedNotification } from "crossdock/modes/break/boxBreakNotifications";
import { FlowTransitionDuration, FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { lastUnitScannedState, currentRunDestinationState } from "../../boxBreakState";
import { chuteByWarehouseState } from "../../../station/stationState";
import { getVerifyChuteTransition } from "../../../transitions";

export function useBoxBreakUnexpectedBarcode(unitBarcode: string) {
  const ejectBox = useBoxBreakEjectBox();
  const { transition } = useScanFlow();
  const { hideModal } = useCrossdockModal();
  const currentRunDestination = useRecoilValue(currentRunDestinationState);
  const chuteByWarehouse = useRecoilValue(chuteByWarehouseState);
  const lastUnitScanned = useRecoilValue(lastUnitScannedState);
  const chute = chuteByWarehouse[currentRunDestination!];

  const onCancelClick = () => {
    logStart({ fn: "BoxBreakUnexpectedBarcodeModal.onCancelClick" });
    hideModal(BoxBreakModal.BOX_BREAK_UNRECOGNIZED_BARCODE);
  };

  const onEndBreakClick = async () => {
    logStart({ fn: "BoxBreakUnexpectedBarcodeModal.onEndBreakClick" });
    await ejectBox();
    hideModal(BoxBreakModal.BOX_BREAK_UNRECOGNIZED_BARCODE);

    const returnUnitTransition: FlowTransition = {
      duration: FlowTransitionDuration.MEDIUM,
      card: createBoxBreakReturnUnitCard({ unitBarcode }),
    };

    const endEarlyTransition: FlowTransition = {
      card: createBoxBreakEndEarlyCard({}),
    };

    if (lastUnitScanned.length === 0) {
      transition([
        returnUnitTransition,
        { ...endEarlyTransition, notification: getReturnToBoxNotification(unitBarcode) },
      ]);
      return;
    }

    transition([
      returnUnitTransition,
      getVerifyChuteTransition({
        chute,
        postVerifyTransition: {
          ...endEarlyTransition,
          notification: getChuteScannedNotification(chute.letter),
        },
        unitBarcode,
      }),
    ]);
  };

  return {
    onCancelClick,
    onEndBreakClick,
  };
}
