import React from "react";
import { FormattedMessage } from "react-intl";

import { getAccentColorValue } from "@deliverr/ui-facility/lib-facility/flow";
import { useResetBoxAuditState } from "./useResetBoxAuditState";
import { ModalTemplate, UnderstandButton } from "@deliverr/ui-facility";

export const BoxAuditAccurateLabelModal: React.FC = () => {
  const { restartBoxAudit } = useResetBoxAuditState();

  return (
    <ModalTemplate
      title={<FormattedMessage id="crossdock.boxAuditAccurateLabelModal.title" defaultMessage="Accurate Box" />}
      text={
        <FormattedMessage
          id="crossdock.boxAuditAccurateLabelModal.text"
          defaultMessage="This box's contents are accurate. Please scan this box to a pallet."
        />
      }
      iconProps={{
        type: "check-circle",
        color: getAccentColorValue("GREEN"),
        size: "3x",
      }}
    >
      <UnderstandButton onClick={restartBoxAudit} />
    </ModalTemplate>
  );
};
