import { useRecoilValue, useRecoilState } from "recoil";
import { selectedDestinationsArrayState, transfersDestinationsState } from "./TransfersDestinationsState";
import { useMount } from "react-use";
import { map } from "lodash/fp";
import { logError } from "@deliverr/ui-facility/lib-facility/utils";
import { uniq } from "lodash";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { crossdockClient } from "crossdock/base/Clients";

export const useCreatePallet = () => {
  const [transferDestinationsState, setTransferDestinationsState] = useRecoilState(transfersDestinationsState);
  const { warehouseId: fromWarehouseId } = useRecoilValue(userState);
  const selectedDestinationsArray = useRecoilValue(selectedDestinationsArrayState);

  useMount(async () => {
    try {
      const palletBuildStations = await crossdockClient.getPalletBuildStations(fromWarehouseId);
      const palletBuildStationBarcodes = palletBuildStations ? uniq(map("barcode", palletBuildStations)) : [];

      setTransferDestinationsState({ palletBuildStationBarcodes });
    } catch (err) {
      logError({ fn: "getTransfersDestinationsState" }, err);
      throw err;
    }
  });

  return {
    ...transferDestinationsState,
    selectedDestinationsArray,
  };
};
