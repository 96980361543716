import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useLinkContainerScanChute } from "./useLinkContainerScanChute";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { LINK_CONTAINER_LABELS as lcLabels } from "../LinkContainer.labels";

export interface LinkContainerScanChuteCardProps {
  containerBarcode: string;
}

export const LinkContainerScanChuteCard: React.FC<LinkContainerScanChuteCardProps> = ({ containerBarcode }) => {
  const { formatMessage } = useIntl();
  const { chuteId, submitLinkContainerScanChute, onChuteIdChange, emptyChute, errorMessage } =
    useLinkContainerScanChute(containerBarcode);

  return (
    <TextInputCard
      title={formatMessage(lcLabels.titleScanChute, { letter: emptyChute?.letter })}
      message={formatMessage(lcLabels.messageScanChute)}
      value={chuteId}
      placeholder={formatMessage(WarehouseMessages.chuteScanPlaceholder)}
      textIcon={emptyChute?.letter}
      onChange={onChuteIdChange}
      accentColor="BLUE"
      errorMessage={errorMessage}
      onSubmit={submitLinkContainerScanChute}
      newest={true}
    />
  );
};
