import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export default defineMessages({
  scanInboundPalletTitle: {
    id: "crossdock.movePalletScanInboundPallet.title",
    defaultMessage: "Scan Inbound Pallet Label",
  },
  scanInboundPalletInvalidBarcode: crossdockLabels.scanInboundPalletInvalidBarcode,
  scanStagingLaneTitle: {
    id: "crossdock.movePalletScanStagingLane.title",
    defaultMessage: "Scan Lane",
  },
  scanStagingLaneInvalidBarcode: crossdockLabels.invalidStagingLaneBarcode,
  scanStagingLaneSuccessNotification: {
    id: "crossdock.movePalletScanStagingLane.successNotification",
    defaultMessage: "Pallet {palletBarcode} successfully moved to lane {laneBarcode}.",
  },
});
