import React from "react";
import { useMissingLabelScanBoxInfo } from "../useMissingLabelScanBoxInfo";
import { FormattedMessage, useIntl } from "react-intl";
import { MissingLabelBoxInfoUnknownButton } from "../MissingLabelBoxInfoUnknownButton";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";

export const MissingLabelScanTrackingCard: React.FC = () => {
  const { onBoxInfoChange, submitBoxInfo, boxInfo, loading, errorMessage } = useMissingLabelScanBoxInfo();
  const { formatMessage } = useIntl();

  return (
    <ContentTextInputCard
      onSubmit={submitBoxInfo}
      title={<FormattedMessage id="crossdock.missingLabelScanTrackingCard.title" defaultMessage="Scan Tracking #" />}
      onChange={onBoxInfoChange}
      value={boxInfo}
      disabled={loading}
      errorMessage={errorMessage}
      placeholder={formatMessage({
        id: "crossdock.missingLabelScanTrackingCard.placeholder",
        defaultMessage: "Tracking #",
      })}
    >
      <MissingLabelBoxInfoUnknownButton />
    </ContentTextInputCard>
  );
};
