import { Title } from "@deliverr/ui";
import React from "react";
import { PalletsSummaryTable } from "./PalletsSummaryTable";
import { usePalletsSummaryTable } from "./usePalletsSummaryTable";
import { TextAlign } from "@deliverr/ui/lib-esm/shared";
import { PalletsSummaryCountdown } from "./PalletsSummaryCountdown";

export const PalletsSummaryHomepage: React.FC = () => {
  const { warehouseId, setPalletsSummary, getPalletsSummary, getAndSetPalletsSummary, palletsSummaryRows, appReady } =
    usePalletsSummaryTable();

  return (
    <div>
      <Title textAlign={TextAlign.center} displayAs="h2" uppercase={true}>
        {warehouseId}
      </Title>

      <PalletsSummaryCountdown
        appReady={appReady}
        duration={30}
        expectedApiResponseTime={5}
        fetchData={getPalletsSummary}
        updateData={setPalletsSummary}
      />

      <PalletsSummaryTable appReady={appReady} rows={palletsSummaryRows} onLoad={getAndSetPalletsSummary} />
    </div>
  );
};
