import { defineMessages } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export default defineMessages({
  title: {
    id: "crossdock.freightArrivalMovePalletNowCard.title",
    defaultMessage: "Move Pallet Now?",
  },
  message: {
    id: "crossdock.freightArrivalMovePalletNowCard.message",
    defaultMessage: "This pallet is ready to move to the {dropLocation}.",
  },
  yes: crossdockLabels.YesOption,
  no: crossdockLabels.NoOption,
});
