import { useSolveBoxLabelIncident } from "./useSolveBoxLabelIncident";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { BoxLabelIncidentErrorCode, NonComplianceErrors } from "@deliverr/commons-clients";
import { useCrossdockModal } from "crossdock/common/modal";
import { MissingLabelModal } from "./MissingLabelModal";
import { useIntl } from "react-intl";
import { isValidUnitBarcode } from "@deliverr/ui-facility";
import { toast } from "@deliverr/ui";

export function useScanMissingLabelSku() {
  const { setIncidentDskuQty } = useSolveBoxLabelIncident();
  const { showFlowButton, successResponse, errorResponse, handleUnknownError, addNotification } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const { formatMessage } = useIntl();

  const onBoxContentsQtyChange = async (barcode: string, newQty?: number): Promise<void> => {
    const ctx = logStart({ fn: "onBoxContentsQtyChange", barcode, newQty });

    if (barcode.length === 0 || !isValidUnitBarcode(barcode)) {
      errorResponse();
      toast.error(
        formatMessage({
          id: "crossdock.missingLabelBoxContents.error",
          defaultMessage: "Please scan a valid SKU barcode",
        }),
        {
          autoClose: 5000,
          toastId: "missingLabelBoxContentsScanError",
        }
      );
      return;
    }

    try {
      await setIncidentDskuQty(barcode, newQty);
      if (!newQty) {
        // dont want this to happen for qty adjustments
        successResponse();
      }
      showFlowButton(ScanFlowButtonType.MISSING_LABEL_SCANNING_COMPLETE);
    } catch (err) {
      if (err?.code === BoxLabelIncidentErrorCode.BARCODE_NOT_FOUND) {
        log({ ...ctx, err }, "unknown barcode");
        errorResponse();
        showModal(MissingLabelModal.MISSING_LABEL_UNEXPECTED_SKU, {});
      } else if (err?.subcode === NonComplianceErrors.VALIDATION_ERROR) {
        log({ ...ctx, err }, "validation error, quantity too large?");
        errorResponse();
        addNotification({
          appearance: "DANGER",
          content: {
            description: formatMessage({
              id: "crossdock.useScanMissingLabelSku.validationError",
              defaultMessage: "One or more items has an invalid quantity. Quantity must be < 5000 units.",
            }),
          },
        });
      } else {
        handleUnknownError(ctx, err);
      }
    }
  };

  return { onBoxContentsQtyChange };
}
