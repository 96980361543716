import { defineMessages } from "react-intl";

export const OVERLAPPING_SHIPPING_PLAN_SAME_SELLER_LABELS = defineMessages({
  title: {
    id: "crossdock.startProcessingOverlappingShippingPlanSameSellerModal.title",
    defaultMessage: "Finish the existing shipping-plan",
  },
  message: {
    id: "crossdock.startProcessingOverlappingShippingPlanSameSellerModal.message",
    defaultMessage: `{sellerId} already has an open shipping plan: {lastShippingPlanId}. Finish the existing PO before starting new shipping-plan: {shippingPlanId}.`,
  },
  pendingPalletMessage: {
    id: "crossdock.startProcessingOverlappingShippingPlanSameSellerModal.pendingPalletMessage",
    defaultMessage: "Pending pallets: {numPendingPallets}",
  },
});
