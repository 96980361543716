import React from "react";
import { ErrorTextCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { FormattedMessage } from "react-intl";
import { PALLET_TRACKING_SCAN_ERROR } from "./PalletTrackingScanErrorCard.labels";

export interface PalletTrackingScanErrorCardProps {
  palletId: string;
  errorText: string;
  newest?: boolean;
}

export const PalletTrackingScanErrorCard: React.FC<PalletTrackingScanErrorCardProps> = ({
  palletId,
  errorText,
  newest,
}) => {
  return (
    <ErrorTextCard
      title={<FormattedMessage {...PALLET_TRACKING_SCAN_ERROR.title} />}
      value={palletId}
      message={errorText}
      newest={newest}
    />
  );
};
