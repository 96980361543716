import React from "react";
import { IconV2 } from "@deliverr/ui";
import styled from "@emotion/styled";
import { SquareIcon, SquareIconProps } from "./SquareIcon";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";

interface Props {
  leftIconProps: SquareIconProps;
  rightIconProps: SquareIconProps;
}

const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${FACILITY_THEME.colors.NEUTRAL["00"]};
`;

const OffsetSquareIcon = styled(SquareIcon)<{ direction: "left" | "right" }>(
  ({ direction }) => `
  position: relative;
  ${direction}: ${FACILITY_THEME.spacing.S4};
`
);

const ArrowIconContainer = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: ${FACILITY_THEME.zindex.Z1};
  border-radius: 40px;
  width: 50px;
  height: 30px;
  border: ${FACILITY_THEME.border.width.B2} ${FACILITY_THEME.border.type} ${FACILITY_THEME.colors.NEUTRAL["00"]};
  background: ${FACILITY_THEME.colors.BLUE[100]};
  color: ${FACILITY_THEME.colors.BLUE[500]};
`;

export const IconToIcon: React.FC<Props> = ({ leftIconProps, rightIconProps }) => {
  return (
    <IconsContainer>
      <OffsetSquareIcon direction="left" {...leftIconProps} />
      <ArrowIconContainer>
        <IconV2 type="arrow-right" />
      </ArrowIconContainer>
      <OffsetSquareIcon direction="right" {...rightIconProps} />
    </IconsContainer>
  );
};
