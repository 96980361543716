import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createBoxBreakScanBoxCard, createBoxBreakScanAnyUnitCard } from "../../../boxBreakCardCreators";
import { useRecoilValue } from "recoil";
import { currentBoxBarcodeState } from "../../boxBreakState";

export function useBoxBreakRemoveContainerCancel() {
  const { addFlowCard } = useScanFlow();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);

  return () => {
    if (currentBoxBarcode.length > 0) {
      addFlowCard(createBoxBreakScanAnyUnitCard({}));
    } else {
      addFlowCard(createBoxBreakScanBoxCard({}));
    }
  };
}
