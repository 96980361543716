import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { useBoxBreakCountUnexpectedSkuUnits } from "./useBoxBreakCountUnexpectedSkuUnits";

export interface BoxBreakCountUnexpectedSkuUnitsCardProps {
  unitBarcode?: string;
}

export const BoxBreakCountUnexpectedSkuUnitsCard: React.FC<BoxBreakCountUnexpectedSkuUnitsCardProps> = ({
  unitBarcode,
}) => {
  const { currentUnitCount, onUnitCountChange, onUnitCountSubmit, errorMessage } = useBoxBreakCountUnexpectedSkuUnits();
  const { formatMessage } = useIntl();

  return (
    <TextInputCard
      title={formatMessage(WarehouseMessages.boxBreakCountUnitsTitle)}
      message={formatMessage(WarehouseMessages.boxBreakCountUnitsMessage, { unitBarcode })}
      value={currentUnitCount}
      placeholder={formatMessage(WarehouseMessages.boxBreakCountUnitsPlaceholder)}
      icon="search"
      onChange={onUnitCountChange}
      onSubmit={onUnitCountSubmit}
      accentColor="BLUE"
      errorMessage={errorMessage}
      newest={true}
    />
  );
};
