import { Chute } from "@deliverr/commons-clients";
import { atom, selector } from "recoil";
import { mapValues, sumBy, uniqBy } from "lodash";
import { CrossdockStateKey } from "../../../../base/CrossdockStateKey";
import { unitTransfersByChuteBoxState, currentRunDestinationState } from "../boxBreakState";
import { chuteByWarehouseState, chuteByBarcodeState } from "../../station/stationState";

// How many units are present in each container
export const unitTransferUnitTotalsByChuteState = selector<Record<string, number>>({
  key: CrossdockStateKey.BOX_BREAK_UNIT_TRANSFER_UNIT_TOTALS,
  get: ({ get }) => mapValues(get(unitTransfersByChuteBoxState), (chuteTransfers) => sumBy(chuteTransfers, "qty")),
});

// How many unique SKUs are present in each container
export const unitTransferSkuTotalsByChuteState = selector<Record<string, number>>({
  key: CrossdockStateKey.BOX_BREAK_UNIT_TRANSFER_SKU_TOTALS,
  get: ({ get }) =>
    mapValues(get(unitTransfersByChuteBoxState), (chuteTransfers) => uniqBy(chuteTransfers, "dsku").length),
});

// The barcodes associated with each product in each container
export const boxBreakBoxContentsBarcodesState = atom<Record<string, string>>({
  key: CrossdockStateKey.BOX_BREAK_BOX_CONTENTS_BARCODES,
  default: {},
});

// Chute whose details are currently selected on the chute overview blade
export const selectedSidebarChuteIdState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_SELECTED_SIDEBAR_CHUTE_ID,
  default: "",
});

export const selectedSidebarChuteValue = selector<Chute>({
  key: CrossdockStateKey.BOX_BREAK_SELECTED_SIDEBAR_CHUTE,
  get: ({ get }) => {
    const chuteId = get(selectedSidebarChuteIdState);
    const chuteByBarcode = get(chuteByBarcodeState);
    return chuteByBarcode?.[chuteId];
  },
});

// DSKU of the unit currently being handled
export const activeDskuValue = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_ACTIVE_DSKU,
  default: "",
});

// Chute of the current destination
export const activeChuteBarcodeValue = selector<string>({
  key: CrossdockStateKey.BOX_BREAK_ACTIVE_CHUTE_BARCODE,
  get: ({ get }) => {
    const currentRunDestination = get(currentRunDestinationState);
    const chuteByWarehouse = get(chuteByWarehouseState);
    return currentRunDestination ? chuteByWarehouse?.[currentRunDestination]?.barcode ?? "" : "";
  },
});
