import { FACILITY_COMMONS_BUTTON_LABELS } from "@deliverr/ui-facility";
import { defineMessages } from "react-intl";

export const FreightArrivalPalletDuplicateInboundPalletLabelModalLabels = defineMessages({
  title: {
    id: "crossdock.freightArrivalPalletPreviouslyRecordedModal.title",
    defaultMessage: "Duplicate inbound pallet label",
  },
  message: {
    id: "crossdock.freightArrivalPalletPreviouslyRecordedModal.message",
    defaultMessage:
      "{inboundPalletLabelBarcode} was previously arrival scanned. Confirm to reassign PO# associated with this pallet, or cancel to scan a different inbound pallet label.",
  },
  confirm: FACILITY_COMMONS_BUTTON_LABELS.confirmButton,
  cancel: FACILITY_COMMONS_BUTTON_LABELS.cancelButton,
});
