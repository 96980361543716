import { useBoxBreakEjectBox } from "../../hooks/useBoxBreakEjectBox";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { createBoxBreakEndEarlyCard } from "../../../boxBreakCardCreators";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";

export function useEjectBoxButton() {
  const ejectBox = useBoxBreakEjectBox();
  const { transition } = useScanFlow();

  return async () => {
    logStart({ fn: "ejectBoxButton" });
    await ejectBox();
    transition({
      card: createBoxBreakEndEarlyCard({}),
    });
  };
}
