import { useState } from "react";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";

export const EMPTY_ERROR_MESSAGE = "";

// Util hook to quickly set and reset input error messages.
export function useInputErrorMessage() {
  const { errorResponse } = useScanFlow();
  const [inputErrorMessage, setInputErrorMessage] = useState<string>(EMPTY_ERROR_MESSAGE);

  const resetError = () => {
    setInputErrorMessage(EMPTY_ERROR_MESSAGE);
  };

  const resetErrorOnExecution = <T extends (...arg: any[]) => Promise<any>>(fn: T) => {
    return async (...args) => {
      resetError();
      await fn(...args);
    };
  };

  const inputError = (errorMessage: string, resetFn?: () => void): void => {
    setInputErrorMessage(errorMessage);
    errorResponse(resetFn);
  };

  return { inputErrorMessage, resetError, resetErrorOnExecution, inputError };
}
