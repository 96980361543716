import { RecoilRoot } from "recoil";
import React from "react";
import ReactDOM from "react-dom";
import { ScanApp } from "crossdock/base/ScanApp";
import { EmotionCache } from "@deliverr/ui-facility/lib-facility/base/EmotionCache";
import { initializeZebraApp } from "@deliverr/ui-facility/lib-facility/base/initializeZebraApp";
import { IntlProvider } from "react-intl";
import "@deliverr/ui-facility/lib-facility/index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppName } from "@deliverr/ui-facility/lib-facility/types";
import { AuthProvider, ProtectedRoute } from "@deliverr/ui-facility/lib-facility/base";
import { initDataDog } from "@deliverr/ui-facility/lib-facility/utils/thirdParty/DataDog";
import { VERSION } from "lodash";

const DEFAULT_LOCALE = "en";

function renderApp() {
  const locale = DEFAULT_LOCALE;

  const app = (
    <IntlProvider locale={locale} defaultLocale={DEFAULT_LOCALE}>
      <RecoilRoot>
        <EmotionCache>
          <Router basename={"/"}>
            <AuthProvider app={AppName.CD}>
              <ProtectedRoute path="/" apps={[AppName.CD, AppName.HB]} component={ScanApp} />
            </AuthProvider>
          </Router>
        </EmotionCache>
      </RecoilRoot>
    </IntlProvider>
  );
  ReactDOM.render(app, document.getElementById("root"));
}

initDataDog({
  clientToken: process.env.DATADOG_CLIENT_TOKEN!,
  applicationId: process.env.DATADOG_APPLICATION_ID!,
  service: process.env.DATADOG_SERVICE!,
  version: VERSION,
});
initializeZebraApp(AppName.CD, renderApp);
