import React from "react";
import { FormattedMessage } from "react-intl";

import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { IconToIcon } from "crossdock/common/components";
export interface BoxBreakUnitInChuteCardProps {
  unitBarcode: string;
  chute: string;
}

export const BoxBreakUnitInChuteCard: React.FC<BoxBreakUnitInChuteCardProps> = ({ chute }) => (
  <TransitionCard
    title={
      <FormattedMessage
        id="crossdock.boxBreakUnitInChuteCard.title"
        defaultMessage="Place Unit Into Chute {chute}"
        values={{ chute }}
      />
    }
  >
    <IconToIcon
      leftIconProps={{
        iconType: "shopping-bag",
        accentColor: "BLUE",
      }}
      rightIconProps={{
        textIcon: chute,
        accentColor: "BLUE",
      }}
    />
  </TransitionCard>
);
