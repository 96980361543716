import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface ReopenPalletScanPalletSuccessCardProps {
  palletLabelId: string;
  newest?: boolean;
}

export const ReopenPalletScanPalletSuccessCard: React.FC<ReopenPalletScanPalletSuccessCardProps> = ({
  palletLabelId,
  newest,
}) => (
  <TextInputCard
    icon="check"
    accentColor="GREEN"
    title="Re-opened pallet"
    value={palletLabelId}
    newest={newest}
    disabled
  />
);
