import { MessageDescriptor } from "react-intl";
import { LOADING_DOCK_SCAN_ERROR_LABELS, LOADING_DOCK_SCAN_ERROR_TITLES } from "./freightLoadingDockScan.labels";

export const getFreightLoadingDockScanErrorTitleDescriptor = (error: any): MessageDescriptor => {
  const descriptor: MessageDescriptor | undefined =
    LOADING_DOCK_SCAN_ERROR_TITLES[error.response?.data?.code ?? error.response?.data];

  return (
    descriptor ?? {
      id: "loadingDockScanEventError.title.defaultError",
      defaultMessage: "Pallet not found",
    }
  );
};

export const getFreightLoadingDockScanErrorMessageDescriptor = (error: any): MessageDescriptor => {
  const descriptor: MessageDescriptor | undefined =
    LOADING_DOCK_SCAN_ERROR_LABELS[error.response?.data?.code ?? error.response?.data];

  return (
    descriptor ?? {
      id: "loadingDockScanEventError.message.defaultError",
      defaultMessage: "There was an error when scanning this pallet.",
    }
  );
};
