import { FC } from "react";
import { ShipPalletDestinationMismatchModal } from "../ShipPallet/ShipPalletDestinationMismatchModal";
import { CaseOnNonOpenPalletWarningModal } from "../AddCaseToPallet/CaseOnNonOpenPalletWarningModal";
import { TransfersModal } from "./TransfersModal";
import { ShipPalletNotClosedModal } from "../ShipPallet/ShipPalletNotClosedModal";
import { ClosePalletPromptModal } from "../ClosePallet/ClosePalletPromptModal";
import {
  FreightArrivalPalletPreviouslyRecordedModal,
  FreightArrivalDuplicateInboundPalletLabelModal,
  FreightArrivalLogPalletWeightModal,
} from "../FreightArrival";
import { StartProcessingSendToFulfillmentModal } from "../InboundPalletLabels/StartProcessingPallet/StartProcessingSendToFulfillmentModal";
import { FreightArrivalScanPalletErrorModal } from "../FreightArrival/cards/FreightArrivalScanPalletCard/FreightArrivalScanPalletErrorModal/FreightArrivalScanPalletErrorModal";
import { StartProcessingOverlappingShippingPlanSameSellerModal } from "../InboundPalletLabels/StartProcessingPallet/StartProcessingOverlappingShippingPlanSameSellerModal";
import { NoCaseScanForPalletModal } from "../InboundPalletLabels/StartProcessingPallet/NoCaseScanForPalletModal";

export const transfersModalMap: Record<TransfersModal, FC<any>> = {
  [TransfersModal.TRANSFERS_DESTINATION_MISMATCH]: ShipPalletDestinationMismatchModal,
  [TransfersModal.TRANSFERS_CASE_ON_NON_OPEN_PALLET_WARNING]: CaseOnNonOpenPalletWarningModal,
  [TransfersModal.TRANSFERS_SHIP_PALLET_NOT_CLOSED]: ShipPalletNotClosedModal,
  [TransfersModal.TRANSFERS_CONFIRM_CLOSE_PALLET]: ClosePalletPromptModal,
  [TransfersModal.FREIGHT_ARRIVAL_PALLET_PREVIOUSLY_RECORDED]: FreightArrivalPalletPreviouslyRecordedModal,
  [TransfersModal.FREIGHT_ARRIVAL_PALLET_ERROR]: FreightArrivalScanPalletErrorModal,
  [TransfersModal.FREIGHT_ARRIVAL_DUPLICATE_INBOUND_PALLET_LABEL_MODAL]: FreightArrivalDuplicateInboundPalletLabelModal,
  [TransfersModal.FREIGHT_ARRIVAL_LOG_PALLET_WEIGHT]: FreightArrivalLogPalletWeightModal,
  [TransfersModal.START_PROCESSING_SEND_TO_FULFILLMENT]: StartProcessingSendToFulfillmentModal,
  [TransfersModal.START_PROCESSING_OVERLAPPING_SHIPPING_PLAN_SAME_SELLER]:
    StartProcessingOverlappingShippingPlanSameSellerModal,
  [TransfersModal.NO_CASE_SCAN_FOR_PALLET]: NoCaseScanForPalletModal,
};
