import { Chute } from "@deliverr/commons-clients";

import { FlowTransition } from "@deliverr/ui-facility/lib-facility/flow/FlowTransition";
import { createBoxBreakVerifyChuteCard } from "../boxBreakCardCreators";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { getUnitInChuteNotification } from "../boxBreakNotifications";

export const getVerifyChuteTransition = ({
  chute,
  postVerifyTransition,
  unitBarcode,
}: {
  chute: Chute;
  postVerifyTransition?: FlowTransition;
  unitBarcode?: string;
}): FlowTransition => ({
  card: createBoxBreakVerifyChuteCard({ chuteToVerify: chute, postVerifyTransition }),
  sfx: SoundFx.INFO,
  onTransition: () => {
    if (unitBarcode) {
      getUnitInChuteNotification(unitBarcode);
    }
  },
});
