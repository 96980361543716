import { useSetRecoilState, useResetRecoilState } from "recoil";
import { fromPairs, map, difference, union, find } from "lodash";
import { crossdockClient, inboundClient } from "crossdock/base/Clients";
import {
  boxUnitTransfersState,
  boxContentsAllUnitsDistributionByDskuState,
  dskuToProductBarcodesState,
} from "../../boxBreakState";
import { boxBreakBoxContentsBarcodesState } from "../../sidebar/boxBreakSidebarState";
import { BoxBreakDistributionsByDsku } from "../../types/BoxBreakDistributionsByDsku";
import {
  formatExpectedBoxBreakDistributions,
  formatUnexpectedBoxBreakDistributions,
} from "../../utils/formatBoxBreakDistributions";
import { singleSkuBoxDskuState } from "../../../bulk/bulkState";
import { getProductsBarcodesMap } from "./getProductsBarcodesMap";

// Updates state related to the box being broken.
export function useBoxBreakBox() {
  const setBoxUnitTransfers = useSetRecoilState(boxUnitTransfersState);
  const resetBoxUnitTransfers = useResetRecoilState(boxUnitTransfersState);
  const setBoxBreakBoxContentsBarcodes = useSetRecoilState(boxBreakBoxContentsBarcodesState);
  const resetBoxBreakBoxContentsBarcodes = useResetRecoilState(boxBreakBoxContentsBarcodesState);
  const setBoxContentsAllUnitsDistributionByDsku = useSetRecoilState(boxContentsAllUnitsDistributionByDskuState);
  const resetBoxContentsAllUnitsDistributionByDsku = useResetRecoilState(boxContentsAllUnitsDistributionByDskuState);
  const setSingleSkuBoxDsku = useSetRecoilState(singleSkuBoxDskuState);
  const setDskuToProductBarcodes = useSetRecoilState(dskuToProductBarcodesState);

  const scanBox = async (boxCdsku: string) => {
    // marks the package as broken
    const { items: boxBreakBoxContents, shippingPlanId, cdsku } = await inboundClient.breakPackage(boxCdsku);
    if (boxBreakBoxContents.length === 1) {
      setSingleSkuBoxDsku(boxBreakBoxContents[0].dsku);
    }

    // get current state of units already transferred from current box being broken (in case it was paused)
    const boxUnitTransfers = await crossdockClient.listTransferredUnits({ fromCdsku: boxCdsku });
    setBoxUnitTransfers(boxUnitTransfers);

    // Box contents are based on what a seller has inputted, but they may not match the reality of what was sent.
    // Unit transfers represent the history of what has already been transferred from the current box, expected or not.
    // As a result, the difference of these sets should represent the DSKUs that are unexpected.
    const boxContentDskus = map(boxBreakBoxContents, "dsku");
    const unitTransferDskus = map(boxUnitTransfers, "dsku");
    const unexpectedDskus = difference(unitTransferDskus, boxContentDskus);

    const unexpectedDskusDistributions = fromPairs(
      await Promise.all(
        unexpectedDskus.map(async (dsku) => [
          dsku,
          await crossdockClient.getBoxBreakDistributions(shippingPlanId, dsku, undefined, cdsku),
        ])
      )
    );

    const unexpectedFormattedSkusDistributions: BoxBreakDistributionsByDsku = formatUnexpectedBoxBreakDistributions(
      unexpectedDskusDistributions,
      boxUnitTransfers
    );
    const expectedFormattedDskusDistributions: BoxBreakDistributionsByDsku = formatExpectedBoxBreakDistributions(
      boxBreakBoxContents,
      boxUnitTransfers
    );
    setBoxContentsAllUnitsDistributionByDsku({
      ...expectedFormattedDskusDistributions,
      ...unexpectedFormattedSkusDistributions,
    });

    const dskuToProductBarcodes = await getProductsBarcodesMap(boxContentDskus);
    setDskuToProductBarcodes(dskuToProductBarcodes);

    // get display barcode for each DSKU either expected in the box or unexpected and already scanned once for distribution
    // use DSKU for any item not yet scanned
    const uniqDskus = union<string>(boxContentDskus, unitTransferDskus);
    const dskuToUnitBarcode = Object.fromEntries(
      uniqDskus.map((dsku) => [dsku, find(boxUnitTransfers, ["dsku", dsku])?.unitBarcode ?? dsku])
    );
    setBoxBreakBoxContentsBarcodes(dskuToUnitBarcode);
  };

  const removeBox = async () => {
    resetBoxUnitTransfers();
    resetBoxBreakBoxContentsBarcodes();
    resetBoxContentsAllUnitsDistributionByDsku();
  };

  return {
    scanBox,
    removeBox,
  };
}
