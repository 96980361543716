import { createFlowCard } from "@deliverr/ui-facility/lib-facility/flow/createFlowCard";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx/SoundFx";
import { NoOtherProps } from "@deliverr/ui-facility/lib-facility/types/styling";
import { ScanFlowCardType } from "crossdock/common/flow/ScanFlowCardType";
import { FreightArrivalScanSuccessCardProps } from "./cards/FreightArrivalScanSuccessCard";
import { FreightArrivalScanTransferCardProps } from "./cards";

export const createScanInboundPalletLabelCard = createFlowCard<NoOtherProps>(
  ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_INBOUND_PALLET_LABEL
);
export const createScanPalletCard = createFlowCard<NoOtherProps>(ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_PALLET);
export const createEnterPoNumCard = createFlowCard<NoOtherProps>(ScanFlowCardType.FREIGHT_ARRIVAL_ENTER_PO_NUM);
export const createMovePalletNowCard = createFlowCard<NoOtherProps>(ScanFlowCardType.FREIGHT_ARRIVAL_MOVE_PALLET_NOW);
export const createScanLaneCard = createFlowCard<FreightArrivalScanSuccessCardProps>(
  ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_LANE
);
export const createFreightArrivalScanTransferCard = createFlowCard<FreightArrivalScanTransferCardProps>(
  ScanFlowCardType.FREIGHT_ARRIVAL_SCAN_TRANSFER,
  SoundFx.INFO
);
export const createFreightArrivalHighPriorityPalletCard = createFlowCard<NoOtherProps>(
  ScanFlowCardType.FREIGHT_ARRIVAL_HIGH_PRIORITY_PALLET
);
export const createFreightArrivalSendToFulfillmentCard = createFlowCard<NoOtherProps>(
  ScanFlowCardType.FREIGHT_ARRIVAL_SEND_TO_FULFILLMENT,
  SoundFx.INFO
);
