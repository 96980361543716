import React from "react";
import { useBoxAuditScanBoxInfo } from "./useBoxAuditScanBoxInfo";
import { useIntl } from "react-intl";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export const BoxAuditScanBoxCard: React.FC = () => {
  const { onBoxInfoChange, submitBoxInfo, boxInfo, loading, errorMessage } = useBoxAuditScanBoxInfo();
  const { formatMessage } = useIntl();

  return (
    <ContentTextInputCard
      onSubmit={submitBoxInfo}
      title={formatMessage({ id: "crossdock.BoxAuditScanBoxCard.title", defaultMessage: "Scan Box" })}
      onChange={onBoxInfoChange}
      value={boxInfo}
      disabled={loading}
      errorMessage={errorMessage}
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
      placeholder={formatMessage({
        id: "crossdock.BoxAuditScanBoxCard.placeholder",
        defaultMessage: "Box barcode",
      })}
    ></ContentTextInputCard>
  );
};
