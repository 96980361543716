import * as React from "react";
import { useMount } from "react-use";
import { NumberStepper } from "@deliverr/ui";
import { bulkQtyTransferredState, bulkTransferMaxUnitsValue } from "../bulkState";
import styled from "@emotion/styled";
import { useRecoilValue, useRecoilState } from "recoil";

const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: 436px;
  margin-bottom: 0;
`;

/**
 * Choose how many units were moved in a bulk transfer
 */
export const BulkTransferStepper: React.FC = () => {
  const [bulkQtyTransferred, setBulkQtyTransferred] = useRecoilState(bulkQtyTransferredState);
  const bulkTransferMaxUnits = useRecoilValue(bulkTransferMaxUnitsValue);

  // Sets a new default value after each bulk transfer
  useMount(() => {
    setBulkQtyTransferred(bulkTransferMaxUnits);
  });

  return (
    <Container>
      <NumberStepper
        value={bulkQtyTransferred}
        onChange={setBulkQtyTransferred}
        increment={1}
        min={1}
        max={bulkTransferMaxUnits}
        editableInput
      />
    </Container>
  );
};
