import { findIndex } from "lodash/fp";
import { crossdockClient } from "crossdock/base/Clients";
import { UnitTransfersByChuteBox } from "../types";
import { useBoxBreakState } from "./useBoxBreakState";
import { getUnitTransfersByChute } from "../utils/getUnitTransfersByChute";
import { BoxBreakDistributionsByDsku } from "../types/BoxBreakDistributionsByDsku";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { addItem, log, logError, logStart, replaceAtIndex } from "@deliverr/ui-facility/lib-facility/utils";
import { bulkQtyTransferredState, isSingleSkuBoxValue } from "../../bulk/bulkState";
import { useRecoilValue } from "recoil";

export const useSetTransferredUnits = () => {
  const {
    lastUnitScanned,
    boxBreakStationId,
    cdsku,
    chuteBoxByBarcode,
    boxUnitTransfers,
    setBoxUnitTransfers,
    unitTransfersByChute,
    setUnitTransfersByChute,
    boxContentsAllUnitsDistributionByDsku,
    setBoxContentsAllUnitsDistributionByDsku,
    setBoxBreakBoxContentsBarcodes,
    resetCurrentUnitCount,
  } = useBoxBreakState();
  const { handleUnknownError } = useScanFlow();
  const bulkQtyTransferred = useRecoilValue(bulkQtyTransferredState);
  const isSingleSkuBox = useRecoilValue(isSingleSkuBoxValue);

  const setTransferredUnits = async (
    newChute: string,
    unexpectedQty = 0,
    newUnitBarcode = lastUnitScanned
  ): Promise<{ dsku: string; newBoxContentsAllUnitsDistributionByDsku: BoxBreakDistributionsByDsku }> => {
    const ctx = logStart({ fn: "setTransferredUnits", chute: newChute, unitBarcode: newUnitBarcode });
    try {
      const chuteBox = chuteBoxByBarcode[newChute];
      const fromCdsku = cdsku;
      const toCdsku = chuteBox.cdsku;

      // unit transfers will be empty until we start scanning some units
      const unitTransferIndex = findIndex({ fromCdsku, toCdsku, unitBarcode: newUnitBarcode }, boxUnitTransfers);
      const unitTransfer = unitTransferIndex !== -1 ? boxUnitTransfers[unitTransferIndex] : undefined;

      const previousQty = unitTransfer?.qty ?? 0;
      const unitsTransferredQty = isSingleSkuBox ? bulkQtyTransferred : 1;

      const { unitTransfer: newUnitTransfer } = await crossdockClient.setTransferredUnitsV2({
        boxBreakStationId,
        fromCdsku,
        toCdsku,
        previousQty,
        qty: previousQty + unitsTransferredQty,
        unitBarcode: newUnitBarcode,
      });
      log(ctx, "set unit transfers");
      setBoxUnitTransfers(
        unitTransferIndex !== -1 ? replaceAtIndex(unitTransferIndex, newUnitTransfer) : addItem(newUnitTransfer)
      );
      const dsku = newUnitTransfer.dsku;
      setBoxBreakBoxContentsBarcodes((previous) => ({ ...previous, [dsku]: newUnitBarcode }));

      // update overall state of chute containers for sidebar display
      const newUnitTransfersByChute: UnitTransfersByChuteBox = getUnitTransfersByChute(
        unitTransfersByChute!,
        newUnitTransfer,
        toCdsku
      );
      setUnitTransfersByChute(newUnitTransfersByChute);

      // update state of scanned vs total units per sku for Box Contents display
      const newBoxContentsAllUnitsDistributionByDsku = {
        ...boxContentsAllUnitsDistributionByDsku,
        [dsku]: {
          unexpected: boxContentsAllUnitsDistributionByDsku[dsku]?.unexpected ?? true,
          scannedUnits: (boxContentsAllUnitsDistributionByDsku[dsku]?.scannedUnits ?? 0) + unitsTransferredQty,
          totalUnits: boxContentsAllUnitsDistributionByDsku[dsku]?.totalUnits ?? unexpectedQty,
        },
      };
      setBoxContentsAllUnitsDistributionByDsku(newBoxContentsAllUnitsDistributionByDsku);
      resetCurrentUnitCount();
      return {
        dsku: newUnitTransfer.dsku,
        newBoxContentsAllUnitsDistributionByDsku,
      };
    } catch (err) {
      logError(ctx, err);
      handleUnknownError(ctx, err);
      return {
        dsku: "",
        newBoxContentsAllUnitsDistributionByDsku: boxContentsAllUnitsDistributionByDsku,
      };
    }
  };

  return { setTransferredUnits };
};
