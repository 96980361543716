import React from "react";
import { IconV2, IconV2Types } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { AccentColor, getAccentColorValue } from "@deliverr/ui-facility/lib-facility/flow/AccentColor";
import { Property } from "csstype";

export interface SquareIconProps {
  accentColor: AccentColor;
  length?: Property.Width<string>;
  textIcon?: string;
  iconType?: IconV2Types;
  className?: string;
}

interface IconContainerProps {
  accentColor: AccentColor;
  length?: Property.Width<string>;
}

const DEFAULT_LENGTH = "64px";

const IconContainer = styled.span<IconContainerProps>(
  ({ accentColor, length }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${getAccentColorValue(accentColor)};
  color: ${FACILITY_THEME.colors.NEUTRAL["00"]};
  border-radius: ${FACILITY_THEME.border.radius.R5};
  width: ${length ?? DEFAULT_LENGTH};
  height: ${length ?? DEFAULT_LENGTH};
`
);

const TextIcon = styled.span`
  font-family: ${FACILITY_THEME.font.family.TITLE};
  font-size: ${FACILITY_THEME.font.size.F6};
  font-weight: ${FACILITY_THEME.font.weight.BOLD};
`;

export const SquareIcon: React.FC<SquareIconProps> = ({ className, accentColor, length, textIcon, iconType }) => {
  return (
    <IconContainer accentColor={accentColor} length={length} className={className}>
      {textIcon && <TextIcon>{textIcon}</TextIcon>}
      {iconType && <IconV2 type={iconType} size="lg" />}
    </IconContainer>
  );
};
