import { defineMessages } from "react-intl";

export const LINK_CONTAINER_LABELS = defineMessages({
  titleScanContainer: {
    id: "crossdock.linkContainer.titleScanContainer",
    defaultMessage: "Scan Empty Container",
  },
  messageScanContainer: {
    id: "crossdock.linkContainer.messageScanContainer",
    defaultMessage: "Scan an empty container to link it to a chute",
  },
  titleScanChute: {
    id: "crossdock.linkContainer.titleScanChute",
    defaultMessage: "Scan Chute {letter}",
  },
  messageScanChute: {
    id: "crossdock.linkContainer.messageScanChute",
    defaultMessage: "Scan to confirm the correct chute ID",
  },
  errorChuteNotFound: {
    id: "crossdock.linkContainer.errorChuteNotFound",
    defaultMessage: "Chute not found",
  },
  errorContainerNotFound: {
    id: "crossdock.linkContainer.errorChuteNotFound",
    defaultMessage: "Container not found",
  },
  errorUsedContainer: {
    id: "crossdock.linkContainer.errorUsedContainer",
    defaultMessage: "Container used. Try a different container.",
  },
  errorContainerLinkedToDifferentChute: {
    id: "crossdock.linkContainer.errorContainerLinkedToDifferentChute",
    defaultMessage: "Container linked to another chute. Try a different container. ",
  },
});
