import { FlowCardData } from "@deliverr/ui-facility/lib-facility/flow/types";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";
import { CaseUnreceivedCardProps } from "crossdock/common/flow/cards";
import {
  ScannerScanCardProps,
  SortByBoxBreakCardProps,
  SortByWarehouseCardProps,
  SortPalletToMilkRunCardErrorProps,
  SortPalletToMilkRunCardProps,
} from "crossdock/modes/caseScanner/cards";
import {
  ShipPalletScanPalletCardProps,
  ShipPalletScanPalletSuccessCardProps,
  ShipPalletHasTrackingCardProps,
  ShipPalletScanTrackingCardProps,
  ShipPalletScanTrackingSuccessCardProps,
  ShipPalletScanTrackingInUseCardProps,
} from "crossdock/modes/transfers/ShipPallet";
import {
  AddCaseScanCaseCardProps,
  AddCaseScanCaseSuccessCardProps,
  AddCaseHasPalletCardProps,
  AddCaseScanPalletCardProps,
  AddCaseScanPalletSuccessCardProps,
} from "crossdock/modes/transfers/AddCaseToPallet";
import { ScanFlowCardType } from "./ScanFlowCardType";
import { AddCaseToClosedPalletProps } from "crossdock/modes/transfers/AddCaseToPallet/AddCaseToClosedPalletErrorCard";
import { ClosePalletScanPalletCardProps } from "crossdock/modes/transfers/ClosePallet/ClosePalletScanPalletCard/ClosePalletScanPalletCard";
import { ClosePalletScanPalletSuccessCardProps } from "crossdock/modes/transfers/ClosePallet";
import { ClosePalletInvalidStatusCardProps } from "crossdock/modes/transfers/ClosePallet/ClosePalletAlreadyClosedErrorCard";
import { InvalidPalletInputCardProps } from "crossdock/modes/transfers/InvalidPalletInputCard";
import { ReopenPalletScanPalletSuccessCardProps } from "crossdock/modes/transfers/ReopenPallet/ReopenPalletScanPalletSuccessCard";
import { ReopenPalletInvalidStatusCardProps } from "crossdock/modes/transfers/ReopenPallet/ReopenPalletInvalidStatusCard";
import { ReopenPalletScanPalletCardProps } from "crossdock/modes/transfers/ReopenPallet/ReopenPalletScanPalletCard";
import { PalletNoBoxesErrorCardProps } from "crossdock/modes/transfers/common/PalletNoBoxesErrorCard";
import { PalletTrackingScanErrorCardProps } from "crossdock/modes/transfers/common/PalletTrackingScanErrorCard";

export function createFlowCard<P extends Record<string, any>>(type: ScanFlowCardType, sfx?: SoundFx) {
  return (props: P): FlowCardData<P> => {
    return {
      type,
      props,
      ...(sfx ? { sfx } : {}),
    };
  };
}

export const createCaseUnreceivedCard = createFlowCard<CaseUnreceivedCardProps>(
  ScanFlowCardType.CASE_UNRECEIVED,
  SoundFx.ERROR
);

export const createScannerScanCard = createFlowCard<ScannerScanCardProps>(ScanFlowCardType.SCANNER_SCAN);
export const createSortByBoxBreakCard = createFlowCard<SortByBoxBreakCardProps>(
  ScanFlowCardType.SORT_BY_BOX_BREAK,
  SoundFx.SUCCESS
);
export const createSortByWarehouseCard = createFlowCard<SortByWarehouseCardProps>(
  ScanFlowCardType.SORT_BY_WAREHOUSE,
  SoundFx.SUCCESS
);

export const createSortPalletToMilkRunCard = createFlowCard<SortPalletToMilkRunCardProps>(
  ScanFlowCardType.SORT_PALLET_TO_MILK_RUN,
  SoundFx.SUCCESS
);

export const createSortPalletToMilkRunErrorCard = createFlowCard<SortPalletToMilkRunCardErrorProps>(
  ScanFlowCardType.SORT_PALLET_TO_MILK_RUN_ERROR,
  SoundFx.ERROR
);

export const createInvalidPalletInputCard = createFlowCard<InvalidPalletInputCardProps>(
  ScanFlowCardType.INVALID_PALLET_INPUT
);

export const createAddCaseScanCaseCard = createFlowCard<AddCaseScanCaseCardProps>(ScanFlowCardType.ADD_CASE_SCAN_CASE);
export const createAddCaseScanCaseSuccessCard = createFlowCard<AddCaseScanCaseSuccessCardProps>(
  ScanFlowCardType.ADD_CASE_SCAN_CASE_SUCCESS,
  SoundFx.SUCCESS
);
export const createAddCaseHasPalletCard = createFlowCard<AddCaseHasPalletCardProps>(
  ScanFlowCardType.ADD_CASE_HAS_PALLET,
  SoundFx.INFO
);
export const createAddCaseScanPalletCard = createFlowCard<AddCaseScanPalletCardProps>(
  ScanFlowCardType.ADD_CASE_SCAN_PALLET
);
export const createAddCaseScanPalletSuccessCard = createFlowCard<AddCaseScanPalletSuccessCardProps>(
  ScanFlowCardType.ADD_CASE_SCAN_PALLET_SUCCESS,
  SoundFx.SUCCESS
);

export const createAddCaseClosedPalletErrorCard = createFlowCard<AddCaseToClosedPalletProps>(
  ScanFlowCardType.ADD_CASE_CLOSED_PALLET_ERROR,
  SoundFx.ERROR
);

export const createShipPalletScanPalletCard = createFlowCard<ShipPalletScanPalletCardProps>(
  ScanFlowCardType.SHIP_PALLET_SCAN_PALLET
);
export const createShipPalletScanPalletSuccessCard = createFlowCard<ShipPalletScanPalletSuccessCardProps>(
  ScanFlowCardType.SHIP_PALLET_SCAN_PALLET_SUCCESS,
  SoundFx.SUCCESS
);
export const createShipUnbundledPalletScanPalletSuccessCard = createFlowCard<ShipPalletScanPalletSuccessCardProps>(
  ScanFlowCardType.SHIP_PALLET_SCAN_PALLET_SUCCESS,
  SoundFx.INFO
);
export const createShipPalletHasTrackingCard = createFlowCard<ShipPalletHasTrackingCardProps>(
  ScanFlowCardType.SHIP_PALLET_HAS_TRACKING,
  SoundFx.INFO
);
export const createShipPalletScanTrackingCard = createFlowCard<ShipPalletScanTrackingCardProps>(
  ScanFlowCardType.SHIP_PALLET_SCAN_TRACKING
);
export const createShipPalletScanTrackingSuccessCard = createFlowCard<ShipPalletScanTrackingSuccessCardProps>(
  ScanFlowCardType.SHIP_PALLET_SCAN_TRACKING_SUCCESS,
  SoundFx.SUCCESS
);
export const createShipPalletTrackingInUseCard = createFlowCard<ShipPalletScanTrackingInUseCardProps>(
  ScanFlowCardType.SHIP_PALLET_TRACKING_IN_USE,
  SoundFx.ERROR
);

// CLOSE PALLET
export const createClosePalletScanPalletCard = createFlowCard<ClosePalletScanPalletCardProps>(
  ScanFlowCardType.CLOSE_PALLET_SCAN_PALLET
);
export const createClosePalletScanPalletSuccessCard = createFlowCard<ClosePalletScanPalletSuccessCardProps>(
  ScanFlowCardType.CLOSE_PALLET_SCAN_PALLET_SUCCESS,
  SoundFx.SUCCESS
);
export const createClosePalletInvalidStatusCard = createFlowCard<ClosePalletInvalidStatusCardProps>(
  ScanFlowCardType.CLOSE_PALLET_INVALID_STATUS_ERROR,
  SoundFx.ERROR
);
export const createPalletNoBoxesCard = createFlowCard<PalletNoBoxesErrorCardProps>(
  ScanFlowCardType.PALLET_NO_BOXES_ERROR,
  SoundFx.ERROR
);

// REOPEN PALLET
export const createReopenPalletScanPalletCard = createFlowCard<ReopenPalletScanPalletCardProps>(
  ScanFlowCardType.REOPEN_PALLET_SCAN_PALLET
);
export const createReopenPalletScanPalletSuccessCard = createFlowCard<ReopenPalletScanPalletSuccessCardProps>(
  ScanFlowCardType.REOPEN_PALLET_SCAN_PALLET_SUCCESS,
  SoundFx.SUCCESS
);
export const createReopenPalletInvalidStatusCard = createFlowCard<ReopenPalletInvalidStatusCardProps>(
  ScanFlowCardType.REOPEN_PALLET_INVALID_STATUS_ERROR,
  SoundFx.ERROR
);

// PALLET SCAN ERRORS
export const createPalletTrackingScanErrorCard = createFlowCard<PalletTrackingScanErrorCardProps>(
  ScanFlowCardType.PALLET_TRACKING_SCAN_ERROR,
  SoundFx.ERROR
);
