import React from "react";

import { commonUploadMessages } from "@deliverr/ui-facility/lib-facility/labels";
import { createUploadBarcodeCard } from "../BoxAuditCardCreators";
import { BoxAuditUnknownBarcodeUploadCard } from "./BoxAuditUnknownBarcodeUploadCard";
import { BoxAuditUnknownBarcodeNonComplianceField } from "../BoxAuditTypes";

export const BoxAuditUnknownBarcodeBclUploadCard: React.FC = () => (
  <BoxAuditUnknownBarcodeUploadCard
    title={commonUploadMessages.takeAPhotoBclTitle}
    uploads={[
      {
        iconText: commonUploadMessages.takePhoto,
        photoType: "boxContentPicture",
        iconId: "bcl",
        field: BoxAuditUnknownBarcodeNonComplianceField.BOXLABEL_PHOTO,
      },
    ]}
    nextCard={createUploadBarcodeCard({})}
  />
);
