import styled from "@emotion/styled";
import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useBoxBreakLogin } from "./useBoxBreakLogin";
import logo from "crossdock/assets/flexport-logo.png";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { BOX_BREAK_COMMON_LABELS } from "../BoxBreak/BoxBreak.labels";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const FlexportLogo = styled.img`
  height: 1.5rem;
`;

export const BoxBreakLoginCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { submitBoxBreakLogin, onBoxBreakStationChange, boxBreakStationId, errorMessage } = useBoxBreakLogin();

  return (
    <TextInputCard
      accentColor="RED"
      title={formatMessage(BOX_BREAK_COMMON_LABELS.loginCardTitle)}
      message={formatMessage(BOX_BREAK_COMMON_LABELS.loginCardMessage)}
      value={boxBreakStationId}
      onChange={onBoxBreakStationChange}
      placeholder={{ id: "crossdock.boxBreakLoginCard.placeholder", defaultMessage: "Station barcode" }}
      helpText={formatMessage(WarehouseMessages.boxBreakHelpText)}
      topChildren={
        <LogoContainer>
          <FlexportLogo src={logo} alt="Flexport logo" />
        </LogoContainer>
      }
      errorMessage={errorMessage}
      onSubmit={submitBoxBreakLogin}
      centerAlign
      newest={true}
    />
  );
};
