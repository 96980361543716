import { useCrossdockModal } from "crossdock/common/modal";

export function useExitButton(modalId: string) {
  const { showModal } = useCrossdockModal();

  const onClick = () => {
    showModal(modalId, {});
  };

  return { onClick };
}
