import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";

export interface SortByBoxBreakCardProps {
  cdsku: string;
  newest?: boolean;
}

export const SortByBoxBreakCard: React.FC<SortByBoxBreakCardProps> = ({ cdsku, newest }) => (
  <TextInputCard
    title={`Attach new label; sort for Box Breaking`}
    value={cdsku}
    accentColor="BLUE"
    icon="arrow-right"
    newest={newest}
    disabled
  />
);
