import { atom, selector } from "recoil";

import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";
import { createBoxAudit } from "./BoxAuditTypes";
import { AddUnknownBarcodeRequest, Audit } from "@deliverr/crossdock-service-client";

export const scannedBoxAuditState = atom<Audit>({
  key: CrossdockStateKey.BOX_AUDIT_SCANNED_AUDIT,
  default: createBoxAudit(),
});

export const allSkusHaveQtyValue = selector<boolean>({
  key: CrossdockStateKey.BOX_AUDIT_ALL_SKUS_HAVE_QTY,
  get: ({ get }) => {
    const scannedDskus = get(scannedBoxAuditState).items;
    return scannedDskus?.every(({ qty }) => Boolean(qty && qty > 0)) ?? false;
  },
});

export const boxAuditUnknownBarcodeState = atom<AddUnknownBarcodeRequest>({
  key: CrossdockStateKey.BOX_AUDIT_UNKNOWN_BARCODE,
  default: {} as AddUnknownBarcodeRequest,
});

export const boxContentsQtyLoadingState = atom<boolean>({
  key: CrossdockStateKey.BOX_AUDIT_CONTENTS,
  default: false,
});
