import { useCrossdockModal } from "crossdock/common/modal";
import { useResetMissingLabelState } from "../useResetMissingLabelState";

export function useMissingLabelReportManuallyButton() {
  const { hideAllModals } = useCrossdockModal();
  const { returnToProblem } = useResetMissingLabelState();

  const onReportManuallyClick = () => {
    hideAllModals();
    returnToProblem();
  };

  return {
    onReportManuallyClick,
  };
}
