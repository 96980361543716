import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { useRecoilValue } from "recoil";
import { useCrossdockDimsAndWeightSubmitImages } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/submitImagesButton/useCrossdockDimsAndWeightSubmitImages";
import { crossdockDimsAndWeightCubiscanImagesState } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeightState";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";

export const CrossdockDimsAndWeightSubmitImagesButton: React.FC = () => {
  const { onClick } = useCrossdockDimsAndWeightSubmitImages();
  const nonComplianceImagesMetadata = useRecoilValue(crossdockDimsAndWeightCubiscanImagesState);

  const { cubiscanScreen, cubiscanProduct, cubiscanAll } = nonComplianceImagesMetadata;
  const disableButton = !cubiscanScreen || !cubiscanProduct || !cubiscanAll;

  return (
    <Button onClick={onClick} block disabled={disableButton}>
      <FormattedMessage {...DIMS_AND_WEIGHT_LABELS.crossdockDimsAndWeightSubmitImagesButtonTitle} />
    </Button>
  );
};
