import { DateFormat } from "@deliverr/ui-facility/lib-facility/utils/dateUtils";
import { plural } from "@deliverr/ui-facility/lib-facility/utils/stringUtils";
import { format } from "date-fns";
import { useCrossdockModal } from "crossdock/common/modal/useCrossdockModal";
import { usePrintCrossdockLabel } from "../usePrintCrossdockLabel";
import { CaseScannerDuplicateLabelPromptModalProps } from "./CaseScannerDuplicateLabelPromptModal";
import { CaseScannerModal } from "./CaseScannerModal";
import { SortToPalletBuildStationResponse } from "@deliverr/commons-clients";

export function useCaseScannerDuplicateLabelPromptModal({
  printSummary,
  scanResponse,
}: CaseScannerDuplicateLabelPromptModalProps) {
  const { hideModal, showModal } = useCrossdockModal();
  const { printScanCrossdockLabel } = usePrintCrossdockLabel();

  const scannedAt = format(new Date(scanResponse.previousScanResponse!.appliedAt!), DateFormat.DateAtTime);

  let message = `This label was last scanned on ${scannedAt}`;

  // show additional text if the label is being reprinted
  const reprints = printSummary.prints - 1;
  if (reprints > 0) {
    message += `, and its label has been reprinted ${reprints} ${plural("time", reprints)}.`;
  }

  const handleCommonClick = () => {
    hideModal(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_PROMPT);
  };

  const onYesClick = () => {
    handleCommonClick();
    showModal(CaseScannerModal.CASE_SCANNER_DUPLICATE_LABEL_CONFIRMATION, {});
  };

  const onNoClick = async () => {
    handleCommonClick();
    await printScanCrossdockLabel(
      scanResponse.cdsku,
      scanResponse.action,
      (scanResponse as SortToPalletBuildStationResponse).palletBuildStationBarcode,
      printSummary?.prints >= 1
    );
  };

  return {
    message,
    onYesClick,
    onNoClick,
  };
}
