import * as React from "react";
import { ScanPath, ScanPathName } from "./ScanPath";
import { InvalidWarehouseId, MissingWarehouseId } from "@deliverr/ui-facility";
import { CaseScanner } from "crossdock/modes/caseScanner/CaseScanner";
import { PrinterSelect } from "@deliverr/ui-facility/lib-facility/printer/managePrinters";
import { Transfers } from "crossdock/modes/transfers/Transfers";
import { CreatePallet } from "crossdock/modes/transfers/CreatePallet";
import { AddCaseToPallet } from "crossdock/modes/transfers/AddCaseToPallet";
import { ShipPallet } from "crossdock/modes/transfers/ShipPallet";
import { Problem } from "crossdock/modes/problem/Problem";
import { MissingLabel } from "crossdock/modes/problem/MissingLabel/MissingLabel";
import { ClosePallet } from "crossdock/modes/transfers/ClosePallet";
import { ReopenPallet } from "crossdock/modes/transfers/ReopenPallet";
import { BoxBreak, CreateContainers } from "crossdock/modes/break/boxBreakPages";
import { FreightArrival } from "crossdock/modes/transfers/FreightArrival";
import { MessageDescriptor } from "react-intl";
import { ROUTE_LABELS } from "./routes.labels";
import { MissingLabelAddSkuButton } from "crossdock/modes/problem/MissingLabel/MissingLabelAddSkuButton";
import { MissingLabelLeftHeaderIcon } from "crossdock/modes/problem/MissingLabel/MissingLabelLeftHeaderIcon";
import { BoxAuditLeftHeaderIcon } from "crossdock/modes/problem/BoxAudit/BoxAuditLeftHeaderIcon/BoxAuditLeftHeaderIcon";
import { BoxAuditAddSkuButton } from "crossdock/modes/problem/BoxAudit/BoxAuditAddSkuButton";
import { BoxAudit } from "crossdock/modes/problem/BoxAudit";
import { CrossdockDamagedProduct } from "crossdock/modes/problem/CrossdockDamagedProduct";
import { PrintInboundPalletLabel } from "../../modes/transfers/InboundPalletLabels";
import { MovePallet } from "crossdock/modes/transfers/InboundPalletLabels/MovePallet";
import { StartProcessingPallet } from "crossdock/modes/transfers/InboundPalletLabels/StartProcessingPallet";
import { PalletsSummaryHomepage } from "crossdock/modes/pallets/PalletsSummary/PalletsSummaryHomePage";
import { PalletsPage } from "crossdock/modes/pallets/Pallets/PalletsPage";
import { FcDimsAndWeight } from "crossdock/modes/inspection/fc-dims-and-weight/FcDimsAndWeight";
import { CrossdockDimsAndWeight } from "crossdock/modes/inspection/crossdock-dims-and-weight/CrossdockDimsAndWeight";

export interface RouteConfig {
  name?: string;
  nameDescriptor?: MessageDescriptor;
  path: ScanPath;
  page: React.ReactNode;
  previousPage?: ScanPath;
  leftHeaderAction?: React.ReactNode;
  rightHeaderAction?: React.ReactNode;
}

export const routes: RouteConfig[] = [
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.Scanner],
    path: ScanPath.Scanner,
    page: <CaseScanner />,
  },
  /* login redirects to /scan, so we add a duplicate entry for that path */
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.Scanner2],
    path: ScanPath.Scanner2,
    page: <CaseScanner />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.Transfers],
    path: ScanPath.Transfers,
    page: <Transfers />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.Problem],
    path: ScanPath.Problem,
    page: <Problem />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.TransfersCreatePallet],
    path: ScanPath.TransfersCreatePallet,
    page: <CreatePallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.TransfersAddCase],
    path: ScanPath.TransfersAddCase,
    page: <AddCaseToPallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.TransfersShipPallet],
    path: ScanPath.TransfersShipPallet,
    page: <ShipPallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.TransfersClosePallet],
    path: ScanPath.TransfersClosePallet,
    page: <ClosePallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.TransfersReopenPallet],
    path: ScanPath.TransfersReopenPallet,
    page: <ReopenPallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.ManagePrinters],
    path: ScanPath.ManagePrinters,
    page: <PrinterSelect />,
  },
  {
    path: ScanPath.InvalidWarehouseId,
    page: <InvalidWarehouseId />,
  },
  {
    path: ScanPath.MissingWarehouseId,
    page: <MissingWarehouseId />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPathName.MISSING_LABEL_FREIGHT],
    path: ScanPath.MissingLabel,
    page: <MissingLabel />,
    leftHeaderAction: <MissingLabelLeftHeaderIcon />,
    rightHeaderAction: <MissingLabelAddSkuButton />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPathName.MISSING_LABEL_SPD],
    path: ScanPath.MissingLabel,
    page: <MissingLabel />,
    leftHeaderAction: <MissingLabelLeftHeaderIcon />,
    rightHeaderAction: <MissingLabelAddSkuButton />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.DuplicateLabel],
    path: ScanPath.DuplicateLabel,
    page: <MissingLabel />, // shares same component with missing label
    rightHeaderAction: <MissingLabelAddSkuButton />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.BoxAudit],
    path: ScanPath.BoxAudit,
    page: <BoxAudit />,
    leftHeaderAction: <BoxAuditLeftHeaderIcon />,
    rightHeaderAction: <BoxAuditAddSkuButton />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.DamagedUnits],
    path: ScanPath.DamagedUnits,
    page: <CrossdockDamagedProduct />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.BoxBreakCreateContainers],
    path: ScanPath.BoxBreakCreateContainers,
    page: <CreateContainers />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.BoxBreak],
    path: ScanPath.BoxBreak,
    page: <BoxBreak />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.PalletSummary],
    path: ScanPath.PalletSummary,
    page: <PalletsSummaryHomepage />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.Pallets],
    path: ScanPath.Pallets,
    page: <PalletsPage />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.FreightArrival],
    path: ScanPath.FreightArrival,
    page: <FreightArrival />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.SmallParcelArrival],
    path: ScanPath.SmallParcelArrival,
    page: <FreightArrival />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.PrintInboundPalletLabel],
    path: ScanPath.PrintInboundPalletLabel,
    page: <PrintInboundPalletLabel />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.MovePallet],
    path: ScanPath.MovePallet,
    page: <MovePallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.StartProcessingPallet],
    path: ScanPath.StartProcessingPallet,
    page: <StartProcessingPallet />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.FcDimsAndWeight],
    path: ScanPath.FcDimsAndWeight,
    page: <FcDimsAndWeight />,
  },
  {
    nameDescriptor: ROUTE_LABELS[ScanPath.CrossdockDimsAndWeight],
    path: ScanPath.CrossdockDimsAndWeight,
    page: <CrossdockDimsAndWeight />,
  },
];
