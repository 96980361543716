import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { useMount, useUnmount } from "react-use";
import { useIntl } from "react-intl";

import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { scannedBoxAuditState } from "../BoxAuditState";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxAuditModal } from "../BoxAuditModal";
import { usePrintCrossdockLabel } from "crossdock/modes/caseScanner/usePrintCrossdockLabel";
import { InvalidDataError } from "crossdock/common/errors";
import { AuditError } from "../BoxAuditTypes";
import { headerTitleState } from "crossdock/base/headerTitleState";
import { crossdockClient } from "crossdock/base/Clients";
import { clientIdState } from "crossdock/base/clientIdState";
import { useState } from "react";
import { ScanResponse } from "@deliverr/commons-clients/lib/crossdock/Scan";
import { Audit } from "@deliverr/crossdock-service-client";

export function useBoxAuditConfirmAudit() {
  const setHeaderTitle = useSetRecoilState(headerTitleState);
  const resetHeaderTitle = useResetRecoilState(headerTitleState);
  const clientId = useRecoilValue(clientIdState);
  const { formatMessage } = useIntl();
  const { handleUnknownError } = useScanFlow();
  const boxAudit = useRecoilValue(scannedBoxAuditState);
  const { showModal } = useCrossdockModal();
  const { printScanCrossdockLabel } = usePrintCrossdockLabel();
  const [loading, setLoading] = useState<boolean>(false);

  useMount(() => {
    setHeaderTitle(
      formatMessage({
        id: "crossdock.BoxAuditReviewSkusCard.title",
        defaultMessage: "Confirm SKUs",
      })
    );
  });

  useUnmount(() => {
    resetHeaderTitle();
  });

  const getScanResponse = async (): Promise<ScanResponse> => {
    let scanResponse;
    try {
      scanResponse = await crossdockClient.scan({
        clientId,
        barcode: boxAudit.cdsku,
        warehouseId: boxAudit.crossdockId,
      });
    } catch (err) {
      if (err.code && err.code === "ALREADY_PRESENT_IN_WAREHOUSE") {
        return err.payload.appliedComputedScanResponse;
      } else {
        throw err;
      }
    }
    return scanResponse;
  };

  const printAuditedLabel = async (): Promise<void> => {
    const ctx = logStart({ fn: "printAuditedLabel", cdsku: boxAudit.cdsku, status: boxAudit.status });

    if (!boxAudit?.items || boxAudit.items.length === 0) {
      handleUnknownError(ctx, new InvalidDataError("Missing audit box items"));
      return;
    }

    const scanResponse = await getScanResponse();

    await printScanCrossdockLabel(boxAudit.cdsku, scanResponse.action);
  };

  const onButtonClick = async () => {
    const ctx = logStart({
      fn: "submitBoxAudit",
      audit: boxAudit,
    });

    try {
      setLoading(true);

      // complete audit
      const auditResponse: Audit = await crossdockClient.completeAudit(boxAudit.id);

      if (auditResponse.varianceNoted) {
        log(ctx, "variance noted, show print label modal");
        await printAuditedLabel();
        showModal(BoxAuditModal.BOX_AUDIT_PRINT_LABEL, {});
      } else {
        log(ctx, "no variance noted, show accurate label modal");
        showModal(BoxAuditModal.BOX_AUDIT_ACCURATE_LABEL, {});
      }
    } catch (err) {
      log({ ...ctx, err }, "error completing audit");
      showModal(BoxAuditModal.BOX_AUDIT_ERROR, { reason: AuditError.COMPLETING });
      handleUnknownError(ctx, err);
    }
    setLoading(false);
  };

  return { onButtonClick, printAuditedLabel, loading };
}
