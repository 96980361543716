import React from "react";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards/TextInputCard/TextInputCard";
import { TextInputCardProps } from "@deliverr/ui-facility/lib-facility/flow/cards/TextInputCard/TextInputCardProps";
import styled from "@emotion/styled";

const Container = styled.div`
  section {
    height: max-content;
  }
`;

// temp solution to get around card height
// https://app.asana.com/0/1158655394777794/1201218439972675
export const ContentTextInputCard: React.FC<TextInputCardProps> = (props) => (
  <Container>
    <TextInputCard {...props} />
  </Container>
);
