import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useResetRecoilState } from "recoil";
import { cdskuState, palletIdState } from "../AddCaseToPalletState";
import { createAddCaseScanCaseCard } from "crossdock/common/flow/scanFlowCardCreators";

export function useUndoAddCasePalletButton() {
  const { addFlowCard, resetFlowCards, hideAllFlowButtons } = useScanFlow();
  const resetCdsku = useResetRecoilState(cdskuState);
  const resetPalletId = useResetRecoilState(palletIdState);

  function onUndoAddCasePalletButton() {
    resetFlowCards();
    resetCdsku();
    resetPalletId();
    addFlowCard(createAddCaseScanCaseCard({}));
    hideAllFlowButtons();
  }

  return {
    onUndoAddCasePalletButton,
  };
}
