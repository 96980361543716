import { useRecoilState, useSetRecoilState } from "recoil";
import { toast } from "@deliverr/ui";
import { useIntl } from "react-intl";

import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { isValidUnitBarcode, log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxAuditModal } from "./BoxAuditModal";
import { findMatchingAuditItem, findMatchingAuditItemIndex, formatAuditUpdateRequest } from "./utils";
import { useBoxAuditSubtitle } from "./useBoxAuditSubtitle";
import { AuditError } from "./BoxAuditTypes";
import { boxAuditUnknownBarcodeState, boxContentsQtyLoadingState, scannedBoxAuditState } from "./BoxAuditState";
import { BoxAuditErrorModalProps } from "./BoxAuditErrorModal";
import { createUploadBclCard } from "./BoxAuditCardCreators";
import { useShowAddSku } from "../hooks";
import { Audit, CrossdockErrors } from "@deliverr/crossdock-service-client";
import { crossdockClient } from "crossdock/base/Clients";

export const setDskuQty = async (audit: Audit, barcode: string, qty?: number): Promise<Audit> => {
  const matchingItem = audit.items ? findMatchingAuditItem(barcode, audit.items) : undefined;
  const matchingItemIndex = audit?.items?.length ? findMatchingAuditItemIndex(barcode, audit?.items) : -1;
  const updatedQty = qty ?? (matchingItem?.qty ?? 0) + 1;
  const formattedBoxAuditItems = formatAuditUpdateRequest(audit?.items ?? []);

  if (!matchingItem) {
    // add new audit item for new scans, not qty adjustments or repeat scans
    formattedBoxAuditItems.push({ barcode, qty: updatedQty });
  } else {
    // adjust audit qty for manual qty adjustments and repeat scans, not new scans
    formattedBoxAuditItems[matchingItemIndex] = { barcode: matchingItem.barcode, qty: updatedQty };
  }
  return await crossdockClient.updateAudit({ auditId: audit.id, items: formattedBoxAuditItems });
};

export function useScanBoxAuditSku() {
  const { formatMessage } = useIntl();
  const { hideAddSku } = useShowAddSku();
  const { addFlowCard } = useScanFlow();
  const { hideFlowButton, showFlowButton, successResponse, errorResponse, infoResponse } = useScanFlow();
  const { showModal } = useCrossdockModal();
  const { setBoxAuditCountsSubtitle } = useBoxAuditSubtitle();
  const [audit, updateBoxAudit] = useRecoilState(scannedBoxAuditState);
  const [boxAuditUnknownBarcode, setBoxAuditUnknownBarcode] = useRecoilState(boxAuditUnknownBarcodeState);
  const setBoxContentsQtyLoadingState = useSetRecoilState(boxContentsQtyLoadingState);

  const onBoxContentsQtyChange = async (barcode: string, newQty?: number): Promise<void> => {
    const ctx = logStart({ fn: "onBoxContentsQtyChange", barcode, newQty });

    setBoxContentsQtyLoadingState(true);

    if (barcode.length === 0 || !isValidUnitBarcode(barcode)) {
      errorResponse();
      toast.error(
        formatMessage({
          id: "crossdock.boxAuditBoxContents.error",
          defaultMessage: "Please scan a valid SKU barcode",
        }),
        {
          autoClose: 5000,
          toastId: "boxAuditBoxContentsScanError",
        }
      );
      return;
    }

    try {
      const scanResponse: Audit = await setDskuQty(audit, barcode, newQty);
      if (!newQty) {
        // don't want this to happen for qty adjustments
        successResponse();
      }
      updateBoxAudit(scanResponse);
      setBoxAuditCountsSubtitle(scanResponse);
      showFlowButton(ScanFlowButtonType.BOX_AUDIT_SCANNING_COMPLETE);
    } catch (err) {
      const errorProps: BoxAuditErrorModalProps = {};
      if (err?.code === CrossdockErrors.BARCODE_NOT_FOUND) {
        log({ ...ctx, err }, "unknown barcode");
        infoResponse();
        setBoxAuditUnknownBarcode({
          ...boxAuditUnknownBarcode,
          barcode,
          auditId: audit.id,
        });
        hideFlowButton(ScanFlowButtonType.BOX_AUDIT_SCANNING_COMPLETE);
        addFlowCard(createUploadBclCard({}));
        hideAddSku();
        setBoxContentsQtyLoadingState(false);
        return;
      }
      if (err.code === CrossdockErrors.SELLER_ID_CONFLICT) {
        errorProps.title = "Seller ID Conflict";
      } else {
        errorProps.reason = AuditError.UPDATING;
      }
      log({ ...ctx, err }, "error updating audit");
      showModal(BoxAuditModal.BOX_AUDIT_ERROR, errorProps);
      errorResponse();
    }
    setBoxContentsQtyLoadingState(false);
  };
  return { onBoxContentsQtyChange };
}
