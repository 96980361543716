import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { TextButton } from "@deliverr/ui-facility/lib-facility/components/TextButton";
import labels from "./MovePallet.labels";
import { useMovePalletScanStagingLane } from "./useMovePalletScanStagingLane";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export const MovePalletScanStagingLaneCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { inputErrorMessage, stagingLaneBarcode, loading, onChange, onSubmit, onClick } =
    useMovePalletScanStagingLane();

  return (
    <TextInputCard
      accentColor="BLUE"
      icon="scan"
      title={formatMessage(labels.scanStagingLaneTitle)}
      placeholder={formatMessage(crossdockLabels.stagingLanePlaceholder)}
      errorMessage={inputErrorMessage}
      value={stagingLaneBarcode}
      disabled={loading}
      onChange={onChange}
      onSubmit={onSubmit}
    >
      <TextButton onClick={onClick} disabled={loading}>
        {formatMessage(crossdockLabels.cancelButton)}
      </TextButton>
    </TextInputCard>
  );
};
