import { useRecoilValue } from "recoil";
import { useState } from "react";
import { isNaN } from "lodash/fp";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { inboundClient } from "crossdock/base/Clients";
import { usePrintCrossdockLabel } from "crossdock/modes/caseScanner/usePrintCrossdockLabel";
import { useConnectToPrinterWarning } from "crossdock/common/zebra";
import { log, logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { getContainersPrintedNotification } from "crossdock/modes/break/boxBreakNotifications";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";
import { useInputErrorMessage } from "crossdock/common/useInputErrorMessage";
import { useIntl } from "react-intl";
import crossdockLabels from "crossdock/common/CrossdockLabels";

export function useCreateContainersAmount() {
  const { inputErrorMessage, resetErrorOnExecution, inputError } = useInputErrorMessage();
  const [containersAmount, setContainersAmount] = useState<string>("");
  const { handleUnknownError, successResponse, addAutoCloseNotification } = useScanFlow();
  const { warehouseId: crossDockWarehouseId } = useRecoilValue(userState);
  const resetContainersAmounts = () => {
    setContainersAmount("");
  };
  const { printBoxBreakContainerLabel } = usePrintCrossdockLabel();
  const { verifyAndShowPrinterConnected } = useConnectToPrinterWarning(true);
  const { formatMessage } = useIntl();

  const submitCreateContainers = async (newContainersAmountStr: string): Promise<void> => {
    const ctx = logStart({ fn: "submitCreateContainers", amount: newContainersAmountStr });

    if (!verifyAndShowPrinterConnected()) {
      return;
    }

    const newContainersAmount = parseInt(newContainersAmountStr, 10);
    if (isNaN(newContainersAmount)) {
      log(ctx, "container amount not a number");
      inputError(formatMessage(crossdockLabels.notANumberError), resetContainersAmounts);
      return;
    } else if (newContainersAmount <= 0) {
      log(ctx, "negative container amount");
      inputError(formatMessage(crossdockLabels.negativeNumberError), resetContainersAmounts);
      return;
    }

    try {
      const packages = await inboundClient.createEmptyBoxBreakOutputPackages(newContainersAmount, crossDockWarehouseId);
      log(ctx, "empty containers created");

      await Promise.all(
        packages.map(async ({ cdsku }) => {
          await printBoxBreakContainerLabel(cdsku!);
        })
      );

      successResponse();
      addAutoCloseNotification(getContainersPrintedNotification(newContainersAmount));
      resetContainersAmounts();
    } catch (err) {
      handleUnknownError(ctx, err, resetContainersAmounts);
    }
  };

  return {
    submitCreateContainers: resetErrorOnExecution(submitCreateContainers),
    onContainerAmountChange: setContainersAmount,
    containersAmount,
    errorMessage: inputErrorMessage,
  };
}
