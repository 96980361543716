import { InboundPackageItemData, UnitTransfer } from "@deliverr/commons-clients";
import { mapValues, reduce } from "lodash";
import { BoxBreakDistributionsByDsku } from "../types/BoxBreakDistributionsByDsku";

export const formatExpectedBoxBreakDistributions = (
  boxContents: InboundPackageItemData[],
  unitTransfers: UnitTransfer[]
): BoxBreakDistributionsByDsku =>
  reduce(
    boxContents,
    (result, boxContentItem) => ({
      ...result,
      [boxContentItem.dsku]: {
        scannedUnits: reduce(
          unitTransfers,
          (total, transferItem) => (total += transferItem.dsku === boxContentItem.dsku ? transferItem.qty : 0),
          0
        ),
        totalUnits: boxContentItem.qty,
        unexpected: false,
      },
    }),
    {}
  );

export const formatUnexpectedBoxBreakDistributions = (
  unexpectedSkusDistributions,
  unitTransfers: UnitTransfer[]
): BoxBreakDistributionsByDsku =>
  mapValues(unexpectedSkusDistributions, (distribution, item) => ({
    scannedUnits: reduce(
      unitTransfers,
      (total, transferItem) => (total += transferItem.dsku === item ? transferItem.qty : 0),
      0
    ),
    totalUnits: reduce(distribution.targetDistribution, (total: number, current: number) => (total += current)),
    unexpected: true,
  }));
