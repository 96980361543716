import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Stack } from "@deliverr/ui";
import { FixedWidthButton, ModalTemplate } from "@deliverr/ui-facility";
import { infoModalIcon } from "@deliverr/ui-facility/lib-facility/common/modal/ModalIcons";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";

interface BoxBreakEndModalProps {
  numberOfPendingItems: number;
  modalCancelClick: () => void;
  modalEndClick: () => void;
}

const buttonWidth = "128px";

export const DimsAndWeightCompleteSessionModal: React.FC<BoxBreakEndModalProps> = ({
  numberOfPendingItems,
  modalCancelClick,
  modalEndClick,
}) => {
  const { formatMessage } = useIntl();
  const modalText =
    numberOfPendingItems === 1
      ? formatMessage(DIMS_AND_WEIGHT_LABELS.singularDimsAndWeightCompleteSessionModalText)
      : formatMessage(DIMS_AND_WEIGHT_LABELS.pluralDimsAndWeightCompleteSessionModalText, { numberOfPendingItems });
  return (
    <ModalTemplate
      title={formatMessage(DIMS_AND_WEIGHT_LABELS.dimsAndWeightCompleteSessionModalTitle)}
      text={modalText}
      iconProps={infoModalIcon}
    >
      <Stack direction="HORIZONTAL" gap="S5">
        <FixedWidthButton width={buttonWidth} onClick={modalCancelClick} secondary>
          <FormattedMessage id="crossdock.DimsAndWeightCompleteSessionModal.cancelButton" defaultMessage="Cancel" />
        </FixedWidthButton>
        <FixedWidthButton width={buttonWidth} onClick={modalEndClick}>
          <FormattedMessage
            id="crossdock.DimsAndWeightCompleteSessionModal.confirmButton"
            defaultMessage="Yes, end the session!"
          />
        </FixedWidthButton>
      </Stack>
    </ModalTemplate>
  );
};
