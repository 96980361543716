import { useIntl, defineMessages } from "react-intl";
import { useSetRecoilState } from "recoil";

import { headerSubtitleState } from "crossdock/base/headerTitleState";

const subtitleMessages = defineMessages({
  skuSubtitle: { id: "crossdock.problem.skuSubtitle", defaultMessage: "{skusCount} SKU" },
  skusSubtitle: { id: "crossdock.problem.skusSubtitle", defaultMessage: "{skusCount} SKUs" },
  unitSubtitle: { id: "crossdock.problem.unitSubtitle", defaultMessage: "{unitsCount} unit" },
  unitsSubtitle: { id: "crossdock.problem.unitsSubtitle", defaultMessage: "{unitsCount} units" },
});

const getProblemSkusSubtitlePluralProps = (skusCount: number) =>
  skusCount === 1 ? subtitleMessages.skuSubtitle : subtitleMessages.skusSubtitle;

const getProblemUnitsSubtitlePluralProps = (unitsCount: number) =>
  unitsCount === 1 ? subtitleMessages.unitSubtitle : subtitleMessages.unitsSubtitle;

export function useProblemSubtitle() {
  const { formatMessage } = useIntl();
  const setHeaderSubtitle = useSetRecoilState(headerSubtitleState);

  const setProblemSubtitle = (mainSubtitle): void => {
    setHeaderSubtitle(mainSubtitle);
  };

  // react-intl does not make getting plural i18 strings easy
  const setCountsSubtitle = ({ mainSubtitle, skusCount, unitsCount }: any): void => {
    const subtitle = [
      ...(mainSubtitle ? [mainSubtitle, " - "] : []),
      formatMessage(getProblemSkusSubtitlePluralProps(skusCount), { skusCount }),
      " ",
      formatMessage(getProblemUnitsSubtitlePluralProps(unitsCount), { unitsCount }),
    ].join("");
    setHeaderSubtitle(subtitle);
  };

  return {
    setProblemSubtitle,
    setCountsSubtitle,
  };
}
