import React from "react";
import { useIntl } from "react-intl";
import { SCAN_BOX_LABELS as labels } from "./BoxBreakScanBoxCard.labels";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useBoxBreakScanBoxCard } from "./useBoxBreakScanBoxCard";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";

export const BoxBreakScanBoxCard: React.FC = () => {
  const { boxBarcode, onBoxBarcodeChange, submitBoxBarcode, errorMessage } = useBoxBreakScanBoxCard();
  const { formatMessage } = useIntl();
  return (
    <TextInputCard
      title={formatMessage(WarehouseMessages.boxTitleText)}
      message={formatMessage(labels.message)}
      value={boxBarcode}
      placeholder={formatMessage(WarehouseMessages.boxIdentifierPlaceholder)}
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
      icon="scan"
      onChange={onBoxBarcodeChange}
      accentColor="BLUE"
      errorMessage={errorMessage}
      onSubmit={submitBoxBarcode}
      newest={true}
    />
  );
};
