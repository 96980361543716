import React from "react";
import { Stack, Title, Text, DefaultTheme } from "@deliverr/ui";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import ScanSKU from "@deliverr/ui-facility/lib-facility/common/assets/scanSKU.svg";

const PaddedStack = styled(Stack)<Record<string, unknown>, DefaultTheme>(
  ({ theme }) => `
  padding: ${theme.spacing.S5};
  margin-top: ${theme.spacing.S8};
`
);

const CenterText = styled(Text)`
  text-align: center;
`;

export const BoxAuditEmptyContents: React.FC = () => (
  <PaddedStack center gap="S4">
    <img src={ScanSKU} alt="scan sku" />
    <Title>
      <FormattedMessage id="crossdock.boxAuditContentsEmptyContents.title" defaultMessage="Scan a SKU" />
    </Title>
    <CenterText>
      <FormattedMessage
        id="crossdock.boxAuditContentsEmptyContents.body1"
        defaultMessage="or manually add a SKU barcode"
      />
    </CenterText>
    <CenterText appearance="INFO">
      <FormattedMessage
        id="crossdock.boxAuditContentsEmptyContents.body2"
        defaultMessage="Take inventory of all the units inside the box to create a new label"
      />
    </CenterText>
  </PaddedStack>
);
