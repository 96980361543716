import styled from "@emotion/styled";
import { Card, Stack, Title } from "@deliverr/ui";
import React from "react";
import { FulfillmentCenterSelect } from "crossdock/modes/transfers/CreatePallet/FulfillmentCenterSelect";
import { PrintPalletLabels } from "./PrintPalletLabels";
import { FACILITY_THEME } from "@deliverr/ui-facility/lib-facility/theme";
import { FormattedMessage } from "react-intl";
import { useCreatePallet } from "./useCreatePallet";

const VerticalPaddingCard = styled(Card)`
  padding: ${FACILITY_THEME.spacing.S4} 0;
  overflow-y: scroll;
  height: 88vh;
  padding-bottom: 0;
`;

const CreatePalletText = styled.p`
  margin-top: 0;
`;

export const CreatePallet: React.FC = () => {
  const { palletBuildStationBarcodes, selectedDestinationsArray } = useCreatePallet();

  return (
    <VerticalPaddingCard as="section">
      <Stack center>
        <Title as="h2" displayAs="h3">
          <FormattedMessage id="crossdock.createPallet.title" defaultMessage="Select Fulfillment Centers" />
        </Title>
        <CreatePalletText>
          <FormattedMessage id="crossdock.createPallet.message" defaultMessage="Maximum 100 labels per FC" />
        </CreatePalletText>
        <FulfillmentCenterSelect
          title="Pallet Build Stations"
          destinations={palletBuildStationBarcodes}
          selectedDestinations={selectedDestinationsArray}
          arePalletBuildStations={true}
        />
        <PrintPalletLabels />
      </Stack>
    </VerticalPaddingCard>
  );
};
