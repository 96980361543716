import { useRecoilValue, useSetRecoilState } from "recoil";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useMount } from "react-use";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards/TextInputCard";
import { useCaseScanner } from "crossdock/modes/caseScanner/useCaseScanner";
import { scanInputDisabledState } from "crossdock/modes/caseScanner/caseScanState";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { headerTitleState } from "crossdock/base/headerTitleState";
import { userState } from "@deliverr/ui-facility/lib-facility/base/Auth/userState";

export interface ScannerScanCardProps {
  disabled?: boolean;
  newest?: boolean;
}

export const ScannerScanCard: React.FC<ScannerScanCardProps> = ({ disabled, newest }) => {
  const { formatMessage } = useIntl();
  const { isCrossdock } = useRecoilValue(userState);
  const { scanInput, handleChange, handleSubmit } = useCaseScanner();
  const scanInputDisabled = useRecoilValue(scanInputDisabledState);
  const setHeaderTitle = useSetRecoilState(headerTitleState);

  useMount(() => {
    if (!isCrossdock) {
      setHeaderTitle(formatMessage({ id: "warehouse.scannerScanCard.headerTitle", defaultMessage: "Pallet Scanner" }));
    }
  });

  return (
    <TextInputCard
      value={scanInput}
      newest={newest}
      title={formatMessage(isCrossdock ? WarehouseMessages.boxOrPalletTitleText : WarehouseMessages.palletTitleText)}
      message={
        <FormattedMessage
          id="crossdock.scannerScanCard.message"
          defaultMessage={
            isCrossdock
              ? "Scanning a box prints a label with sorting directions. Scanning a pallet results in sorting directions."
              : "Scanning a pallet results in sorting directions."
          }
        />
      }
      icon="scan"
      accentColor="BLUE"
      onChange={handleChange}
      onSubmit={handleSubmit}
      placeholder={formatMessage(
        isCrossdock ? WarehouseMessages.palletsFreightPalletBoxMixHelpText : WarehouseMessages.palletHelpText
      )}
      disabled={scanInputDisabled || disabled}
    />
  );
};
