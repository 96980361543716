import styled from "@emotion/styled";
import { DefaultTheme } from "@deliverr/ui";

export const LeftSideBarContainer = styled.section<{ isDisplay: boolean }, DefaultTheme>(
  ({ isDisplay, theme }) => `
  position: absolute;
  left: ${isDisplay ? 0 : "-320px"};
  top: -.65rem;
  transition: left .5s ease-in-out;
  min-width: 320px;
  padding: ${theme.spacing.S5};
  background:  ${theme.colors.NEUTRAL["00"]}};
  overflow-y: scroll;
  height: 100%;
  margin-left: -.65rem;
  `
);

export const RightSidebarContainer = styled.aside<{ isDisplay: boolean }, DefaultTheme>(
  ({ isDisplay, theme }) => `
  position: absolute;
  right: ${isDisplay ? 0 : "-320px"};
  top: -.65rem;
  transition: right .5s ease-in-out;
  min-width: 320px;
  padding: ${theme.spacing.S5};
  background:  ${theme.colors.NEUTRAL["00"]}};
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  `
);
