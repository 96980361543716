import * as React from "react";
import styled from "@emotion/styled";
import { ButtonIcon, DefaultTheme, Text } from "@deliverr/ui";
import { ThemeProps } from "@deliverr/ui/lib-esm/shared";
import { FormattedMessage } from "react-intl";
import { SpacedIcon } from "crossdock/common/components";

const BoxContentsItemContainer = styled.div<{ activeDsku: boolean; dimsTaken?: boolean }, DefaultTheme>(
  ({ theme, activeDsku, dimsTaken }) => `
  width: 100%;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors.NEUTRAL[60]};
  border-radius: ${theme.border.radius.R5};
  background-color: ${activeDsku ? theme.colors.BLUE["400"] : theme.colors.NEUTRAL["60"]};
  padding: ${theme.spacing.S4};
  margin-top: ${theme.spacing.S4};
  align-items: center;
`
);

const BoxContentsText = styled(Text)<{ activeDsku: boolean }, DefaultTheme>`
  ${({ activeDsku, theme }) =>
    `width: 300px; overflow: hidden; text-overflow: ellipsis;white-space: nowrap;${activeDsku ? `color: ${theme.colors.NEUTRAL["00"]}` : ""}`};
`;

const FlexContainer = styled.div<ThemeProps>`
  margin: 0 0 ${({ theme }) => theme.spacing.S2} 0;
  display: flex;
  justify-content: space-between;
`;

const OverageOrUnexpectedSkuContainer = styled.div<ThemeProps>(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  border: ${theme.border.width.B1} ${theme.border.type} ${theme.colors[theme.config.colorByAppearance.PRIMARY]["50"]};
  background-color: ${theme.colors[theme.config.colorByAppearance.PRIMARY]["50"]};
  margin: 0 -${theme.spacing.S4} -${theme.spacing.S4};
  padding: ${theme.spacing.S1} 0;
  color: ${theme.colors.NEUTRAL["300"]};
  font-size: ${theme.font.size.F2};
`
);

interface BoxBreakBoxContentsItemProps {
  dsku?: string;
  productName?: string;
  barcode?: string;
  isActive: boolean;
  unexpected?: boolean;
  dimsTaken?: boolean;
}

export const DimsAndWeightBoxContentsItem: React.FC<BoxBreakBoxContentsItemProps> = ({
  dsku,
  productName,
  barcode,
  isActive,
  dimsTaken,
  unexpected = false,
}) => {
  const title = dsku ?? barcode;
  const skuInfo = !dsku ? "Unknown barcode" : unexpected ? "Unexpected SKU" : undefined;
  return (
    <BoxContentsItemContainer dimsTaken={dimsTaken} activeDsku={isActive}>
      {title ? (
        <FlexContainer>
          <BoxContentsText activeDsku={isActive} appearance="INFO" size="label">
            {title}
          </BoxContentsText>
          {dimsTaken ? <ButtonIcon iconType={"check"} aria-label={""} /> : null}
        </FlexContainer>
      ) : null}
      {
        <FlexContainer>
          <BoxContentsText activeDsku={isActive} bold size="bodyLarge">
            {productName}
          </BoxContentsText>
        </FlexContainer>
      }
      {!dsku || unexpected ? (
        <OverageOrUnexpectedSkuContainer>
          <SpacedIcon type="info-circle" />
          <FormattedMessage id="crossdock.boxBreaksBoxContentsItem.unexpectedSku" defaultMessage={skuInfo} />
        </OverageOrUnexpectedSkuContainer>
      ) : null}
    </BoxContentsItemContainer>
  );
};
