import React from "react";
import { useIntl } from "react-intl";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { useFcDimsAndWeightProductDims } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ScanProductDimsCard/useFcDimsAndWeightProductDims";
import { ProductDetailsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/ProductDetailsCard/ProductDetailsCard";
import { TakeProductDimsCard } from "crossdock/modes/inspection/fc-dims-and-weight/cards/TakeProductDimsCard/TakeProductDimsCard";
import { ContentTextInputCard } from "crossdock/common/flow/cards/ContentTextInputCard";

export const FcDimsAndWeightProductDimsCard: React.FC = () => {
  const { productInfo, productDimsQr, onProductDimsChange, submitProductDims, loading, errorMessage } =
    useFcDimsAndWeightProductDims();

  const { formatMessage } = useIntl();

  return (
    <>
      <ProductDetailsCard product={productInfo} />
      <TakeProductDimsCard />
      <ContentTextInputCard
        onSubmit={submitProductDims}
        title={formatMessage(DIMS_AND_WEIGHT_LABELS.scanProductDimsCardTitle)}
        onChange={onProductDimsChange}
        value={productDimsQr}
        disabled={loading}
        errorMessage={errorMessage}
        placeholder={formatMessage(DIMS_AND_WEIGHT_LABELS.productDimsQR)}
        icon="scan"
        accentColor="BLUE"
      />
    </>
  );
};
