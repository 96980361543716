import { Button } from "@deliverr/ui";
import React from "react";
import { useShipPalletButton } from "./useShipPalletButton";

export const UndoShipPalletButton: React.FC = () => {
  const { onShipPalletButtonClick } = useShipPalletButton();

  return (
    <Button secondary onClick={onShipPalletButtonClick}>
      Cancel
    </Button>
  );
};
