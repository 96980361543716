import React from "react";
import { FormattedMessage } from "react-intl";
import { Stack } from "@deliverr/ui";

import { FixedWidthButton, ModalTemplate, dangerModalIcon } from "@deliverr/ui-facility";
import { useUnexpectedSkuModal } from "./useUnexpectedSkuModal";

export interface UnexpectedSkuModalProps {
  ReportManuallyButton: React.ComponentType;
}

export const UnexpectedSkuModal: React.FC<UnexpectedSkuModalProps> = ({ ReportManuallyButton }) => {
  const { onContinueClick } = useUnexpectedSkuModal();
  return (
    <ModalTemplate
      title={<FormattedMessage id="crossdock.unexpectedSkuModal.title" defaultMessage="Unknown Barcode" />}
      text={
        <FormattedMessage
          id="crossdock.unexpectedSkuModal.text"
          defaultMessage="This is an unknown input. If this is accurate, report this barcode as a non-compliant barcode. Otherwise, continue scanning."
        />
      }
      iconProps={dangerModalIcon}
    >
      <Stack direction="HORIZONTAL" gap="S2">
        <ReportManuallyButton />
        <FixedWidthButton width="134px" onClick={onContinueClick}>
          <FormattedMessage id="crossdock.unexpectedSkuModal.continueBtn" defaultMessage="Continue" />
        </FixedWidthButton>
      </Stack>
    </ModalTemplate>
  );
};
