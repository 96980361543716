import React from "react";
import styled from "@emotion/styled";

import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useFreightArrivalScanPallet } from "./useFreightArrivalScanPallet";
import { Button, ThemeProps } from "@deliverr/ui";

const ResizedTextInputCard = styled(TextInputCard)`
  min-height: 138px !important;
  max-height: none !important;
  height: auto !important;
`;

const StyledBorderlessButton = styled(Button)<ThemeProps>(
  ({ theme }) => `
  border: none;
  background: none;
  margin-bottom: 0;
  padding: 0;
  text-align: left;
  height: ${theme.spacing.S6};

  :hover {
    border: none;
  }
`
);

export const FreightArrivalScanPalletCard: React.FC = () => {
  const {
    palletLabelId,
    onPalletLabelIdChange,
    loading,
    disabled,
    onSubmit,
    handlePalletLabelMissing,
    title,
    placeholder,
    labelMissingButton,
    errorMessage,
  } = useFreightArrivalScanPallet();

  return (
    <ResizedTextInputCard
      icon="scan"
      onSubmit={onSubmit}
      accentColor="BLUE"
      title={title}
      placeholder={placeholder}
      errorMessage={errorMessage}
      value={palletLabelId}
      onChange={onPalletLabelIdChange}
      disabled={disabled}
      loading={loading}
      newest
    >
      {!loading && (
        <StyledBorderlessButton onClick={handlePalletLabelMissing} secondary disabled={loading}>
          {labelMissingButton}
        </StyledBorderlessButton>
      )}
    </ResizedTextInputCard>
  );
};
