import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@deliverr/ui";
import { useCrossdockDimsAndWeightEndSession } from "crossdock/modes/inspection/crossdock-dims-and-weight/buttons/DimsAndWeightEndSessionButton/useCrossdockDimsAndWeightEndSession";

export const CrossdockDimsAndWeightEndSessionButton: React.FC = () => {
  const { onButtonClick } = useCrossdockDimsAndWeightEndSession();

  return (
    <Button onClick={onButtonClick} block>
      <FormattedMessage id="crossdock.crossdockDimsAndWeightEndSessionButton.title" defaultMessage="End Session" />
    </Button>
  );
};
