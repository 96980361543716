import { useLogPalletArrival } from "../../useLogPalletArrival";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { logStart } from "@deliverr/ui-facility";
import { useCrossdockModal } from "crossdock/common/modal";
import { inboundPalletLabelIdState } from "../../FreightArrivalState";
import { useResetRecoilState } from "recoil";

export function useFreightArrivalDuplicateInboundPalletLabelModal(inboundPalletLabelBarcode: string) {
  const logPalletArrival = useLogPalletArrival();
  const { handleUnknownError } = useScanFlow();
  const { hideAllModals } = useCrossdockModal();
  const resetInboundPalletLabelBarcode = useResetRecoilState(inboundPalletLabelIdState);

  const onConfirmClick = async () => {
    const ctx = logStart({
      fn: "useFreightArrivalDuplicateInboundPalletLabelModal.onConfirmClick",
      inboundPalletLabelBarcode,
    });

    try {
      await logPalletArrival(inboundPalletLabelBarcode, true);
      hideAllModals();
    } catch (err) {
      handleUnknownError(ctx, err);
    }
  };

  const onCancelClick = () => {
    resetInboundPalletLabelBarcode();
    hideAllModals();
  };

  return { onConfirmClick, onCancelClick };
}
