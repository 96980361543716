import { useRecoilValue } from "recoil";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { createRemoveContainerCard } from "../../../../boxBreakCardCreators";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { selectedSidebarChuteValue } from "../../boxBreakSidebarState";

export function useBoxBreakRemoveContainer() {
  const { addFlowCard } = useScanFlow();
  const selectedSidebarChute = useRecoilValue(selectedSidebarChuteValue);

  return () => {
    logStart({ fn: "onRemoveContainerClick" });
    addFlowCard(createRemoveContainerCard({ chuteToRemoveFrom: selectedSidebarChute }));
  };
}
