import { useRecoilValue } from "recoil";
import { useScanFlow } from "crossdock/common/flow/useScanFlow";
import { useCrossdockModal } from "crossdock/common/modal";
import { BoxBreakModal } from "../BoxBreakModal";
import { useResetCurrentBreakState } from "../../hooks/useResetCurrentBreakState";
import { currentBoxBarcodeState } from "../../boxBreakState";
import { logStart } from "@deliverr/ui-facility/lib-facility/utils";
import { createBoxBreakScanBoxCard } from "../../../boxBreakCardCreators";
import { useUpdateBoxBreakHeader } from "../../../useUpdateBoxBreakHeader";
import { getBoxEndedNotification } from "crossdock/modes/break/boxBreakNotifications";
import { ScanFlowButtonType } from "crossdock/common/flow/ScanFlowButtonType";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx";

export function useBoxBreakEnd() {
  const { hideModal } = useCrossdockModal();
  const { resetCurrentBreak } = useResetCurrentBreakState();
  const { transition, showFlowButton } = useScanFlow();
  const currentBoxBarcode = useRecoilValue(currentBoxBarcodeState);
  const { setBoxBreakHeader } = useUpdateBoxBreakHeader();

  const onCancelClick = () => {
    logStart({ fn: "BoxBreakEnd.onCancelClick" });
    hideModal(BoxBreakModal.BOX_BREAK_END_MODAL);
  };

  const onEndClick = async () => {
    logStart({ fn: "BoxBreakEnd.onEndClick" });
    hideModal(BoxBreakModal.BOX_BREAK_END_MODAL);

    transition([
      {
        card: createBoxBreakScanBoxCard({}),
        notification: getBoxEndedNotification(currentBoxBarcode),
        onTransition: () => {
          showFlowButton(ScanFlowButtonType.DAMAGED_PRODUCT_DONE);
        },
        sfx: SoundFx.INFO,
      },
    ]);

    resetCurrentBreak();
    setBoxBreakHeader();
  };

  return {
    onCancelClick,
    onEndClick,
    currentBoxBarcode,
  };
}
