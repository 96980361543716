import React from "react";
import { Button } from "@deliverr/ui";
import { ModalTemplate, warningModalIcon } from "@deliverr/ui-facility";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx/SoundFx";
import { useCaseScannerDuplicateLabelPalletModal } from "./useCaseScannerDuplicateLabelPalletModal";
import { SortToWarehouseResponse } from "@deliverr/commons-clients/lib/crossdock/Scan";

export interface CaseScannerDuplicateLabelPalletModalProps {
  scanResponse: SortToWarehouseResponse;
  itemCreatedAt?: Date;
  palletLabelId?: string;
}
export const CaseScannerDuplicateLabelPalletModal: React.FC<CaseScannerDuplicateLabelPalletModalProps> = (props) => {
  const { palletLabelId, itemCreatedAt } = props;
  const { onConfirmClick, scannedAt } = useCaseScannerDuplicateLabelPalletModal(itemCreatedAt);

  return (
    <ModalTemplate
      title="Duplicate box label!"
      text={
        (scannedAt ? `This box was scanned on to pallet ${palletLabelId} on ${scannedAt}. ` : "") +
        `Move this box to the Problem Area.`
      }
      iconProps={warningModalIcon}
      soundFx={SoundFx.ERROR}
    >
      <Button block onClick={onConfirmClick}>
        Done
      </Button>
    </ModalTemplate>
  );
};
