import { defineMessages, MessageDescriptor } from "react-intl";
import { LoadingDockScanEventApiErrorCode } from "@deliverr/freight-client";

// These error codes are not constants within freight-client.
enum LocalFreightLoadingDockErrorCodes {
  NOT_FOUND = "NOT_FOUND",
  LEGACY_NOT_FOUND = "FreightPallet not found",
}

export const LOADING_DOCK_SCAN_LABELS: Record<string, MessageDescriptor> = defineMessages({
  defaultPalletTransferInstructionTitle: {
    id: "loadingDockScanEventErrorMessage.defaultPalletTransferInstructionTitle",
    defaultMessage: "To outbound staging",
  },
  palletTransferInstructionTitle: {
    id: "loadingDockScanEventErrorMessage.palletTransferInstructionTitle",
    defaultMessage: "To outbound staging {nextLocation}",
  },
  defaultPalletTransferInstruction: {
    id: "loadingDockScanEventErrorMessage.defaultPalletTransferInstruction",
    defaultMessage: "Move pallet into the truck.",
  },
  palletTransferInstruction: {
    id: "loadingDockScanEventErrorMessage.palletTransferInstruction",
    defaultMessage: "Move pallet into the truck going to {nextLocation}.",
  },
});

export const LOADING_DOCK_SCAN_ERROR_TITLES: Record<string, MessageDescriptor> = defineMessages({
  [LoadingDockScanEventApiErrorCode.DUPLICATE_SCAN]: {
    id: "loadingDockScanEventError.title.duplicateScan",
    defaultMessage: "Duplicate scan",
  },
  [LoadingDockScanEventApiErrorCode.SCAN_LOCATION_NOT_IN_JOURNEY]: {
    id: "loadingDockScanEventError.title.notInJourney",
    defaultMessage: "Pallet shouldn't be at this warehouse",
  },
  [LoadingDockScanEventApiErrorCode.UNABLE_TO_LOAD_SHIPMENT_DETAILS_FOR_PALLET]: {
    id: "loadingDockScanEventError.title.cannotLoadDetails",
    defaultMessage: "Can't load pallet details",
  },
  [LocalFreightLoadingDockErrorCodes.NOT_FOUND]: {
    id: "loadingDockScanEventError.title.palletNotFound",
    defaultMessage: "Pallet not found",
  },
  [LocalFreightLoadingDockErrorCodes.LEGACY_NOT_FOUND]: {
    id: "loadingDockScanEventError.title.palletNotFound",
    defaultMessage: "Pallet not found",
  },
});

export const LOADING_DOCK_SCAN_ERROR_LABELS: Record<string, MessageDescriptor> = defineMessages({
  [LoadingDockScanEventApiErrorCode.DUPLICATE_SCAN]: {
    id: "loadingDockScanEventError.message.duplicateScan",
    defaultMessage: "This pallet has recently received the same type of scan at this location.",
  },
  [LoadingDockScanEventApiErrorCode.SCAN_LOCATION_NOT_IN_JOURNEY]: {
    id: "loadingDockScanEventError.message.notInJourney",
    defaultMessage: "This location is not on the pallet's list of destinations.",
  },
  [LoadingDockScanEventApiErrorCode.UNABLE_TO_LOAD_SHIPMENT_DETAILS_FOR_PALLET]: {
    id: "loadingDockScanEventError.message.cannotLoadDetails",
    defaultMessage: "Details about the journey of this pallet cannot be fetched at this time.",
  },
  [LocalFreightLoadingDockErrorCodes.NOT_FOUND]: {
    id: "loadingDockScanEventError.message.palletNotFound",
    defaultMessage: "The pallet scanned was not found.",
  },
  [LocalFreightLoadingDockErrorCodes.LEGACY_NOT_FOUND]: {
    id: "loadingDockScanEventError.message.palletNotFound",
    defaultMessage: "The pallet scanned was not found.",
  },
});
