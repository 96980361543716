import { keyBy } from "lodash/fp";
import { atom, selector } from "recoil";
import { CrossdockStateKey } from "../../../base/CrossdockStateKey";
import { ChuteByBarcode } from "./ChuteByBarcode";
import { ChuteByWarehouse } from "./ChuteByWarehouse";
import { ChuteBoxByBarcode } from "./ChuteBoxByBarcode";
import { Chute } from "@deliverr/commons-clients";
import { ChuteBoxByCdsku } from "./ChuteBoxByCdsku";

export const boxBreakStationIdState = atom<string>({
  key: CrossdockStateKey.BOX_BREAK_STATION_ID,
  default: "",
});

// chute barcode -> Chute
export const chuteByBarcodeState = atom<ChuteByBarcode>({
  key: CrossdockStateKey.BOX_BREAK_CHUTE_BY_BARCODE,
  default: {},
});

/**
 * destination warehouse id -> Chute
 * This used to be derived from chute data alone, however with dynamic box breaks (2023/06)
 * we can't do this anymore due to the Chute.transferToWarehouseId field being deprecated.
 */
export const chuteByWarehouseState = selector<ChuteByWarehouse>({
  key: CrossdockStateKey.BOX_BREAK_CHUTE_BY_WAREHOUSE,
  get: ({ get }) => {
    const chutes = Object.values(get(chuteByBarcodeState));
    const chuteBoxes = Object.values(get(chuteBoxByBarcodeState));

    const chuteByWarehouse: ChuteByWarehouse = {};
    for (const { transferToWarehouseId, chuteId } of chuteBoxes) {
      if (transferToWarehouseId) {
        chuteByWarehouse[transferToWarehouseId] = chutes.find((chute) => chute.barcode === chuteId)!;
      }
    }
    return chuteByWarehouse;
  },
});

// chute barcode -> ChuteBox (container linked to chute)
export const chuteBoxByBarcodeState = atom<ChuteBoxByBarcode>({
  key: CrossdockStateKey.BOX_BREAK_CHUTE_BOX_BY_BARCODE,
  default: {},
});

// chute box cdsku -> ChuteBox
export const chuteBoxByCdskuState = selector<ChuteBoxByCdsku>({
  key: CrossdockStateKey.BOX_BREAK_CHUTE_BOX_BY_CDSKU,
  get: ({ get }) => keyBy("cdsku", Object.values(get(chuteBoxByBarcodeState))),
});

// chute that does not have a container which we are currently linking
export const emptyChuteState = atom<Chute | undefined>({
  key: CrossdockStateKey.BOX_BREAK_EMPTY_CHUTE,
  default: undefined,
});
