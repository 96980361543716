import { atom, selector } from "recoil";
import { CrossdockStateKey } from "crossdock/base/CrossdockStateKey";

export const MIN_PALLETS_PER_WAREHOUSE = 0;
export const MAX_PALLETS_PER_WAREHOUSE = 100;

export interface SelectedDestinationsState {
  warehouseIds: Set<string>;
  palletBuildStationBarcodes: Set<string>;
}

export interface TransfersDestinationsState {
  palletBuildStationBarcodes: string[];
}

export const transfersDestinationsState = atom<TransfersDestinationsState>({
  key: CrossdockStateKey.TRANSFERS_DESTINATIONS,
  default: {
    palletBuildStationBarcodes: [],
  },
});

export const createLabelLoadingState = atom<boolean>({
  key: CrossdockStateKey.TRANSFERS_CREATE_PALLET_LOADING,
  default: false,
});

export const selectedDestinationsState = atom<SelectedDestinationsState>({
  key: CrossdockStateKey.SELECTED_TRANSFERS_DESTINATIONS,
  default: { warehouseIds: new Set(), palletBuildStationBarcodes: new Set() },
});

export const selectedDestinationsArrayState = selector<string[]>({
  key: CrossdockStateKey.SELECTED_TRANSFERS_DESTINATIONS_ARRAY,
  get: ({ get }) => {
    const { warehouseIds, palletBuildStationBarcodes } = get(selectedDestinationsState);
    return [...new Set([...warehouseIds, ...palletBuildStationBarcodes])];
  },
});

export const selectedWarehouseIdsState = selector<Set<string>>({
  key: CrossdockStateKey.SELECTED_TRANSFER_WAREHOUSE_IDS,
  get: ({ get }) => get(selectedDestinationsState).warehouseIds,
  set: ({ set, get }, newSet) => {
    const sets = get(selectedDestinationsState);
    set(selectedDestinationsState, { ...sets, warehouseIds: newSet as Set<string> });
  },
});

export const selectedPalletBuildStationBarcodesState = selector<Set<string>>({
  key: CrossdockStateKey.SELECTED_TRANSFER_PALLET_BUILD_STATION_BARCODES,
  get: ({ get }) => get(selectedDestinationsState).palletBuildStationBarcodes,
  set: ({ set, get }, newSet) => {
    const sets = get(selectedDestinationsState);
    set(selectedDestinationsState, { ...sets, palletBuildStationBarcodes: newSet as Set<string> });
  },
});

export const palletLabelsPerWarehouseState = atom<string>({
  key: CrossdockStateKey.PALLET_LABELS_PER_WAREHOUSE,
  default: "1",
});
