import { DefaultTheme, Table, TableProps } from "@deliverr/ui";
import styled from "@emotion/styled";

export const PalletsTable = styled(Table)<TableProps, DefaultTheme>(
  ({ theme }) => `
  margin-top: ${theme.spacing.S3};

  th, td {
    text-align: center;
  }

  thead tr {
    background-color: ${theme.colors.BLUE[600]};
  }
  thead th {
    color: ${theme.colors.NEUTRAL["00"]};
    padding: ${theme.spacing.S6} ${theme.spacing.S4};
  }
  tbody tr:nth-of-type(odd) {
    background-color: ${theme.colors.NEUTRAL["00"]};
  }
  tbody tr:nth-of-type(even) {
    background-color: ${theme.colors.BLUE[50]};
  }
  tbody td {
    padding: ${theme.spacing.S4};
  }
`
);
