import { BoxLabelIncidentDsku } from "@deliverr/commons-clients";
import { useRecoilValue } from "recoil";
import { sortedIncidentDskusValue } from "../MissingLabelState";
import { useScanMissingLabelSku } from "../useScanMissingLabelSku";

export const mapIncidentItemsToBoxContents = (incidentDskus: BoxLabelIncidentDsku[]) => {
  return incidentDskus.map(({ barcode, dsku, qty }) => ({
    barcode: barcode ?? "",
    description: dsku, // dsku for now until we include product name in incident response
    qty: qty ?? 0,
  }));
};

export function useBoxContents() {
  const sortedIncidentDskus = useRecoilValue(sortedIncidentDskusValue);
  const { onBoxContentsQtyChange } = useScanMissingLabelSku();

  return {
    boxContents: mapIncidentItemsToBoxContents(sortedIncidentDskus ?? []),
    onBoxContentsQtyChange,
  };
}
