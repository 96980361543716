import { useIntl, defineMessages } from "react-intl";
import { sumBy } from "lodash/fp";

import { useProblemSubtitle } from "../hooks";
import { Audit } from "@deliverr/crossdock-service-client";

const boxAuditSubtitleMessages = defineMessages({
  boxSubtitle: {
    id: "crossdock.boxAudit.boxSubtitle",
    defaultMessage: "Box {box}",
  },
});

export function useBoxAuditSubtitle() {
  const { setProblemSubtitle, setCountsSubtitle } = useProblemSubtitle();
  const { formatMessage } = useIntl();

  const setBoxAuditSubtitle = (box: string): void => {
    setProblemSubtitle(formatMessage(boxAuditSubtitleMessages.boxSubtitle, { box }));
  };

  // react-intl does not make getting plural i18 strings easy
  const setBoxAuditCountsSubtitle = (audit: Audit): void => {
    const box = audit.cdsku;
    const skusCount = (audit.items ?? []).length;
    const unitsCount = sumBy("qty", audit.items ?? []);

    setCountsSubtitle({
      mainSubtitle: formatMessage(boxAuditSubtitleMessages.boxSubtitle, { box }),
      skusCount,
      unitsCount,
    });
  };

  return {
    setBoxAuditSubtitle,
    setBoxAuditCountsSubtitle,
  };
}
