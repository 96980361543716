import React from "react";
import { SoundFx } from "@deliverr/ui-facility/lib-facility/common/sfx/SoundFx";
import { PromptModal, warningModalIcon } from "@deliverr/ui-facility";
import { ScanResponse } from "@deliverr/commons-clients/lib/crossdock/Scan";
import { CrossDockLabelPrintSummary } from "@deliverr/legacy-inbound-client";
import { useCaseScannerDuplicateLabelPromptModal } from "./useCaseScannerDuplicateLabelPromptModal";

export interface CaseScannerDuplicateLabelPromptModalProps {
  scanResponse: ScanResponse;
  printSummary: CrossDockLabelPrintSummary;
}

export const CaseScannerDuplicateLabelPromptModal: React.FC<CaseScannerDuplicateLabelPromptModalProps> = (props) => {
  const { onYesClick, onNoClick, message } = useCaseScannerDuplicateLabelPromptModal(props);

  return (
    <PromptModal
      title="Is this a duplicate label?"
      text={message}
      iconProps={warningModalIcon}
      soundFx={SoundFx.ERROR}
      onCancelClick={onNoClick}
      onConfirmClick={onYesClick}
      cancelButtonLabel="No"
      confirmButtonLabel="Yes"
    />
  );
};
