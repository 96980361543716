import React from "react";
import { TransitionCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { DIMS_AND_WEIGHT_LABELS } from "crossdock/modes/inspection/labels";
import { IconToIcon } from "crossdock/common/components";
import { useIntl } from "react-intl";

export const TakeProductDimsCard: React.FC = () => {
  const { formatMessage } = useIntl();
  return (
    <TransitionCard title={formatMessage(DIMS_AND_WEIGHT_LABELS.takeDimsCardTitle)}>
      <IconToIcon
        leftIconProps={{
          iconType: "shopping-bag",
          accentColor: "BLUE",
        }}
        rightIconProps={{
          iconType: "scan",
          accentColor: "BLUE",
        }}
      />
    </TransitionCard>
  );
};
