import React from "react";
import { useIntl } from "react-intl";
import { TextInputCard } from "@deliverr/ui-facility/lib-facility/flow/cards";
import { useLinkContainerScanContainer } from "./useLinkContainerScanContainer";
import { WarehouseMessages } from "@deliverr/ui-facility/lib-facility/utils";
import { LINK_CONTAINER_LABELS as lcLabels } from "../LinkContainer.labels";
import { BOX_BREAK_COMMON_LABELS as bbLabels } from "../../BoxBreak/BoxBreak.labels";

export const LinkContainerScanContainerCard: React.FC = () => {
  const { formatMessage } = useIntl();
  const { containerBarcode, onContainerBarcodeChange, submitLinkContainerScanContainer, errorMessage } =
    useLinkContainerScanContainer();

  return (
    <TextInputCard
      title={formatMessage(lcLabels.titleScanContainer)}
      message={formatMessage(lcLabels.messageScanContainer)}
      value={containerBarcode}
      placeholder={formatMessage(bbLabels.placeholderContainer)}
      helpText={formatMessage(WarehouseMessages.boxHelpText)}
      icon="scan"
      onChange={onContainerBarcodeChange}
      accentColor="BLUE"
      errorMessage={errorMessage}
      onSubmit={submitLinkContainerScanContainer}
      newest={true}
    />
  );
};
